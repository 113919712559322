import React, { Fragment, useContext, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { useMutation, useReactiveVar } from '@apollo/client';
import { CREATE_SUBMISSION_MUTATION } from '../../../operations/submission';
import { isLoadingVar, loggedInMeVar } from '../../../cache';
import SubmissionContext from '../../contexts/SubmissionContext';
import { Submission } from '../../../models/submissions';
import { Survey } from '../../../models/surveys';
import Grid from '@mui/material/Grid';
import AlertTitle from '@mui/material/AlertTitle';
import Alert from '@mui/material/Alert';
import { removeAuth } from '../../../operations/auth';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Translation } from '../../../models/translation';

export const HAS_COMPLETED_SURVEY = 'HAS_COMPLETED_SURVEY_';

export const set_surveyCompletion_locally = (targetGroupId: string) => {
  if (!targetGroupId) return;

  localStorage.setItem(`${HAS_COMPLETED_SURVEY}${targetGroupId}'`, 'true');
};

export const get_surveyCompletion_locally = (targetGroupId?: string) => {
  if (!targetGroupId) return false;

  const has_completed = localStorage.getItem(`${HAS_COMPLETED_SURVEY}${targetGroupId}'`);
  return has_completed === 'true';
};

const createSubmissionDataForMutation = (
  surveyId: string,
  targetGroupId: string,
  submission: Submission
) => {
  return {
    survey: surveyId,
    targetGroup: targetGroupId,
    data: submission.answers?.edges.map((answer) => {
      const answerData = {
        question: answer.node?.question?.id,
      } as any;

      if (answer.node?.choices) {
        answerData.choices = answer.node?.choices;
      }

      if (answer.node?.comment) {
        answerData.comment = answer.node?.comment;
      }

      return answerData;
    }),
  };
};

interface Props {
  survey: Survey;
  targetGroupId: string;
  translation: Translation;
}

export default function SurveyParticipationFinishComponent(props: Props) {
  const { survey, targetGroupId, translation } = props;
  const loggedInMe = useReactiveVar(loggedInMeVar);

  const submission = useContext(SubmissionContext);

  const [submissionState, setSubmissionState] = useState<string | null>(null);
  const [createSubmissionMutation] = useMutation(CREATE_SUBMISSION_MUTATION, {
    onCompleted() {
      if (!submission.submitted) {
        submission.submitted = true;
      }
      setSubmissionState('success');
      if (submission.answers) {
        submission.answers.edges = [];
      }
      if (!loggedInMe) {
        removeAuth(false);
      }
      // Save locally so that the user does not participate twice
      set_surveyCompletion_locally(targetGroupId);
    },
    onError(error) {
      console.error(error);
      setSubmissionState('error');
    },
  });

  useEffect(() => {
    if (submissionState) {
      return;
    }

    const createSubmission = async () => {
      setSubmissionState('saving');
      isLoadingVar(true);

      try {
        await createSubmissionMutation({
          variables: {
            input: createSubmissionDataForMutation(survey.id ?? '', targetGroupId, submission),
          },
        });
      } catch (e) {
        setSubmissionState('error');
      }

      isLoadingVar(false);
    };

    createSubmission();
  }, [submissionState, loggedInMe, submission, survey, targetGroupId, createSubmissionMutation]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {submissionState === 'saving' && (
          <Fragment>
            <Typography paragraph>{translation.answers_being_submitted}</Typography>
            <Typography paragraph>{translation.please_wait}</Typography>
          </Fragment>
        )}
        {submissionState === 'success' && (
          <Alert severity="success">
            <AlertTitle>{translation.thank_you}</AlertTitle>
            {translation.answers_successfully_submitted}
          </Alert>
        )}
        {submissionState === 'error' && (
          <Fragment>
            <Alert severity="error">
              <AlertTitle>{translation.data_transmission_failed}</AlertTitle>
              {translation.wait_to_resubmit}
            </Alert>
            <Box mt={3}>
              <Button
                type="button"
                variant="contained"
                size="large"
                color="primary"
                onClick={() => setSubmissionState(null)}
              >
                {translation.resubmit_answers}
              </Button>
            </Box>
          </Fragment>
        )}
      </Grid>
    </Grid>
  );
}

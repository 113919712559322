import { Command } from '@ckeditor/ckeditor5-core';

export default class InsertDocumentLinkCommand extends Command {
  execute({ dataDocumentId, dataDocumentTitle }) {
    this.editor.model.change((writer) => {
      const documentLink = writer.createElement('documentLink', {
        'data-document-link': dataDocumentId,
        'data-document-title': dataDocumentTitle,
      });
      this.editor.model.insertContent(documentLink);
    });
  }

  refresh() {
    const model = this.editor.model;
    const selection = model.document.selection;
    const allowedIn = model.schema.findAllowedParent(selection.getFirstPosition(), 'documentLink');

    this.isEnabled = allowedIn !== null;
  }
}

import React, { Fragment, useState } from 'react';
import { Formik, Form, Field, FormikValues, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-mui';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import useGlobalStyles from '../../hooks/useGlobalStyles';
import { ConfirmDialog, FormActions, ConfirmNavigation, FormikContextDirty } from '../common';
import SaveIcon from '@mui/icons-material/SaveRounded';
import CancelIcon from '@mui/icons-material/HighlightOffRounded';
import { useReactiveVar } from '@apollo/client';
import { isFormDirtyVar } from '../../cache';

interface Props {
  formData?: any;
  submitHandler: (values: FormikValues, formikBag: FormikHelpers<any>) => void;
  formDisabled?: boolean;
  children?: React.ReactNode;
}

const FacilityFormComponent: React.FC<Props> = (props) => {
  const { formData, submitHandler, formDisabled } = props;
  const { classes: globalClasses } = useGlobalStyles();
  let navigate = useNavigate();

  const isFormDirty = useReactiveVar(isFormDirtyVar);
  const [resetConfirmOpen, setResetConfirmOpen] = useState<boolean>(false);

  return (
    <Fragment>
      <ConfirmNavigation shouldBlock={isFormDirty} />
      <Formik
        initialValues={{
          name: formData?.name ?? '',
          director: formData?.director ?? '',
          street: formData?.street ?? '',
          houseNumber: formData?.houseNumber ?? '',
          zipCode: formData?.zipCode ?? '',
          city: formData?.city ?? '',
          phone: formData?.phone ?? '',
          fax: formData?.fax ?? '',
          email: formData?.email ?? '',
          tenantId: formData?.tenant?.id ?? null,
          tenantName: formData?.tenant?.name ?? null,
        }}
        enableReinitialize
        validationSchema={Yup.object({
          name: Yup.string().required('Pflichtfeld'),
          street: Yup.string().required('Pflichtfeld'),
          houseNumber: Yup.string().required('Pflichtfeld'),
          zipCode: Yup.string().required('Pflichtfeld'),
          city: Yup.string().required('Pflichtfeld'),
          email: Yup.string().email('Keine E-Mail Adresse'),
        })}
        onSubmit={(values, formikBag) => {
          isFormDirtyVar(false);
          submitHandler(values, formikBag);
        }}
      >
        {(props) => {
          return (
            <Form autoComplete="off" style={{ width: '100%' }}>
              <FormikContextDirty />
              {props.status && (
                <Box mb={2}>
                  <Alert severity="error">{props.status}</Alert>
                </Box>
              )}
              <Paper component="section" variant="outlined" className={globalClasses.paper}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <FormLabel htmlFor="firstName">Name</FormLabel>
                          <Field
                            component={TextField}
                            type="text"
                            name="name"
                            id="name"
                            variant="outlined"
                            fullWidth
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <FormLabel htmlFor="lastName">
                            Leitung
                            <span className="labelInfo">optional</span>
                          </FormLabel>
                          <Field
                            component={TextField}
                            type="text"
                            name="director"
                            id="director"
                            variant="outlined"
                            fullWidth
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={9}>
                        <FormControl fullWidth>
                          <FormLabel htmlFor="street">Straße</FormLabel>
                          <Field
                            component={TextField}
                            type="text"
                            name="street"
                            id="street"
                            variant="outlined"
                            fullWidth
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={3}>
                        <FormControl fullWidth>
                          <FormLabel htmlFor="houseNumber">Nr.</FormLabel>
                          <Field
                            component={TextField}
                            type="text"
                            name="houseNumber"
                            id="houseNumber"
                            variant="outlined"
                            fullWidth
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={3}>
                        <FormControl fullWidth>
                          <FormLabel htmlFor="zipCode">PLZ</FormLabel>
                          <Field
                            component={TextField}
                            type="text"
                            name="zipCode"
                            id="zipCode"
                            variant="outlined"
                            fullWidth
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={9}>
                        <FormControl fullWidth>
                          <FormLabel htmlFor="city">Ort</FormLabel>
                          <Field
                            component={TextField}
                            type="text"
                            name="city"
                            id="city"
                            variant="outlined"
                            fullWidth
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <FormLabel htmlFor="phone">
                            Telefon
                            <span className="labelInfo">optional</span>
                          </FormLabel>
                          <Field
                            component={TextField}
                            type="tel"
                            name="phone"
                            id="phone"
                            variant="outlined"
                            fullWidth
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <FormLabel htmlFor="fax">
                            Fax
                            <span className="labelInfo">optional</span>
                          </FormLabel>
                          <Field
                            component={TextField}
                            type="tel"
                            name="fax"
                            id="fax"
                            variant="outlined"
                            fullWidth
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <FormLabel htmlFor="email">
                            E-Mail Adresse
                            <span className="labelInfo">optional</span>
                          </FormLabel>
                          <Field
                            component={TextField}
                            type="email"
                            name="email"
                            id="email"
                            variant="outlined"
                            fullWidth
                          />
                        </FormControl>
                      </Grid>
                      {props.values.tenantName && (
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <FormLabel htmlFor="email">
                              Träger
                              <span className="labelInfo">keine Änderung möglich</span>
                            </FormLabel>
                            <Field
                              component={TextField}
                              type="text"
                              name="tenantName"
                              id="tenantName"
                              variant="outlined"
                              fullWidth
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </FormControl>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
              <FormActions>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  startIcon={<SaveIcon />}
                  disabled={props.isSubmitting || formDisabled}
                >
                  {formData ? 'Änderungen speichern' : 'Speichern'}
                </Button>
                <Button
                  type="button"
                  variant="outlined"
                  size="large"
                  color="primary"
                  startIcon={<CancelIcon />}
                  onClick={() => {
                    if (isFormDirty) {
                      setResetConfirmOpen(true);
                    } else {
                      navigate(-1);
                    }
                  }}
                >
                  Abbrechen
                </Button>
              </FormActions>
              <ConfirmDialog
                open={resetConfirmOpen}
                title={`Änderungen verwerfen`}
                content={`Wollen Sie die vorgenommenen Änderungen im Formular verwerfen?`}
                onClose={(confirm) => {
                  setResetConfirmOpen(false);
                  if (confirm) {
                    props.handleReset();
                    isFormDirtyVar(false);
                    navigate(-1);
                  }
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </Fragment>
  );
};

export default FacilityFormComponent;

import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { FetchResult, gql, useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {
  CHAPTERS_QUERY,
  UPDATE_CHAPTER_MUTATION,
  DELETE_CHAPTER_MUTATION,
  CREATE_CHAPTER_MUTATION,
  FRAGMENT_CHAPTER_BASE,
} from '../../operations/chapter';
import { useSnackbar } from 'notistack';
import {
  createDataTree,
  encodeIriToUrlParam,
  getChaptersFlatSortedArray,
  parseUuidFromId,
} from '../../utils/helper';
import AddIcon from '@mui/icons-material/AddCircleRounded';
import useGlobalStyles from '../../hooks/useGlobalStyles';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import { default as ManualChapterLevel } from './ManualChapterLevel.component';
import { Chapter, ChapterNode, TreeChapter } from '../../models/chapters';
import { default as ManualChapterMoveDialog } from './ManualChapterMoveDialog.component';
import { default as ManualChapterNewAndCopyDialog } from './ManualChapterNewAndCopyDialog.component';
import { FormikHelpers, FormikValues } from 'formik';
import { isLoadingVar, loggedInMeVar } from '../../cache';
import { routes } from '../../models/routes';
import { useNavigate } from 'react-router-dom';
import { permissionComponentKeys } from '../../models/permissions';
import { Manual } from '../../models/manuals';
import useLoggedInMePermissions from '../../hooks/useLoggedInMePermissions';
import { ManualChapterRemoveDialog } from './index';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMoreRounded';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLessRounded';
import ButtonGroup from '@mui/material/ButtonGroup';
import { FileUploadDialog } from '../common';
import { MediaObject } from '../../models/mediaObject';
import useDeleteMediaObject from '../../hooks/useDeleteMediaObject';
import useManualImport from '../../hooks/manuals/useManualImport';

const insert = (chapterNodes: ChapterNode[], parentId: string | null, targetIndex: number) => {
  const chaptersFlatSorted = getChaptersFlatSortedArray(chapterNodes);
  const siblings = chaptersFlatSorted.filter((chapter: Chapter) => chapter.parentId === parentId);
  siblings.splice(targetIndex, 0, null);
  return siblings;
};

const rearrange = (
  chapterNodes: ChapterNode[],
  chapterId: string,
  parentId: string | null,
  targetIndex: number
) => {
  const chaptersFlatSorted = getChaptersFlatSortedArray(chapterNodes);
  let currentSiblings = [];
  let targetSiblings = [];
  const chapterToMove = chaptersFlatSorted.find((chapter: Chapter) => chapter.id === chapterId);
  const currentParentId = chapterToMove.parentId ?? null;

  currentSiblings = chaptersFlatSorted.filter(
    (chapter: Chapter) => chapter.parentId === currentParentId
  );
  const currentIndex = currentSiblings.findIndex((chapter) => chapter.id === chapterId);
  const [removed] = currentSiblings.splice(currentIndex, 1);

  if (currentParentId === parentId) {
    currentSiblings.splice(targetIndex, 0, removed);
  } else {
    targetSiblings = chaptersFlatSorted.filter((chapter: Chapter) => chapter.parentId === parentId);
    targetSiblings.splice(targetIndex, 0, removed);
  }

  return { currentSiblings, targetSiblings };
};

const remove = (chapterNodes: ChapterNode[], chapterId: string) => {
  const chaptersFlatSorted = getChaptersFlatSortedArray(chapterNodes);
  const chapter2Remove = chaptersFlatSorted.find((chapter: Chapter) => chapter.id === chapterId);
  const currentParentId = chapter2Remove.parentId ?? null;
  const currentSiblings = chaptersFlatSorted.filter(
    (chapter: Chapter) => chapter.parentId === currentParentId
  );
  const currentIndex = currentSiblings.findIndex((chapter) => chapter.id === chapterId);
  currentSiblings.splice(currentIndex, 1);
  return currentSiblings;
};

interface InputDataCreate {
  customChapterNumber?: string;
  title: string;
  content: string;
  manual: string;
  parent?: string;
  position: number;
  version: number;
}

function useChapterCreateHandler() {
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [createChapterMutation] = useMutation(CREATE_CHAPTER_MUTATION, {
    onCompleted({ createChapter }) {
      if (createChapter?.chapter?.id) {
        navigate(
          routes['CHAPTER_EDIT'].path
            .replace(':manualId', encodeIriToUrlParam(createChapter.chapter.manual.id))
            .replace(':chapterId', encodeIriToUrlParam(createChapter.chapter.id))
        );
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten', {
          variant: 'warning',
        });
      }
    },
    onError(error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
    update(cache, { data: { createChapter } }) {
      cache.modify({
        fields: {
          chapters: (existingItemsRefs, { readField }) => {
            const totalCount: number = readField('totalCount', existingItemsRefs) || 0;
            const newItemNodeRef = cache.writeFragment({
              data: {
                ...createChapter?.chapter,
              },
              fragment: gql`
                fragment ChapterNew on Chapter {
                  ...ChapterBase
                }
                ${FRAGMENT_CHAPTER_BASE}
              `,
              fragmentName: 'ChapterNew',
            });
            const newItemEdge = {
              node: newItemNodeRef,
            };
            return {
              ...existingItemsRefs,
              totalCount: totalCount + 1,
              edges: [...existingItemsRefs.edges, newItemEdge],
            };
          },
        },
      });
    },
  });

  return (inputData: InputDataCreate) => {
    return createChapterMutation({
      variables: {
        input: {
          ...inputData,
        },
      },
    });
  };
}

interface InputDataUpdate {
  position?: number;
  parent?: string | null;
}

function useChaptersUpdateHandler() {
  const [updateChapterMutation] = useMutation(UPDATE_CHAPTER_MUTATION);

  return (chapterId: string, inputData: InputDataUpdate) => {
    return updateChapterMutation({
      variables: {
        input: {
          id: chapterId,
          ...inputData,
        },
      },
    });
  };
}

function useChapterDeleteHandler() {
  const { enqueueSnackbar } = useSnackbar();

  const [deleteChapterMutation] = useMutation(DELETE_CHAPTER_MUTATION, {
    onCompleted({ deleteChapter }) {
      if (deleteChapter) {
        enqueueSnackbar('Kapitel erfolgreich gelöscht', {
          variant: 'success',
        });
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten', {
          variant: 'warning',
        });
      }
    },
    onError(error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
    update(cache, { data: { deleteChapter } }) {
      cache.modify({
        fields: {
          chapters: (existingItemsRefs = [], { readField }) => {
            const totalCount: number = readField('totalCount', existingItemsRefs) || 0;
            return {
              ...existingItemsRefs,
              totalCount: totalCount - 1,
              edges: [
                ...existingItemsRefs.edges.filter(
                  (itemRef: any) => deleteChapter?.chapter?.id !== readField('id', itemRef.node)
                ),
              ],
            };
          },
        },
      });
    },
  });

  return (chapterId: string) => {
    return deleteChapterMutation({
      variables: {
        input: {
          id: chapterId,
        },
      },
    });
  };
}

interface IProps {
  manual: Manual;
  editMode?: boolean;
}

const chapterAll = 99999;

export default function ManualChaptersComponent(props: IProps) {
  const { manual, editMode } = props;
  const { classes: globalClasses } = useGlobalStyles();
  const loggedInMe = useReactiveVar(loggedInMeVar);
  const permissions = useLoggedInMePermissions(permissionComponentKeys.MANUALS);
  const { enqueueSnackbar } = useSnackbar();

  const [chaptersQueried, setChaptersQueried] = useState<boolean>(false);
  const [queryChapters, { error, data, loading }] = useLazyQuery(CHAPTERS_QUERY, {
    fetchPolicy: 'network-only',
  });
  useEffect(() => {
    if (chaptersQueried || !manual?.id) {
      return;
    }
    const publishedVersion =
      manual.workingCopyVersion && manual.workingCopyVersion > 1
        ? manual.workingCopyVersion - 1
        : 1;
    queryChapters({
      variables: {
        first: chapterAll,
        after: null,
        manual: manual.id,
        version: editMode ? manual.workingCopyVersion : publishedVersion,
      },
    });
    setChaptersQueried(true);
  }, [chaptersQueried, queryChapters, manual, editMode]);

  const [chapterTree, setChapterTree] = useState<TreeChapter[]>([]);
  const [hasSubChapters, setHasSubChapters] = useState<boolean>(false);
  const [chapterTreeExpanded, setChapterTreeExpanded] = useState<boolean | undefined>(undefined);
  const [hasExpandedChapter, setHasExpandedChapter] = useState<boolean>(false);

  const toggleChapterTree = (expanded: boolean | undefined) => {
    setChapterTreeExpanded(expanded);
    if (expanded === true || expanded === false) {
      setHasExpandedChapter(expanded);
    }
  };

  const toggleChapterExpanded = (expanded: boolean) => {
    setHasExpandedChapter(expanded);
  };

  const [newDialogOpen, setNewDialogOpen] = useState<boolean>(false);
  const handleChapterCreate = useChapterCreateHandler();

  const [chapterId, setChapterId] = useState<string | null>(null);
  const [moveDialogOpen, setMoveDialogOpen] = useState<boolean>(false);
  const [copyDialogOpen, setCopyDialogOpen] = useState<boolean>(false);
  const handleChaptersPositionUpdate = useChaptersUpdateHandler();

  const [deleteChapterId, setDeleteChapterId] = useState<string | null>(null);
  const handleChapterDelete = useChapterDeleteHandler();

  useEffect(() => {
    if (!data?.chapters?.edges) {
      return;
    }
    const chaptersFlatSorted = getChaptersFlatSortedArray(data.chapters.edges);
    const chapterDataTree = createDataTree(chaptersFlatSorted);
    setChapterTree(chapterDataTree);
    setHasSubChapters(
      chapterDataTree.some(
        (chapter: any) => Array.isArray(chapter?.childNodes) && chapter.childNodes.length > 0
      )
    );
  }, [data?.chapters?.edges]);

  const [importDialogOpen, setImportDialogOpen] = useState<boolean>(false);
  const [importedMediaObject, setImportedMediaObject] = useState<MediaObject | null>(null);
  const { importManual } = useManualImport(manual?.id ?? null);
  const { deleteMediaObject } = useDeleteMediaObject();

  // Cleanup on unmount
  useEffect(() => {
    // https://dmitripavlutin.com/react-cleanup-async-effects/
    (async () => {
      if (importedMediaObject === null) {
        return;
      }
      try {
        await deleteMediaObject(importedMediaObject.id);
      } catch (error) {
        console.error(error);
      } finally {
        setImportedMediaObject(null);
      }
    })();
    // https://stackoverflow.com/a/55041347
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitImportDialog = useCallback(
    async (mediaObject: MediaObject) => {
      if (importedMediaObject) {
        await deleteMediaObject(importedMediaObject.id);
      }
      setImportDialogOpen(false);
      setImportedMediaObject(mediaObject);
      try {
        const importedManualUuid = await importManual(mediaObject.id);
        if (importedManualUuid === parseUuidFromId(manual?.id)) {
          setChaptersQueried(false);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setImportedMediaObject(null);
      }
    },
    [importedMediaObject, deleteMediaObject, importManual, manual]
  );

  const createOrCopyChapter = async (
    values: FormikValues,
    formikBag: FormikHelpers<any>,
    copyChapter: Chapter
  ) => {
    const { customChapterNumber, title, parentId, targetIndex } = values;
    const promises: Promise<FetchResult>[] = [];
    const siblings = insert(data?.chapters?.edges ?? [], parentId, targetIndex);
    // NOTE: Explicitly using isLoadingVar b/c Apollo networkStatusNotifier slow

    try {
      isLoadingVar(true);

      let content = '';
      let newTitle = title;

      if (copyChapter) {
        content = copyChapter.content ?? '';
        newTitle = `Kopie von ${copyChapter.title}`;
      }

      const inputData: InputDataCreate = {
        title: newTitle,
        content: content,
        manual: manual.id,
        parent: parentId,
        position: targetIndex + 1,
        version: manual.workingCopyVersion ?? 1,
      };

      if (manual.customChapterLabel) {
        inputData.customChapterNumber = customChapterNumber;
      }

      await handleChapterCreate(inputData);

      const createMessage = copyChapter
        ? 'Kapitel erfolgreich kopiert'
        : 'Kapitel erfolgreich angelegt';
      enqueueSnackbar(createMessage, {
        variant: 'success',
      });

      siblings.forEach((chapter: Chapter, index: number) => {
        if (!chapter || chapter.position === index + 1) {
          return;
        }
        let promise: Promise<FetchResult> = handleChaptersPositionUpdate(chapter.id, {
          position: index + 1,
        });
        promises.push(promise);
      });
      await Promise.all(promises);
      enqueueSnackbar('Kapitelstruktur erfolgreich aktualisiert', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
      console.error(error);
    } finally {
      formikBag.setSubmitting(false);
      formikBag.resetForm();
      isLoadingVar(false);
    }
  };

  const handleCreateAndCopy = useCallback(createOrCopyChapter, [
    data?.chapters?.edges,
    manual.id,
    handleChapterCreate,
    handleChaptersPositionUpdate,
    enqueueSnackbar,
    manual.workingCopyVersion,
    manual.customChapterLabel,
  ]);

  const handleMove = useCallback(
    (values: FormikValues, formikBag: FormikHelpers<any>) => {
      if (!chapterId || !data?.chapters?.edges) {
        return;
      }
      const { parentId, targetIndex } = values;
      const promises: Promise<FetchResult>[] = [];
      const { currentSiblings, targetSiblings } = rearrange(
        data.chapters.edges,
        chapterId,
        parentId,
        targetIndex
      );
      // NOTE: Explicitly using isLoadingVar b/c Apollo networkStatusNotifier slow
      isLoadingVar(true);
      currentSiblings.forEach((chapter: Chapter, index: number) => {
        if (chapter.position === index + 1) {
          return;
        }
        let promise: Promise<FetchResult> = handleChaptersPositionUpdate(chapter.id, {
          position: index + 1,
        });
        promises.push(promise);
      });
      targetSiblings.forEach((chapter: Chapter, index: number) => {
        const inputData: InputDataUpdate = {};
        if (chapter.position !== index + 1) {
          inputData.position = index + 1;
        }
        if (chapter.id === chapterId && chapter.parentId !== parentId) {
          inputData.parent = parentId;
        }
        if (Object.keys(inputData).length === 0) {
          return;
        }
        let promise: Promise<FetchResult> = handleChaptersPositionUpdate(chapter.id, inputData);
        promises.push(promise);
      });
      Promise.all(promises)
        .then(
          () => {
            enqueueSnackbar('Kapitel erfolgreich aktualisiert', {
              variant: 'success',
            });
          },
          (error) => {
            enqueueSnackbar(error.message, {
              variant: 'error',
            });
          }
        )
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          isLoadingVar(false);
          formikBag.setSubmitting(false);
          formikBag.resetForm();
        });
    },
    [data?.chapters?.edges, chapterId, handleChaptersPositionUpdate, enqueueSnackbar]
  );

  const handleDelete = useCallback(() => {
    if (!deleteChapterId || !data?.chapters?.edges) {
      return;
    }
    const promises: Promise<FetchResult>[] = [];
    const siblings = remove(data.chapters.edges, deleteChapterId);
    // NOTE: Explicitly using isLoadingVar b/c Apollo networkStatusNotifier slow
    isLoadingVar(true);
    const promise: Promise<FetchResult> = handleChapterDelete(deleteChapterId);
    promises.push(promise);
    siblings.forEach((chapter: Chapter, index: number) => {
      if (chapter.position === index + 1) {
        return;
      }
      let promise: Promise<FetchResult> = handleChaptersPositionUpdate(chapter.id, {
        position: index + 1,
      });
      promises.push(promise);
    });
    Promise.all(promises)
      .then(
        () => {
          enqueueSnackbar('Kapitelstruktur erfolgreich aktualisiert', {
            variant: 'success',
          });
        },
        (error) => {
          enqueueSnackbar(error.message, {
            variant: 'error',
          });
        }
      )
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        isLoadingVar(false);
      });
  }, [
    data?.chapters?.edges,
    deleteChapterId,
    handleChapterDelete,
    handleChaptersPositionUpdate,
    enqueueSnackbar,
  ]);

  if (loading) {
    return null;
  }
  if (error?.message)
    return (
      <Container>
        <Alert severity="error">Es ist ein Fehler aufgetreten: {error.message}</Alert>
      </Container>
    );

  return (
    <Box mt={4}>
      <Box
        component="header"
        mb={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="baseline">
          <Typography component="h2" variant="h4">
            Kapitel
          </Typography>
          {manual?.workingCopyVersion && loggedInMe?.tenant !== null && (
            <Box ml={2}>
              <Typography component="p" variant="caption">
                {editMode ? (
                  `(Arbeitsversion)`
                ) : (
                  <Fragment>
                    {manual?.workingCopyVersion > 1
                      ? `(veröffentlichte Version ${
                          manual.mostRecentVersionName ?? manual.workingCopyVersion - 1
                        })`
                      : '(unveröffentlicht)'}
                  </Fragment>
                )}
              </Typography>
            </Box>
          )}
        </Box>
        <div>{chapterTreeExpanded}</div>
        <Box>
          <ButtonGroup variant="outlined" color="primary" aria-label="Kapitelbaum auf-/zuklappen">
            <Button
              endIcon={<UnfoldMoreIcon />}
              onClick={() => toggleChapterTree(true)}
              disabled={!hasSubChapters || chapterTreeExpanded === true}
            >
              Aufklappen
            </Button>
            <Button
              startIcon={<UnfoldLessIcon />}
              onClick={() => toggleChapterTree(false)}
              disabled={!hasSubChapters || chapterTreeExpanded === false || !hasExpandedChapter}
            >
              Zuklappen
            </Button>
          </ButtonGroup>
        </Box>
      </Box>
      <Paper component="section" variant="outlined">
        {chapterTree.length > 0 ? (
          <ManualChapterLevel
            manualId={manual.id}
            manualCustomChapterLabel={manual.customChapterLabel}
            chapters={chapterTree}
            level={1}
            chapterTreeExpanded={chapterTreeExpanded}
            toggleChapterTree={toggleChapterTree}
            toggleChapterExpanded={toggleChapterExpanded}
            moveHandler={(chapterId) => {
              if (chapterId) {
                setChapterId(chapterId);
                setMoveDialogOpen(true);
              }
            }}
            copyHandler={(chapterId) => {
              if (chapterId) {
                setChapterId(chapterId);
                setCopyDialogOpen(true);
              }
            }}
            deleteHandler={(chapterId) => {
              if (chapterId) {
                setDeleteChapterId(chapterId);
              }
            }}
            permissions={permissions}
            editMode={editMode}
          />
        ) : (
          <Box p={2}>
            <Typography variant="body1">Keine Kapitel vorhanden</Typography>
          </Box>
        )}
        {editMode && permissions?.create && (
          <Box component="footer" className={globalClasses.paperActions}>
            {/* NOTE: On-hold until import feature more robust
            <Button
              type="button"
              variant="contained"
              color="primary"
              startIcon={<AddBookIcon />}
              onClick={() => {
                setImportDialogOpen(true);
              }}
            >
              Handbuch aus Word-Dokument importieren
            </Button>
            */}
            <Button
              type="button"
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => {
                setNewDialogOpen(true);
              }}
            >
              Neues Kapitel hinzufügen
            </Button>
          </Box>
        )}
      </Paper>
      <FileUploadDialog
        acceptedFileTypes={[
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        ]}
        dialogOpen={importDialogOpen}
        onSubmit={(mediaObject) => {
          submitImportDialog(mediaObject);
        }}
        onClose={() => setImportDialogOpen(false)}
        maxFileSize={32 * 1024 * 1024}
      />
      <ManualChapterNewAndCopyDialog
        dialogOpen={newDialogOpen}
        chapters={chapterTree}
        submitHandler={handleCreateAndCopy}
        resetHandler={() => {
          setNewDialogOpen(false);
          setChapterId(null);
        }}
        manualCustomChapterLabel={manual.customChapterLabel}
      />
      <ManualChapterNewAndCopyDialog
        dialogOpen={copyDialogOpen}
        chapters={chapterTree}
        submitHandler={handleCreateAndCopy}
        resetHandler={() => {
          setCopyDialogOpen(false);
          setChapterId(null);
        }}
        copyChapterId={chapterId}
        manualCustomChapterLabel={manual.customChapterLabel}
      />
      <ManualChapterMoveDialog
        dialogOpen={moveDialogOpen}
        chapterId={chapterId}
        chapters={chapterTree}
        submitHandler={handleMove}
        resetHandler={() => {
          setMoveDialogOpen(false);
          setChapterId(null);
        }}
      />
      {deleteChapterId && (
        <ManualChapterRemoveDialog
          dialogOpen={true}
          chapterId={deleteChapterId}
          onClose={(confirm) => {
            if (confirm) {
              handleDelete();
            }
            setDeleteChapterId(null);
          }}
        />
      )}
    </Box>
  );
}

import { Plugin } from '@ckeditor/ckeditor5-core';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import iconDocumentLink from '../../assets/ckeditor/icon-documentlink.svg';
import { ChapterContentLabels } from '../../models/chapters';

export default class DocumentLinkUI extends Plugin {
  init() {
    const editor = this.editor;
    const t = editor.t;
    const openDocumentLinkDialog = editor.config.get('custom').openDocumentLinkDialog;

    const handleNewDocumentLink = (id, title, documents) => {
      if (!id || !title) {
        return;
      }
      editor.config.set('relatedDocuments', documents);
      editor.execute('insertDocumentLink', {
        dataDocumentId: id,
        dataDocumentTitle: `${title}`.trim(),
      });
      editor.editing.view.focus();
    };

    editor.ui.componentFactory.add('documentLink', (locale) => {
      const command = editor.commands.get('insertDocumentLink');

      const buttonView = new ButtonView(locale);

      buttonView.set({
        label: t(ChapterContentLabels.get('add-document-link')),
        icon: iconDocumentLink,
        tooltip: true,
      });

      buttonView.bind('isOn', 'isEnabled').to(command, 'value', 'isEnabled');

      this.listenTo(buttonView, 'execute', () => {
        openDocumentLinkDialog(handleNewDocumentLink);
      });

      return buttonView;
    });
  }
}

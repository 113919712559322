import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Question } from '../../../models/questions';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { ResponsiveBar } from '@nivo/bar';
import { Theme } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import { makeStyles } from 'tss-react/mui';
import {
  SurveyEvaluationQuestionStatus,
  SurveyEvaluationResultTable,
  SurveyEvaluationTargetGroupSelect,
} from './index';
import { Survey } from '../../../models/surveys';
import { formatPercent } from '../../../utils/helper';
import parse from 'html-react-parser';
import useGlobalStyles from '../../../hooks/useGlobalStyles';

interface Props {
  question: Question;
  aggregatedResult: any | null;
  openCommentsDialog: (targetGroupId: string | null) => void;
  loadAggregationsByTargetGroupId: (targetGroupId: string) => void;
  survey: Survey;
  aggregatedResultState: number;
  targetgroup: string;
}

const colors = [
  '#e5202e',
  '#007ac3',
  '#85bc20',
  '#474747',
  '#009881',
  '#ea8f00',
  '#940c72',
  '#241866',
  '#f8b3b3',
  '#a6d1eb',
  '#d5e8b1',
  '#bfbfbf',
  '#a6dbd3',
  '#f8d8a6',
  '#daaace',
  '#aca4ce',
];

const createBarData = (aggregatedResult: any): any[] => {
  const barData: any[] = [];

  let colorIndex = 0;
  for (let choiceIndex in aggregatedResult.choices) {
    const choiceCount = aggregatedResult.choices[choiceIndex];

    if (0 === choiceCount) {
      continue;
    }

    const totalAnswers = aggregatedResult?.totalAnswers ?? 0;
    const percentage = totalAnswers > 0 ? (choiceCount * 100.0) / totalAnswers : 0;

    barData.push({
      id: choiceIndex,
      label: choiceIndex,
      value: choiceCount,
      color: colors[colorIndex],
      percent: formatPercent(percentage, 0),
    });
    colorIndex++;
  }

  return barData;
};

const useStyles = makeStyles({ name: 'SurveyEvaluationChoice' })((theme: Theme) => {
  return {
    chartGridContainer: {
      '@media print': {
        display: 'block',
      },
      '& .MuiGrid-item': {
        '@media print': {
          display: 'block',
          maxWidth: 'none',
          width: '100%',
        },
      },
    },
    chartContainer: {
      height: theme.spacing(40),
    },
    chartLegendContainer: {
      marginTop: theme.spacing(4),
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(6),
        marginLeft: theme.spacing(2),
      },
      [theme.breakpoints.up('lg')]: {
        marginLeft: theme.spacing(4),
      },
      '@media print': {
        marginLeft: 0,
      },
    },
    chartLegend: {
      display: 'grid',
      gridTemplateColumns: '24px auto',
      gridColumnGap: theme.spacing(2),
      gridRowGap: theme.spacing(2),
      margin: theme.spacing(0, 0, 2),
      width: '100%',
      padding: 0,
      '& dt': {
        gridColumn: 1,
        border: '12px solid transparent',
        width: 0,
        height: 0,
      },
      '& dd': {
        gridColumn: 2,
      },
      '@media print': {
        pageBreakInside: 'avoid',
      },
    },
  };
});

const renderBarLabel = (labelValue: string | number) => {
  const style = {
    fontSize: '14px',
  };

  return (
    <tspan y={-10} style={style}>
      {labelValue}
    </tspan>
  );
};

export default function SurveyEvaluationChoiceComponent(props: Props) {
  const {
    question,
    aggregatedResult,
    openCommentsDialog,
    survey,
    loadAggregationsByTargetGroupId,
    aggregatedResultState,
    targetgroup,
  } = props;
  const { classes: globalClasses } = useGlobalStyles();
  const { classes } = useStyles();
  const [barData, setBarData] = useState<any[]>([]);
  const renderIndex = useRef<number>(0);
  const [selectedTargetgroup, setSelectedTargetgroup] = useState<string>('all');
  const isMultipleChoice = question.maxAnswers > Math.max(1, question.minAnswers);

  const changeTargetGroup = useCallback(
    (targetGroupId: string) => {
      setSelectedTargetgroup(targetGroupId);
      renderIndex.current++;
      if (!(targetGroupId in aggregatedResult)) {
        loadAggregationsByTargetGroupId(targetGroupId);
      }
    },
    [aggregatedResult, loadAggregationsByTargetGroupId, renderIndex]
  );

  const getAggregatedResultByTargetGroup = useCallback(
    (targetgroupId: string): any | null => {
      if (null === aggregatedResult) {
        return null;
      }

      if (targetgroupId in aggregatedResult) {
        return aggregatedResult[targetgroupId];
      }

      return aggregatedResult['all'];
    },
    [aggregatedResult]
  );

  useEffect(() => {
    if (null === aggregatedResult) {
      setBarData([]);

      return;
    }
    setBarData(createBarData(aggregatedResult[selectedTargetgroup] ?? aggregatedResult.all));
  }, [question, aggregatedResult, selectedTargetgroup, aggregatedResultState]);

  useEffect(() => {
    if (selectedTargetgroup !== targetgroup) {
      changeTargetGroup(targetgroup);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetgroup]);

  return (
    <Box mb={3}>
      <Paper component="section" variant="outlined">
        <Box m={3} display="flex">
          <Box component="header" mb={2} className={globalClasses.richText}>
            {parse(question.text)}
          </Box>
          <Box ml={3}>
            <SurveyEvaluationQuestionStatus question={question} />
          </Box>
        </Box>
        <Box>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Box>
        {aggregatedResult && 'all' === selectedTargetgroup && barData.length === 0 && (
          <Box m={3}>
            <Typography component="p" gutterBottom>
              Es liegen keine Ergebnisse vor
            </Typography>
          </Box>
        )}
        {aggregatedResult && ('all' !== selectedTargetgroup || barData.length > 0) && (
          <Box m={3}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography component="span" gutterBottom>
                  Antworten:&nbsp;<b>{aggregatedResult[selectedTargetgroup]?.totalAnswers ?? 0}</b>
                </Typography>
              </Grid>
              <Grid item>
                <SurveyEvaluationTargetGroupSelect
                  survey={survey}
                  changeTargetGroup={changeTargetGroup}
                  selectedTargetgroup={selectedTargetgroup}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="flex-end" className={classes.chartGridContainer}>
              <Grid item xs={12} md={7} lg={6}>
                <Box className={classes.chartContainer}>
                  <ResponsiveBar
                    layout="vertical"
                    margin={{
                      top: 32,
                      right: 8,
                      bottom: 8,
                      left: 32,
                    }}
                    data={barData}
                    indexBy={(_index) => _index.label}
                    keys={['value']}
                    colors={(d: any) => d.data.color}
                    groupMode="grouped"
                    enableGridX={false}
                    enableGridY={true}
                    borderRadius={1}
                    padding={0.4}
                    axisLeft={{
                      tickSize: 0,
                      tickPadding: 4,
                      tickRotation: 0,
                    }}
                    enableLabel={true}
                    labelTextColor="black"
                    label={(d: any) =>
                      renderBarLabel(`${d.value} / ${d.data.percent}`) as unknown as string
                    }
                    animate={false}
                    key={`${aggregatedResultState}-${selectedTargetgroup}-${renderIndex.current}`}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={5} lg={6}>
                <Box className={classes.chartLegendContainer}>
                  <dl className={classes.chartLegend}>
                    {barData.map((barDataEntry, index) => (
                      <Fragment key={barDataEntry.label}>
                        <dt
                          style={{
                            borderColor: barDataEntry.color,
                          }}
                        >
                          <span style={visuallyHidden}>{index + 1}</span>
                        </dt>
                        <Typography component="dd">{barDataEntry.label}</Typography>
                      </Fragment>
                    ))}
                  </dl>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
        <Box>
          <Grid container spacing={2}>
            <Divider />
          </Grid>
        </Box>
        {aggregatedResult && (
          <Box p={3}>
            <Grid container spacing={2}>
              <SurveyEvaluationResultTable
                aggregatedResult={getAggregatedResultByTargetGroup(selectedTargetgroup)}
                openCommentsDialog={openCommentsDialog}
                showPercentRegardingChoices={isMultipleChoice}
              />
            </Grid>
          </Box>
        )}
      </Paper>
    </Box>
  );
}

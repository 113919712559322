import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import useGlobalStyles from '../../hooks/useGlobalStyles';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import { Chapter } from '../../models/chapters';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/CloseRounded';
import AppBar from '@mui/material/AppBar';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { useLazyQuery } from '@apollo/client';
import { CHAPTER_QUERY } from '../../operations/chapter';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightIcon from '@mui/icons-material/ChevronRightRounded';
import ButtonGroup from '@mui/material/ButtonGroup';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import { renderEditorHTML } from './ManualChapter.component';
import { DocumentsJoined } from '../documents';
import Tooltip from '@mui/material/Tooltip';
import { DocumentNode } from '../../models/documents';
import { default as ManualChapterRevision } from './ManualChapterRevision.component';
import { useDocumentVersionDownload } from '../documents/Document.component';

const useStyles = makeStyles({ name: 'ManualChapterPreview' })((theme: Theme) => {
  return {
    dialogAppBar: {
      boxShadow: '0 1rem 1rem #fff',
    },
    dialogToolbar: {
      padding: theme.spacing(3, 1, 2),
    },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(3, 1),
    },
    dialogActions: {
      zIndex: 1, // NOTE: -> boxShadow
      justifyContent: 'space-between',
      margin: 0,
      boxShadow: '0 -1rem 1rem #fff',
      backgroundColor: theme.palette.background.light,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.text.secondary,
    },
  };
});

interface Props {
  dialogOpen: boolean;
  previewId: string;
  chapterList: Chapter[];
  resetHandler: () => void;
  children?: React.ReactNode;
}

const ManualChapterPreviewComponent: React.FC<Props> = (props) => {
  const { dialogOpen, previewId, chapterList, resetHandler } = props;
  const { classes: globalClasses } = useGlobalStyles();
  const { classes } = useStyles();
  const [chapterQueryId, setChapterQueryId] = useState<string | null>(previewId ?? null);
  const [chapterId, setChapterId] = useState<string | null>(null);
  const [prevChapterId, setPrevChapterId] = useState<string | null>(null);
  const [nextChapterId, setNextChapterId] = useState<string | null>(null);
  const download = useDocumentVersionDownload();

  const [queryChapter, { error, data, loading }] = useLazyQuery(CHAPTER_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted({ chapter }) {
      setChapterId(chapter?.id || null);
    },
  });

  useEffect(() => {
    if (!chapterQueryId) {
      return;
    }
    queryChapter({ variables: { id: chapterQueryId } });
  }, [chapterQueryId, queryChapter]);

  useEffect(() => {
    const currentIndex = chapterList.findIndex((chapter) => chapter.id === chapterId);
    const prevChapter = chapterList[currentIndex - 1];
    const nextChapter = chapterList[currentIndex + 1];
    setPrevChapterId(prevChapter?.id ?? null);
    setNextChapterId(nextChapter?.id ?? null);
  }, [chapterId, chapterList]);

  return (
    <Dialog
      open={dialogOpen}
      fullScreen
      TransitionProps={{
        onExit: () => setChapterQueryId(previewId),
      }}
    >
      <AppBar
        position="static"
        color="inherit"
        classes={{
          root: classes.dialogAppBar,
        }}
      >
        <Toolbar
          classes={{
            root: classes.dialogToolbar,
          }}
        >
          <Container>
            <Typography variant="h3">
              {data ? `${data.chapter.chapterNumber} ${data.chapter.title}`.trim() : 'Kapitel'}
              {loading && ' wird geladen...'}
            </Typography>
          </Container>
          <Tooltip title="Schließen">
            <IconButton
              aria-label="Schließen"
              color="inherit"
              size="small"
              className={classes.closeButton}
              onClick={() => resetHandler()}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
        {loading && <LinearProgress />}
      </AppBar>
      <DialogContent
        classes={{
          root: classes.dialogContent,
        }}
      >
        {error?.message && (
          <Container>
            <Alert severity="error">Es ist ein Fehler aufgetreten: {error.message}</Alert>
          </Container>
        )}
        {!data?.chapter && !loading && (
          <Container>
            <Alert severity="warning">Kapitel nicht gefunden</Alert>
          </Container>
        )}
        {data?.chapter && (
          <Container>
            <Grid container spacing={2}>
              <Grid item xs={12} className={globalClasses.richText}>
                {renderEditorHTML(
                  data.chapter.content,
                  chapterList,
                  data.chapter.documents?.edges.map(
                    (documentNode: DocumentNode) => documentNode.node
                  ) ?? [],
                  download
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ManualChapterRevision chapter={data.chapter} />
            </Grid>
            {data.chapter.manual?.tenant?.id && chapterId && (
              <Box mt={6} mb={3}>
                <DocumentsJoined entityId={chapterId} editing={false} />
              </Box>
            )}
          </Container>
        )}
      </DialogContent>
      <DialogActions
        classes={{
          root: classes.dialogActions,
        }}
      >
        <ButtonGroup variant="contained" disableElevation color="primary">
          {prevChapterId ? (
            <Button
              startIcon={<ChevronLeftIcon />}
              onClick={() => {
                setChapterQueryId(prevChapterId);
              }}
            >
              Vorheriges Kapitel
            </Button>
          ) : (
            <Button startIcon={<ChevronLeftIcon />} disabled={true}>
              Vorheriges Kapitel
            </Button>
          )}
          {nextChapterId ? (
            <Button
              endIcon={<ChevronRightIcon />}
              onClick={() => {
                setChapterQueryId(nextChapterId);
              }}
            >
              Nächstes Kapitel
            </Button>
          ) : (
            <Button endIcon={<ChevronRightIcon />} disabled={true}>
              Nächstes Kapitel
            </Button>
          )}
        </ButtonGroup>
        <Button
          type="reset"
          variant="outlined"
          color="primary"
          size="large"
          startIcon={<CloseIcon />}
          onClick={() => resetHandler()}
        >
          Schließen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ManualChapterPreviewComponent;

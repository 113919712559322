import React, { Fragment, useEffect, useState } from 'react';
import { Formik, Form, Field, FormikValues, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-mui';
import { TextField as MuiTextField } from '@mui/material';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { Autocomplete } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import FormLabel from '@mui/material/FormLabel';
import { useNavigate } from 'react-router-dom';
import { Role } from '../../models/roles';
import { ROLES_QUERY } from '../../operations/role';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import useGlobalStyles from '../../hooks/useGlobalStyles';
import { ConfirmDialog, ConfirmNavigation, FormActions, FormikContextDirty } from '../common';
import SaveIcon from '@mui/icons-material/SaveRounded';
import CancelIcon from '@mui/icons-material/HighlightOffRounded';
import useLoggedInMeFacilities from '../../hooks/useLoggedInMeFacilities';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { FacilityOption } from '../../models/facilities';
import { isFormDirtyVar } from '../../cache';

interface FormValues {
  email: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  group: string;
  facilities: FacilityOption[];
  role: null | Role;
  tenantWideEditPermission: boolean;
}

const initialValues: FormValues = {
  email: '',
  firstName: '',
  lastName: '',
  jobTitle: '',
  group: '',
  facilities: [],
  role: null,
  tenantWideEditPermission: false,
};

interface IProps {
  submitHandler: (values: FormikValues, formikBag: FormikHelpers<any>) => void;
  children?: React.ReactNode;
}

const UserNewFormComponent: React.FC<IProps> = (props) => {
  const { submitHandler } = props;
  const { classes: globalClasses } = useGlobalStyles();
  let navigate = useNavigate();

  const [openFacilities, setOpenFacilities] = useState<boolean>(false);
  const { facilities: facilitiesOptions, loadingFacilities } =
    useLoggedInMeFacilities(openFacilities);

  const [openRoles, setOpenRoles] = useState<boolean>(false);
  const [rolesOptions, setRolesOptions] = useState<Role[]>([]);
  const [queryRoles, { data: dataRoles, loading: loadingRoles }] = useLazyQuery(ROLES_QUERY);
  useEffect(() => {
    if (loadingRoles || !openRoles) {
      return;
    }
    if (dataRoles?.roles?.edges?.length > 0) {
      setRolesOptions(
        dataRoles.roles.edges.map((edge: any) => ({
          id: edge.node.id,
          name: edge.node.name,
          tenantAdmin: edge.node.tenantAdmin,
        })) as Role[]
      );
    } else {
      queryRoles();
    }
  }, [openRoles, queryRoles, dataRoles, loadingRoles]);

  const isFormDirty = useReactiveVar(isFormDirtyVar);
  const [resetConfirmOpen, setResetConfirmOpen] = useState<boolean>(false);

  return (
    <Fragment>
      <ConfirmNavigation shouldBlock={isFormDirty} />
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object({
          email: Yup.string().email('Keine E-Mail Adresse').required('Pflichtfeld'),
          firstName: Yup.string().required('Pflichtfeld'),
          lastName: Yup.string().required('Pflichtfeld'),
        })}
        onSubmit={(values, formikBag) => {
          isFormDirtyVar(false);
          submitHandler(values, formikBag);
        }}
      >
        {(props) => {
          return (
            <Form autoComplete="off" style={{ width: '100%' }}>
              <FormikContextDirty />
              {props.status && (
                <Box mb={2}>
                  <Alert severity="error">{props.status}</Alert>
                </Box>
              )}
              <Paper component="section" variant="outlined" className={globalClasses.paper}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor="firstName">Vorname</FormLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name="firstName"
                        id="firstName"
                        variant="outlined"
                        fullWidth
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor="lastName">Nachname</FormLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name="lastName"
                        id="lastName"
                        variant="outlined"
                        fullWidth
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor="email">E-Mail Adresse</FormLabel>
                      <Field
                        component={TextField}
                        type="email"
                        name="email"
                        id="email"
                        variant="outlined"
                        fullWidth
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor="role">
                        Benutzerrolle
                        <span className="labelInfo">optional</span>
                      </FormLabel>
                      <Autocomplete
                        id="role"
                        open={openRoles}
                        onOpen={() => {
                          setOpenRoles(true);
                        }}
                        onClose={() => {
                          setOpenRoles(false);
                        }}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        getOptionLabel={(option) => option?.name ?? ''}
                        onChange={(e, value) => {
                          props.setFieldValue('role', value);
                          if (value?.tenantAdmin) {
                            props.setFieldValue('tenantWideEditPermission', true);
                          }
                        }}
                        options={rolesOptions}
                        value={props.values.role}
                        loading={loadingRoles}
                        renderInput={(params) => (
                          <MuiTextField
                            type="text"
                            name="role"
                            variant="outlined"
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {loadingRoles ? (
                                    <CircularProgress color="inherit" size={20} />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor="jobTitle">
                        Position
                        <span className="labelInfo">optional</span>
                      </FormLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name="jobTitle"
                        id="jobTitle"
                        variant="outlined"
                        fullWidth
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor="facilities">
                        Einrichtungen
                        <span className="labelInfo">optional</span>
                      </FormLabel>
                      <Autocomplete
                        id="facilities"
                        multiple
                        filterSelectedOptions
                        open={openFacilities}
                        onOpen={() => {
                          setOpenFacilities(true);
                        }}
                        onClose={() => {
                          setOpenFacilities(false);
                        }}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        getOptionLabel={(option) => option?.name ?? ''}
                        onChange={(e, value) => {
                          props.setFieldValue('facilities', value);
                        }}
                        options={facilitiesOptions}
                        value={props.values.facilities}
                        loading={loadingFacilities}
                        renderInput={(params) => (
                          <MuiTextField
                            type="text"
                            name="facilities"
                            variant="outlined"
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {loadingFacilities ? (
                                    <CircularProgress color="inherit" size={20} />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor="group">
                        Gruppe
                        <span className="labelInfo">optional</span>
                      </FormLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name="group"
                        id="group"
                        variant="outlined"
                        fullWidth
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <FormLabel component="div" focused={false}>
                        Berechtigungen für trägerweite Inhalte
                      </FormLabel>
                      <FormControlLabel
                        control={
                          <Field
                            component={Checkbox}
                            type="checkbox"
                            name="tenantWideEditPermission"
                            id="tenantWideEditPermission"
                            color="primary"
                          />
                        }
                        checked={props.values.tenantWideEditPermission}
                        onChange={(event: React.ChangeEvent<any>) => {
                          const tenantWideEditPermission = event.target.checked;
                          props.setFieldValue('tenantWideEditPermission', tenantWideEditPermission);
                        }}
                        disabled={props.values.role?.tenantAdmin}
                        label="kann trägerweite Inhalte bearbeiten"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Paper>
              <FormActions>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  startIcon={<SaveIcon />}
                  disabled={props.isSubmitting}
                >
                  Speichern
                </Button>
                <Button
                  type="button"
                  variant="outlined"
                  size="large"
                  color="primary"
                  startIcon={<CancelIcon />}
                  onClick={() => {
                    if (isFormDirty) {
                      setResetConfirmOpen(true);
                    } else {
                      navigate(-1);
                    }
                  }}
                >
                  Abbrechen
                </Button>
              </FormActions>
              <ConfirmDialog
                open={resetConfirmOpen}
                title={`Änderungen verwerfen`}
                content={`Wollen Sie die vorgenommenen Änderungen im Formular verwerfen?`}
                onClose={(confirm) => {
                  setResetConfirmOpen(false);
                  if (confirm) {
                    props.handleReset();
                    isFormDirtyVar(false);
                    navigate(-1);
                  }
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </Fragment>
  );
};

export default UserNewFormComponent;

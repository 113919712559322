import { Plugin } from '@ckeditor/ckeditor5-core';
import iconCut from '../../assets/ckeditor/icon-cut.svg';
import iconCopy from '../../assets/ckeditor/icon-copy.svg';
import iconPaste from '../../assets/ckeditor/icon-paste.svg';
import { CutCopyPasteContentLabels } from '../../models/ckeditor';
import { createDropdown } from '@ckeditor/ckeditor5-ui/src/dropdown/utils';

// Basic plugin tutorial: https://github.com/ckeditor/ckeditor5-tutorials-examples/blob/main/timestamp-plugin/final-project/app.js
export default class CutCopyPastePlugin extends Plugin {
  init() {
    const editor = this.editor;
    const t = editor.t;

    editor.ui.componentFactory.add('cutContent', (locale) => {
      const dropdownView = createDropdown(locale);

      dropdownView.buttonView.set({
        label: t(CutCopyPasteContentLabels.get('cutContent')),
        icon: iconCut,
        tooltip: true,
      });

      dropdownView.render();

      const panelHTML =
        '<div class="ck-dropdown__panel__info" style="min-width: 28rem;"><p><strong>Ausschneiden</strong></p><p>Aus Sicherheitsgründen ist das Ausschneiden mithilfe dieses Buttons deaktiviert. Zum Ausschneiden von Inhalten gehen Sie bitte wie folgt vor:</p><ol><li>Markieren Sie die auszuschneidenden Inhalte.</li><li>Verwenden Sie folgende Tastenkombination, um den markierten Inhalt auszuschneiden und in die Zwischenablage ihres Computers zu kopieren:<ul><li>Windows: <strong>Strg + x</strong></li><li>MAC: <strong>⌘ + x</strong></li></ul></li></ol></div>';

      dropdownView.panelView.element.insertAdjacentHTML('afterbegin', panelHTML);

      return dropdownView;
    });

    editor.ui.componentFactory.add('copyContent', (locale) => {
      const dropdownView = createDropdown(locale);

      dropdownView.buttonView.set({
        label: t(CutCopyPasteContentLabels.get('copyContent')),
        icon: iconCopy,
        tooltip: true,
      });

      dropdownView.render();

      const panelHTML =
        '<div class="ck-dropdown__panel__info" style="min-width: 28rem;"><p><strong>Kopieren</strong></p><p>Aus Sicherheitsgründen ist das Kopieren mithilfe dieses Buttons deaktiviert. Zum Kopieren von Inhalten gehen Sie bitte wie folgt vor:</p><ol><li>Markieren Sie die zu kopierenden Inhalte.</li><li>Verwenden Sie folgende Tastenkombination, um den markierten Inhalt in die Zwischenablage ihres Computers zu kopieren:<ul><li>Windows: <strong>Strg + c</strong></li><li>MAC: <strong>⌘ + c</strong></li></ul></li></ol></div>';

      dropdownView.panelView.element.insertAdjacentHTML('afterbegin', panelHTML);

      /*
      button.on('execute', () => {
        document.execCommand('copy');
      });
      */

      return dropdownView;
    });

    editor.ui.componentFactory.add('pasteContent', (locale) => {
      const dropdownView = createDropdown(locale);

      dropdownView.buttonView.set({
        label: t(CutCopyPasteContentLabels.get('pasteContent')),
        icon: iconPaste,
        tooltip: true,
      });

      dropdownView.render();

      const panelHTML =
        '<div class="ck-dropdown__panel__info" style="min-width: 28rem;"><p><strong>Einfügen</strong></p><p>Aus Sicherheitsgründen ist das Einfügen aus der Zwischenablage Ihres Computers mithilfe dieses Buttons deaktiviert. Zum Einfügen von Inhalten gehen Sie bitte wie folgt vor:</p><ol><li>Klicken Sie an die Stelle, an der der Inhalt  eingefügt werden soll.</li><li>Verwenden Sie folgende Tastenkombination, um den Inhalt aus der Zwischenablage ihres Computers einzufügen:<ul><li>Windows: <strong>Strg + v</strong></li><li>MAC: <strong>⌘ + v</strong></li></ul></li></ol></div>';

      dropdownView.panelView.element.insertAdjacentHTML('afterbegin', panelHTML);

      /*
      button.on('execute', async () => {
        // PROBLEM: Not getting full HTML from clipboard, only plain text
        const data = await navigator.clipboard.readText().then((clipText) => clipText);
        let dT = null;
        try {
          dT = new DataTransfer();
        } catch (e) {
          console.error(e);
        }
        const evt = new ClipboardEvent('paste', { clipboardData: dT });
        evt.clipboardData.setData('text/html', data);
        editor.ui.view.editable.element.dispatchEvent(evt);
      });
      */

      return dropdownView;
    });
  }
}

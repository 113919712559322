import React, { HTMLAttributes, useContext } from 'react';
import { Question, QuestionKeys } from '../../../models/questions';
import {
  SurveyParticipationCopytext,
  SurveyParticipationFreeText,
  SurveyParticipationMultipleChoice,
  SurveyParticipationQuestionNavigation,
  SurveyParticipationScaleChoice,
  SurveyParticipationSingleChoice,
} from './index';
import { Survey } from '../../../models/surveys';
import Box from '@mui/material/Box';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import SubmissionContext from '../../contexts/SubmissionContext';
import useQuestionSubmitHandler from '../../../hooks/useQuestionSubmitHandler';
import { Submission } from '../../../models/submissions';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import LinearProgress from '@mui/material/LinearProgress';

import Paper from '@mui/material/Paper';
import useGlobalStyles from '../../../hooks/useGlobalStyles';
import parse from 'html-react-parser';
import Divider from '@mui/material/Divider';
import { Translation } from '../../../models/translation';

interface Props extends HTMLAttributes<HTMLElement> {
  position: number;
  questionCount: number;
  question: Question;
  stepForward: () => void;
  stepBack: () => void;
  cancel: () => void;
  survey: Survey;
  preview?: boolean;
  translation: Translation;
}

const getSubmittedValuesByQuestion = (question: Question, submission: Submission) => {
  const answers =
    submission.answers?.edges.filter((answer) => answer?.node?.question?.id === question.id) ?? [];

  if (!answers.length) {
    return {};
  }

  const answer = answers[0];

  const submittedValues = {} as any;

  if (answer?.node?.choices) {
    if (question.type === QuestionKeys.MULTIPLE_CHOICE) {
      submittedValues.choices = answer.node.choices;
    } else {
      submittedValues.choice = answer.node.choices[0];
    }
  }

  if (answer?.node?.comment) {
    submittedValues.comment = answer.node.comment;
  }

  return submittedValues;
};

export default function SurveyParticipationQuestionComponent(props: Props) {
  const {
    question,
    position,
    questionCount,
    stepBack,
    survey,
    stepForward,
    cancel,
    preview,
    translation,
  } = props;
  const { classes: globalClasses } = useGlobalStyles();

  const submission = useContext(SubmissionContext);
  const submitHandler = useQuestionSubmitHandler(survey, question, stepForward, submission);
  const submittedValues = getSubmittedValuesByQuestion(question, submission);

  let initialValues: any = {};
  let yupValidation: any = {};
  const hasAdditionalAnswer =
    question.additionalAnswerAllowed && question.type !== QuestionKeys.FREE_TEXT;
  const isFreeTextQuestion =
    question.type === QuestionKeys.FREE_TEXT && question.additionalAnswerAllowed;
  const isCopytextQuestion =
    question.type === QuestionKeys.FREE_TEXT && !question.additionalAnswerAllowed;

  const cancelSubmission = () => {
    if (submission.answers) {
      submission.answers.edges = [];
    }
    cancel();
  };

  switch (question.type) {
    case QuestionKeys.FREE_TEXT:
      if (question.additionalAnswerAllowed) {
        initialValues = {
          comment: submittedValues?.comment ?? '',
        };
        yupValidation = {};
      }
      break;

    case QuestionKeys.SINGLE_CHOICE:
    case QuestionKeys.SCALE_CHOICE:
      initialValues = {
        choice: submittedValues?.choice ?? null,
      };
      yupValidation = {
        choice: Yup.string().nullable().required(translation.please_select),
      };
      break;

    case QuestionKeys.MULTIPLE_CHOICE:
      initialValues = {
        choices: submittedValues?.choices ?? [],
      };
      yupValidation = {
        choices: Yup.array()
          .max(question.maxAnswers, translation.too_many_answers_chosen)
          .required(translation.choose_at_least_one_answer),
      };
      break;
  }

  if (hasAdditionalAnswer) {
    initialValues.comment = submittedValues?.comment ?? '';
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object(yupValidation)}
      onSubmit={(values, formikBag) => {
        submitHandler(values, formikBag);
      }}
      key={`${question.id}`}
    >
      {(props: FormikProps<any>) => (
        <Form autoComplete="off" style={{ width: '100%' }}>
          <LinearProgress
            variant="determinate"
            color="secondary"
            value={(position / questionCount) * 100}
          />
          <Paper component="section" variant="outlined" className={globalClasses.paper}>
            <Grid container spacing={3}>
              {!isCopytextQuestion && (
                <Grid item xs={12}>
                  <Box component="header" mb={2} className={globalClasses.richText}>
                    {parse(question.text)}
                  </Box>
                  <Divider />
                  {question.minAnswers >= 2 && (
                    <Typography gutterBottom>
                      {translation.at_least} {question.minAnswers} {translation.answers}
                    </Typography>
                  )}
                  {question.maxAnswers >= 2 && question.maxAnswers < question.choices.length && (
                    <Typography gutterBottom>
                      {translation.at_most} {question.maxAnswers} {translation.answers}
                    </Typography>
                  )}
                </Grid>
              )}
              {isFreeTextQuestion && (
                <Grid item xs={12}>
                  <SurveyParticipationFreeText question={question} formikProps={props} />
                </Grid>
              )}
              {isCopytextQuestion && (
                <Grid item xs={12}>
                  <SurveyParticipationCopytext question={question} />
                </Grid>
              )}
              {question.type === QuestionKeys.MULTIPLE_CHOICE && (
                <Grid item xs={12}>
                  <SurveyParticipationMultipleChoice question={question} formikProps={props} />
                </Grid>
              )}
              {question.type === QuestionKeys.SINGLE_CHOICE && (
                <Grid item xs={12}>
                  <SurveyParticipationSingleChoice question={question} formikProps={props} />
                </Grid>
              )}
              {question.type === QuestionKeys.SCALE_CHOICE && (
                <Grid item xs={12}>
                  <SurveyParticipationScaleChoice question={question} formikProps={props} />
                </Grid>
              )}

              {hasAdditionalAnswer && (
                <Grid item xs={12}>
                  <Box mt={2}>
                    <FormLabel htmlFor="comment">
                      {question.additionalAnswerLabel
                        ? question.additionalAnswerLabel
                        : translation.comment}
                    </FormLabel>
                  </Box>
                  <TextField
                    key={question.id}
                    type="text"
                    multiline={true}
                    rows={10}
                    variant="outlined"
                    fullWidth={true}
                    name="comment"
                    id="comment"
                    onChange={(event) => props.setFieldValue('comment', event.target.value)}
                    defaultValue={props.values.comment}
                  />
                </Grid>
              )}

              {question.explanation && (
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    {translation.additional_question_info}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: '0.9rem',
                    }}
                  >
                    {question.explanation
                      .split('\n')
                      .map((explanationLine, explanationLineIndex) => {
                        return (
                          <React.Fragment key={explanationLineIndex}>
                            {explanationLine}
                            <br />
                          </React.Fragment>
                        );
                      })}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Paper>
          <SurveyParticipationQuestionNavigation
            isSubmitting={props.isSubmitting}
            position={position}
            stepBack={stepBack}
            questionCount={questionCount}
            cancel={cancelSubmission}
            preview={preview}
            translation={translation}
          />
        </Form>
      )}
    </Formik>
  );
}

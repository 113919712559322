import React from 'react';
import Chip from '@mui/material/Chip';
import useGlobalStyles from '../../hooks/useGlobalStyles';
import {
  QualityManagementTaskStatusKeys,
  QualityManagementTaskStatusLabels,
} from '../../models/qualityManagementTasks';
import { QualityManagementAppointment } from '../../models/qualityManagementTaskAppointments';

interface Props {
  qualityManagementAppointment: QualityManagementAppointment;
}

export const QualityManagementTaskStatus: React.FC<Props> = (props) => {
  const { qualityManagementAppointment } = props;
  const { classes: globalClasses } = useGlobalStyles();

  let stateClass;
  switch (qualityManagementAppointment.stateName) {
    case QualityManagementTaskStatusKeys.IN_PROGRESS:
      stateClass = globalClasses.chipActive;
      break;
    case QualityManagementTaskStatusKeys.RESOLVED:
      stateClass = globalClasses.chipExpired;
      break;
    default:
      stateClass = globalClasses.chipPrimary;
  }

  return (
    <Chip
      size="small"
      classes={{
        root: stateClass,
        sizeSmall: globalClasses.chipStatus,
      }}
      label={
        QualityManagementTaskStatusLabels.get(qualityManagementAppointment.stateName) ||
        QualityManagementTaskStatusLabels.get(QualityManagementTaskStatusKeys.OPEN)
      }
    />
  );
};

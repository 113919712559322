import React, { Fragment } from 'react';
import Button from '@mui/material/Button';
import { TreeChapter } from '../../models/chapters';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import InsertIcon from '@mui/icons-material/ArrowBackRounded';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import CollapsedIcon from '@mui/icons-material/AddBoxOutlined';

const useStyles = makeStyles({ name: 'ManualChapterTreeLevel' })((theme: Theme) => {
  return {
    treeActionItem: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'flex-end',
      listStyle: 'none',
      '&:before': {
        position: 'absolute',
        top: '50%',
        left: 0,
        display: 'block',
        borderTop: '1px dashed',
        borderTopColor: theme.palette.primary.light,
        backgroundColor: 'transparent',
        width: '100%',
        height: '1px',
        content: '""',
      },
      '&:last-child': {
        marginBottom: theme.spacing(3),
      },
      '& .MuiButton-outlined': {
        backgroundColor: theme.palette.background.paper,
      },
    },
    treeItem: {
      '& > .MuiTreeItem-content': {
        cursor: 'default',
      },
      '&[aria-expanded] > .MuiTreeItem-content': {
        cursor: 'pointer',
      },
      '&.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
        backgroundColor: 'transparent',
      },
      '&.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label': {
        backgroundColor: 'transparent',
      },
    },
  };
});

const maxChapterTreeLevels = 6;

interface Props {
  chapters: TreeChapter[];
  currentPosId: string | null;
  currentLevel: number;
  changeHandler: (newPosId: string | null) => void;
  fieldHandler: (field: string, value: any, shouldValidate?: boolean) => void;
  chapterToMoveId: string | null;
  branchIsOffLimits?: boolean;
  manualCustomChapterLabel?: boolean | undefined;
}

const ManualChapterTreeLevelComponent: React.FC<Props> = (props) => {
  const {
    chapters,
    currentPosId,
    currentLevel,
    changeHandler,
    fieldHandler,
    chapterToMoveId,
    branchIsOffLimits,
    manualCustomChapterLabel,
  } = props;
  const { classes } = useStyles();

  return (
    <Fragment>
      {chapters.map((chapter: TreeChapter, index: number) => {
        const hasChildren = chapter.childNodes?.length > 0;
        const nextSibling = chapters[index + 1] ?? null;
        const isChapterToMove = !!(chapterToMoveId && chapterToMoveId === chapter.id);
        const nextSiblingIsChapterToMove = !!(
          chapterToMoveId &&
          nextSibling &&
          chapterToMoveId === nextSibling.id
        );
        const isOffLimits =
          !!branchIsOffLimits ||
          !!(chapterToMoveId && chapterToMoveId === chapter.parentId) ||
          currentLevel > maxChapterTreeLevels;
        return (
          <Fragment key={'moveDialogItem' + chapter.id}>
            {index === 0 && (
              <li className={classes.treeActionItem}>
                <Button
                  variant={
                    currentPosId === `${chapter.parentId}-${index}` ? 'contained' : 'outlined'
                  }
                  color="primary"
                  size="small"
                  startIcon={<InsertIcon />}
                  disabled={isChapterToMove || isOffLimits}
                  onClick={() => {
                    changeHandler(`${chapter.parentId}-${index}`);
                    fieldHandler('parentId', chapter.parentId ?? null);
                    fieldHandler('targetIndex', index);
                  }}
                >
                  {chapterToMoveId ? 'gewünschte Position' : 'neue Position'}
                </Button>
              </li>
            )}
            <TreeItem
              nodeId={'moveDialogTreeItem' + chapter.id}
              label={<Typography>{`${chapter.chapterNumber} ${chapter.title}`.trim()}</Typography>}
              expandIcon={!hasChildren && <CollapsedIcon />}
              classes={{ root: classes.treeItem }}
            >
              {hasChildren ? (
                <ManualChapterTreeLevelComponent
                  chapters={chapter.childNodes}
                  currentPosId={currentPosId}
                  currentLevel={currentLevel + 1}
                  changeHandler={changeHandler}
                  fieldHandler={fieldHandler}
                  chapterToMoveId={chapterToMoveId}
                  branchIsOffLimits={isOffLimits}
                  manualCustomChapterLabel={manualCustomChapterLabel}
                />
              ) : (
                <li className={classes.treeActionItem}>
                  <Button
                    variant={currentPosId === `${chapter.id}-0` ? 'contained' : 'outlined'}
                    color="primary"
                    size="small"
                    startIcon={<InsertIcon />}
                    disabled={
                      isChapterToMove || isOffLimits || currentLevel >= maxChapterTreeLevels
                    }
                    onClick={() => {
                      changeHandler(`${chapter.id}-0`);
                      fieldHandler('parentId', chapter.id ?? null);
                      fieldHandler('targetIndex', 0);
                    }}
                  >
                    {chapterToMoveId ? 'gewünschte Position' : 'neue Position'}
                  </Button>
                </li>
              )}
            </TreeItem>
            <li className={classes.treeActionItem}>
              <Button
                variant={
                  currentPosId === `${chapter.parentId}-${index + 1}` ? 'contained' : 'outlined'
                }
                color="primary"
                size="small"
                startIcon={<InsertIcon />}
                disabled={isChapterToMove || nextSiblingIsChapterToMove || isOffLimits}
                onClick={() => {
                  changeHandler(`${chapter.parentId}-${index + 1}`);
                  fieldHandler('parentId', chapter.parentId ?? null);
                  fieldHandler('targetIndex', index + 1);
                }}
              >
                {chapterToMoveId ? 'gewünschte Position' : 'neue Position'}
              </Button>
            </li>
          </Fragment>
        );
      })}
    </Fragment>
  );
};

export default ManualChapterTreeLevelComponent;

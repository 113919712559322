import React from 'react';
import Typography from '@mui/material/Typography';
import { Survey } from '../../../models/surveys';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Translation } from '../../../models/translation';

interface Props {
  survey: Survey;
  stepForward: () => void;
  translation: Translation;
}

export default function SurveyParticipationIntroComponent(props: Props) {
  const { survey, stepForward, translation } = props;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography paragraph>{survey.description}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          size="large"
          color="primary"
          onClick={() => stepForward()}
          data-test="actionSubmit"
        >
          {translation.start_survey}
        </Button>
      </Grid>
    </Grid>
  );
}

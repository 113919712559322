import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { NavLink, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useMutation, useQuery } from '@apollo/client';
import {
  QUALITYMANAGEMENTTASK_QUERY,
  UPDATE_QUALITYMANAGEMENTTASK_MUTATION,
} from '../../operations/qualityManagementTask';
import { routes } from '../../models/routes';
import { default as QualityManagementTaskEditForm } from './QualityManagementTaskEditForm.component';
import { FormikHelpers, FormikValues } from 'formik';
import { decodeIriFromUrlParam, encodeIriToUrlParam, parseUuidFromId } from '../../utils/helper';
import { CREATE_ACTIVITYLOG_MUTATION } from '../../operations/activityLog';
import Alert from '@mui/material/Alert';
import { ToolbarPaper } from '../common';
import Button from '@mui/material/Button';
import ListIcon from '@mui/icons-material/ReorderRounded';
import EditMultipleIcon from '@mui/icons-material/AppRegistrationRounded';
import { QualityManagementTaskRepeatCycleKeys } from '../../models/qualityManagementTasks';
import CalendarIcon from '@mui/icons-material/CalendarMonthRounded';
import {
  QualityManagementTaskAppointment,
  QualityManagementTaskAppointmentNode,
} from '../../models/qualityManagementTaskAppointments';
import { UserOption } from '../../models/users';

function useQualityManagementTaskSubmitHandler(qualityManagementTaskId: string | null) {
  const { enqueueSnackbar } = useSnackbar();

  const [updateQualityManagementTaskMutation] = useMutation(UPDATE_QUALITYMANAGEMENTTASK_MUTATION, {
    onCompleted({ updateQualityManagementTask }) {
      if (updateQualityManagementTask) {
        enqueueSnackbar('QM-Aufgabe erfolgreich aktualisiert', {
          variant: 'success',
        });
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten', {
          variant: 'warning',
        });
      }
    },
    onError(error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  return async (values: FormikValues, formikBag: FormikHelpers<any>) => {
    if (!qualityManagementTaskId?.length) {
      formikBag.setStatus('Es ist ein Fehler aufgetreten');
      formikBag.setSubmitting(false);
      return false;
    }
    try {
      const inputData: { [k: string]: any } = {
        id: qualityManagementTaskId,
        title: values.title,
        description: values.description,
        assignedUsers: values.assignedUsers.map((assignedUser: UserOption) => assignedUser.id),
      };
      if (values.appointmentDate) {
        inputData.appointmentDate = values.appointmentDate;
      }
      if (values.state) {
        inputData.state = values.state;
      }
      if (values.appointmentId) {
        inputData.modifyOnly = parseUuidFromId(values.appointmentId);
      }
      await updateQualityManagementTaskMutation({
        variables: {
          input: {
            ...inputData,
          },
        },
      });
    } catch (e) {
      console.error(e);
    } finally {
      formikBag.setSubmitting(false);
      formikBag.resetForm();
    }
  };
}

export default function QualityManagementTaskEditComponent() {
  const [qualityManagementTaskId, setQualityManagementTaskId] = useState(null);
  const handleSubmit = useQualityManagementTaskSubmitHandler(qualityManagementTaskId);
  let { qualityManagementTaskId: qualityManagementTaskIdParam } =
    useParams<Record<string, string | undefined>>();
  let { qualityManagementTaskAppointmentId: qualityManagementTaskAppointmentIdParam } =
    useParams<Record<string, string | undefined>>();

  const qualityManagementTaskQueryId: string = decodeIriFromUrlParam(qualityManagementTaskIdParam);
  const qualityManagementTaskAppointmentId: string = decodeIriFromUrlParam(
    qualityManagementTaskAppointmentIdParam
  );

  const { error, data, loading } = useQuery(QUALITYMANAGEMENTTASK_QUERY, {
    variables: {
      id: qualityManagementTaskQueryId,
    },
    fetchPolicy: 'network-only',
    onCompleted({ qualityManagementTask }) {
      setQualityManagementTaskId(qualityManagementTask?.id || null);
    },
  });

  const [createActivityLogMutation] = useMutation(CREATE_ACTIVITYLOG_MUTATION, {
    onError(error) {
      console.error(error);
    },
  });

  useEffect(() => {
    if (!qualityManagementTaskId) {
      return;
    }

    createActivityLogMutation({
      variables: {
        input: {
          accessedEntity: qualityManagementTaskId,
        },
      },
    });

    if (!qualityManagementTaskAppointmentId) {
      return;
    }

    createActivityLogMutation({
      variables: {
        input: {
          accessedEntity: qualityManagementTaskAppointmentId,
        },
      },
    });
  }, [qualityManagementTaskId, qualityManagementTaskAppointmentId, createActivityLogMutation]);

  // Note: Log single appointments when no qualityManagementTaskAppointmentId is included in the url
  useEffect(() => {
    if (qualityManagementTaskAppointmentId || !data?.qualityManagementTask) {
      return;
    }

    const appointments: QualityManagementTaskAppointment[] =
      data?.qualityManagementTask.qualityManagementTaskAppointments?.edges?.map(
        (appointmentEdge: QualityManagementTaskAppointmentNode) => appointmentEdge.node
      );

    const appointment: QualityManagementTaskAppointment | null =
      appointments.length > 0 ? appointments[0] : null;

    if (!appointment) {
      return;
    }

    createActivityLogMutation({
      variables: {
        input: {
          accessedEntity: appointment.id,
        },
      },
    });
  }, [qualityManagementTaskAppointmentId, data, createActivityLogMutation]);

  if (loading) {
    return null;
  }
  if (error?.message)
    return (
      <Container>
        <Alert severity="error">Es ist ein Fehler aufgetreten: {error.message}</Alert>
      </Container>
    );

  return (
    <Container>
      <Box component="header" mb={3}>
        <Typography component="h1" variant="h2" gutterBottom>
          {qualityManagementTaskAppointmentId.length ? 'QM-Aufgabentermin' : 'QM-Aufgabe'}{' '}
          bearbeiten
        </Typography>
        {data?.qualityManagementTask?.repeatCycleName !==
          QualityManagementTaskRepeatCycleKeys.NONE &&
          data.qualityManagementTask.id &&
          qualityManagementTaskAppointmentId.length && (
            <Box display="flex" justifyContent="space-between" mt={2}>
              <Button
                component={NavLink}
                to={routes['QUALITYMANAGEMENTTASKAPPOINTMENTS_EDIT'].path
                  .replace(
                    ':qualityManagementTaskId',
                    encodeIriToUrlParam(data.qualityManagementTask.id)
                  )
                  .replace(
                    ':qualityManagementTaskAppointmentId',
                    encodeIriToUrlParam(qualityManagementTaskAppointmentId)
                  )}
                variant="outlined"
                color="primary"
                startIcon={<EditMultipleIcon />}
              >
                Terminserie bearbeiten
              </Button>
            </Box>
          )}
      </Box>
      {data?.qualityManagementTask ? (
        <>
          <QualityManagementTaskEditForm
            formData={data.qualityManagementTask}
            appointmentId={qualityManagementTaskAppointmentId}
            submitHandler={handleSubmit}
          />
          <ToolbarPaper>
            <Button
              component={NavLink}
              to={routes['QUALITYMANAGEMENTTASKS'].path}
              variant="outlined"
              color="primary"
              startIcon={<ListIcon />}
              className="alignRight"
            >
              QM-Aufgabenliste
            </Button>
            <Button
              component={NavLink}
              to={routes['QUALITYMANAGEMENTTASKS_CALENDAR'].path}
              variant="outlined"
              color="primary"
              startIcon={<CalendarIcon />}
            >
              QM-Aufgabenkalender
            </Button>
          </ToolbarPaper>
        </>
      ) : (
        <Alert severity="warning">QM-Aufgabe nicht gefunden</Alert>
      )}
    </Container>
  );
}

import React from 'react';
import Chip from '@mui/material/Chip';
import useGlobalStyles from '../../hooks/useGlobalStyles';
import { Document, DocumentStatusLabels } from '../../models/documents';

interface Props {
  document: Document | undefined;
}

export const DocumentStatus: React.FC<Props> = (props) => {
  const { document } = props;
  const { classes: globalClasses } = useGlobalStyles();

  if (document?.tenant?.id) {
    return null;
  }

  return (
    <Chip
      size="small"
      classes={{
        root: globalClasses.chipWarning,
        sizeSmall: globalClasses.chipStatus,
      }}
      label={DocumentStatusLabels.TEMPLATE_SYSTEM}
    />
  );
};

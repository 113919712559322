import { MediaObject } from '../models/mediaObject';
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { CREATE_MEDIA_OBJECT_MUTATION } from '../operations/mediaObject';

interface useFileUploadReturnType {
  uploadFile: (file: any) => void;
  mediaObject: MediaObject | null;
  errorUpload: string | null;
  isUploading: boolean;
  lastUploadedFile: any | null;
  clearUpload: () => void;
}

export default function useFileUpload(): useFileUploadReturnType {
  const [mediaObject, setMediaObject] = useState<MediaObject | null>(null);
  const [errorUpload, setErrorUpload] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [lastUploadedFile, setLastUploadedFile] = useState<any | null>(null);

  const [createMediaObjectMutation] = useMutation(CREATE_MEDIA_OBJECT_MUTATION, {
    onCompleted() {},
    onError(error) {
      console.error(error);
      setErrorUpload(error.message);
    },
  });

  const clearUpload = () => {
    setMediaObject(null);
    setErrorUpload(null);
    setIsUploading(false);
    setLastUploadedFile(null);
  };

  const uploadFile = async (file: any) => {
    setIsUploading(true);

    try {
      const createMediaObjectResponse = await createMediaObjectMutation({
        variables: {
          file,
        },
      });

      const mediaObject: MediaObject = createMediaObjectResponse.data.uploadMediaObject.mediaObject;

      setMediaObject(mediaObject);
      setIsUploading(false);
      setLastUploadedFile(file);
    } catch (error) {
      setErrorUpload(error.message);
      setIsUploading(false);
    }
  };

  return { uploadFile, mediaObject, errorUpload, isUploading, lastUploadedFile, clearUpload };
}

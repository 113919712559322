import React, { Fragment } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { ToolbarPaper } from '../common';
import Button from '@mui/material/Button';
import { NavLink, useParams } from 'react-router-dom';
import { routes } from '../../models/routes';
import ListIcon from '@mui/icons-material/ReorderRounded';
import { useQuery } from '@apollo/client';
import useGlobalStyles from '../../hooks/useGlobalStyles';
import EditIcon from '@mui/icons-material/EditRounded';
import useLoggedInMePermissions from '../../hooks/useLoggedInMePermissions';
import { permissionComponentKeys } from '../../models/permissions';
import { FACILITY_QUERY } from '../../operations/facility';
import CallIcon from '@mui/icons-material/CallRounded';
import FaxIcon from '@mui/icons-material/SettingsPhoneRounded';
import EmailIcon from '@mui/icons-material/EmailRounded';
import IconButton from '@mui/material/IconButton';
import { decodeIriFromUrlParam, encodeIriToUrlParam } from '../../utils/helper';
import Tooltip from '@mui/material/Tooltip';

export default function FacilityComponent() {
  const { classes: globalClasses } = useGlobalStyles();
  const permissions = useLoggedInMePermissions(permissionComponentKeys.FACILITIES);

  let { facilityId: facilityIdParam } = useParams<Record<string, string | undefined>>();

  const facilityQueryId: string = decodeIriFromUrlParam(facilityIdParam);

  const { error, data, loading } = useQuery(FACILITY_QUERY, {
    variables: {
      id: facilityQueryId,
    },
    fetchPolicy: 'network-only',
  });

  if (loading) return null;
  if (error?.message)
    return (
      <Container>
        <Alert severity="error">Es ist ein Fehler aufgetreten: {error.message}</Alert>
      </Container>
    );

  return (
    <Container>
      <Box component="header" mb={3}>
        <Typography component="h1" variant="h2" gutterBottom>
          Einrichtung
        </Typography>
      </Box>
      {data?.facility ? (
        <Fragment>
          <Paper component="section" variant="outlined" className={globalClasses.paper}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography component="h2" variant="h3" gutterBottom>
                  {data.facility.name}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h6">Träger:</Typography>
                <Typography variant="body1">{data.facility.tenant?.name ?? '—'}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h6">Leitung:</Typography>
                <Typography variant="body1">{data.facility.director ?? '—'}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h6">Adresse:</Typography>
                <Typography variant="body1">
                  {data.facility.street &&
                  data.facility.houseNumber &&
                  data.facility.zipCode &&
                  data.facility.city ? (
                    <Fragment>
                      {data.facility.street} {data.facility.houseNumber}
                      <br />
                      {data.facility.zipCode} {data.facility.city}
                    </Fragment>
                  ) : (
                    '—'
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h6">Telefon:</Typography>
                <Box display="flex" alignItems="center">
                  <Typography variant="body1">{data.facility.phone ?? '—'}</Typography>
                  {data.facility.phone && (
                    <Box ml={1}>
                      <Tooltip title="Anrufen">
                        <IconButton
                          href={`tel:${data.facility.phone}`}
                          size="small"
                          color="primary"
                          aria-label="Anrufen"
                        >
                          <CallIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h6">Fax:</Typography>
                <Box display="flex" alignItems="center">
                  <Typography variant="body1">{data.facility.fax ?? '—'}</Typography>
                  {data.facility.fax && (
                    <Box ml={1}>
                      <Tooltip title="Fax">
                        <IconButton
                          href={`tel:${data.facility.fax}`}
                          size="small"
                          color="primary"
                          aria-label="Fax"
                        >
                          <FaxIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h6">E-Mail:</Typography>
                <Box display="flex" alignItems="center">
                  <Typography variant="body1">{data.facility.email ?? '—'}</Typography>
                  {data.facility.email && (
                    <Box ml={1}>
                      <Tooltip title="E-Mail">
                        <IconButton
                          href={`mailto:${data.facility.email}`}
                          size="small"
                          color="primary"
                          aria-label="E-Mail"
                        >
                          <EmailIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Fragment>
      ) : (
        <Alert severity="warning">Einrichtung nicht gefunden</Alert>
      )}
      {data?.facility && (
        <Fragment>
          <ToolbarPaper>
            {permissions?.update && (
              <Button
                component={NavLink}
                to={routes['FACILITY_EDIT'].path.replace(
                  ':facilityId',
                  encodeIriToUrlParam(data.facility.id)
                )}
                variant="contained"
                color="primary"
                startIcon={<EditIcon />}
              >
                Bearbeiten
              </Button>
            )}
            <Button
              component={NavLink}
              to={routes['FACILITIES'].path}
              variant="outlined"
              color="primary"
              startIcon={<ListIcon />}
              className="alignRight"
            >
              Alle Einrichtungen
            </Button>
          </ToolbarPaper>
        </Fragment>
      )}
    </Container>
  );
}

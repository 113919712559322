import React, { useCallback, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/EditRounded';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import { ConfirmDialog, CustomDialogTitle, InfoDialog } from '../common';
import { useSnackbar } from 'notistack';
import AddIcon from '@mui/icons-material/AddCircleRounded';
import { DELETE_TARGETGROUP_MUTATION } from '../../operations/targetGroup';
import { config } from '../../models/config';
import { routes } from '../../models/routes';
import useGlobalStyles from '../../hooks/useGlobalStyles';
import Grid from '@mui/material/Grid';
import {
  convertBlobToBase64,
  downloadByFetch,
  encodeIriToUrlParam,
  getFileBlobByFetch,
  parseUuidFromId,
} from '../../utils/helper';
import TextField from '@mui/material/TextField';
import CopyIcon from '@mui/icons-material/FileCopyRounded';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import useLoggedInMePermissions from '../../hooks/useLoggedInMePermissions';
import { permissionComponentKeys } from '../../models/permissions';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/InfoRounded';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import LoadingButton from '@mui/lab/LoadingButton';
import QrCodeIcon from '@mui/icons-material/QrCode2Rounded';
import BlockIcon from '@mui/icons-material/BlockRounded';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CircularProgress from '@mui/material/CircularProgress';

function useTargetGroupDeleteHandler(questionId: string | null, survey: any) {
  const { enqueueSnackbar } = useSnackbar();

  const [deleteTargetGroupMutation] = useMutation(DELETE_TARGETGROUP_MUTATION, {
    onCompleted({ deleteTargetGroup }) {
      if (deleteTargetGroup) {
        enqueueSnackbar('Teilnahme-Link erfolgreich gelöscht', {
          variant: 'success',
        });
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten', {
          variant: 'warning',
        });
      }
    },
    onError(error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
    update(cache, { data: { deleteTargetGroup } }) {
      cache.modify({
        id: cache.identify(survey),
        fields: {
          targetGroups: (existingItemsRefs = [], { readField }) => {
            const totalCount: number = readField('totalCount', existingItemsRefs) || 0;
            return {
              ...existingItemsRefs,
              totalCount: totalCount - 1,
              edges: [
                ...existingItemsRefs.edges.filter(
                  (itemRef: any) =>
                    deleteTargetGroup?.targetGroup?.id !== readField('id', itemRef.node)
                ),
              ],
            };
          },
        },
      });
    },
  });

  return () => {
    if (!questionId?.length) {
      return false;
    }
    deleteTargetGroupMutation({
      variables: {
        input: {
          id: questionId,
        },
      },
    }).catch((e) => {
      console.error(e);
    });
  };
}

interface IProps {
  survey: any;
  editHandler?: (id: string | null) => void;
  enableDelete?: boolean;
}

export default function SurveyTargetGroupsComponent(props: IProps) {
  const { survey, editHandler, enableDelete } = props;
  const { classes: globalClasses } = useGlobalStyles();
  const { enqueueSnackbar } = useSnackbar();
  const permissions = useLoggedInMePermissions(permissionComponentKeys.SURVEYS);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);
  const [deleteTargetGroupId, setDeleteTargetGroupId] = useState<string | null>(null);
  const handleDelete = useTargetGroupDeleteHandler(deleteTargetGroupId, survey);
  const [qrCodeDialogOpen, setQrCodeDialogOpen] = useState<boolean>(false);
  const [qrCodeTargetGroupId, setQrCodeTargetGroupId] = useState<string | null>(null);
  const [qrCodeTargetGroupDescription, setQrCodeTargetGroupDescription] = useState<string | null>(
    null
  );
  const [isGeneratingQrCode, setIsGeneratingQrCode] = useState<boolean>(false);
  const [qrCodeBlob, setQrCodeBlob] = useState<Blob | null>(null);
  const [qrCodeImage, setQrCodeImage] = useState<string | null>(null);
  const [isDownloadingQrCodePdf, setIsDownloadingQrCodePdf] = useState<boolean>(false);

  const [
    surveyEditParticipationLinksHeadlineDialogOpen,
    setSurveyEditParticipationLinksHeadlineDialogOpen,
  ] = useState<boolean>(false);

  useEffect(() => {
    setEditMode(Boolean(!survey.isActive && (typeof editHandler === 'function' || enableDelete)));
  }, [survey, editHandler, enableDelete]);

  const closeQrCodeDialog = useCallback(() => {
    setQrCodeDialogOpen(false);
    setIsGeneratingQrCode(false);
    setIsDownloadingQrCodePdf(false);
    setQrCodeBlob(null);
    setQrCodeImage(null);
    setQrCodeTargetGroupId(null);
    setQrCodeTargetGroupDescription(null);
  }, []);

  return (
    <Box mt={4}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box className={globalClasses.tooltipBox}>
          <Typography component="h2" variant="h4">
            Teilnahmelinks
          </Typography>
          <Tooltip title="Info zu “Teilnahmelinks”">
            <IconButton
              className={globalClasses.tooltipIcon}
              color="primary"
              aria-label="Info"
              onClick={() => {
                setSurveyEditParticipationLinksHeadlineDialogOpen(true);
              }}
              size="large"
            >
              <InfoIcon />
            </IconButton>
          </Tooltip>
          <InfoDialog
            open={surveyEditParticipationLinksHeadlineDialogOpen}
            title={`Teilnahmelinks`}
            onClose={() => {
              setSurveyEditParticipationLinksHeadlineDialogOpen(false);
            }}
          >
            {editMode ? (
              <Typography paragraph>
                Erstellen Sie Teilnahmelinks, über die die Evaluationsteilnehmer*innen die
                Evaluation erreichen und an dieser teilnehmen können. Über den Button “Neuen
                Teilnahmelink hinzufügen” erstellen Sie einen neuen Teilnahmelink. Jedem
                Teilnahmelink können Sie einen Namen zuweisen. Mithilfe unterschiedlicher
                Teilnahmelinks, die Sie an verschiedene Gruppen von Teilnehmer*innen verschicken,
                haben Sie die Möglichkeit, Ihre Evaluation später automatisiert gruppenspezifisch
                auszuwerten.
              </Typography>
            ) : (
              <Typography paragraph>
                Hier werden Ihnen alle Teilnahmelinks angezeigt, die für diese Evaluation erstellt
                wurden. Mithilfe unterschiedlicher Teilnahmelinks, die Sie an verschiedene Gruppen
                von Teilnehmer*innen verschicken, haben Sie die Möglichkeit, Ihre Evaluation später
                automatisiert gruppenspezifisch auszuwerten. Indem Sie auf das Kopieren-Icon neben
                dem Teilnahmelink klicken, kopieren Sie den ausgewählten Teilnahmelink in den
                Zwischenspeicher ihres Geräts und können diesen in einem beliebigen Programm (z.B.
                E-Mail-Programm) durch die Tastenkombination Strg + v oder durch Rechtsklick +
                Einfügen in das Zielprogramm einfügen und z.B. an die entsprechende Teilnehmergruppe
                versenden.
              </Typography>
            )}
          </InfoDialog>
        </Box>
        {/*
        {!survey.isActive && typeof editHandler === 'function' && (
          <Button
            type="button"
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => editHandler(null)}
          >
            Neuen Teilnahme-Link hinzufügen
          </Button>
        )}
        */}
      </Box>
      <Paper component="section" variant="outlined">
        {survey?.targetGroups?.edges.length > 0 ? (
          <ul className={globalClasses.listStriped} data-test="list">
            {survey?.targetGroups?.edges.map((edge: any) => {
              const { node: targetGroup } = edge;
              const link =
                config.BASE_URL +
                routes['SURVEY_PARTICIPATE'].path.replace(
                  ':targetGroupId',
                  encodeIriToUrlParam(targetGroup.id)
                );
              return (
                <li key={targetGroup.id} data-test="listItem">
                  <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                    <Grid item xs={12} sm={9}>
                      <Typography variant="h6">{targetGroup.description}</Typography>
                      <Box display="flex" mt={2}>
                        <TextField
                          type="text"
                          name="targetGroupLink"
                          id={`targetGroupLink_${targetGroup.id}`}
                          label="Teilnahme-Link"
                          value={link}
                          variant="outlined"
                          size="small"
                          fullWidth
                          InputProps={{
                            readOnly: true,
                            endAdornment: (
                              <InputAdornment position="end">
                                <Tooltip title="Teilnahme-Link in Zwischenablage kopieren">
                                  <IconButton
                                    aria-label="Teilnahme-Link in Zwischenablage kopieren"
                                    onClick={() => {
                                      // https://stackoverflow.com/a/60239236
                                      if (
                                        !navigator.clipboard ||
                                        typeof ClipboardItem === 'undefined'
                                      ) {
                                        const linkField = document.getElementById(
                                          `targetGroupLink_${targetGroup.id}`
                                        ) as HTMLInputElement;
                                        linkField.select();
                                        // NOTE: setSelectionRange for mobile devices
                                        linkField.setSelectionRange(0, 99999);
                                        document.execCommand('copy');
                                        enqueueSnackbar(
                                          'Teilnahme-Link in Zwischenablage kopiert',
                                          {
                                            variant: 'success',
                                          }
                                        );
                                      } else {
                                        navigator.clipboard
                                          .writeText(link)
                                          .then(() => {
                                            enqueueSnackbar(
                                              'Teilnahme-Link in Zwischenablage kopiert',
                                              {
                                                variant: 'success',
                                              }
                                            );
                                          })
                                          .catch(() => {
                                            enqueueSnackbar('Es ist ein Fehler aufgetreten', {
                                              variant: 'error',
                                            });
                                          });
                                      }
                                    }}
                                    edge="end"
                                    size="small"
                                  >
                                    <CopyIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </InputAdornment>
                            ),
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Grid container spacing={1} justifyContent="flex-end">
                        <Grid item>
                          <Tooltip title="QR-Code für Teilnahme-Link generieren">
                            <Button
                              variant="outlined"
                              color="grey"
                              aria-label="QR-Code für Teilnahme-Link generieren"
                              className={globalClasses.buttonSquare}
                              onClick={async () => {
                                setQrCodeDialogOpen(true);
                                setQrCodeTargetGroupId(targetGroup.id);
                                setQrCodeTargetGroupDescription(targetGroup.description);
                                setIsGeneratingQrCode(true);
                                const blob = await getFileBlobByFetch(
                                  `${
                                    config.API_BASE_URL
                                  }/export/survey-qrcode-image/${parseUuidFromId(targetGroup.id)}`
                                );
                                setQrCodeBlob(blob);
                                const base64img = await convertBlobToBase64(blob);
                                setQrCodeImage(base64img);
                                setIsGeneratingQrCode(false);
                              }}
                            >
                              <QrCodeIcon />
                            </Button>
                          </Tooltip>
                        </Grid>
                        {typeof editHandler === 'function' && (
                          <Grid item>
                            <Tooltip title="Bearbeiten">
                              <Button
                                variant="outlined"
                                color="grey"
                                aria-label="Bearbeiten"
                                className={globalClasses.buttonSquare}
                                onClick={() => {
                                  editHandler(targetGroup.id);
                                }}
                              >
                                <EditIcon />
                              </Button>
                            </Tooltip>
                          </Grid>
                        )}
                        {permissions?.delete && enableDelete && (
                          <Grid item>
                            <Tooltip title="Löschen">
                              <Button
                                variant="outlined"
                                color="grey"
                                aria-label="Löschen"
                                className={globalClasses.buttonSquare}
                                onClick={() => {
                                  setDeleteTargetGroupId(targetGroup.id);
                                  setDeleteConfirmOpen(true);
                                }}
                              >
                                <DeleteIcon />
                              </Button>
                            </Tooltip>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </li>
              );
            })}
          </ul>
        ) : (
          <Box p={2}>
            <Typography variant="body1">Keine Teilnahmelinks vorhanden</Typography>
          </Box>
        )}
        {!survey.isActive && typeof editHandler === 'function' && permissions?.create && (
          <Box component="footer" className={globalClasses.paperActions}>
            <Button
              type="button"
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => editHandler(null)}
            >
              Neuen Teilnahme-Link hinzufügen
            </Button>
          </Box>
        )}
      </Paper>
      <ConfirmDialog
        open={deleteConfirmOpen}
        title={`Teilnahme-Link löschen`}
        content={`Möchten Sie den Teilnahme-Link wirklich löschen?`}
        onClose={(confirm) => {
          setDeleteConfirmOpen(false);
          if (confirm) {
            handleDelete();
          } else {
            setDeleteTargetGroupId(null);
          }
        }}
      />
      <Dialog
        open={qrCodeDialogOpen}
        onClose={() => closeQrCodeDialog()}
        aria-labelledby="modal-qrcode-title"
      >
        <CustomDialogTitle id="dialog-qrcode-title" onClose={() => closeQrCodeDialog()}>
          QR-Code für Teilnahme-Link
        </CustomDialogTitle>
        <DialogContent
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: 0,
          }}
        >
          {isGeneratingQrCode ? (
            <Box my={4}>
              <CircularProgress />
            </Box>
          ) : (
            <Box id="qrCodeDialogImage">
              {qrCodeImage ? (
                <img
                  src={qrCodeImage}
                  alt="QR-Code für Teilnahme-Link"
                  style={{ display: 'block', width: '24rem', height: 'auto' }}
                />
              ) : (
                <Box my={4}>
                  <BlockIcon fontSize="large" />
                </Box>
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            type="reset"
            color="primary"
            onClick={() => closeQrCodeDialog()}
            data-test="dialogReset"
          >
            Abbrechen
          </Button>
          {qrCodeImage && (
            <Tooltip title="QR-Code in die Zwischenablage kopieren">
              <Button
                type="button"
                variant="contained"
                color="primary"
                onClick={() => {
                  // https://stackoverflow.com/a/60239236
                  if (!navigator.clipboard || typeof ClipboardItem === 'undefined') {
                    // https://stackoverflow.com/a/48020189
                    const qrCodeContainer = document.getElementById(
                      'qrCodeDialogImage'
                    ) as HTMLElement;
                    if (!qrCodeContainer) {
                      return;
                    }
                    const range = document.createRange();
                    range.selectNode(qrCodeContainer);
                    const selection = window.getSelection();
                    if (!selection) {
                      return;
                    }
                    selection.removeAllRanges();
                    selection.addRange(range);
                    document.execCommand('copy');
                    selection.removeAllRanges();
                    enqueueSnackbar('QR-Code in Zwischenablage kopiert', {
                      variant: 'success',
                    });
                    closeQrCodeDialog();
                  } else {
                    // https://stackoverflow.com/a/59162806
                    if (!qrCodeBlob) {
                      enqueueSnackbar('Es ist ein Fehler aufgetreten', {
                        variant: 'error',
                      });
                      return;
                    }
                    const item = new ClipboardItem({ 'image/png': qrCodeBlob });
                    navigator.clipboard
                      .write([item])
                      .then(() => {
                        enqueueSnackbar('QR-Code in Zwischenablage kopiert', {
                          variant: 'success',
                        });
                        closeQrCodeDialog();
                      })
                      .catch(() => {
                        enqueueSnackbar('Es ist ein Fehler aufgetreten', {
                          variant: 'error',
                        });
                      });
                  }
                }}
              >
                Kopieren
              </Button>
            </Tooltip>
          )}
          {qrCodeTargetGroupId && (
            <Tooltip title="QR-Code PDF für Teilnahme-Link generieren">
              <LoadingButton
                onClick={async () => {
                  if (!qrCodeTargetGroupId || !qrCodeTargetGroupDescription) {
                    return;
                  }
                  setIsDownloadingQrCodePdf(true);
                  try {
                    await downloadByFetch(
                      `${config.API_BASE_URL}/export/survey-qrcode-pdf/${parseUuidFromId(
                        qrCodeTargetGroupId
                      )}`,
                      `QR-Code Teilnahme-Link ${qrCodeTargetGroupDescription}`,
                      'pdf'
                    );
                  } catch (error) {
                    enqueueSnackbar(error.message, {
                      variant: 'error',
                    });
                  }
                  closeQrCodeDialog();
                }}
                loading={isDownloadingQrCodePdf}
                variant="contained"
                color="primary"
                loadingPosition="start"
                startIcon={<PdfIcon />}
                aria-label="QR-Code PDF für Teilnahme-Link generieren"
              >
                PDF herunterladen
              </LoadingButton>
            </Tooltip>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
}

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { ForwardedRef, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import debounce from 'lodash.debounce';
import { headerHeight } from '../App.component';
import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Essentials } from '@ckeditor/ckeditor5-essentials';
import {
  Bold,
  Italic,
  Underline,
  Strikethrough,
  Subscript,
  Superscript,
  Code,
} from '@ckeditor/ckeditor5-basic-styles';
import { Link, LinkImage } from '@ckeditor/ckeditor5-link';
import { FontFamily, FontSize, FontColor, FontBackgroundColor } from '@ckeditor/ckeditor5-font';
import { Heading } from '@ckeditor/ckeditor5-heading';
import { Paragraph } from '@ckeditor/ckeditor5-paragraph';
import { BlockQuote } from '@ckeditor/ckeditor5-block-quote';
import { List } from '@ckeditor/ckeditor5-list';
import { Indent, IndentBlock } from '@ckeditor/ckeditor5-indent';
import { Alignment } from '@ckeditor/ckeditor5-alignment';
import {
  Table,
  TableToolbar,
  TableProperties,
  TableCellProperties,
} from '@ckeditor/ckeditor5-table';
import {
  Image,
  ImageCaption,
  ImageStyle,
  ImageToolbar,
  ImageUpload,
  ImageResize,
} from '@ckeditor/ckeditor5-image';
import { RemoveFormat } from '@ckeditor/ckeditor5-remove-format';
import { PasteFromOffice } from '@ckeditor/ckeditor5-paste-from-office';
import ConfigurableBase64UploadAdapter from '../../ckeditor/ConfigurableBase64UploadAdapter';
import CrossreferencePlugin from '../../ckeditor/crossreference';
import CrossreferenceInlinePlugin from '../../ckeditor/crossreferenceInline';
import CutCopyPastePlugin from '../../ckeditor/cutCopyPaste';
import DocumentLinkPlugin from '../../ckeditor/documentLink';
import DocumentLinkInlinePlugin from '../../ckeditor/documentLinkInline';
import LineHeightPlugin from '../../ckeditor/lineHeight';
import { useSnackbar } from 'notistack';
import { FormikHelpers, FormikValues } from 'formik';
import { Chapter, ChapterContentLabels } from '../../models/chapters';
import { flatten } from '../../utils/helper';
import {
  ckeditorConfigAlignmentOptions,
  ckeditorConfigColors,
  ckeditorConfigFontFamilyOptions,
  ckeditorConfigFontSizeOptions,
  ckeditorConfigHeadingOptions,
  ckeditorConfigImageResizeOptions,
  ckeditorConfigImageUploadMaxSize,
  ckeditorConfigImageUploadTypes,
  ckeditorConfigIndentBlock,
  ckeditorTableCellDefaultProperties,
  ckeditorTableDefaultProperties,
} from '../../models/ckeditor';
import { config } from '../../models/config';
import { ManualChapterCrossreferenceDialog } from './index';
import { DocumentSelectDialog } from '../common';
import { Document, DocumentNode } from '../../models/documents';
import useManualChapterSetDocumentsHandler from '../../hooks/manualChapters/useManualChapterSetDocumentHandler';
import usePrevious from '../../hooks/usePrevious';

type NewCrossreferenceHandler = (id: string, chapterNumber: string, title: string) => void;
type NewDocumentLinkHandler = (id: string, title: string, updatedDocuments: Document[]) => void;

const ManualChapterEditorComponent = React.forwardRef(
  (props: any, ref: ForwardedRef<ClassicEditor>) => {
    const { value, onChange, editorConfigCustom } = props;
    const theme = useTheme();
    const viewportLandscape = useMediaQuery('(min-width:0px) and (orientation: landscape)');
    const viewportSm = useMediaQuery(theme.breakpoints.up('sm'));
    const { enqueueSnackbar } = useSnackbar();
    const [editorInstance, setEditorInstance] = useState<any>();
    const [crossreferenceDialogOpen, setCrossreferenceDialogOpen] = useState<boolean>(false);
    const [newCrossreferenceHandler, setNewCrossreferenceHandler] =
      useState<NewCrossreferenceHandler | null>(null);
    const [documentLinkDialogOpen, setDocumentLinkDialogOpen] = useState<boolean>(false);
    const [newDocumentLinkHandler, setNewDocumentLinkHandler] =
      useState<NewDocumentLinkHandler | null>(null);
    const manualChapterSetDocuments = useManualChapterSetDocumentsHandler(
      editorConfigCustom?.chapter?.id ?? null
    );

    useEffect(() => {
      if (!editorInstance) {
        return;
      }
      if (isMobile) {
        // https://github.com/ckeditor/ckeditor5/issues/5465#issuecomment-969737308
        editorInstance.ui.view.stickyPanel.unbind('isActive');
        editorInstance.ui.view.stickyPanel.set('isActive', false);
      }
      if (!ref) {
        return;
      }
      if (typeof ref === 'function') {
        ref(editorInstance.ui.element);
      } else {
        ref.current = editorInstance.ui.element;
      }
    }, [editorInstance, ref]);

    useEffect(() => {
      if (!editorInstance) {
        return;
      }
      let offset = 56;
      if (viewportSm) {
        offset = 64;
      } else if (viewportLandscape) {
        offset = 48;
      }
      editorInstance.ui.viewportOffset = {
        top: headerHeight + offset,
      };
    }, [editorInstance, viewportLandscape, viewportSm]);

    const chapterList: Chapter[] = useMemo(
      () =>
        editorConfigCustom?.chapterTree && editorConfigCustom?.chapterTree.length > 0
          ? flatten(editorConfigCustom.chapterTree)
          : [],
      [editorConfigCustom]
    );

    const handleCrossreferenceSubmit = useCallback(
      (values: FormikValues, formikBag: FormikHelpers<any>) => {
        const { crossreferenceId, crossreferenceChapterNumber, crossreferenceTitle } = values;

        if (!crossreferenceId || !newCrossreferenceHandler) {
          enqueueSnackbar('Es ist ein Fehler aufgetreten', {
            variant: 'warning',
          });
          return;
        }

        newCrossreferenceHandler(
          crossreferenceId,
          crossreferenceChapterNumber,
          crossreferenceTitle
        );

        if (formikBag) {
          formikBag.setSubmitting(false);
          formikBag.resetForm();
        }
      },
      [newCrossreferenceHandler, enqueueSnackbar]
    );

    const crossreferenceContentHandler = useCallback(
      (crossreferenceId: string): object => {
        const chapter = chapterList.find((chapter: Chapter) => chapter.id === crossreferenceId);
        let label = `${ChapterContentLabels.get('cross-reference')} (ID: '${crossreferenceId}')`;
        let valid = true;
        if (chapterList.length > 0) {
          if (chapter) {
            label = `${chapter.chapterNumber} ${chapter.title}`.trim();
          } else {
            label = ChapterContentLabels.get('invalid-cross-reference');
          }
          valid = !!chapter;
        }
        return {
          crossreferenceLabel: label,
          crossreferenceValid: valid,
        };
      },
      [chapterList]
    );

    const contextDocuments: Document[] = useMemo(
      () =>
        editorConfigCustom.chapter?.documents?.edges.map(
          (documentNode: DocumentNode) => documentNode.node
        ) ?? [],
      [editorConfigCustom]
    );
    // Note: Currently not used b/c of missing CKEditor editingDowncast update trigger
    const prevContextDocuments: Document[] | undefined = usePrevious(contextDocuments);

    const handleDocumentLinkSubmit = useCallback(
      async (values: FormikValues, formikBag: FormikHelpers<any>) => {
        const { documentId, documentTitle } = values;

        if (!documentId || !newDocumentLinkHandler) {
          enqueueSnackbar('Es ist ein Fehler aufgetreten', {
            variant: 'warning',
          });
          return;
        }

        const updatedChapter = await manualChapterSetDocuments([
          documentId,
          ...editorConfigCustom.chapter?.documents?.edges.map(
            (documentNode: DocumentNode) => documentNode.node.id
          ),
        ]);

        const updatedDocuments = updatedChapter?.documents?.edges.map(
          (documentNode: DocumentNode) => documentNode.node
        );

        newDocumentLinkHandler(documentId, documentTitle, updatedDocuments);

        if (formikBag) {
          formikBag.setSubmitting(false);
          formikBag.resetForm();
        }
      }, // eslint-disable-next-line react-hooks/exhaustive-deps
      [newDocumentLinkHandler, enqueueSnackbar, editorConfigCustom]
    );

    const documentLinkContentHandler = useCallback(
      (documentLinkId: string, documentLinkTitle: string, relatedDocuments: Document[]): object => {
        const documents: Document[] =
          relatedDocuments && relatedDocuments.length > 0
            ? [...relatedDocuments]
            : [...contextDocuments];
        const document = documents.find((document: Document) => document.id === documentLinkId);
        return {
          documentLinkLabel: document
            ? `${ChapterContentLabels.get('document-link')}: ${document.title}`.trim()
            : `${ChapterContentLabels.get('invalid-document-link')}: ${documentLinkTitle}`,
          documentLinkValid: !!document,
        };
      },
      [contextDocuments]
    );

    //Note: Currently not used b/c of missing CKEditor editingDowncast update trigger
    useEffect(() => {
      if (!editorInstance || !prevContextDocuments) {
        return;
      }
      // @ts-ignore
      if (prevContextDocuments.length !== contextDocuments.length) {
        editorInstance.config.set('relatedDocuments', contextDocuments);
        // TODO: Find way to trigger CKEditor editingDowncast update
      }
    }, [editorInstance, contextDocuments, prevContextDocuments]);

    // https://dmitripavlutin.com/react-throttle-debounce/
    const debouncedOnChangeHandler = useMemo(
      () =>
        debounce(() => {
          if (editorInstance) {
            onChange(editorInstance.getData());
          }
        }, 500),
      [onChange, editorInstance]
    );

    useEffect(() => {
      return () => {
        debouncedOnChangeHandler.cancel();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // NOTE: chapterTree, chapterList, crossreferenceContentHandler and documentLinkContentHandler need to be defined
    if (
      !editorConfigCustom?.chapterTree ||
      chapterList.length === 0 ||
      !crossreferenceContentHandler ||
      !documentLinkContentHandler
    ) {
      return null;
    }

    return (
      <Fragment>
        <CKEditor
          editor={ClassicEditor}
          data={value}
          onReady={(editor) => {
            setEditorInstance(editor);
          }}
          onChange={() => {
            debouncedOnChangeHandler();
          }}
          onBlur={() => {
            onChange(editorInstance.getData());
          }}
          config={{
            licenseKey: config.CKEDITOR_LICENSE_KEY,
            plugins: [
              Essentials,
              Bold,
              Italic,
              Underline,
              Strikethrough,
              Subscript,
              Superscript,
              Code,
              Link,
              FontFamily,
              FontSize,
              FontColor,
              FontBackgroundColor,
              Heading,
              Paragraph,
              BlockQuote,
              List,
              Indent,
              IndentBlock,
              Alignment,
              Table,
              TableToolbar,
              TableProperties,
              TableCellProperties,
              Image,
              ImageCaption,
              ImageStyle,
              ImageToolbar,
              ImageUpload,
              ImageResize,
              LinkImage,
              RemoveFormat,
              PasteFromOffice,
              ConfigurableBase64UploadAdapter,
              CrossreferencePlugin,
              CrossreferenceInlinePlugin,
              DocumentLinkPlugin,
              DocumentLinkInlinePlugin,
              LineHeightPlugin,
              CutCopyPastePlugin,
            ],
            link: {
              decorators: {
                openInNewTab: {
                  mode: 'manual',
                  label: 'In neuem Fenster/Tab öffnen',
                  attributes: {
                    target: '_blank',
                    rel: 'noopener noreferrer',
                  },
                },
              },
              defaultProtocol: 'https://',
            },
            fontFamily: {
              options: ckeditorConfigFontFamilyOptions,
            },
            fontSize: {
              options: ckeditorConfigFontSizeOptions,
            },
            fontColor: {
              colors: ckeditorConfigColors,
            },
            fontBackgroundColor: {
              colors: ckeditorConfigColors,
            },
            heading: {
              options: ckeditorConfigHeadingOptions,
            },
            alignment: {
              options: ckeditorConfigAlignmentOptions,
            },
            indentBlock: ckeditorConfigIndentBlock,
            table: {
              contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableCellProperties'],
              tableProperties: {
                backgroundColors: ckeditorConfigColors,
                defaultProperties: ckeditorTableDefaultProperties,
              },
              tableCellProperties: {
                backgroundColors: ckeditorConfigColors,
                borderColors: ckeditorConfigColors,
                defaultProperties: ckeditorTableCellDefaultProperties,
              },
            },
            image: {
              insert: {
                type: 'auto',
              },
              upload: {
                types: ckeditorConfigImageUploadTypes,
              },
              resizeOptions: ckeditorConfigImageResizeOptions,
              toolbar: [
                'imageStyle:breakText',
                'imageStyle:wrapText',
                'resizeImage',
                'toggleImageCaption',
                'imageTextAlternative',
              ],
            },
            upload: {
              maxSize: ckeditorConfigImageUploadMaxSize,
              onMaxSizeExceeded: (file: any, confirm: any, cancel: any) => {
                let maxSizeAmount = ckeditorConfigImageUploadMaxSize / 1024;
                let maxSizeUnit = 'KB';
                if (maxSizeAmount > 1024) {
                  maxSizeAmount = maxSizeAmount / 1024;
                  maxSizeUnit = 'MB';
                }
                enqueueSnackbar(
                  `Die Bilddatei ist zu groß (max. ${maxSizeAmount} ${maxSizeUnit})!`,
                  {
                    variant: 'error',
                  }
                );
                cancel();
              },
            },
            custom: {
              openCrossreferenceDialog: (handler: NewCrossreferenceHandler) => {
                if (!handler) {
                  return;
                }
                setNewCrossreferenceHandler(() => handler);
                setCrossreferenceDialogOpen(true);
              },
              getCrossreferenceContent: crossreferenceContentHandler,
              openDocumentLinkDialog: (handler: NewDocumentLinkHandler) => {
                if (!handler) {
                  return;
                }
                setNewDocumentLinkHandler(() => handler);
                setDocumentLinkDialogOpen(true);
              },
              getDocumentLinkContent: documentLinkContentHandler,
            },
            toolbar: {
              items: [
                'heading',
                '|',
                'alignment',
                '|',
                'bold',
                'italic',
                'strikethrough',
                'underline',
                'code',
                'subscript',
                'superscript',
                'removeFormat',
                '|',
                'bulletedList',
                'numberedList',
                '|',
                'outdent',
                'indent',
                '|',
                'blockQuote',
                '-',
                'cutContent',
                'copyContent',
                'pasteContent',
                '|',
                'fontFamily',
                'fontSize',
                'lineHeight',
                '|',
                'fontColor',
                'fontBackgroundColor',
                '|',
                'link',
                'crossreferenceInline',
                'documentLinkInline',
                '|',
                'insertTable',
                '|',
                'uploadImage',
                '|',
                'undo',
                'redo',
              ],
              shouldNotGroupWhenFull: true,
            },
          }}
        />
        <ManualChapterCrossreferenceDialog
          dialogOpen={crossreferenceDialogOpen}
          chapters={editorConfigCustom.chapterTree}
          submitHandler={handleCrossreferenceSubmit}
          resetHandler={() => setCrossreferenceDialogOpen(false)}
        />
        <DocumentSelectDialog
          dialogOpen={documentLinkDialogOpen}
          submitHandler={handleDocumentLinkSubmit}
          resetHandler={() => setDocumentLinkDialogOpen(false)}
          facilityFilterIds={
            editorConfigCustom?.chapter?.manual?.facility?.id
              ? [editorConfigCustom.chapter.manual.facility.id]
              : []
          }
        />
      </Fragment>
    );
  }
);

export default ManualChapterEditorComponent;

import { useSnackbar } from 'notistack';
import { useApolloClient } from '@apollo/client';
import { Manual } from '../../models/manuals';
import { INVALID_REFERENCES_CHAPTER_QUERY } from '../../operations/invalidReferencesChapter';
import { InvalidReferencesChapter } from '../../models/invalidReferencesChapters';

export default function useCheckInvalidReferencesChapters(manual: Manual | null): any {
  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();

  const checkInvalidReferencesChapterPositions = async (): Promise<
    Map<string, number[]> | boolean | null
  > => {
    const invalidReferencesChapterPositions: Map<string, number[]> = new Map<string, number[]>();
    try {
      const response = await client.query({
        query: INVALID_REFERENCES_CHAPTER_QUERY,
        fetchPolicy: 'network-only',
        variables: {
          manual: manual?.id ?? null,
        },
      });

      const invalidReferencesChapters: InvalidReferencesChapter[] =
        response.data.invalidReferencesChapters;

      invalidReferencesChapters.forEach((invalidReferencesChapter) => {
        const chapterTitle = invalidReferencesChapter.title;
        if (!invalidReferencesChapterPositions.has(chapterTitle)) {
          invalidReferencesChapterPositions.set(chapterTitle, []);
        }
        const newInvalidReferencesChapterPositions = [
          ...(invalidReferencesChapterPositions.get(chapterTitle) ?? []),
          invalidReferencesChapter.position,
        ];
        invalidReferencesChapterPositions.set(chapterTitle, newInvalidReferencesChapterPositions);
      });
    } catch (error) {
      console.error(error.message);
      enqueueSnackbar(error.message, {
        variant: 'error',
      });

      return false;
    }

    if (!invalidReferencesChapterPositions.size) {
      return null;
    }

    return invalidReferencesChapterPositions;
  };

  return checkInvalidReferencesChapterPositions;
}

import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { gql, useMutation, useReactiveVar } from '@apollo/client';
import { loggedInMeVar } from '../../cache';
import { CREATE_DOCUMENT_MUTATION, FRAGMENT_DOCUMENT_BASE } from '../../operations/documents';
import {
  CREATE_DOCUMENTVERSION_MUTATION,
  FRAGMENT_DOCUMENTVERSION_BASE,
} from '../../operations/documentVersion';
import { routes } from '../../models/routes';
import { FormikHelpers, FormikValues } from 'formik';
import { CREATE_ACTIVITYLOG_MUTATION } from '../../operations/activityLog';

export default function useDocumentSubmitHandler() {
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const loggedInMe = useReactiveVar(loggedInMeVar);

  const [createActivityLogMutation] = useMutation(CREATE_ACTIVITYLOG_MUTATION, {
    onError(error) {
      console.error(error);
    },
  });

  const [createDocumentMutation] = useMutation(CREATE_DOCUMENT_MUTATION, {
    async onCompleted({ createDocument }) {
      if (createDocument) {
        enqueueSnackbar('Dokument erfolgreich angelegt', {
          variant: 'success',
        });

        await createActivityLogMutation({
          variables: {
            input: {
              accessedEntity: createDocument.document.id,
            },
          },
        });
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten', {
          variant: 'warning',
        });
      }
    },
    onError(error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
    update(cache, { data: { createDocument } }) {
      cache.modify({
        fields: {
          documents: (existingItemsRefs, { readField }) => {
            const totalCount: number = readField('totalCount', existingItemsRefs) || 0;
            const newItemNodeRef = cache.writeFragment({
              data: {
                ...createDocument?.document,
              },
              fragment: gql`
                fragment DocumentNew on Document {
                  ...DocumentBase
                }
                ${FRAGMENT_DOCUMENT_BASE}
              `,
              fragmentName: 'DocumentNew',
            });
            const newItemEdge = {
              node: newItemNodeRef,
            };
            return {
              ...existingItemsRefs,
              totalCount: totalCount + 1,
              edges: [...existingItemsRefs.edges, newItemEdge],
            };
          },
        },
      });
    },
  });

  const [createDocumentVersionMutation] = useMutation(CREATE_DOCUMENTVERSION_MUTATION, {
    onCompleted({ createDocumentVersion }) {
      if (createDocumentVersion) {
        enqueueSnackbar('Neue Dokumentenversion erfolgreich erstellt', {
          variant: 'success',
        });
        navigate(routes['DOCUMENTS'].path);
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten', {
          variant: 'warning',
        });
      }
    },
    onError(error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
    update(cache, { data: { createDocumentVersion } }) {
      cache.modify({
        fields: {
          documentVersions: (existingItemsRefs, { readField }) => {
            const totalCount: number = readField('totalCount', existingItemsRefs) || 0;
            const newItemNodeRef = cache.writeFragment({
              data: {
                ...createDocumentVersion?.documentVersion,
              },
              fragment: gql`
                fragment DocumentVersionNew on DocumentVersion {
                  ...DocumentVersionBase
                }
                ${FRAGMENT_DOCUMENTVERSION_BASE}
              `,
              fragmentName: 'DocumentVersionNew',
            });
            const newItemEdge = {
              node: newItemNodeRef,
            };
            return {
              ...existingItemsRefs,
              totalCount: totalCount + 1,
              edges: [...existingItemsRefs.edges, newItemEdge],
            };
          },
        },
      });
    },
  });

  return async (values: FormikValues, formikBag: FormikHelpers<any>): Promise<boolean> => {
    try {
      const inputData: { [k: string]: string | null } = {
        title: values.title,
        description: values.description,
        tenant: loggedInMe?.tenant?.id ?? null,
        createdBy: loggedInMe?.id ?? null,
      };
      if (loggedInMe?.tenant?.id && values.facility?.id) {
        inputData.facility = values.facility.id;
      }
      if (loggedInMe?.tenant?.id) {
        inputData.tags = values.tags.map((item: any) => item.id);
      }
      const { data: createDocumentData } = await createDocumentMutation({
        variables: {
          input: {
            ...inputData,
          },
        },
      });

      const documentId = await createDocumentData.createDocument?.document?.id;

      await createDocumentVersionMutation({
        variables: {
          input: {
            document: documentId,
            file: values.file,
            version: 1,
            createdBy: loggedInMe?.id ?? null,
            versionNumber: values.versionNumber || undefined,
            mostRecentEditBy: values.mostRecentEditBy || undefined,
            revisionDate: values.revisionDate ?? null,
            checkedBy: values.checkedBy || undefined,
            checkedDate: values.checkedDate ?? null,
            publishedBy: values.publishedBy || undefined,
            publishDate: values.publishDate ?? null,
            revisionChangelog: values.revisionChangelog || undefined,
          },
        },
      });

      return true;
    } catch (e) {
      console.error(e);
      return false;
    } finally {
      formikBag.setSubmitting(false);
    }
  };
}

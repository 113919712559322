import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { NavLink } from 'react-router-dom';
import { routes } from '../../models/routes';
import useLoggedInMePermissions from '../../hooks/useLoggedInMePermissions';
import { permissionComponentKeys } from '../../models/permissions';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FileCopyRoundedIcon from '@mui/icons-material/FileCopyRounded';
import Alert from '@mui/material/Alert';
import useGlobalStyles from '../../hooks/useGlobalStyles';
import { ApolloQueryResult, useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import Grid from '@mui/material/Grid';
import EditIcon from '@mui/icons-material/EditRounded';
import { DELETE_DOCUMENT_MUTATION, DOCUMENTS_QUERY } from '../../operations/documents';
import {
  Document,
  DocumentItem,
  DocumentNode,
  DocumentsFiltersSet,
  documentsFiltersSetInitial,
  documentsIriPrefix,
} from '../../models/documents';
import {
  compareByKey2Sort,
  downloadByFetch,
  encodeIriToUrlParam,
  generateLocalStorageKey,
  getFileTypeByFilepath,
  parseUuidFromId,
} from '../../utils/helper';
import GetAppIcon from '@mui/icons-material/GetApp';
import { useSnackbar } from 'notistack';
import { DocumentStatus } from './DocumentStatus.component';
import { default as DocumentDeleteDialog } from './DocumentDeleteDialog.component';
import InfoIcon from '@mui/icons-material/Info';
import { InfoDialog, OrderSelectSearch, ImagePreview } from '../common';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import {
  documentsFiltersSetVar,
  documentsLastFetchedItemsCountVar,
  documentsLastFetchedItemIdVar,
  documentsLastFetchedSearchPageIndexVar,
  documentsOrderSelectedIndexSearchVar,
  documentsOrderSelectedIndexVar,
  loggedInMeVar,
} from '../../cache';
import { Field, Form, Formik, FormikHelpers, FormikProps, FormikValues } from 'formik';
import FormControl from '@mui/material/FormControl';
import { Select, TextField } from 'formik-mui';
import MenuItem from '@mui/material/MenuItem';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import useOrderByHandler, { OrderFacet } from '../../hooks/useOrderByHandler';
import FacilityFilterSearch from '../common/filters/FacilityFilterSearch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tooltip from '@mui/material/Tooltip';
import { Tag, TagOption } from '../../models/tags';
import TagsFilterSearch from '../common/filters/TagsFilterSearch';
import Chip from '@mui/material/Chip';
import { useDocumentVersionDownload } from './Document.component';
import {
  DocumentVersion,
  DocumentVersionNode,
  documentVersionsIriPrefix,
} from '../../models/documentVersions';
import DocumentsEmailDialog from './DocumentsEmailDialog.component';
import SendIcon from '@mui/icons-material/SendRounded';
import IconButton from '@mui/material/IconButton';
import { ACTIVITYLOGS_QUERY } from '../../operations/activityLog';
import { ActivityLogNode, ActivityLogs } from '../../models/activityLogs';
import dayjs from 'dayjs';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import NewIcon from '@mui/icons-material/AutorenewRounded';
import Divider from '@mui/material/Divider';
import { ElasticSearchPayload, ElasticSearchResultEntity } from '../../models/elasticSearch';
import useElasticSearch from '../../hooks/useElasticSearch';
import parse from 'html-react-parser';
import { config } from '../../models/config';

function useDocumentsDeleteHandler(): (documentId: string) => Promise<void> {
  const { enqueueSnackbar } = useSnackbar();

  const [deleteDocumentMutation] = useMutation(DELETE_DOCUMENT_MUTATION, {
    onCompleted({ deleteDocument }) {
      if (deleteDocument) {
        enqueueSnackbar('Dokument erfolgreich gelöscht', {
          variant: 'success',
        });
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten', {
          variant: 'warning',
        });
      }
    },
    onError(error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
    update(cache, { data: { deleteDocument } }) {
      cache.modify({
        fields: {
          documents: (existingItemsRefs = [], { readField }) => {
            const totalCount: number = readField('totalCount', existingItemsRefs) || 0;
            return {
              ...existingItemsRefs,
              totalCount: totalCount - 1,
              edges: [
                ...existingItemsRefs.edges.filter(
                  (itemRef: any) => deleteDocument?.document?.id !== readField('id', itemRef.node)
                ),
              ],
            };
          },
        },
      });
    },
  });

  return async (documentId: string) => {
    if (!documentId?.length) {
      return;
    }
    try {
      await deleteDocumentMutation({
        variables: {
          input: {
            id: documentId,
          },
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
}

const documentsPerPage = 50;
const documentsActivityLogsPerPage = 5;
const documentsSearchResultsPerPage = 10;

const orderByOptions = [
  {
    key: 'createdAt',
    value: 'DESC',
    label: 'Erstellungsdatum absteigend',
  },
  {
    key: 'createdAt',
    value: 'ASC',
    label: 'Erstellungsdatum aufsteigend',
  },
  {
    key: 'title',
    value: 'ASC',
    label: 'Titel/Name aufsteigend',
  },
  {
    key: 'title',
    value: 'DESC',
    label: 'Titel/Name absteigend',
  },
  {
    key: 'updatedAt',
    value: 'ASC',
    label: 'Änderungsdatum aufsteigend',
  },
  {
    key: 'updatedAt',
    value: 'DESC',
    label: 'Änderungsdatum absteigend',
  },
];

const orderByOptionsSearch = [
  {
    key: 'score',
    value: 'DESC',
    label: 'Relevanz absteigend',
  },
  {
    key: 'score',
    value: 'ASC',
    label: 'Relevanz aufsteigend',
  },
  ...orderByOptions,
];

interface FilterByTemplateOptions {
  [key: string]: string;
}

const filterByTemplateOptions: FilterByTemplateOptions = {
  template: 'Vorlage',
  notTemplate: 'nicht Vorlagen',
};

interface ExistsFilterProps {
  [key: string]: boolean;
}

interface FilterProps {
  exists?: ExistsFilterProps[] | undefined;
  facility_list?: string[] | undefined;
  search: string | undefined;
  hasTags?: boolean | undefined;
  containsTag?: Iterable<string> | undefined;
}

function buildFilterProps(values: FormikValues | DocumentsFiltersSet, hideTemplates: boolean) {
  const { template, facility, search, tags } = values;

  // NOTE: 'undefined' needed to specifically remove unused variables for refetch
  // https://github.com/apollographql/react-apollo/issues/2300#issuecomment-458717902
  const filterProps: FilterProps = {
    exists: undefined,
    facility_list: undefined,
    search: undefined,
    hasTags: undefined,
    containsTag: undefined,
  };
  let tenantExists: boolean | null = null;

  switch (template) {
    case 'template':
      tenantExists = false;
      break;

    case 'notTemplate':
      tenantExists = true;
      break;
  }

  if (hideTemplates) {
    tenantExists = true;
  }

  if (facility?.id) {
    filterProps.facility_list = [facility.id];
  }

  if (tenantExists !== null || facility?.id === null) {
    filterProps.exists = [];
    if (tenantExists !== null) {
      filterProps.exists.push({ tenant: tenantExists });
    }
    if (facility?.id === null) {
      filterProps.exists.push({ facility: false });
    }
  }

  if (search && search.trim() !== '') {
    filterProps.search = search;
  }

  if (tags?.length > 0) {
    const containsTag: string[] = [];
    tags.forEach((tag: Tag) => {
      if (tag.id === null) {
        filterProps.hasTags = false;
        return;
      }
      containsTag.push(tag.id);
    });
    if (containsTag.length > 0) {
      filterProps.containsTag = [...containsTag];
    }
  }

  return filterProps;
}

function useDocumentsFilterByHandler(
  refetch:
    | ((variables?: Partial<Record<string, any>> | undefined) => Promise<ApolloQueryResult<any>>)
    | undefined,
  hideTemplates: boolean,
  updateFiltersSet?: (filters: DocumentsFiltersSet) => void
) {
  return async (values: FormikValues, formikBag: FormikHelpers<any>) => {
    if (!refetch) {
      return;
    }

    try {
      await refetch({
        after: null,
        ...buildFilterProps(values, hideTemplates),
        ids: undefined,
      });
      if (updateFiltersSet) {
        const { template, facility, search, tags } = values;
        updateFiltersSet({
          template: template || '',
          facility: facility ?? null,
          search: search.trim() || '',
          tags: tags || [],
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      formikBag.setSubmitting(false);
    }
  };
}

function buildElasticSearchProps(
  values: FormikValues,
  orderFacet: OrderFacet,
  currentPage: number = 0,
  hideTemplates: boolean = false
) {
  const { template, facility, search, tags } = values;

  const searchProps: ElasticSearchPayload = {
    index: 'document',
    size: documentsSearchResultsPerPage,
    from: currentPage * documentsSearchResultsPerPage,
  };

  if (search !== '') {
    searchProps.term = search.trim();
  }

  if (facility?.id) {
    searchProps['facilityIds[]'] = `${parseUuidFromId(facility.id)}`;
  }

  if (facility?.id === null) {
    searchProps['facilityIds[]'] = 'NULL';
  }

  let showTemplates: boolean | null = null;

  switch (template) {
    case 'template':
      showTemplates = true;
      break;

    case 'notTemplate':
      showTemplates = false;
      break;
  }

  if (hideTemplates) {
    showTemplates = false;
  }

  if (showTemplates !== null) {
    searchProps['additionalFields[qeasyTemplate]'] = showTemplates ? 'true' : 'false';
  }

  if (tags?.length > 0) {
    if (tags.some((tag: Tag) => tag.id === null)) {
      searchProps['additionalFields[hasTags]'] = 'false';
    } else {
      const containsTag: string[] = [];
      tags.forEach((tag: Tag) => {
        containsTag.push(`${tag.name}`);
      });
      if (containsTag.length > 0) {
        searchProps['additionalFields[tagNames]'] = [...containsTag];
      }
    }
  }

  if (orderFacet?.key && orderFacet?.value) {
    searchProps.sortBy = orderFacet.key;
    searchProps.sortOrder = orderFacet.value.toLowerCase();
  }

  return searchProps;
}

function useDocumentsElasticSearchHandler(
  getSearchResult: ((payload: ElasticSearchPayload | null) => Promise<void>) | undefined,
  updateFiltersSet?: (filters: DocumentsFiltersSet) => void,
  hideTemplates?: boolean
) {
  const orderSelectedIndexSearch = useReactiveVar(documentsOrderSelectedIndexSearchVar);

  return async (values: FormikValues, formikBag: FormikHelpers<any>, currentPage?: number) => {
    if (!getSearchResult) {
      return;
    }

    try {
      // NOTE: isDirty needed to ignore currentPage b/c of update race condition
      const payload = buildElasticSearchProps(
        values,
        orderByOptionsSearch[orderSelectedIndexSearch],
        currentPage ?? undefined,
        hideTemplates ?? undefined
      );
      await getSearchResult(payload);
      if (updateFiltersSet) {
        const { template, facility, search, tags } = values;
        updateFiltersSet({
          template: template || '',
          facility: facility ?? null,
          search: search.trim() || '',
          tags: tags || [],
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      formikBag.setSubmitting(false);
    }
  };
}

const useDocumentItemDownload = () => {
  const { enqueueSnackbar } = useSnackbar();

  return async (fileId: string, fileName: string) => {
    if (!fileId || !fileName) {
      return;
    }
    try {
      const url = `${config.API_BASE_URL}/download/${parseUuidFromId(fileId)}`;
      await downloadByFetch(url, fileName);
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
      console.error(error);
    }
  };
};

export default function DocumentsComponent() {
  const { classes: globalClasses, cx } = useGlobalStyles();
  const loggedInMe = useReactiveVar(loggedInMeVar);
  const permissions = useLoggedInMePermissions(permissionComponentKeys.DOCUMENTS);
  const download = useDocumentVersionDownload();
  const downloadItem = useDocumentItemDownload();
  const searchFormRef = useRef<FormikProps<FormikValues> | null>(null);

  const [hideTemplates, setHideTemplates] = React.useState<boolean>(
    loggedInMe
      ? localStorage.getItem(
          generateLocalStorageKey('QEasyDocumentsHideTemplates', loggedInMe.id)
        ) === 'true'
      : false
  );
  useEffect(() => {
    if (!loggedInMe) {
      return;
    }
    localStorage.setItem(
      generateLocalStorageKey('QEasyDocumentsHideTemplates', loggedInMe.id),
      hideTemplates ? 'true' : 'false'
    );
  }, [hideTemplates, loggedInMe]);

  const [displayActivityLogs, setDisplayActivityLogs] = useState<boolean>(
    loggedInMe
      ? localStorage.getItem(
          generateLocalStorageKey('QEasyDocumentsDisplayActivityLogs', loggedInMe.id)
        ) === 'true'
      : false
  );
  useEffect(() => {
    if (!loggedInMe) {
      return;
    }
    localStorage.setItem(
      generateLocalStorageKey('QEasyDocumentsDisplayActivityLogs', loggedInMe.id),
      displayActivityLogs ? 'true' : 'false'
    );
  }, [displayActivityLogs, loggedInMe]);

  const [deleteDocumentId, setDeleteDocumentId] = useState<string | null>(null);
  const deleteDocument = useDocumentsDeleteHandler();

  const lastFetchedItemsCount = useReactiveVar(documentsLastFetchedItemsCountVar);
  const lastFetchedItemId = useReactiveVar(documentsLastFetchedItemIdVar);
  const lastFetchedSearchPageIndex = useReactiveVar(documentsLastFetchedSearchPageIndexVar);
  const orderSelectedIndex = useReactiveVar(documentsOrderSelectedIndexVar);
  const orderSelectedIndexSearch = useReactiveVar(documentsOrderSelectedIndexSearchVar);
  const filtersSet = useReactiveVar(documentsFiltersSetVar);
  const searchActive = useMemo(
    () =>
      !displayActivityLogs &&
      JSON.stringify(filtersSet) !== JSON.stringify(documentsFiltersSetInitial),
    [displayActivityLogs, filtersSet]
  );
  const { getSearchResult, searchResult, loadingSearch } = useElasticSearch();
  const [initializingSearch, setInitializingSearch] = useState<boolean>(false);

  const [documents, setDocuments] = useState<Document[] | DocumentItem[]>([]);
  const documentsRefs = useRef<{ [key: string]: HTMLElement | null }>({});

  const scrollToLastFetchedItem = useCallback(() => {
    setTimeout(() => {
      if (
        lastFetchedItemId !== null &&
        documentsRefs &&
        !!documentsRefs.current[lastFetchedItemId]
      ) {
        // @ts-ignore
        documentsRefs.current[lastFetchedItemId].scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }, 1000);
  }, [documentsRefs, lastFetchedItemId]);

  const [documentsQueried, setDocumentsQueried] = useState<boolean>(false);
  const [queryDocuments, { error, data, loading, fetchMore, refetch }] = useLazyQuery(
    DOCUMENTS_QUERY,
    {
      fetchPolicy: 'cache-and-network',
    }
  );

  useEffect(() => {
    if (documentsQueried || loading || loadingSearch) {
      return;
    }

    setDocumentsQueried(true);

    if (searchActive) {
      // Note: Timeout to give newly added items time to be added to the index
      // Not sure, if 2 secs sufficient?
      setInitializingSearch(true);
      setTimeout(async () => {
        try {
          const payload = buildElasticSearchProps(
            filtersSet,
            orderByOptionsSearch[orderSelectedIndexSearch],
            lastFetchedSearchPageIndex
          );
          await getSearchResult(payload);
          scrollToLastFetchedItem();
        } catch (e) {
          console.error(e);
        } finally {
          setInitializingSearch(false);
        }
      }, 2000);
    } else {
      const orderOption = orderByOptions[orderSelectedIndex] ?? orderByOptions[0];
      const queryOrder = {
        [orderOption.key]: orderOption.value,
      };

      queryDocuments({
        variables: {
          first:
            lastFetchedItemsCount > documentsPerPage ? lastFetchedItemsCount : documentsPerPage,
          after: null,
          order: [queryOrder],
          ...buildFilterProps(filtersSet, hideTemplates),
        },
        onCompleted() {
          scrollToLastFetchedItem();
        },
      });
    }
  }, [
    documentsQueried,
    loading,
    loadingSearch,
    queryDocuments,
    orderSelectedIndex,
    orderSelectedIndexSearch,
    hideTemplates,
    filtersSet,
    searchActive,
    getSearchResult,
    lastFetchedSearchPageIndex,
    lastFetchedItemsCount,
    scrollToLastFetchedItem,
  ]);

  const [
    queryActivityLogs,
    { data: dataActivityLogs, loading: loadingActivityLogs, fetchMore: fetchMoreActivityLogs },
  ] = useLazyQuery(ACTIVITYLOGS_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted(data) {
      refetchByIds(data);
    },
    onError(error) {
      console.error(error);
    },
  });

  useEffect(() => {
    if (!queryActivityLogs || searchActive) {
      return;
    }
    if (displayActivityLogs) {
      queryActivityLogs({
        variables: {
          accessedEntity: documentsIriPrefix,
          first: documentsActivityLogsPerPage,
          after: null,
        },
      });
    } else {
      setDocumentsQueried(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayActivityLogs, queryActivityLogs]);

  useEffect(() => {
    if (
      (searchActive && (!searchResult || loadingSearch)) ||
      (!searchActive && (!data?.documents?.edges || loading)) ||
      (displayActivityLogs && loadingActivityLogs)
    ) {
      return;
    }

    const items = searchActive
      ? searchResult?.entities.map((entity: ElasticSearchResultEntity) => {
          const { data } = entity;
          let snippet = '';
          if (data['attachment.content'].includes('<em>')) {
            snippet = data['attachment.content'];
          } else if (data.description.includes('<em>')) {
            snippet = data.description;
          } else if (data.comment.includes('<em>')) {
            snippet = data.comment;
          }
          const item: DocumentItem = {
            id: `${documentsIriPrefix}/${data.id}`,
            title: data.title,
            createdAt: data.createdAt,
            updatedAt: data.updatedAt,
            snippet: snippet || undefined,
            author: data.author || undefined,
            fileId: data.fileId || undefined,
            fileName: data.fileName || undefined,
            currentVersionId: data.currentVersionId
              ? `${documentVersionsIriPrefix}/${data.currentVersionId}`
              : undefined,
            previewThumbnail: data.previewThumbnail || undefined,
          };
          if (data.facilityIds[0] && data.facilityIds[0] !== 'NULL' && data.facilityName) {
            item.facility = {
              id: data.facilityIds[0],
              name: data.facilityName,
            };
          }
          if (data.tenantId && data.tenantId !== 'NULL' && data.tenantName) {
            item.tenant = {
              id: data.tenantId,
              name: data.tenantName,
            };
          }
          if (data.tagNames && data.tagNames.length > 0) {
            if (Array.isArray(data.tagNames)) {
              item.tagNames = [...data.tagNames];
            } else {
              item.tagNames = [data.tagNames];
            }
          }
          return item;
        })
      : data.documents.edges.map((edge: DocumentNode) => edge.node);

    if (displayActivityLogs && dataActivityLogs?.activityLogs?.edges) {
      const sortedItems = items.sort((itemA: Document, itemB: Document) => {
        const activityLogA = dataActivityLogs.activityLogs.edges.find(
          (edge: ActivityLogNode) => edge.node.accessedEntity === itemA.id
        );
        const activityLogB = dataActivityLogs.activityLogs.edges.find(
          (edge: ActivityLogNode) => edge.node.accessedEntity === itemB.id
        );
        if (!activityLogA?.node?.lastAccessDate || !activityLogB?.node?.lastAccessDate) {
          return 0;
        }
        return dayjs(activityLogA.node.lastAccessDate).isBefore(
          dayjs(activityLogB.node.lastAccessDate)
        )
          ? 1
          : -1;
      });
      setDocuments(sortedItems);
    } else {
      setDocuments(items);
    }
  }, [
    loading,
    data,
    loadingActivityLogs,
    dataActivityLogs,
    displayActivityLogs,
    searchActive,
    loadingSearch,
    searchResult,
  ]);

  const refetchByIds = async (data: any) => {
    if (!refetch) {
      return;
    }
    const ids = data?.activityLogs?.edges.map((edge: ActivityLogNode) => edge.node.accessedEntity);
    try {
      await refetch({
        first: undefined,
        after: undefined,
        order: undefined,
        ...buildFilterProps({}, false),
        ids: ids.length ? ids : [''],
      });
    } catch (error) {
      console.error(error);
    }
  };

  const refetchHideTemplates = (hide: boolean) => {
    if (!refetch) {
      return;
    }
    try {
      refetch({
        after: null,
        exists: hide ? [{ tenant: true }] : undefined,
        ids: undefined,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleOrderBy = useOrderByHandler(refetch, (index: number) => {
    const selectedKey = orderByOptions[index].key;
    const selectedValue = orderByOptions[index].value;
    // Check if selected option also available for search list and update search orderByIndex accordingly
    const mappedIndex = orderByOptionsSearch.findIndex(
      (option) => option.key === selectedKey && option.value === selectedValue
    );
    if (mappedIndex > -1) {
      documentsOrderSelectedIndexSearchVar(mappedIndex);
    }
    documentsOrderSelectedIndexVar(index);
  });

  const handleFilterBy = useDocumentsFilterByHandler(
    refetch,
    hideTemplates,
    (filters: DocumentsFiltersSet) => {
      documentsFiltersSetVar(filters);
    }
  );

  const handleOrderBySearch = useCallback(
    (values: FormikValues, formikBag: FormikHelpers<any>) => {
      const { orderByIndex } = values;
      if (orderByIndex > -1) {
        const selectedKey = orderByOptionsSearch[orderByIndex].key;
        const selectedValue = orderByOptionsSearch[orderByIndex].value;
        // Check if selected option also available for standard list and update standard orderByIndex accordingly
        const mappedIndex = orderByOptions.findIndex(
          (option) => option.key === selectedKey && option.value === selectedValue
        );
        if (mappedIndex > -1) {
          documentsOrderSelectedIndexVar(mappedIndex);
        }
        documentsOrderSelectedIndexSearchVar(orderByIndex);
      }
      formikBag.setSubmitting(false);
      if (searchFormRef?.current) {
        searchFormRef.current.handleSubmit();
      }
    },
    [searchFormRef]
  );

  const handleElasticSearch = useDocumentsElasticSearchHandler(
    getSearchResult,
    (filters: DocumentsFiltersSet) => {
      documentsFiltersSetVar(filters);
    },
    hideTemplates
  );

  const [documentsEmailOpen, setDocumentsEmailOpen] = useState<boolean>(false);
  const [documentsEmailInfoOpen, setDocumentsEmailInfoOpen] = useState<boolean>(false);
  const [documentThumbnailOpen, setDocumentThumbnailOpen] = useState<boolean>(false);
  const [documentCurrentPreviewId, setDocumentCurrentPreviewId] = useState<string | undefined>(
    undefined
  );

  const handlePreviewOpenClick = (documentId: string) => {
    setDocumentCurrentPreviewId(documentId);
    setDocumentThumbnailOpen(true);
  };

  const handlePreviewCloseClick = () => {
    setDocumentCurrentPreviewId(undefined);
    setDocumentThumbnailOpen(false);
  };

  if (error?.message)
    return (
      <Container>
        <Alert severity="error">Es ist ein Fehler aufgetreten: {error.message}</Alert>
      </Container>
    );

  return (
    <Container>
      {documentCurrentPreviewId && (
        <ImagePreview
          dialogOpen={documentThumbnailOpen}
          onClose={() => handlePreviewCloseClick()}
          documentId={documentCurrentPreviewId}
        />
      )}
      <Box component="header" mb={6}>
        <Typography component="h1" variant="h2" gutterBottom>
          Dokumente
        </Typography>
        {permissions?.create && (
          <Grid container spacing={2}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                component={NavLink}
                to={routes['DOCUMENT_NEW'].path}
                startIcon={<InsertDriveFileIcon />}
              >
                Neues Dokument hochladen
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                component={NavLink}
                to={routes['DOCUMENTS_NEW'].path}
                startIcon={<FileCopyRoundedIcon />}
              >
                Mehrere neue Dokumente hochladen
              </Button>
            </Grid>
            <Grid item>
              <Box display="flex" alignItems="center">
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<SendIcon />}
                  onClick={() => setDocumentsEmailOpen(true)}
                >
                  Dokumente per E-Mail teilen
                </Button>
                <Box ml={1}>
                  <Tooltip title="Info zu “Dokumente per E-Mail teilen”">
                    <IconButton
                      className={globalClasses.tooltipIcon}
                      color="primary"
                      aria-label="Info"
                      onClick={() => {
                        setDocumentsEmailInfoOpen(true);
                      }}
                      size="large"
                    >
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                  <InfoDialog
                    open={documentsEmailInfoOpen}
                    title={`Dokumente per E-Mail teilen`}
                    onClose={() => {
                      setDocumentsEmailInfoOpen(false);
                    }}
                  >
                    <Typography paragraph>
                      Mithilfe der Funktion “Dokumente per E-Mail teilen” können Sie Zugangslinks zu
                      den von Ihnen ausgewählten Dokumenten per E-Mail versenden. Der/die
                      Empfänger*in muss über einen QEasy-Account verfügen, um die Dokumente aufrufen
                      zu können.
                    </Typography>
                  </InfoDialog>
                </Box>
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
      <Box my={2} display="flex" alignItems="flex-end" justifyContent="flex-end">
        <FormGroup row>
          {!displayActivityLogs && (
            <FormControlLabel
              control={
                <Switch
                  checked={hideTemplates}
                  disabled={initializingSearch}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const hide = event.target.checked;
                    setHideTemplates(hide);
                    if (searchActive && searchFormRef?.current) {
                      searchFormRef?.current?.handleSubmit();
                    } else {
                      refetchHideTemplates(hide);
                    }
                  }}
                  name="hideTemplates"
                  color="primary"
                />
              }
              label="Vorlagen ausblenden"
            />
          )}
          <FormControlLabel
            control={
              <Switch
                checked={displayActivityLogs}
                disabled={initializingSearch}
                onChange={() => {
                  setDisplayActivityLogs(!displayActivityLogs);
                }}
                name="displayActivityLogs"
                color="primary"
              />
            }
            label="Zuletzt aufgerufene"
          />
        </FormGroup>
      </Box>
      {!displayActivityLogs && (
        <Box className={globalClasses.listSearch}>
          <Box mx={1} width={'100%'}>
            <Typography component="h2" variant="h4" mb={3}>
              Bestehende Dokumente durchsuchen und filtern:
            </Typography>
            <Formik
              innerRef={searchFormRef}
              initialValues={filtersSet}
              enableReinitialize
              onSubmit={(values, formikBag) => {
                // Note: searchEnabled computed onSubmit b/c searchActive not updated after search triggered
                const searchEnabled =
                  JSON.stringify(values) !== JSON.stringify(documentsFiltersSetInitial);
                if (searchEnabled) {
                  let pageIndex = lastFetchedSearchPageIndex;
                  const isDirty = JSON.stringify(values) !== JSON.stringify(filtersSet);
                  if (isDirty) {
                    documentsLastFetchedSearchPageIndexVar(0);
                    pageIndex = 0;
                  }
                  handleElasticSearch(values, formikBag, isDirty ? 0 : pageIndex);
                } else {
                  handleFilterBy(values, formikBag);
                }
              }}
            >
              {(props) => {
                // Note: searchEnabled computed onSubmit b/c searchActive not updated after search triggered
                const searchEnabled =
                  JSON.stringify(props.values) !== JSON.stringify(documentsFiltersSetInitial);
                return (
                  <Form autoComplete="off">
                    <Grid container spacing={0} rowSpacing={1} alignItems="stretch">
                      <Grid item display="flex" xs={12} md={6} lg={3}>
                        <FormControl variant="outlined" fullWidth>
                          <Field
                            component={TextField}
                            type="text"
                            name="search"
                            id="search"
                            variant="outlined"
                            placeholder="Suchbegriff eingeben"
                            fullWidth
                            data-test="filterSearchTerm"
                            sx={{ backgroundColor: 'background.default' }}
                          />
                        </FormControl>
                      </Grid>
                      {!hideTemplates && (
                        <Grid item display="flex" xs={12} md={6} lg={2}>
                          <Field
                            component={Select}
                            name="template"
                            inputProps={{
                              'aria-label': 'Vorlagentyp',
                            }}
                            label="Vorlagentyp"
                            labelId="templateLabel"
                            formControl={{ fullWidth: true }}
                            autoWidth
                            disabled={props.isSubmitting}
                            sx={{ backgroundColor: 'background.default' }}
                          >
                            <MenuItem value="">Alle</MenuItem>
                            {Object.keys(filterByTemplateOptions).map((key) => (
                              <MenuItem key={`filterByTemplate${key}`} value={key}>
                                {filterByTemplateOptions[key]}
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid>
                      )}
                      <Grid item display="flex" xs={12} md={6} lg={hideTemplates ? 3 : 2}>
                        <FacilityFilterSearch formikProps={props} />
                      </Grid>
                      <Grid item display="flex" xs={12} md={6} lg={hideTemplates ? 3 : 2}>
                        <TagsFilterSearch formikProps={props} />
                      </Grid>
                      <Grid item display="flex" xs={12} md={12} lg={3}>
                        <Button
                          type="submit"
                          size="large"
                          variant="contained"
                          color="primary"
                          disabled={props.isSubmitting}
                          startIcon={<SearchOutlinedIcon />}
                          data-test="filterSubmit"
                          fullWidth
                        >
                          {props.dirty ? 'Suche aktualisieren' : 'Suchen'}
                        </Button>
                      </Grid>
                    </Grid>
                    {searchEnabled && (
                      <Grid container mt={1} spacing={0} rowSpacing={1} alignItems="stretch">
                        <Grid item display="flex" alignItems="center">
                          {props.values.search !== '' && (
                            <Chip
                              label={props.values.search}
                              icon={
                                props.values.search !== props.initialValues.search ? (
                                  <NewIcon />
                                ) : undefined
                              }
                              onDelete={() => {
                                props.setFieldValue('search', '');
                              }}
                              color="primary"
                              size="small"
                              className={globalClasses.listSearchChip}
                            />
                          )}
                          {props.values.search !== '' &&
                            (props.values.template !== '' ||
                              props.values.facility !== null ||
                              props.values.tags.length > 0) && (
                              <Divider
                                orientation="vertical"
                                variant="middle"
                                flexItem
                                sx={{ mr: 2 }}
                              />
                            )}
                          <Box display="flex" flexWrap="wrap">
                            {!hideTemplates && props.values.template !== '' && (
                              <Chip
                                label={filterByTemplateOptions[props.values.template]}
                                icon={
                                  props.values.template !== props.initialValues.template ? (
                                    <NewIcon />
                                  ) : undefined
                                }
                                onDelete={() => {
                                  props.setFieldValue('template', '');
                                }}
                                color="dark"
                                size="small"
                                className={globalClasses.listSearchChip}
                              />
                            )}
                            {props.values.facility !== null && (
                              <Chip
                                label={props.values.facility.name}
                                icon={
                                  props.values.facility?.id !== props.initialValues.facility?.id ? (
                                    <NewIcon />
                                  ) : undefined
                                }
                                onDelete={() => {
                                  props.setFieldValue('facility', null);
                                }}
                                color="dark"
                                size="small"
                                className={globalClasses.listSearchChip}
                              />
                            )}
                            {props.values.tags.map((chipTag: TagOption) => (
                              <Chip
                                key={'tagFilterChip' + chipTag.id}
                                label={chipTag.name}
                                icon={
                                  !props.initialValues.tags.some(
                                    (tag: TagOption) => tag.id === chipTag.id
                                  ) ? (
                                    <NewIcon />
                                  ) : undefined
                                }
                                onDelete={() => {
                                  props.setFieldValue(
                                    'tags',
                                    props.values.tags.filter(
                                      (tag: TagOption) => tag.id !== chipTag.id
                                    )
                                  );
                                }}
                                color="dark"
                                size="small"
                                className={globalClasses.listSearchChip}
                                data-test="tagFilterChip"
                              />
                            ))}
                          </Box>
                          <Button
                            type="reset"
                            size="large"
                            variant="text"
                            color="grey"
                            disabled={props.isSubmitting}
                            onClick={() => {
                              props.resetForm({
                                values: documentsFiltersSetInitial,
                              });
                              props.handleSubmit();
                            }}
                            sx={{ whiteSpace: 'nowrap' }}
                            data-test="filterReset"
                          >
                            alle löschen
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                  </Form>
                );
              }}
            </Formik>
          </Box>
          <Box mt={2} mx={1} width={'100%'}>
            <Grid
              container
              spacing={0}
              rowSpacing={1}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item display="flex" xs={12} md="auto">
                {searchActive && searchResult && (
                  <Typography variant="h3">{searchResult.hits} Treffer</Typography>
                )}
              </Grid>
              <Grid item display="flex" xs={12} md="auto">
                {searchActive ? (
                  <OrderSelectSearch
                    selectOptions={orderByOptionsSearch}
                    selectedIndex={orderSelectedIndexSearch}
                    submitHandler={handleOrderBySearch}
                  />
                ) : (
                  <OrderSelectSearch
                    selectOptions={orderByOptions}
                    selectedIndex={orderSelectedIndex}
                    submitHandler={handleOrderBy}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
      <Box className={cx({ [globalClasses.listWrapper]: !displayActivityLogs })}>
        {!(loading || loadingActivityLogs || loadingSearch) && documents.length > 0 ? (
          <ul className={globalClasses.listCards} data-test="documentsList">
            {documents.map((document: any) => {
              const currentVersion: DocumentVersion | undefined = document?.versions?.edges
                .map((documentVersion: DocumentVersionNode) => documentVersion.node)
                .sort((versionA: DocumentVersion, versionB: DocumentVersion) =>
                  compareByKey2Sort(versionA, versionB, 'version')
                )
                .pop();
              const documentTags: TagOption[] | undefined = document?.tags?.edges.map(
                (edge: any) => ({
                  id: edge.node.id,
                  name: edge.node.name,
                })
              ) as TagOption[];
              const userHasEditScope =
                loggedInMe?.tenantWideEditPermission ||
                !!loggedInMe?.facilities?.edges.some(
                  (edge) =>
                    parseUuidFromId(edge.node?.id ?? 'a') ===
                    parseUuidFromId(document.facility?.id ?? 'b')
                );
              return (
                <li
                  key={document.id}
                  ref={(element) => (documentsRefs.current[document.id] = element)}
                  data-test="listItem"
                >
                  <Grid container spacing={2} rowSpacing={1}>
                    <Grid
                      container
                      spacing={2}
                      item
                      xs={12}
                      display="flex"
                      flexWrap="nowrap"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid item>
                        {(document.facility || document.tenant) && (
                          <Typography variant="subtitle2" color="primary" mb={1.5}>
                            {document.facility?.name ?? document.tenant?.name}
                          </Typography>
                        )}
                        <Typography variant="h5" mb={0} className={globalClasses.listSearchSnippet}>
                          {parse(`${document.title}`)}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Grid container spacing={1} display="flex" flexWrap="nowrap">
                          {!document?.tenant?.id && (
                            <Grid item>
                              <Box mr={2}>
                                <DocumentStatus document={document} />
                              </Box>
                            </Grid>
                          )}
                          {currentVersion?.file.previewThumbnail && (
                            <Grid item>
                              <Tooltip title="Vorschau öffnen">
                                <Button
                                  onClick={() => {
                                    handlePreviewOpenClick(currentVersion.id);
                                  }}
                                  style={{
                                    backgroundImage: `url('data:image/jpeg;base64, ${currentVersion?.file.previewThumbnail}')`,
                                  }}
                                  className={cx(
                                    globalClasses.buttonSquare,
                                    globalClasses.buttonSquareImage
                                  )}
                                  variant="outlined"
                                  color="grey"
                                  aria-label="Vorschau öffnen"
                                />
                              </Tooltip>
                            </Grid>
                          )}
                          {!currentVersion &&
                            document.currentVersionId &&
                            document.previewThumbnail && (
                              <Grid item>
                                <Tooltip title="Vorschau öffnen">
                                  <Button
                                    onClick={() => {
                                      handlePreviewOpenClick(document.currentVersionId);
                                    }}
                                    style={{
                                      backgroundImage: `url('data:image/jpeg;base64, ${document.previewThumbnail}')`,
                                    }}
                                    className={cx(
                                      globalClasses.buttonSquare,
                                      globalClasses.buttonSquareImage
                                    )}
                                    variant="outlined"
                                    color="grey"
                                    aria-label="Vorschau öffnen"
                                  />
                                </Tooltip>
                              </Grid>
                            )}
                          <Grid item>
                            <Tooltip title="Details">
                              <Button
                                component={NavLink}
                                to={routes['DOCUMENT'].path.replace(
                                  ':documentId',
                                  encodeIriToUrlParam(document.id)
                                )}
                                variant="outlined"
                                color="grey"
                                className={globalClasses.buttonSquare}
                                aria-label="Details"
                                onClick={() => documentsLastFetchedItemIdVar(document.id)}
                                data-test="listItemActionDocumentDetails"
                              >
                                <InfoIcon />
                              </Button>
                            </Tooltip>
                          </Grid>
                          {currentVersion && (
                            <Grid item>
                              <Tooltip title="Datei herunterladen">
                                <Button
                                  onClick={() => download(currentVersion)}
                                  variant="outlined"
                                  color="grey"
                                  className={globalClasses.buttonSquare}
                                  aria-label="Datei herunterladen"
                                >
                                  <GetAppIcon />
                                </Button>
                              </Tooltip>
                            </Grid>
                          )}
                          {!currentVersion && document.fileId && document.fileName && (
                            <Grid item>
                              <Tooltip title="Datei herunterladen">
                                <Button
                                  onClick={() => downloadItem(document.fileId, document.fileName)}
                                  variant="outlined"
                                  color="grey"
                                  className={globalClasses.buttonSquare}
                                  aria-label="Datei herunterladen"
                                >
                                  <GetAppIcon />
                                </Button>
                              </Tooltip>
                            </Grid>
                          )}
                          {permissions?.update &&
                            (document.tenant?.id || loggedInMe?.tenant === null) &&
                            userHasEditScope && (
                              <Grid item>
                                <Tooltip title="Bearbeiten">
                                  <Button
                                    component={NavLink}
                                    to={routes['DOCUMENT_EDIT'].path.replace(
                                      ':documentId',
                                      encodeIriToUrlParam(document.id)
                                    )}
                                    variant="outlined"
                                    color="grey"
                                    aria-label="Bearbeiten"
                                    className={globalClasses.buttonSquare}
                                    onClick={() => documentsLastFetchedItemIdVar(document.id)}
                                    data-test="listItemActionDocumentEdit"
                                  >
                                    <EditIcon />
                                  </Button>
                                </Tooltip>
                              </Grid>
                            )}
                          {permissions?.delete && document.tenant?.id && userHasEditScope && (
                            <Grid item>
                              <Tooltip title="Löschen">
                                <Button
                                  onClick={() => setDeleteDocumentId(document.id)}
                                  variant="outlined"
                                  color="grey"
                                  aria-label="Löschen"
                                  className={globalClasses.buttonSquare}
                                  data-test="listItemActionDocumentDelete"
                                >
                                  <DeleteIcon />
                                </Button>
                              </Tooltip>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    {document.snippet && (
                      <Grid item xs={12} className={globalClasses.listSearchSnippet}>
                        {parse(`${document.snippet}`)}
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      {(currentVersion || document.fileName) && (
                        <Typography className={globalClasses.listCardDetail}>
                          Dateityp:{' '}
                          <strong>
                            {currentVersion
                              ? getFileTypeByFilepath(currentVersion?.file?.filePath ?? '') ?? '—'
                              : getFileTypeByFilepath(document.fileName ?? '') ?? '—'}
                          </strong>
                        </Typography>
                      )}
                      {document.updatedAt === document.createdAt ? (
                        <Typography className={globalClasses.listCardDetail}>
                          Erstellt am{' '}
                          <time dateTime={dayjs(document.createdAt).toISOString()}>
                            <strong>{dayjs(document.createdAt).format('DD.MM.YYYY')}</strong>
                            {(document.createdBy || document.author) && (
                              <>
                                {' '}
                                von{' '}
                                {document.author
                                  ? document.author
                                  : document.createdBy.firstName +
                                    ' ' +
                                    document.createdBy.lastName}
                              </>
                            )}
                          </time>
                        </Typography>
                      ) : (
                        <Typography className={globalClasses.listCardDetail}>
                          Zuletzt geändert am{' '}
                          <time dateTime={dayjs(document.updatedAt).toISOString()}>
                            <strong>{dayjs(document.updatedAt).format('DD.MM.YYYY')}</strong>
                            {document.updatedBy && (
                              <>
                                {' '}
                                von{' '}
                                {document.author
                                  ? document.author
                                  : document.updatedBy.firstName +
                                    ' ' +
                                    document.updatedBy.lastName}
                              </>
                            )}
                          </time>
                        </Typography>
                      )}
                    </Grid>
                    {documentTags && documentTags.length > 0 && (
                      <Grid item xs={12}>
                        <div className={globalClasses.listChips}>
                          {documentTags
                            .sort((optionA: TagOption, optionB: TagOption) =>
                              compareByKey2Sort(optionA, optionB, 'name')
                            )
                            .map((tag: TagOption) => (
                              <Chip key={tag.id} size="small" label={tag.name} data-test="tag" />
                            ))}
                        </div>
                      </Grid>
                    )}
                    {document.tagNames && document.tagNames.length > 0 && (
                      <Grid item xs={12}>
                        <div className={globalClasses.listChips}>
                          {document.tagNames.sort().map((tagName: string, index: number) => (
                            <Chip
                              key={document.id + 'Tag' + index}
                              size="small"
                              label={parse(`${tagName}`)}
                              className={globalClasses.listSearchChipSnippet}
                              data-test="tag"
                            />
                          ))}
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </li>
              );
            })}
          </ul>
        ) : (
          <Box className={globalClasses.listStatus}>
            <Typography variant="body1">
              {!documentsQueried ||
              loading ||
              loadingActivityLogs ||
              loadingSearch ||
              initializingSearch
                ? 'Bitte warten...'
                : 'Keine Dokumente vorhanden'}
            </Typography>
          </Box>
        )}
        {!displayActivityLogs &&
          !searchActive &&
          fetchMore &&
          data?.documents?.pageInfo?.hasNextPage && (
            <Box component="footer" className={globalClasses.listActions}>
              <Button
                type="button"
                variant="contained"
                color="primary"
                disabled={loading}
                onClick={async () => {
                  const { endCursor } = data.documents.pageInfo;

                  const result = await fetchMore({
                    variables: {
                      after: endCursor,
                      first: documentsPerPage,
                    },
                  });

                  // Store amount fetched docs locally
                  const amountEdges = data.documents.edges.length;
                  const addedEdges = result.data.documents.edges.length;
                  documentsLastFetchedItemsCountVar(amountEdges + addedEdges);
                }}
              >
                Mehr...
              </Button>
            </Box>
          )}
        {displayActivityLogs &&
          fetchMoreActivityLogs &&
          dataActivityLogs?.activityLogs?.pageInfo?.hasNextPage && (
            <Box component="footer" className={globalClasses.listActions}>
              <Button
                type="button"
                variant="contained"
                color="primary"
                disabled={loadingActivityLogs || loading}
                onClick={() => {
                  const { endCursor } = dataActivityLogs.activityLogs.pageInfo;
                  fetchMoreActivityLogs({
                    variables: { after: endCursor },
                    updateQuery: (previousResult: any, { fetchMoreResult }) => {
                      const previousData: ActivityLogs = previousResult.activityLogs;
                      const newData: ActivityLogs = fetchMoreResult.activityLogs;
                      const newResult = {
                        activityLogs: {
                          edges: [...previousData.edges, ...newData.edges],
                          totalCount: newData.totalCount,
                          pageInfo: { ...newData.pageInfo },
                        },
                      };
                      refetchByIds(newResult);
                      return newResult;
                    },
                  });
                }}
              >
                Mehr...
              </Button>
            </Box>
          )}
        {searchActive && searchFormRef?.current && searchResult && searchResult.maxPages > 1 && (
          <Box component="footer" className={globalClasses.listActions}>
            {[...Array(searchResult.maxPages)].map((value: any, index: number) => {
              const currentPage = searchResult.currentPage;

              // Display first page, last page && pages around the current page
              if (
                index === 0 ||
                index === searchResult.maxPages - 1 ||
                (index >= currentPage - 2 && index < currentPage + 1)
              ) {
                return (
                  <Button
                    key={'listPagination' + index}
                    type="button"
                    variant={index + 1 === currentPage ? 'contained' : 'outlined'}
                    color="primary"
                    disabled={loadingSearch}
                    onClick={() => {
                      documentsLastFetchedSearchPageIndexVar(index);
                      searchFormRef?.current?.handleSubmit();
                    }}
                  >
                    {index + 1}
                  </Button>
                );
              }

              // Display dots
              if (index === 1 || index === searchResult.maxPages - 2) {
                return <div key={'listPagination' + index}>...</div>;
              }

              // Hide the rest of the pages
              return null;
            })}
          </Box>
        )}
      </Box>
      <DocumentDeleteDialog
        open={deleteDocumentId !== null}
        documentId={deleteDocumentId}
        onClose={(confirm) => {
          if (confirm && deleteDocumentId) {
            deleteDocument(deleteDocumentId);
          }
          setDeleteDocumentId(null);
        }}
      />
      <DocumentsEmailDialog
        open={documentsEmailOpen}
        onClose={() => {
          setDocumentsEmailOpen(false);
        }}
      />
    </Container>
  );
}

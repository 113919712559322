import { Plugin } from '@ckeditor/ckeditor5-core';
import {
  toWidget,
  viewToModelPositionOutsideModelElement,
} from '@ckeditor/ckeditor5-widget/src/utils';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';
import InsertCrossreferenceInlineCommand from './InsertCrossreferenceInlineCommand';
import { ChapterContentLabels } from '../../models/chapters';

// https://ckeditor.com/docs/ckeditor5/latest/tutorials/widgets/implementing-an-inline-widget.html#final-solution
// https://ckeditor.com/docs/ckeditor5/latest/framework/guides/tutorials/using-react-in-a-widget.html#full-source-code
export default class CrossreferenceInlineEditing extends Plugin {
  static get requires() {
    return [Widget];
  }

  init() {
    this._defineSchema();
    this._defineConverters();

    this.editor.commands.add(
      'insertCrossreferenceInline',
      new InsertCrossreferenceInlineCommand(this.editor)
    );

    // https://ckeditor.com/docs/ckeditor5/latest/tutorials/widgets/implementing-an-inline-widget.html#fixing-position-mapping
    this.editor.editing.mapper.on(
      'viewToModelPosition',
      viewToModelPositionOutsideModelElement(
        this.editor.model,
        (viewElement) =>
          viewElement.name === 'span' && viewElement.hasAttribute('data-cross-reference')
      )
    );
  }

  _defineSchema() {
    const schema = this.editor.model.schema;

    schema.register('crossreferenceInline', {
      inheritAllFrom: '$inlineObject',
      allowAttributes: ['data-cross-reference'],
    });
  }

  _defineConverters() {
    const editor = this.editor;
    const conversion = editor.conversion;
    const getCrossreferenceContent = editor.config.get('custom').getCrossreferenceContent;

    conversion.for('upcast').elementToElement({
      view: {
        name: 'span',
        attributes: 'data-cross-reference',
      },
      model: (viewElement, { writer: modelWriter }) => {
        return modelWriter.createElement('crossreferenceInline', viewElement.getAttributes());
      },
    });

    conversion.for('dataDowncast').elementToElement({
      model: 'crossreferenceInline',
      view: (modelElement, { writer: viewWriter }) => {
        return viewWriter.createEmptyElement('span', {
          'data-cross-reference': modelElement.getAttribute('data-cross-reference'),
        });
      },
    });

    conversion.for('editingDowncast').elementToElement({
      model: 'crossreferenceInline',
      view: (modelElement, { writer: viewWriter }) => {
        const crossreferenceId = modelElement.getAttribute('data-cross-reference');
        const { crossreferenceLabel, crossreferenceValid } =
          getCrossreferenceContent(crossreferenceId);

        const crossreference = viewWriter.createContainerElement('span', {
          'data-cross-reference': crossreferenceId,
          class: !crossreferenceValid ? 'invalid' : '',
        });

        const innerText = viewWriter.createText(crossreferenceLabel);
        viewWriter.insert(viewWriter.createPositionAt(crossreference, 0), innerText);

        return toWidget(crossreference, viewWriter, {
          label: ChapterContentLabels.get('cross-reference'),
        });
      },
    });
  }
}

import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { gql, useMutation, useReactiveVar } from '@apollo/client';
import { loggedInMeVar } from '../../cache';
import { routes } from '../../models/routes';
import { FormikHelpers, FormikValues } from 'formik';
import Box from '@mui/material/Box';
import { default as ManualNewForm } from '../manuals/ManualNewForm.component';
import { CREATE_MANUAL_MUTATION, FRAGMENT_MANUAL_BASE } from '../../operations/manual';
import { encodeIriToUrlParam } from '../../utils/helper';

function useManualSubmitHandler() {
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const loggedInMe = useReactiveVar(loggedInMeVar);

  const [createManualMutation] = useMutation(CREATE_MANUAL_MUTATION, {
    onCompleted({ createManual }) {
      if (createManual?.manual?.id) {
        enqueueSnackbar('QM-Handbuch erfolgreich erstellt', {
          variant: 'success',
        });
        navigate(
          routes['MANUAL_EDIT'].path.replace(
            ':manualId',
            encodeIriToUrlParam(createManual.manual.id)
          )
        );
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten', {
          variant: 'warning',
        });
      }
    },
    onError(error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
    update(cache, { data: { createManual } }) {
      cache.modify({
        fields: {
          manuals: (existingItemsRefs, { readField }) => {
            const totalCount: number = readField('totalCount', existingItemsRefs) || 0;
            const newItemNodeRef = cache.writeFragment({
              data: {
                ...createManual?.manual,
              },
              fragment: gql`
                fragment ManualNew on Manual {
                  ...ManualBase
                }
                ${FRAGMENT_MANUAL_BASE}
              `,
              fragmentName: 'ManualNew',
            });
            const newItemEdge = {
              node: newItemNodeRef,
            };
            return {
              ...existingItemsRefs,
              totalCount: totalCount + 1,
              edges: [...existingItemsRefs.edges, newItemEdge],
            };
          },
        },
      });
    },
  });

  return async (values: FormikValues, formikBag: FormikHelpers<any>) => {
    try {
      const inputData: { [k: string]: string | null } = {
        title: values.title,
        tenant: loggedInMe?.tenant?.id ?? null,
        customChapterLabel: values.customChapterLabel,
      };
      if (loggedInMe?.tenant !== null && values.facility?.id) {
        inputData.facility = values.facility.id;
      }
      await createManualMutation({
        variables: {
          input: {
            ...inputData,
          },
        },
      });
    } catch (e) {
      console.error(e);
    } finally {
      formikBag.setSubmitting(false);
    }
  };
}

export default function ManualNewComponent() {
  const handleSubmit = useManualSubmitHandler();

  return (
    <Container>
      <Box component="header" mb={3}>
        <Typography component="h1" variant="h2" gutterBottom>
          QM-Handbuch erstellen
        </Typography>
      </Box>
      <ManualNewForm submitHandler={handleSubmit} />
    </Container>
  );
}

import React, { Fragment, useCallback, useEffect } from 'react';
import useGlobalStyles from '../../hooks/useGlobalStyles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/EditRounded';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import { TreeChapter } from '../../models/chapters';
import ExpandIcon from '@mui/icons-material/ExpandMoreRounded';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import SvgIcon from '@mui/material/SvgIcon';
import MoveIcon from '@mui/icons-material/OpenWithRounded';
import { NavLink } from 'react-router-dom';
import { routes } from '../../models/routes';
import ViewIcon from '@mui/icons-material/VisibilityRounded';
import { isLoadingVar, manualsChaptersTogglesVar } from '../../cache';
import { useReactiveVar } from '@apollo/client';
import { PermissionNode } from '../../models/permissions';
import CopyIcon from '@mui/icons-material/FileCopyRounded';
import { encodeIriToUrlParam } from '../../utils/helper';
import Tooltip from '@mui/material/Tooltip';

const useStyles = makeStyles({ name: 'ManualChapterLevel' })((theme: Theme) => {
  return {
    accordionContainer: {
      margin: 0,
      listStyle: 'none',
      padding: 0,
      width: '100%',
      '& &': {
        borderLeft: '1px solid',
        borderLeftColor: theme.palette.divider,
      },
    },
    accordion: {
      '&::before': {
        display: 'block !important',
        opacity: '1 !important',
        transition: 'none !important',
      },
      '&.Mui-disabled': {
        backgroundColor: theme.palette.background.paper,
      },
      '&.Mui-expanded': {
        marginTop: 0,
        marginBottom: 0,
      },
    },
    accordionSummary: {
      '&.Mui-disabled': {
        opacity: 1,
      },
    },
    accordionSummaryAction: {
      pointerEvents: 'all',
      cursor: 'pointer',
    },
    accordionDetails: {
      padding: theme.spacing(0, 0, 0, 4),
    },
  };
});

interface Props {
  manualId: string;
  manualCustomChapterLabel: boolean | undefined;
  chapters: TreeChapter[];
  level: number;
  chapterTreeExpanded: boolean | undefined;
  toggleChapterTree: (expanded: boolean | undefined) => void;
  toggleChapterExpanded?: (expanded: boolean) => void;
  moveHandler: (chapterId?: string) => void;
  deleteHandler: (chapterId?: string) => void;
  copyHandler: (chapterId?: string) => void;
  permissions?: PermissionNode | null;
  editMode?: boolean;
}

const ManualChapterLevelComponent: React.FC<Props> = (props) => {
  const {
    manualId,
    manualCustomChapterLabel,
    chapters,
    level,
    chapterTreeExpanded,
    toggleChapterTree,
    toggleChapterExpanded,
    moveHandler,
    deleteHandler,
    copyHandler,
    permissions,
    editMode,
  } = props;
  const { classes, cx } = useStyles();
  const { classes: globalClasses } = useGlobalStyles();
  const isLoading = useReactiveVar(isLoadingVar);

  const [togglesInitialized, setTogglesInitialized] = React.useState<boolean>(false);
  const manualsChaptersToggles = useReactiveVar(manualsChaptersTogglesVar);

  const [expandedPanels, setExpandedPanels] = React.useState<Array<string>>([]);

  const updateTogglesSet = useCallback(
    (chapterId: string, isExpanded: boolean) => {
      if (!manualsChaptersToggles[manualId]) {
        manualsChaptersToggles[manualId] = [];
      }
      const manualChaptersToggles = [...manualsChaptersToggles[manualId]];
      if (isExpanded) {
        manualsChaptersToggles[manualId] = [...manualChaptersToggles, chapterId];
      } else {
        manualsChaptersToggles[manualId] = manualChaptersToggles.filter((id) => id !== chapterId);
      }
      manualsChaptersTogglesVar(manualsChaptersToggles);
    },
    [manualsChaptersToggles, manualId]
  );

  useEffect(() => {
    if (togglesInitialized || !chapters || !manualsChaptersToggles[manualId]) {
      return;
    }
    chapters
      .filter((chapter: TreeChapter) => manualsChaptersToggles[manualId].includes(chapter.id))
      .forEach((chapter: TreeChapter) => {
        setExpandedPanels((expandedPanels) => {
          const updatedPanels = [...expandedPanels, chapter.id];
          if (toggleChapterExpanded && updatedPanels.length > 0) {
            toggleChapterExpanded(true);
          }
          return updatedPanels;
        });
      });
    setTogglesInitialized(true);
  }, [togglesInitialized, chapters, toggleChapterExpanded, manualsChaptersToggles, manualId]);

  useEffect(() => {
    if (chapterTreeExpanded === true) {
      setExpandedPanels(chapters.map((chapter: TreeChapter) => chapter.id));
      chapters.forEach((chapter: TreeChapter) => updateTogglesSet(chapter.id, true));
    } else if (chapterTreeExpanded === false) {
      setExpandedPanels([]);
      chapters.forEach((chapter: TreeChapter) => updateTogglesSet(chapter.id, false));
    }
  }, [chapterTreeExpanded, chapters, updateTogglesSet]);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    if (isExpanded) {
      setExpandedPanels((expandedPanels) => {
        const updatedPanels = [...expandedPanels, panel];
        if (toggleChapterExpanded && updatedPanels.length > 0) {
          toggleChapterExpanded(true);
        }
        return updatedPanels;
      });
      updateTogglesSet(panel, true);
    } else {
      setExpandedPanels((expandedPanels) => {
        const updatedPanels = expandedPanels.filter((expandedPanel) => expandedPanel !== panel);
        if (toggleChapterExpanded && updatedPanels.length === 0) {
          toggleChapterExpanded(false);
        }
        return updatedPanels;
      });
      updateTogglesSet(panel, false);
    }
    toggleChapterTree(undefined);
  };

  return (
    <div className={classes.accordionContainer} data-test="listNested">
      {chapters.map((chapter: TreeChapter) => {
        const hasChildren = chapter.childNodes?.length > 0;
        return (
          <Accordion
            key={'chapterLevel' + chapter.id}
            square
            elevation={0}
            TransitionProps={{ unmountOnExit: true }}
            disabled={!hasChildren}
            expanded={expandedPanels.includes(chapter.id)}
            onChange={handleChange(chapter.id)}
            classes={{ root: classes.accordion }}
            data-test="listNestedItem"
          >
            <AccordionSummary
              expandIcon={hasChildren ? <ExpandIcon /> : <SvgIcon />}
              aria-controls={`${chapter.id}-content`}
              id={`${chapter.id}-header`}
              classes={{ root: classes.accordionSummary }}
            >
              <Grid container wrap="nowrap" alignItems="center" justifyContent="space-between">
                <Grid item>
                  <Box display="flex">
                    {manualCustomChapterLabel && chapter.chapterNumber.length ? (
                      <Box mr={2}>
                        <Typography variant="h6" component="div">
                          {chapter.chapterNumber}
                        </Typography>
                      </Box>
                    ) : null}
                    {!manualCustomChapterLabel && (
                      <Box width={`${level * 0.875 + 0.5}rem`}>
                        <Typography variant="h6" component="div">
                          {chapter.chapterNumber}
                        </Typography>
                      </Box>
                    )}
                    <Box>
                      <Typography variant="h6" component="h5">
                        {chapter.title}
                      </Typography>
                      <Typography variant="caption">
                        Letzte Änderung:{' '}
                        <time dateTime={dayjs(chapter.updatedAt).toISOString()}>
                          {dayjs(chapter.updatedAt).format('DD.MM.YYYY HH:mm')} Uhr
                        </time>
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item>
                  <Box mr={1}>
                    <Grid
                      container
                      spacing={1}
                      wrap="nowrap"
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      {!editMode && (
                        <Grid item>
                          <Tooltip title="Details">
                            <Button
                              component={NavLink}
                              to={routes['CHAPTER'].path
                                .replace(':manualId', encodeIriToUrlParam(manualId))
                                .replace(':chapterId', encodeIriToUrlParam(chapter.id))}
                              variant="outlined"
                              color="grey"
                              aria-label="Details"
                              className={cx(
                                globalClasses.buttonSquare,
                                classes.accordionSummaryAction
                              )}
                              onClick={(event: any) => {
                                event.stopPropagation();
                              }}
                              onFocus={(event: any) => event.stopPropagation()}
                              disabled={isLoading}
                            >
                              <ViewIcon />
                            </Button>
                          </Tooltip>
                        </Grid>
                      )}
                      {editMode && permissions?.update && (
                        <Fragment>
                          <Grid item>
                            <Tooltip title="Bearbeiten">
                              <Button
                                component={NavLink}
                                to={routes['CHAPTER_EDIT'].path
                                  .replace(':manualId', encodeIriToUrlParam(manualId))
                                  .replace(':chapterId', encodeIriToUrlParam(chapter.id))}
                                variant="outlined"
                                color="grey"
                                aria-label="Bearbeiten"
                                className={cx(
                                  globalClasses.buttonSquare,
                                  classes.accordionSummaryAction
                                )}
                                onClick={(event: any) => {
                                  event.stopPropagation();
                                }}
                                onFocus={(event: any) => event.stopPropagation()}
                                disabled={isLoading}
                              >
                                <EditIcon />
                              </Button>
                            </Tooltip>
                          </Grid>
                          <Grid item>
                            <Tooltip title="Verschieben">
                              <Button
                                variant="outlined"
                                color="grey"
                                aria-label="Verschieben"
                                disableRipple
                                className={cx(
                                  globalClasses.buttonSquare,
                                  classes.accordionSummaryAction
                                )}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  moveHandler(chapter.id);
                                }}
                                onFocus={(event) => event.stopPropagation()}
                                disabled={isLoading}
                              >
                                <MoveIcon />
                              </Button>
                            </Tooltip>
                          </Grid>
                        </Fragment>
                      )}
                      {editMode && permissions?.delete && !hasChildren && (
                        <Grid item>
                          <Tooltip title="Löschen">
                            <Button
                              variant="outlined"
                              color="grey"
                              aria-label="Löschen"
                              className={cx(
                                globalClasses.buttonSquare,
                                classes.accordionSummaryAction
                              )}
                              onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                event.stopPropagation();
                                deleteHandler(chapter.id);
                              }}
                              onFocus={(event: any) => event.stopPropagation()}
                              disabled={isLoading}
                            >
                              <DeleteIcon />
                            </Button>
                          </Tooltip>
                        </Grid>
                      )}
                      {editMode && permissions?.create && (
                        <Grid item>
                          <Tooltip title="Kopieren">
                            <Button
                              variant="outlined"
                              color="grey"
                              aria-label="Kopieren"
                              className={cx(
                                globalClasses.buttonSquare,
                                classes.accordionSummaryAction
                              )}
                              onClick={(event) => {
                                event.stopPropagation();
                                copyHandler(chapter.id);
                              }}
                              onFocus={(event) => event.stopPropagation()}
                              disabled={isLoading}
                            >
                              <CopyIcon />
                            </Button>
                          </Tooltip>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails classes={{ root: classes.accordionDetails }}>
              {hasChildren && (
                <ManualChapterLevelComponent
                  manualId={manualId}
                  manualCustomChapterLabel={manualCustomChapterLabel}
                  chapters={chapter.childNodes}
                  level={level + 1}
                  chapterTreeExpanded={chapterTreeExpanded}
                  toggleChapterTree={toggleChapterTree}
                  moveHandler={moveHandler}
                  deleteHandler={deleteHandler}
                  copyHandler={copyHandler}
                  permissions={permissions}
                  editMode={editMode}
                />
              )}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

export default ManualChapterLevelComponent;

import { Plugin } from '@ckeditor/ckeditor5-core';
import { toWidget } from '@ckeditor/ckeditor5-widget/src/utils';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';
import InsertDocumentLinkCommand from './InsertDocumentLinkCommand';
import { ChapterContentLabels } from '../../models/chapters';

// https://ckeditor.com/docs/ckeditor5/latest/framework/guides/tutorials/implementing-a-block-widget.html#full-source-code
// https://ckeditor.com/docs/ckeditor5/latest/framework/guides/tutorials/using-react-in-a-widget.html#full-source-code
export default class DocumentLinkEditing extends Plugin {
  static get requires() {
    return [Widget];
  }

  init() {
    this._defineSchema();
    this._defineConverters();

    this.editor.commands.add('insertDocumentLink', new InsertDocumentLinkCommand(this.editor));
  }

  _defineSchema() {
    const schema = this.editor.model.schema;

    schema.register('documentLink', {
      isObject: true,
      allowWhere: '$block',
      allowAttributes: ['data-document-link', 'data-document-title'],
    });
  }

  _defineConverters() {
    const editor = this.editor;
    const conversion = editor.conversion;
    const getDocumentLinkContent = editor.config.get('custom').getDocumentLinkContent;

    conversion.for('upcast').elementToElement({
      view: {
        name: 'div',
        attributes: ['data-document-link', 'data-document-title'],
      },
      model: (viewElement, { writer: modelWriter }) => {
        return modelWriter.createElement('documentLink', viewElement.getAttributes());
      },
    });

    conversion.for('dataDowncast').elementToElement({
      model: 'documentLink',
      view: (modelElement, { writer: viewWriter }) => {
        return viewWriter.createEmptyElement('div', {
          'data-document-link': modelElement.getAttribute('data-document-link'),
          'data-document-title': modelElement.getAttribute('data-document-title'),
        });
      },
    });

    conversion.for('editingDowncast').elementToElement({
      model: 'documentLink',
      view: (modelElement, { writer: viewWriter }) => {
        const documentLinkId = modelElement.getAttribute('data-document-link');
        const documentLinkTitle = modelElement.getAttribute('data-document-title');

        const relatedDocuments = editor.config.get('relatedDocuments') || [];

        const { documentLinkLabel, documentLinkValid } = getDocumentLinkContent(
          documentLinkId,
          documentLinkTitle,
          relatedDocuments
        );

        const documentLink = viewWriter.createContainerElement(
          'div',
          {
            'data-document-link': documentLinkId,
            'data-document-title': documentLinkTitle,
            class: !documentLinkValid ? 'invalid' : '',
          },
          [
            viewWriter.createRawElement('span', {}, function (domElement) {
              domElement.innerHTML = documentLinkLabel;
            }),
          ]
        );

        return toWidget(documentLink, viewWriter, {
          label: ChapterContentLabels.get('document-link'),
        });
      },
    });
  }
}

import React, { Fragment, useEffect, useState } from 'react';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FormikHelpers, FormikValues } from 'formik';
import { default as SurveyEditForm } from './SurveyEditForm.component';
import { default as SurveyQuestions } from './SurveyQuestions.component';
import { default as SurveyQuestionForm } from './SurveyQuestionForm.component';
import { default as SurveyTargetGroups } from './SurveyTargetGroups.component';
import { default as SurveyTargetGroupForm } from './SurveyTargetGroupForm.component';
import { default as SurveyCopytextForm } from './SurveyCopytextForm.component';
import { useSnackbar } from 'notistack';
import { SURVEY_QUERY, UPDATE_SURVEY_MUTATION } from '../../operations/survey';
import { CREATE_QUESTION_MUTATION, UPDATE_QUESTION_MUTATION } from '../../operations/question';
import { QuestionKeys } from '../../models/questions';
import Button from '@mui/material/Button';
import { routes } from '../../models/routes';
import { ConfirmDialog, FileUploadDialog, ToolbarPaper } from '../common';
import ListIcon from '@mui/icons-material/ReorderRounded';
import {
  CREATE_TARGETGROUP_MUTATION,
  UPDATE_TARGETGROUP_MUTATION,
} from '../../operations/targetGroup';
import { decodeIriFromUrlParam, encodeIriToUrlParam } from '../../utils/helper';
import { loggedInMeVar } from '../../cache';
import CopyIcon from '@mui/icons-material/FileCopyRounded';
import useLoggedInMePermissions from '../../hooks/useLoggedInMePermissions';
import { permissionComponentKeys } from '../../models/permissions';
import useSurveyCopyHandler from '../../hooks/surveys/useSurveyCopyHandler';
import useSurveyUpdateHandler from '../../hooks/surveys/useSurveyUpdateHandler';
import { SurveyStatusPayload } from '../../models/surveys';
import PlayIcon from '@mui/icons-material/PlayArrowRounded';
import dayjs from 'dayjs';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import useGlobalStyles from '../../hooks/useGlobalStyles';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import useSurveyAddLogoHandler from '../../hooks/surveys/useSurveyAddLogoHandler';
import { MediaObject } from '../../models/mediaObject';
import useBase64ImageByMediaObject from '../../hooks/mediaObjects/useBase64ImageByMediaObject';
import UploadIcon from '@mui/icons-material/CloudUploadRounded';
import Tooltip from '@mui/material/Tooltip';
import { CREATE_ACTIVITYLOG_MUTATION } from '../../operations/activityLog';
import { default as SurveyCopyDialog } from './SurveyCopyDialog.component';
import ViewIcon from '@mui/icons-material/VisibilityRounded';
import { default as SurveyPreview } from './SurveyPreview.component';
import { default as SurveyPdfDialog } from './SurveyPdfDialog.component';

function useSurveyMutationHandler(surveyId: string | null) {
  const { enqueueSnackbar } = useSnackbar();
  const loggedInMe = useReactiveVar(loggedInMeVar);

  const [updateSurveyMutation] = useMutation(UPDATE_SURVEY_MUTATION, {
    onCompleted({ updateSurvey }) {
      if (updateSurvey) {
        enqueueSnackbar('Evaluation erfolgreich aktualisiert', {
          variant: 'success',
        });
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten', {
          variant: 'warning',
        });
      }
    },
    onError(error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  return async (values: FormikValues, formikBag: FormikHelpers<any>) => {
    if (!surveyId?.length) {
      formikBag.setStatus('Es ist ein Fehler aufgetreten');
      formikBag.setSubmitting(false);
      return false;
    }
    try {
      await updateSurveyMutation({
        variables: {
          input: {
            id: surveyId,
            title: values.title,
            description: values.description,
            isActive: values.isActive,
            tenant: values.tenant?.id ?? null,
            startsAt: values.startsAt,
            endsAt: values.endsAt,
            updatedBy: loggedInMe?.id ?? null,
          },
        },
      });
    } catch (e) {
      console.error(e);
    } finally {
      formikBag.setSubmitting(false);
    }
  };
}

function useTargetGroupSubmitHandler(
  surveyId: string | null,
  targetGroupId: string | null,
  successCallback: () => void
) {
  const { enqueueSnackbar } = useSnackbar();

  const [createTargetGroupMutation] = useMutation(CREATE_TARGETGROUP_MUTATION, {
    onCompleted({ createTargetGroup }) {
      if (createTargetGroup?.targetGroup?.id) {
        enqueueSnackbar('Teilnahme-Link erfolgreich angelegt', {
          variant: 'success',
        });
        successCallback();
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten', {
          variant: 'warning',
        });
      }
    },
    onError(error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  const [updateTargetGroupMutation] = useMutation(UPDATE_TARGETGROUP_MUTATION, {
    onCompleted({ updateTargetGroup }) {
      if (updateTargetGroup) {
        enqueueSnackbar('Teilnahme-Link erfolgreich aktualisiert', {
          variant: 'success',
        });
        successCallback();
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten', {
          variant: 'warning',
        });
      }
    },
    onError(error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  return async (values: FormikValues, formikBag: FormikHelpers<any>) => {
    if (!surveyId?.length) {
      formikBag.setStatus('Es ist ein Fehler aufgetreten');
      formikBag.setSubmitting(false);
      return false;
    }
    try {
      if (targetGroupId) {
        await updateTargetGroupMutation({
          variables: {
            input: {
              id: targetGroupId,
              description: values.description,
            },
          },
        });
      } else {
        await createTargetGroupMutation({
          variables: {
            input: {
              description: values.description,
              survey: surveyId,
            },
          },
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      formikBag.setSubmitting(false);
      formikBag.resetForm();
    }
  };
}

function useQuestionSubmitHandler(
  surveyId: string | null,
  questionId: string | null,
  successCallback: () => void
) {
  const { enqueueSnackbar } = useSnackbar();

  const [createQuestionMutation] = useMutation(CREATE_QUESTION_MUTATION, {
    onCompleted({ createQuestion }) {
      if (createQuestion?.question?.id) {
        enqueueSnackbar('Frage erfolgreich angelegt', {
          variant: 'success',
        });
        successCallback();
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten', {
          variant: 'warning',
        });
      }
    },
    onError(error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  const [updateQuestionMutation] = useMutation(UPDATE_QUESTION_MUTATION, {
    onCompleted({ updateQuestion }) {
      if (updateQuestion) {
        enqueueSnackbar('Frage erfolgreich aktualisiert', {
          variant: 'success',
        });
        successCallback();
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten', {
          variant: 'warning',
        });
      }
    },
    onError(error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  return async (values: FormikValues, formikBag: FormikHelpers<any>) => {
    if (!surveyId?.length) {
      formikBag.setStatus('Es ist ein Fehler aufgetreten');
      formikBag.setSubmitting(false);
      return false;
    }
    let type = values.type;
    let maxAnswers = 1;
    let minAnswers = 1;
    let additionalAnswerAllowed = values.additionalAnswerAllowed;
    if (type === QuestionKeys.MULTIPLE_CHOICE) {
      maxAnswers = values.choices.length;
    } else if (type === QuestionKeys.FREE_TEXT) {
      additionalAnswerAllowed = true;
      minAnswers = 0;
    } else if (type === QuestionKeys.COPY_TEXT) {
      type = QuestionKeys.FREE_TEXT;
      additionalAnswerAllowed = false;
      minAnswers = 0;
    }

    const additionalAnswerLabel =
      values.additionalAnswerLabel && values.additionalAnswerLabel.trim() !== ''
        ? values.additionalAnswerLabel
        : null;

    const inputData = {
      text: values.text,
      position: values.position,
      explanation: values.explanation !== '' ? values.explanation : null,
      survey: surveyId,
      type: type,
      additionalAnswerAllowed: additionalAnswerAllowed,
      additionalAnswerLabel: additionalAnswerLabel,
      maxAnswers: maxAnswers,
      minAnswers: minAnswers,
      choices: values.choices,
    };
    try {
      if (questionId) {
        await updateQuestionMutation({
          variables: {
            input: {
              id: questionId,
              ...inputData,
            },
          },
        });
      } else {
        await createQuestionMutation({
          variables: {
            input: {
              ...inputData,
            },
          },
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      formikBag.setSubmitting(false);
      formikBag.resetForm();
    }
  };
}

export default function SurveyEditComponent() {
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { classes: globalClasses } = useGlobalStyles();
  const loggedInMe = useReactiveVar(loggedInMeVar);
  const permissions = useLoggedInMePermissions(permissionComponentKeys.SURVEYS);
  const [surveyId, setSurveyId] = useState(null);

  const [targetGroupId, setTargetGroupId] = useState<string | null>(null);
  const [targetGroup2Edit, setTargetGroup2Edit] = useState<any | null>(null);
  const [targetGroupDialogOpen, setTargetGroupDialogOpen] = useState<boolean>(false);
  const [questionId, setQuestionId] = useState<string | null>(null);
  const [copyMode, setCopyMode] = useState<boolean>(false);
  const [question2Edit, setQuestion2Edit] = useState<any | null>(null);
  const [questionPositionMax, setQuestionPositionMax] = useState(0);
  const [questionDialogOpen, setQuestionDialogOpen] = useState<boolean>(false);
  const [copytextDialogOpen, setCopytextDialogOpen] = useState<boolean>(false);

  const [activateConfirmOpen, setActivateConfirmOpen] = useState<boolean>(false);
  const [copyDialogOpen, setCopyDialogOpen] = useState<boolean>(false);
  const [pdfDialogOpen, setPdfDialogOpen] = useState<boolean>(false);
  const [previewDialogOpen, setPreviewDialogOpen] = useState<boolean>(false);

  const [surveyEditFormDirty, setSurveyEditFormDirty] = useState(false);

  let { surveyId: surveyIdParam } = useParams<Record<string, string | undefined>>();

  const surveyQueryId: string = decodeIriFromUrlParam(surveyIdParam);

  const { error, data, loading, refetch } = useQuery(SURVEY_QUERY, {
    variables: {
      id: surveyQueryId,
    },
    fetchPolicy: 'network-only',
    onCompleted({ survey }) {
      setSurveyId(survey?.id || null);
    },
  });

  const [createActivityLogMutation] = useMutation(CREATE_ACTIVITYLOG_MUTATION, {
    onError(error) {
      console.error(error);
    },
  });

  useEffect(() => {
    if (!surveyId) {
      return;
    }

    createActivityLogMutation({
      variables: {
        input: {
          accessedEntity: surveyId,
        },
      },
    });
  }, [surveyId, createActivityLogMutation]);

  const handleSurveySubmit = useSurveyMutationHandler(surveyId);

  const handleSurveyActivate = useSurveyUpdateHandler(surveyId, (survey) => {
    if (survey?.id) {
      navigate(routes['SURVEY'].path.replace(':surveyId', encodeIriToUrlParam(survey.id)));
    }
  });
  // Needed b/c effectively no redirect from this view on copy success
  const handleCopyDialogClose = () => {
    setCopyDialogOpen(false);
  };
  const handleSurveyCopy = useSurveyCopyHandler(surveyId, handleCopyDialogClose);

  const handleTargetGroupNewSubmit = useTargetGroupSubmitHandler(surveyId, null, () => {
    setTargetGroupDialogOpen(false);
    // NOTE: Not updating cache (would involve targetGroups AND survey) - refetching survey instead
    refetch();
  });
  const handleTargetGroupEditSubmit = useTargetGroupSubmitHandler(surveyId, targetGroupId, () => {
    setTargetGroupDialogOpen(false);
    setTargetGroupId(null);
  });
  const handleTargetGroupEdit = (id: string | null) => {
    setTargetGroupId(id);
    setTargetGroupDialogOpen(true);
  };
  useEffect(() => {
    const targetGroup =
      data?.survey?.targetGroups?.edges.filter(
        (edge: any) => edge?.node?.id === targetGroupId
      )[0] ?? null;
    setTargetGroup2Edit(targetGroup);
  }, [targetGroupId, data?.survey?.targetGroups?.edges]);

  const handleQuestionNewSubmit = useQuestionSubmitHandler(surveyId, null, () => {
    setQuestionDialogOpen(false);
    // NOTE: Not updating cache (would involve questions AND survey) - refetching survey instead
    refetch();
  });
  const handleQuestionEditSubmit = useQuestionSubmitHandler(surveyId, questionId, () => {
    setQuestionDialogOpen(false);
    setQuestionId(null);
  });
  const handleQuestionEdit = (id: string | null) => {
    setCopyMode(false);
    setQuestionId(id);
    setQuestionDialogOpen(true);
  };
  const handleQuestionCopy = (id: string | null) => {
    setCopyMode(true);
    setQuestionId(id);
    setQuestionDialogOpen(true);
  };
  useEffect(() => {
    const question =
      data?.survey?.questions?.edges.filter((edge: any) => edge?.node?.id === questionId)[0] ??
      null;
    const positionMax = data?.survey?.questions?.edges.reduce((acc: number, edge: any) => {
      return edge?.node?.position > acc ? edge?.node?.position : acc;
    }, 0);
    setQuestion2Edit(question);
    setQuestionPositionMax(positionMax);
  }, [questionId, data?.survey?.questions?.edges]);

  const handleCopytextNewSubmit = useQuestionSubmitHandler(surveyId, null, () => {
    setCopytextDialogOpen(false);
    // NOTE: Not updating cache (would involve questions AND survey) - refetching survey instead
    refetch();
  });
  const handleCopytextEditSubmit = useQuestionSubmitHandler(surveyId, questionId, () => {
    setCopytextDialogOpen(false);
    setQuestionId(null);
  });
  const handleCopytextEdit = (id: string | null) => {
    setCopyMode(false);
    setQuestionId(id);
    setCopytextDialogOpen(true);
  };
  const handleCopytextCopy = (id: string | null) => {
    setCopyMode(true);
    setQuestionId(id);
    setCopytextDialogOpen(true);
  };

  //logo spcific functions
  const { image: logoSrc, refetch: refetchLogoSrc } = useBase64ImageByMediaObject(
    data?.survey?.logo ?? null
  );
  const [logoDialogOpen, setLogoDialogOpen] = useState<boolean>(false);
  const [logoDeleteConfirmOpen, setLogoDeleteConfirmOpen] = useState<boolean>(false);
  const updateSurveyWithLogo = useSurveyAddLogoHandler(surveyId ?? null);

  const closeLogoDialog = () => {
    setLogoDialogOpen(false);
  };

  const submitLogoDialog = async (mediaObject: MediaObject) => {
    setLogoDialogOpen(false);
    await updateSurveyWithLogo(mediaObject.id, data?.manual?.logo?.id ?? null);
    refetchLogoSrc(mediaObject);
  };

  const removeLogo = async () => {
    updateSurveyWithLogo(null, data?.manual?.logo?.id ?? null);
  };

  if (loading) {
    return null;
  }
  if (error?.message)
    return (
      <Container>
        <Alert severity="error">Es ist ein Fehler aufgetreten: {error.message}</Alert>
      </Container>
    );

  return (
    <Fragment>
      <Container>
        <Box component="header" mb={3}>
          <Typography component="h1" variant="h2" gutterBottom>
            {data?.survey?.tenant?.id ? 'Evaluation bearbeiten' : 'Evaluationsvorlage bearbeiten'}
          </Typography>
        </Box>
        {data?.survey ? (
          <Fragment>
            <SurveyEditForm
              formData={data.survey}
              submitHandler={handleSurveySubmit}
              dirtyHandler={(dirty: boolean) => setSurveyEditFormDirty(dirty)}
            />
            {data?.survey?.logo && (
              <Paper component="section" variant="outlined" className={globalClasses.paper}>
                <Box display="flex" width="100%" alignItems="center">
                  <Typography variant="h6" gutterBottom>
                    Logo:
                  </Typography>
                  <Box ml={3}>
                    <img
                      src={logoSrc ?? ''}
                      alt={data.survey.logo.filePath ?? ''}
                      style={{ display: 'block', maxHeight: '5rem' }}
                    />
                  </Box>
                  {permissions?.delete && (
                    <Box ml="auto">
                      <Tooltip title="Löschen">
                        <Button
                          variant="outlined"
                          color="grey"
                          aria-label="Löschen"
                          className={globalClasses.buttonSquare}
                          onClick={() => {
                            setLogoDeleteConfirmOpen(true);
                          }}
                        >
                          <DeleteIcon />
                        </Button>
                      </Tooltip>
                      <ConfirmDialog
                        open={logoDeleteConfirmOpen}
                        title={`Logo entfernen`}
                        content={`Möchten Sie das Logo wirklich entfernen?`}
                        onClose={(confirm) => {
                          setLogoDeleteConfirmOpen(false);
                          if (confirm) {
                            removeLogo();
                          }
                        }}
                      />
                    </Box>
                  )}
                </Box>
              </Paper>
            )}
          </Fragment>
        ) : (
          <Alert severity="warning">Evaluation nicht gefunden</Alert>
        )}
        <ToolbarPaper>
          {data?.survey && (
            <Fragment>
              {data.survey.tenant?.id && permissions?.publish && (
                <Fragment>
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    startIcon={<PlayIcon />}
                    onClick={() => {
                      if (
                        !data.survey.questions?.totalCount ||
                        data.survey.questions.totalCount < 1
                      ) {
                        enqueueSnackbar('Bitte mindestens eine Frage hinzufügen', {
                          variant: 'warning',
                        });
                        return false;
                      }
                      if (
                        !data.survey.targetGroups?.totalCount ||
                        data.survey.targetGroups.totalCount < 1
                      ) {
                        enqueueSnackbar('Bitte mindestens einen Teilnahme-Link hinzufügen', {
                          variant: 'warning',
                        });
                        return false;
                      }
                      if (data.survey.startsAt && dayjs(data.survey.startsAt).isBefore(dayjs())) {
                        enqueueSnackbar('Das Startdatum muss in der Zukunft liegen', {
                          variant: 'warning',
                        });
                        return false;
                      }
                      setActivateConfirmOpen(true);
                    }}
                  >
                    Aktivieren
                  </Button>
                  <ConfirmDialog
                    open={activateConfirmOpen}
                    title={`Evaluation aktivieren`}
                    content={`Möchten Sie die Evalutation jetzt aktivieren?`}
                    onClose={(confirm) => {
                      setActivateConfirmOpen(false);
                      if (confirm) {
                        const payload: SurveyStatusPayload = {
                          isActive: true,
                        };
                        if (!data.survey.startsAt) {
                          payload.startsAt = dayjs().format();
                        }
                        // @ts-ignore
                        handleSurveyActivate(payload);
                      }
                    }}
                  />
                </Fragment>
              )}
              {permissions?.create && loggedInMe?.tenant !== null && (
                <Fragment>
                  <Button
                    type="button"
                    variant="outlined"
                    color="primary"
                    startIcon={<CopyIcon />}
                    onClick={() => {
                      setCopyDialogOpen(true);
                    }}
                  >
                    Kopieren
                  </Button>
                  <SurveyCopyDialog
                    dialogOpen={copyDialogOpen}
                    formData={data.survey}
                    submitHandler={handleSurveyCopy}
                    resetHandler={() => {
                      setCopyDialogOpen(false);
                    }}
                  />
                </Fragment>
              )}
              <Button
                variant="outlined"
                color="primary"
                startIcon={<PdfIcon />}
                onClick={() => {
                  setPdfDialogOpen(true);
                }}
              >
                PDF generieren
              </Button>
              {permissions?.create && (
                <Button
                  type="button"
                  variant="outlined"
                  color="primary"
                  startIcon={<UploadIcon />}
                  onClick={() => {
                    setLogoDialogOpen(true);
                  }}
                >
                  Logo hochladen
                </Button>
              )}
            </Fragment>
          )}
          <Button
            variant="outlined"
            color="primary"
            startIcon={<ViewIcon />}
            onClick={() => {
              setPreviewDialogOpen(true);
            }}
            disabled={surveyEditFormDirty}
          >
            Vorschau
          </Button>
          <Button
            component={NavLink}
            to={routes['SURVEYS'].path}
            variant="outlined"
            color="primary"
            startIcon={<ListIcon />}
            className="alignRight"
          >
            Evaluationsliste
          </Button>
        </ToolbarPaper>
        {data?.survey && (
          <Fragment>
            {data.survey.tenant?.id && (
              <SurveyTargetGroups
                survey={data.survey}
                editHandler={handleTargetGroupEdit}
                enableDelete={true}
              />
            )}
            <SurveyQuestions
              survey={data.survey}
              questionEditHandler={handleQuestionEdit}
              questionCopyHandler={handleQuestionCopy}
              copytextEditHandler={handleCopytextEdit}
              copytextCopyHandler={handleCopytextCopy}
            />
          </Fragment>
        )}
      </Container>
      <SurveyTargetGroupForm
        dialogOpen={targetGroupDialogOpen}
        formData={targetGroup2Edit?.node}
        submitHandler={targetGroup2Edit ? handleTargetGroupEditSubmit : handleTargetGroupNewSubmit}
        resetHandler={() => {
          setTargetGroupDialogOpen(false);
          setTargetGroupId(null);
        }}
      />
      <SurveyQuestionForm
        dialogOpen={questionDialogOpen}
        formData={question2Edit?.node}
        copyMode={copyMode}
        posMax={questionPositionMax}
        submitHandler={
          question2Edit && !copyMode ? handleQuestionEditSubmit : handleQuestionNewSubmit
        }
        resetHandler={() => {
          setQuestionDialogOpen(false);
          setCopyMode(false);
          setQuestionId(null);
        }}
      />
      <SurveyCopytextForm
        dialogOpen={copytextDialogOpen}
        formData={question2Edit?.node}
        copyMode={copyMode}
        posMax={questionPositionMax}
        submitHandler={
          question2Edit && !copyMode ? handleCopytextEditSubmit : handleCopytextNewSubmit
        }
        resetHandler={() => {
          setCopytextDialogOpen(false);
          setCopyMode(false);
          setQuestionId(null);
        }}
      />
      <FileUploadDialog
        acceptedFileTypes={['image/png', 'image/jpeg']}
        dialogOpen={logoDialogOpen}
        onSubmit={submitLogoDialog}
        onClose={closeLogoDialog}
        maxFileSize={5000000}
      />
      <SurveyPreview
        dialogOpen={previewDialogOpen}
        surveyId={surveyId}
        resetHandler={() => {
          setPreviewDialogOpen(false);
          refetch();
        }}
      />
      <SurveyPdfDialog
        dialogOpen={pdfDialogOpen}
        survey={data.survey}
        closeHandler={() => {
          setPdfDialogOpen(false);
        }}
      />
    </Fragment>
  );
}

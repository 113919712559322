import React, { useCallback, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { CustomDialogTitle } from '../common';
import FilterAltIcon from '@mui/icons-material/FilterAltRounded';
import SendIcon from '@mui/icons-material/SendRounded';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { DOCUMENTS_ID_TITLE_QUERY } from '../../operations/documents';
import { DocumentNode } from '../../models/documents';
import { useQuery } from '@apollo/client';
import {
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/HighlightOffRounded';
import InputAdornment from '@mui/material/InputAdornment';
import FormLabel from '@mui/material/FormLabel';
import { Form, Field, Formik, FormikHelpers, FormikValues } from 'formik';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import { TextField as FormikTextField } from 'formik-mui';
import { config } from '../../models/config';
import { routes } from '../../models/routes';
import { encodeIriToUrlParam } from '../../utils/helper';

interface Props {
  open: boolean;
  onClose: () => any;
}

const DocumentsEmailDialog: React.FC<Props> = (props) => {
  const { open, onClose } = props;
  const [documentNodes, setDocumentNodes] = useState<DocumentNode[]>([]);
  const [documentIds, setDocumentIds] = useState<string[]>([]);
  const [searchPhrase, setSearchPhrase] = useState<string>('');
  const [searchTerms, setSearchTerms] = useState<string[]>([]);
  const [mailtoLink, setMailtoLink] = useState<string | null>(null);

  const { error, data } = useQuery(DOCUMENTS_ID_TITLE_QUERY, {
    skip: !open,
    variables: {
      first: 9999999,
      order: [{ title: 'ASC' }],
    },
    fetchPolicy: 'network-only',
  });
  useEffect(() => {
    const terms = searchPhrase
      .trim()
      .split(' ')
      .filter((s) => s !== '')
      .map((s) => s.toLocaleLowerCase());

    setSearchTerms(terms);
  }, [searchPhrase]);

  useEffect(() => {
    const filteredDocumentNodes =
      data?.documents?.edges.filter((document: DocumentNode) => {
        const documentTitle = document.node.title.toLowerCase();
        return searchTerms.every((searchTerm) => documentTitle.indexOf(searchTerm) !== -1);
      }) ?? [];

    setDocumentNodes(filteredDocumentNodes);
  }, [data, searchTerms]);

  const handleToggle = useCallback(
    (id: string) => () => {
      if (documentIds.indexOf(id) === -1) {
        setDocumentIds([...documentIds, id]);
      } else {
        setDocumentIds((prevState) => prevState.filter((documentId) => documentId !== id));
      }
    },
    [documentIds]
  );

  const handleClose = useCallback(() => {
    onClose();
    setSearchPhrase('');
    setDocumentIds([]);
    setMailtoLink(null);
  }, [onClose]);

  const handleSubmit = useCallback(
    (values: FormikValues, formikBag: FormikHelpers<any>) => {
      let emailList = values.emailAddresses.split(',').map((str: string) => str.trim());
      const mailtoEmail = `mailto:${emailList.join(';')}`;
      const documentLinks = documentIds.map((id) => {
        const document = data?.documents?.edges.find(
          (document: DocumentNode) => document.node.id === id
        );
        const title = document?.node ? document.node.title + '\n' : '';
        const url =
          config.BASE_URL +
          routes['DOCUMENT'].path.replace(':documentId', encodeIriToUrlParam(id)) +
          '\n';
        return title + url;
      });
      const mailtoContent =
        documentLinks.length > 0 ? encodeURIComponent(documentLinks.join('\n')) : '';
      setMailtoLink(mailtoEmail + '?body=' + mailtoContent);
      formikBag.setSubmitting(false);
      setDocumentIds([]);
      setSearchPhrase('');
    },
    [documentIds, data]
  );

  return (
    <Formik
      initialValues={{
        emailAddresses: '',
      }}
      validate={(values) => {
        const errors: any = {};
        if (!values.emailAddresses) {
          errors.emailAddresses = 'Pflichtfeld';
        } else {
          let emailList = values.emailAddresses.split(',').map((str: string) => str.trim());
          emailList.forEach((email) => {
            if (email.length === 0) {
              return;
            }
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(email)) {
              errors.emailAddresses =
                'Bitte nur gültige E-Mail Adresse(n) angeben. Bitte trennen Sie mehrere E-Mail Adressen jeweils mit einem Komma.';
            }
          });
        }
        return errors;
      }}
      onSubmit={(values, formikBag) => {
        handleSubmit(values, formikBag);
      }}
      onReset={() => {
        handleClose();
      }}
    >
      {(props) => (
        <Dialog
          open={open}
          onClose={() => props.handleReset()}
          aria-labelledby="dialog-documentsemail-title"
        >
          <CustomDialogTitle id="dialog-documentsemail-title" onClose={() => props.handleReset()}>
            Dokumente per E-Mail teilen
          </CustomDialogTitle>
          <DialogContent>
            <Form autoComplete="off">
              {props.status && (
                <Box mt={2}>
                  <Alert severity="error">{props.status}</Alert>
                </Box>
              )}
              {!mailtoLink && (
                <>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <FormLabel htmlFor="emailAddresses">E-Mail Adresse(n)</FormLabel>
                        <Field
                          component={FormikTextField}
                          type="email"
                          multiple={true}
                          name="emailAddresses"
                          id="emailAddresses"
                          variant="outlined"
                          fullWidth
                          helperText="Mehrere E-Mail Adressen bitte jeweils mit einem Komma trennen"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Suche"
                        fullWidth
                        onChange={(event) => {
                          setSearchPhrase(event.target.value);
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <FilterAltIcon />
                            </InputAdornment>
                          ),
                        }}
                        data-test="dialogFilterSearch"
                      />
                    </Grid>
                  </Grid>
                  {error && (
                    <Alert severity="error">Es ist ein Fehler aufgetreten: {error.message}</Alert>
                  )}
                  <List>
                    {documentNodes.length === 0 && (
                      <Typography component="span">Keine Dokumente gefunden</Typography>
                    )}
                    {documentNodes.map((edge: DocumentNode, index: number) => {
                      const { node: document } = edge;
                      return (
                        <ListItem
                          key={`documentsemail-listitem-${index}`}
                          disablePadding
                          disableGutters
                        >
                          <ListItemButton role={undefined} onClick={handleToggle(document.id)}>
                            <ListItemIcon>
                              <Checkbox
                                edge="start"
                                checked={documentIds.indexOf(document.id) !== -1}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{
                                  'aria-labelledby': `documentsemail-listitemtext-${index}`,
                                }}
                              />
                            </ListItemIcon>
                            <ListItemText
                              id={`documentsemail-listitemtext-${index}`}
                              primary={document.title}
                            />
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                  </List>
                  {props.errors.emailAddresses && props.touched.emailAddresses ? (
                    <Box mt={1}>
                      <Alert severity="error">Bitte Formulareingaben überprüfen</Alert>
                    </Box>
                  ) : null}
                </>
              )}
            </Form>
          </DialogContent>
          <DialogActions>
            <Button
              type="button"
              variant="outlined"
              color="primary"
              startIcon={<CancelIcon />}
              onClick={() => props.handleReset()}
              data-test="dialogReset"
            >
              Abbrechen
            </Button>
            {mailtoLink ? (
              <Button
                variant="contained"
                color="primary"
                startIcon={<SendIcon />}
                target="_blank"
                rel="noopener noreferrer"
                href={mailtoLink}
              >
                Zum Versand in E-Mail Programm öffnen
              </Button>
            ) : (
              <Button
                type="button"
                variant="contained"
                color="primary"
                onClick={() => props.handleSubmit()}
                data-test="dialogSubmit"
              >
                Weiter
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};

export default DocumentsEmailDialog;

import React, { Fragment, useEffect, useMemo, useState } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { InfoDialog, ToolbarPaper, ImagePreview } from '../common';
import Button from '@mui/material/Button';
import { NavLink, useParams } from 'react-router-dom';
import { routes } from '../../models/routes';
import ListIcon from '@mui/icons-material/ReorderRounded';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import useGlobalStyles from '../../hooks/useGlobalStyles';
import { DOCUMENT_QUERY } from '../../operations/documents';
import { Document, DocumentStatusLabels } from '../../models/documents';
import GetAppIcon from '@mui/icons-material/GetApp';
import LaunchIcon from '@mui/icons-material/LaunchRounded';
import dayjs from 'dayjs';
import {
  compareByKey2Sort,
  decodeIriFromUrlParam,
  downloadByMediaObject,
  encodeIriToUrlParam,
  getFileTypeByFilepath,
  getReadableFileSizeString,
  parseFileNameByMediaObject,
} from '../../utils/helper';
import { useSnackbar } from 'notistack';
import { DocumentStatus } from './DocumentStatus.component';
import IconButton from '@mui/material/IconButton';
import { ChapterNode } from '../../models/chapters';
import { QualityDevelopmentMeasureNode } from '../../models/qualityDevelopmentMeasures';
import EditIcon from '@mui/icons-material/EditRounded';
import useLoggedInMePermissions from '../../hooks/useLoggedInMePermissions';
import { permissionComponentKeys } from '../../models/permissions';
import { FacilityStatusLabels } from '../../models/facilities';
import { loggedInMeVar } from '../../cache';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import { TagOption } from '../../models/tags';
import { default as DocumentVersions } from './DocumentVersions.component';
import { DocumentVersion, DocumentVersionNode } from '../../models/documentVersions';
import InfoIcon from '@mui/icons-material/InfoRounded';
import { CREATE_ACTIVITYLOG_MUTATION } from '../../operations/activityLog';

const parseDocumentDescription = (document: Document) => {
  const description = document.description ?? '';

  return description.split('\n').map((item, key) => {
    return (
      <span key={key}>
        {item}
        <br />
      </span>
    );
  });
};

export const useDocumentVersionDownload = () => {
  const { enqueueSnackbar } = useSnackbar();

  return async (documentVersion: DocumentVersion) => {
    if (!documentVersion) {
      return;
    }
    try {
      if (!documentVersion?.file) {
        return;
      }
      await downloadByMediaObject(documentVersion?.file);
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
      console.error(error);
    }
  };
};

export default function DocumentComponent() {
  const { classes: globalClasses, cx } = useGlobalStyles();
  const loggedInMe = useReactiveVar(loggedInMeVar);
  const permissions = useLoggedInMePermissions(permissionComponentKeys.DOCUMENTS);
  const download = useDocumentVersionDownload();

  let { documentId: documentIdParam } = useParams<Record<string, string | undefined>>();

  const documentQueryId: string = decodeIriFromUrlParam(documentIdParam);

  const { error, data, loading } = useQuery(DOCUMENT_QUERY, {
    variables: {
      id: documentQueryId,
    },
    fetchPolicy: 'network-only',
  });

  const [createActivityLogMutation] = useMutation(CREATE_ACTIVITYLOG_MUTATION, {
    onError(error) {
      console.error(error);
    },
  });

  useEffect(() => {
    if (!data?.document?.id) {
      return;
    }

    createActivityLogMutation({
      variables: {
        input: {
          accessedEntity: data.document.id,
        },
      },
    });
  }, [data, createActivityLogMutation]);

  const document: Document | undefined = useMemo(() => data?.document, [data]);
  const currentVersion: DocumentVersion | undefined = useMemo(
    () =>
      document?.versions?.edges
        .map((documentVersion: DocumentVersionNode) => documentVersion.node)
        .sort((versionA, versionB) => compareByKey2Sort(versionA, versionB, 'version'))
        .pop(),
    [document]
  );
  const documentTags: TagOption[] | undefined = useMemo(
    () =>
      document?.tags?.edges.map((edge: any) => ({
        id: edge.node.id,
        name: edge.node.name,
      })) as TagOption[],
    [document]
  );

  const labelTenantWide = useMemo(
    () => document?.tenant?.name ?? FacilityStatusLabels.FACILITY_TENANTWIDE,
    [document]
  );

  const [linkedManualChapterInfoOpen, setLinkedManualChapterInfoOpen] = useState<boolean>(false);
  const [linkedQualityDevelopmentMeasuresInfoOpen, setLinkedQualityDevelopmentMeasuresInfoOpen] =
    useState<boolean>(false);
  const [revisionsInfoOpen, setRevisionsInfoOpen] = useState<boolean>(false);
  const [documentThumbnailOpen, setDocumentThumbnailOpen] = useState<boolean>(false);
  const [documentCurrentPreviewId, setDocumentCurrentPreviewId] = useState<string | undefined>(
    undefined
  );

  const userHasEditScope = useMemo(
    () =>
      loggedInMe?.tenantWideEditPermission ||
      !!loggedInMe?.facilities?.edges.some((edge) => edge.node?.id === document?.facility?.id),
    [loggedInMe, document]
  );

  const handlePreviewOpenClick = (documentId: string) => {
    setDocumentCurrentPreviewId(documentId);
    setDocumentThumbnailOpen(true);
  };

  const handlePreviewCloseClick = () => {
    setDocumentCurrentPreviewId(undefined);
    setDocumentThumbnailOpen(false);
  };

  if (loading) {
    return null;
  }
  if (error?.message)
    return (
      <Container>
        <Alert severity="error">
          Es ist ein Fehler aufgetreten:
          {error?.message ?? ''}
        </Alert>
      </Container>
    );

  return (
    <Container>
      {documentCurrentPreviewId && (
        <ImagePreview
          dialogOpen={documentThumbnailOpen}
          onClose={() => handlePreviewCloseClick()}
          documentId={documentCurrentPreviewId}
        />
      )}
      <Box component="header" mb={3}>
        <Typography component="h1" variant="h2" gutterBottom>
          {document?.tenant?.id ? 'Dokument' : 'Dokumentvorlage'}
        </Typography>
      </Box>
      {document ? (
        <Fragment>
          <Paper
            component="section"
            variant="outlined"
            className={globalClasses.paper}
            data-test="details"
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box display="flex" flexWrap="wrap">
                  <Box>
                    <Typography component="h2" variant="h3" gutterBottom>
                      {document.title}
                    </Typography>
                  </Box>
                  {!document?.tenant?.id && (
                    <Box ml="auto">
                      <DocumentStatus document={document} />
                    </Box>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="h6">Dateiname:</Typography>
                <Typography variant="body1" className={globalClasses.breakWords}>
                  {currentVersion ? parseFileNameByMediaObject(currentVersion.file) : '—'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="h6">Dateityp:</Typography>
                <Typography variant="body1">
                  {getFileTypeByFilepath(currentVersion?.file?.filePath ?? '') ?? '—'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="h6">Dateigröße:</Typography>
                <Typography variant="body1">
                  {getReadableFileSizeString(currentVersion?.file?.fileSize ?? 0)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="h6">Vorlage:</Typography>
                <Typography variant="body1">{document.tenant?.id ? 'nein' : 'ja'}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="h6">Träger/Einrichtung:</Typography>
                {document.tenant?.name ? (
                  <Typography variant="body1">
                    {document.facility?.name ?? labelTenantWide}
                  </Typography>
                ) : (
                  <Typography variant="body1">{DocumentStatusLabels.TEMPLATE_SYSTEM}</Typography>
                )}
              </Grid>
              {currentVersion?.file.previewThumbnail && (
                <Grid item xs={12} sm={6} md={3}>
                  <Tooltip title="Vorschau öffnen">
                    <Button
                      onClick={() => {
                        handlePreviewOpenClick(currentVersion?.id);
                      }}
                      style={{
                        backgroundImage: `url('data:image/jpeg;base64, ${currentVersion?.file.previewThumbnail}')`,
                      }}
                      className={cx(globalClasses.buttonSquare, globalClasses.buttonSquareImage)}
                      variant="outlined"
                      color="grey"
                      aria-label="Vorschau öffnen"
                    />
                  </Tooltip>
                </Grid>
              )}
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="h6">Schlagworte:</Typography>
                {documentTags.length ? (
                  <div className={globalClasses.listChips}>
                    {documentTags
                      .sort((optionA: TagOption, optionB: TagOption) =>
                        compareByKey2Sort(optionA, optionB, 'name')
                      )
                      .map((tag: TagOption) => (
                        <Chip key={tag.id} label={tag.name} data-test="tag" />
                      ))}
                  </div>
                ) : (
                  <Typography variant="body1">—</Typography>
                )}
              </Grid>
              {document.description && document.description.trim() !== '' && (
                <Fragment>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6">Beschreibung:</Typography>
                    <Typography variant="body1">{parseDocumentDescription(document)}</Typography>
                  </Grid>
                </Fragment>
              )}
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box className={globalClasses.tooltipBox}>
                  <Typography variant="h6">Verknüpfte QM-Handbuch Kapitel:</Typography>
                  <Tooltip title="Info zu “Verknüpfte QM-Handbuch-Kapitel”">
                    <IconButton
                      className={globalClasses.tooltipIcon}
                      color="primary"
                      aria-label="Info"
                      onClick={() => {
                        setLinkedManualChapterInfoOpen(true);
                      }}
                      size="large"
                    >
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                  <InfoDialog
                    open={linkedManualChapterInfoOpen}
                    title={`Verknüpfte QM-Handbuch-Kapitel`}
                    onClose={() => {
                      setLinkedManualChapterInfoOpen(false);
                    }}
                  >
                    <Typography paragraph>
                      Verknüpfungen dieses Dokuments zu einem oder mehreren Kapiteln eines
                      QM-Handbuchs werden Ihnen an dieser Stelle angezeigt. Besteht eine
                      Verknüpfung, gelangen sie über den dargestellten Link direkt zum
                      entsprechenden Kapitel des QM-Handbuchs.
                    </Typography>
                  </InfoDialog>
                </Box>
                {document.chapters && document.chapters.edges.length > 0 ? (
                  <ul className={globalClasses.listRelations}>
                    {document.chapters.edges.map((edge: ChapterNode) => {
                      const { node: chapter } = edge;
                      if (!chapter?.id) {
                        return null;
                      }
                      return (
                        <Box key={chapter.id} component="li">
                          <Typography component="span" variant="body1">
                            {`${chapter.chapterNumber} ${chapter.title}`.trim()}
                          </Typography>
                          {chapter.manual?.id && (
                            <Tooltip title="Verknüpftes Kapitel ansehen">
                              <IconButton
                                size="small"
                                color="primary"
                                component={NavLink}
                                to={routes['CHAPTER'].path
                                  .replace(':manualId', encodeIriToUrlParam(chapter.manual.id))
                                  .replace(':chapterId', encodeIriToUrlParam(chapter.id))}
                                aria-label="Verknüpftes Kapitel ansehen"
                              >
                                <LaunchIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Box>
                      );
                    })}
                  </ul>
                ) : (
                  '—'
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box className={globalClasses.tooltipBox}>
                  <Typography variant="h6">Verknüpfte Qualitäts­entwicklungs­maßnahmen:</Typography>
                  <Tooltip title="Info zu “Verknüpfte Qualitätsentwicklungsmaßnahmen”">
                    <IconButton
                      className={globalClasses.tooltipIcon}
                      color="primary"
                      aria-label="Info"
                      onClick={() => {
                        setLinkedQualityDevelopmentMeasuresInfoOpen(true);
                      }}
                      size="large"
                    >
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                  <InfoDialog
                    open={linkedQualityDevelopmentMeasuresInfoOpen}
                    title={`Verknüpfte Qualitätsentwicklungsmaßnahmen`}
                    onClose={() => {
                      setLinkedQualityDevelopmentMeasuresInfoOpen(false);
                    }}
                  >
                    <Typography paragraph>
                      Verknüpfungen dieses Dokuments zu einer oder mehreren
                      Qualitätsentwicklungsmaßnahmen werden Ihnen an dieser Stelle angezeigt.
                      Besteht eine Verknüpfung, gelangen sie über den dargestellten Link direkt zur
                      entsprechenden Qualitätsentwicklungsmaßnahme.
                    </Typography>
                  </InfoDialog>
                </Box>
                {document.qualityDevelopmentMeasures &&
                document.qualityDevelopmentMeasures.edges.length > 0 ? (
                  <ul className={globalClasses.listRelations}>
                    {document.qualityDevelopmentMeasures.edges.map(
                      (edge: QualityDevelopmentMeasureNode) => {
                        const { node: qualityDevelopmentMeasure } = edge;
                        if (!qualityDevelopmentMeasure?.id) {
                          return null;
                        }
                        return (
                          <Box key={qualityDevelopmentMeasure.id} component="li">
                            <Typography component="span" variant="body1">
                              {qualityDevelopmentMeasure.title}
                            </Typography>
                            <Tooltip title="Verknüpfte Qualitäts­entwicklungs­maßnahme ansehen">
                              <IconButton
                                size="small"
                                color="primary"
                                component={NavLink}
                                to={routes['QUALITYDEVELOPMENTMEASURE'].path.replace(
                                  ':qualityDevelopmentMeasureId',
                                  encodeIriToUrlParam(qualityDevelopmentMeasure.id)
                                )}
                                aria-label="Verknüpfte Qualitäts­entwicklungs­maßnahme ansehen"
                              >
                                <LaunchIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        );
                      }
                    )}
                  </ul>
                ) : (
                  '—'
                )}
              </Grid>
              {currentVersion && (
                <Fragment>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Box className={globalClasses.tooltipBox}>
                      <Typography component="h2" variant="h5" className={globalClasses.tooltipText}>
                        Revisionsinformationen zu dieser Dokumentenversion
                      </Typography>
                      <Tooltip title="Info zu Revisionsinformationen">
                        <IconButton
                          className={globalClasses.tooltipIcon}
                          color="primary"
                          aria-label="Info"
                          onClick={() => {
                            setRevisionsInfoOpen(true);
                          }}
                          size="large"
                        >
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                      <InfoDialog
                        open={revisionsInfoOpen}
                        title={`Revisionsinformationen`}
                        onClose={() => {
                          setRevisionsInfoOpen(false);
                        }}
                      >
                        <Typography paragraph>
                          Die Revisionsinformationen enthalten zusätzliche Informationen zu diesem
                          Dokument. Bitte beachten Sie: Da es sich um optionale Angaben handelt,
                          können die Revisionsinformationen unvollständig sein.
                        </Typography>
                      </InfoDialog>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md data-test="documentRevisionVersion">
                    <Typography variant="h6">Versions-Nr.:</Typography>
                    <Typography variant="body1">{currentVersion.versionNumber || '—'}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md>
                    <Typography variant="h6">Erstellt/geändert von:</Typography>
                    <Typography variant="body1">
                      {currentVersion.mostRecentEditBy || '—'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md>
                    <Typography variant="h6">Erstellt/geändert am:</Typography>
                    <Typography variant="body1">
                      {currentVersion.revisionDate && currentVersion.revisionDate.length > 0 ? (
                        <time dateTime={dayjs(currentVersion.revisionDate).toISOString()}>
                          {dayjs(currentVersion.revisionDate).format('DD.MM.YYYY')}
                        </time>
                      ) : (
                        '—'
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md>
                    <Typography variant="h6">Geprüft von:</Typography>
                    <Typography variant="body1">{currentVersion.checkedBy || '—'}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md>
                    <Typography variant="h6">Geprüft am:</Typography>
                    <Typography variant="body1">
                      {currentVersion.checkedDate && currentVersion.checkedDate.length > 0 ? (
                        <time dateTime={dayjs(currentVersion.checkedDate).toISOString()}>
                          {dayjs(currentVersion.checkedDate).format('DD.MM.YYYY')}
                        </time>
                      ) : (
                        '—'
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md>
                    <Typography variant="h6">Freigegeben von:</Typography>
                    <Typography variant="body1">{currentVersion.publishedBy || '—'}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md>
                    <Typography variant="h6">Freigegeben am:</Typography>
                    <Typography variant="body1">
                      {currentVersion.publishDate && currentVersion.publishDate.length > 0 ? (
                        <time dateTime={dayjs(currentVersion.publishDate).toISOString()}>
                          {dayjs(currentVersion.publishDate).format('DD.MM.YYYY')}
                        </time>
                      ) : (
                        '—'
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} data-test="documentRevisionChangelog">
                    <Typography variant="h6">Änderungsvermerk:</Typography>
                    <Typography
                      component="div"
                      variant="body1"
                      gutterBottom
                      style={{ whiteSpace: 'pre-wrap' }}
                    >
                      {currentVersion.revisionChangelog || '—'}
                    </Typography>
                  </Grid>
                </Fragment>
              )}
            </Grid>
          </Paper>
        </Fragment>
      ) : (
        <Alert severity="warning">Dokument nicht gefunden</Alert>
      )}
      {document && (
        <Fragment>
          <ToolbarPaper>
            {currentVersion && (
              <Button
                onClick={() => download(currentVersion)}
                variant="contained"
                color="primary"
                startIcon={<GetAppIcon />}
              >
                Download
                {currentVersion.version > 1 && ' der aktuellen Version'}
              </Button>
            )}
            {permissions?.update &&
              (document.tenant?.id || loggedInMe?.tenant === null) &&
              userHasEditScope && (
                <Button
                  component={NavLink}
                  to={routes['DOCUMENT_EDIT'].path.replace(
                    ':documentId',
                    encodeIriToUrlParam(document.id)
                  )}
                  variant="contained"
                  color="primary"
                  startIcon={<EditIcon />}
                >
                  Bearbeiten
                </Button>
              )}
            <Button
              component={NavLink}
              to={routes['DOCUMENTS'].path}
              variant="outlined"
              color="primary"
              startIcon={<ListIcon />}
              className="alignRight"
            >
              Alle Dokumente
            </Button>
          </ToolbarPaper>
          {document?.versions?.edges.length > 1 && <DocumentVersions document={document} />}
        </Fragment>
      )}
    </Container>
  );
}

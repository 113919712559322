import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/client';
import { UPDATE_CHAPTER_MUTATION } from '../../operations/chapter';

export default function useManualChapterSetDocumentsHandler(
  manualChapterId: string | null
): (documentIds: string[]) => Promise<any> {
  const { enqueueSnackbar } = useSnackbar();

  const [updateChapterMutation] = useMutation(UPDATE_CHAPTER_MUTATION, {
    onCompleted({ updateChapter }) {
      if (updateChapter?.chapter?.id) {
        enqueueSnackbar('Dokumente erfolgreich aktualisiert', {
          variant: 'success',
        });
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten', {
          variant: 'warning',
        });
      }
    },
    onError(error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  return async (documentIds: string[]) => {
    if (!manualChapterId) {
      return;
    }

    try {
      const response = await updateChapterMutation({
        variables: {
          input: {
            id: manualChapterId,
            documents: documentIds,
          },
        },
      });

      return response.data?.updateChapter.chapter;
    } catch (error: any) {
      console.error(error);
    }
  };
}

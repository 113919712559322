import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { AggregatedResult } from '../../../models/aggregatedResult';
import { formatPercent } from '../../../utils/helper';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import { FontWeights } from '../../../models/theme';

interface Props {
  aggregatedResult: AggregatedResult;
  openCommentsDialog: (targetGroupId: string | null) => void;
  showPercentRegardingChoices: boolean;
}

export default function SurveyEvaluationResultTableComponent(props: Props) {
  const { aggregatedResult, openCommentsDialog, showPercentRegardingChoices } = props;

  const gridLayout: any[] = !showPercentRegardingChoices ? [7, 4, 0, 1] : [5, 3, 3, 1];

  if (!aggregatedResult) {
    return null;
  }

  return (
    <>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={gridLayout[0]}>
          <Typography
            component="span"
            sx={{
              fontWeight: FontWeights.MEDIUM,
            }}
            gutterBottom
          >
            Antwortoptionen
          </Typography>
        </Grid>
        <Grid item xs={gridLayout[1]}>
          <Typography
            component="span"
            gutterBottom
            sx={{
              fontWeight: FontWeights.MEDIUM,
            }}
          >
            in % {showPercentRegardingChoices && '(Antworten insgesamt)'}
          </Typography>
        </Grid>
        {showPercentRegardingChoices && (
          <Grid item xs={gridLayout[2]}>
            <Typography
              component="span"
              gutterBottom
              sx={{
                fontWeight: FontWeights.MEDIUM,
              }}
            >
              in % (Antwortoptionen)
            </Typography>
          </Grid>
        )}
        <Grid item xs={gridLayout[3]}>
          <Typography
            component="span"
            gutterBottom
            sx={{
              fontWeight: FontWeights.MEDIUM,
            }}
          >
            absolut
          </Typography>
        </Grid>
      </Grid>
      {Object.entries(aggregatedResult.choices).map(
        (choiceEntry: any, choiceEntryIndex: number) => {
          const totalAnswers = aggregatedResult?.totalAnswers ?? 0;
          const percentageRegardingTotalAnswers =
            totalAnswers > 0 ? (choiceEntry[1] * 100.0) / totalAnswers : 0;
          const totalChoices = aggregatedResult?.totalChoices ?? 0;
          const percentageRegardingTotalChoices =
            totalChoices > 0 ? (choiceEntry[1] * 100.0) / totalChoices : 0;

          return (
            <React.Fragment key={choiceEntryIndex}>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={gridLayout[0]}>
                  <Typography component="span" gutterBottom>
                    {choiceEntry[0]}
                  </Typography>
                </Grid>
                <Grid item xs={gridLayout[1]}>
                  <Typography component="span" gutterBottom>
                    {percentageRegardingTotalAnswers > 0
                      ? formatPercent(percentageRegardingTotalAnswers)
                      : '-'}
                  </Typography>
                </Grid>
                {showPercentRegardingChoices && (
                  <Grid item xs={gridLayout[2]}>
                    <Typography component="span" gutterBottom>
                      {percentageRegardingTotalChoices > 0
                        ? formatPercent(percentageRegardingTotalChoices)
                        : '-'}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={gridLayout[3]}>
                  <Typography component="span" gutterBottom>
                    {choiceEntry[1]}
                  </Typography>
                </Grid>
              </Grid>
            </React.Fragment>
          );
        }
      )}

      <Grid container item xs={12} spacing={2}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={7}>
          <Typography
            component="span"
            gutterBottom
            sx={{
              fontWeight: FontWeights.MEDIUM,
            }}
          >
            Gesamt
          </Typography>
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={1}>
          <Typography
            component="span"
            gutterBottom
            sx={{
              fontWeight: FontWeights.MEDIUM,
            }}
          >
            {aggregatedResult.totalChoices}
          </Typography>
        </Grid>
      </Grid>
      {aggregatedResult.comments > 0 && (
        <>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12}>
              <Box mt={3} mb={3}>
                <Typography component="span" gutterBottom>
                  Antworten mit Kommentar: {aggregatedResult?.comments}
                </Typography>
              </Box>
              <Box mt={2}>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={() => openCommentsDialog(aggregatedResult.targetGroupId ?? null)}
                >
                  Kommentare einblenden
                </Button>
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}

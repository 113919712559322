import { Plugin } from '@ckeditor/ckeditor5-core';
import Model from '@ckeditor/ckeditor5-ui/src/model';
import Collection from '@ckeditor/ckeditor5-utils/src/collection';
import { createDropdown, addListToDropdown } from '@ckeditor/ckeditor5-ui/src/dropdown/utils';
import { isSupported, normalizeOptions } from './utils';
import { PLUGIN_ID, PLUGIN_KEY } from './index';
import iconLineheight from '../../assets/ckeditor/icon-lineheight.svg';

// Based on https://github.com/p0thi/ckeditor5-line-height-plugin/blob/master/src/lineheightui.js
export default class LineHeightUI extends Plugin {
  init() {
    const editor = this.editor;
    const t = editor.t;

    const options = this._getLocalizedOptions();

    const command = editor.commands.get(PLUGIN_KEY);

    editor.ui.componentFactory.add(PLUGIN_KEY, (locale) => {
      const dropdownView = createDropdown(locale);
      addListToDropdown(dropdownView, _prepareListOptions(options, command));

      dropdownView.buttonView.set({
        label: t('Zeilenhöhe'),
        icon: editor.config.get(`${PLUGIN_KEY}.icon`) || iconLineheight,
        tooltip: true,
      });

      dropdownView.extendTemplate({
        attributes: {
          class: [`${PLUGIN_ID}-dropdown`],
        },
      });

      dropdownView.bind('isEnabled').to(command);

      this.listenTo(dropdownView, 'execute', (evt) => {
        editor.execute(evt.source.commandName, { value: evt.source.commandParam });
        editor.editing.view.focus();
      });

      return dropdownView;
    });
  }

  _getLocalizedOptions() {
    const editor = this.editor;
    // const t = editor.t;

    const localizedTitles = {
      // default: t('Standard'), // see optionDefinition
      1.25: 'Kleiner (1,25)',
      1.75: 'Größer (1,75)',
      2: 'Groß (2)',
      2.5: 'Sehr groß (2,5)',
    };

    const options = normalizeOptions(
      editor.config.get(`${PLUGIN_KEY}.options`).filter((option) => isSupported(option))
    );

    return options.map((option) => {
      const title = localizedTitles[option.title];

      if (title && title !== option.title) {
        option = Object.assign({}, option, { title });
      }

      return option;
    });
  }
}

function _prepareListOptions(options, command) {
  const itemDefinitions = new Collection();

  for (const option of options) {
    const def = {
      type: 'button',
      model: new Model({
        commandName: PLUGIN_KEY,
        commandParam: option.model,
        label: option.title,
        class: `${PLUGIN_ID}-dropdown`,
        withText: true,
      }),
    };

    if (option.view && option.view.classes) {
      def.model.set('class', `${def.model.class} ${option.view.classes}`);
    }

    def.model.bind('isOn').to(command, 'value', (value) => {
      const newValue = value ? parseFloat(value) : value;
      return newValue === option.model;
    });

    // Add the option to the collection.
    itemDefinitions.add(def);
  }

  return itemDefinitions;
}

import React, { Fragment, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { CustomDialogTitle } from '../common';
import { useLazyQuery } from '@apollo/client';
import { DOCUMENT_QUERY } from 'operations/documents';
import Alert from '@mui/material/Alert';
import { ChapterNode } from '../../models/chapters';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { NavLink } from 'react-router-dom';
import { routes } from '../../models/routes';
import LaunchIcon from '@mui/icons-material/LaunchRounded';
import useGlobalStyles from '../../hooks/useGlobalStyles';
import LinearProgress from '@mui/material/LinearProgress';
import { QualityDevelopmentMeasureNode } from '../../models/qualityDevelopmentMeasures';
import Divider from '@mui/material/Divider';
import { encodeIriToUrlParam } from '../../utils/helper';
import Tooltip from '@mui/material/Tooltip';
import DialogTransition from '../common/DialogTransition';

interface Props {
  open: boolean;
  documentId: string | null;
  onClose: (confirm: boolean) => any;
}

const DocumentDeleteDialogComponent: React.FC<Props> = (props) => {
  const { open, documentId, onClose } = props;
  const { classes: globalClasses } = useGlobalStyles();

  const [queryDocument, { error, data, loading }] = useLazyQuery(DOCUMENT_QUERY, {
    fetchPolicy: 'network-only',
  });
  useEffect(() => {
    if (!documentId) {
      return;
    }
    queryDocument({
      variables: { id: documentId },
    });
  }, [documentId, queryDocument]);

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      onClose={() => onClose(false)}
      aria-labelledby="dialog-document-delete-title"
      aria-describedby="dialog-document-delete-description"
    >
      <CustomDialogTitle id="dialog-document-delete-title" onClose={() => onClose(false)}>
        Dokument entfernen
      </CustomDialogTitle>
      <DialogContent>
        <DialogContentText id="dialog-document-delete-description" color="textPrimary">
          Möchten Sie das Dokument wirklich entfernen?
        </DialogContentText>
        {error?.message && (
          <Alert severity="error">
            Es ist ein Fehler aufgetreten:
            {error?.message ?? ''}
          </Alert>
        )}
        {data?.document?.chapters && data.document.chapters.edges.length > 0 && (
          <Fragment>
            <Box mt={2} mb={1}>
              <Divider />
            </Box>
            <DialogContentText color="error">
              Folgende Verknüpfungen mit QM-Handbuch Kapiteln werden entfernt:
            </DialogContentText>
            <ul className={globalClasses.listRelations}>
              {data.document.chapters.edges.map((edge: ChapterNode) => {
                const { node: chapter } = edge;
                if (!chapter?.id) {
                  return null;
                }
                return (
                  <Box key={chapter.id} component="li">
                    <Typography component="span" variant="body1">
                      {`${chapter.chapterNumber} ${chapter.title}`.trim()}
                    </Typography>
                    {chapter.manual?.id && (
                      <Tooltip title="Verknüpftes Kapitel ansehen">
                        <IconButton
                          size="small"
                          color="primary"
                          component={NavLink}
                          to={routes['CHAPTER'].path
                            .replace(':manualId', encodeIriToUrlParam(chapter.manual.id))
                            .replace(':chapterId', encodeIriToUrlParam(chapter.id))}
                          aria-label="Verknüpftes Kapitel ansehen"
                        >
                          <LaunchIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>
                );
              })}
            </ul>
          </Fragment>
        )}
        {data?.document?.qualityDevelopmentMeasures &&
          data.document.qualityDevelopmentMeasures.edges.length > 0 && (
            <Fragment>
              <Box mt={2} mb={1}>
                <Divider />
              </Box>
              <DialogContentText color="error">
                Folgende Verknüpfungen mit Qualitäts­entwicklungs­maßnahmen werden entfernt:
              </DialogContentText>
              <ul className={globalClasses.listRelations}>
                {data.document.qualityDevelopmentMeasures.edges.map(
                  (edge: QualityDevelopmentMeasureNode) => {
                    const { node: qualityDevelopmentMeasure } = edge;
                    if (!qualityDevelopmentMeasure?.id) {
                      return null;
                    }
                    return (
                      <Box key={qualityDevelopmentMeasure.id} component="li">
                        <Typography component="span" variant="body1">
                          {qualityDevelopmentMeasure.title}
                        </Typography>
                        <Tooltip title="Verknüpfte Qualitäts­entwicklungs­maßnahme ansehen">
                          <IconButton
                            size="small"
                            color="primary"
                            component={NavLink}
                            to={routes['QUALITYDEVELOPMENTMEASURE'].path.replace(
                              ':qualityDevelopmentMeasureId',
                              encodeIriToUrlParam(qualityDevelopmentMeasure.id)
                            )}
                            aria-label="Verknüpfte Qualitäts­entwicklungs­maßnahme ansehen"
                          >
                            <LaunchIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    );
                  }
                )}
              </ul>
            </Fragment>
          )}
      </DialogContent>
      {loading && <LinearProgress />}
      <DialogActions>
        <Button onClick={() => onClose(false)} color="primary" data-test="dialogReset">
          Abbrechen
        </Button>
        <Button
          onClick={() => onClose(true)}
          variant="contained"
          color="primary"
          disabled={loading || !!error?.message}
          data-test="dialogSubmit"
        >
          Entfernen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DocumentDeleteDialogComponent;

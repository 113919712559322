import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import { useNavigate, useParams } from 'react-router-dom';
import { decodeIriFromUrlParam, encodeIriToUrlParam } from '../../utils/helper';
import { useQuery } from '@apollo/client';
import { QUALITYMANAGEMENTTASKAPPOINTMENT_QUERY } from '../../operations/qualityManagementTaskAppointment';
import { useSnackbar } from 'notistack';
import { routes } from '../../models/routes';

export default function QualityManagementTaskAppointmentComponent() {
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  let { qualityManagementTaskAppointmentId: qualityManagementTaskAppointmentIdParam } =
    useParams<Record<string, string | undefined>>();

  const qualityManagementTaskAppointmentId: string = decodeIriFromUrlParam(
    qualityManagementTaskAppointmentIdParam
  );

  const { error, loading } = useQuery(QUALITYMANAGEMENTTASKAPPOINTMENT_QUERY, {
    variables: {
      id: qualityManagementTaskAppointmentId,
    },
    fetchPolicy: 'network-only',
    onCompleted({ qualityManagementTaskAppointment }) {
      if (
        qualityManagementTaskAppointment?.id &&
        qualityManagementTaskAppointment?.qualityManagementTask?.id
      ) {
        navigate(
          routes['QUALITYMANAGEMENTTASK'].path
            .replace(
              ':qualityManagementTaskId',
              encodeIriToUrlParam(qualityManagementTaskAppointment.qualityManagementTask.id)
            )
            .replace(
              ':qualityManagementTaskAppointmentId',
              encodeIriToUrlParam(qualityManagementTaskAppointment.id)
            )
        );
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten', {
          variant: 'warning',
        });
      }
    },
  });

  if (loading) return null;
  if (error?.message)
    return (
      <Container>
        <Alert severity="error">Es ist ein Fehler aufgetreten: {error.message}</Alert>
      </Container>
    );

  return (
    <Container>
      <Box component="header" mb={3}>
        <Typography component="h1" variant="h2" gutterBottom>
          QM-Aufgabentermin
        </Typography>
      </Box>
      <Alert severity="info">Bitte warten... Sie werden weitergeleitet.</Alert>
    </Container>
  );
}

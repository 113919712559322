import React from 'react';
import HomeIcon from '@mui/icons-material/HomeRounded';
import PasswordIcon from '@mui/icons-material/LockRounded';
import SurveyIcon from '@mui/icons-material/AssignmentRounded';
import ProfileIcon from '@mui/icons-material/AssignmentIndRounded';
import UsersIcon from '@mui/icons-material/GroupRounded';
import UserNewIcon from '@mui/icons-material/PersonAddRounded';
import BookIcon from '@mui/icons-material/MenuBookRounded';
import ChapterIcon from '@mui/icons-material/DescriptionOutlined';
import FileIcon from '@mui/icons-material/InsertDriveFileRounded';
import FacilityIcon from '@mui/icons-material/HomeWorkRounded';
import RolesIcon from '@mui/icons-material/SupervisedUserCircleRounded';
import RoleIcon from '@mui/icons-material/AccountCircleRounded';
import AddIcon from '@mui/icons-material/AddCircleRounded';
import LoopIcon from '@mui/icons-material/LoopRounded';
import TaskIcon from '@mui/icons-material/TaskAltRounded';
import {
  Surveys,
  Survey,
  SurveyNew,
  SurveyEdit,
  SurveyParticipation,
  SurveyEvaluation,
  SurveyEvaluationShared,
} from '../views/surveys';
import { Home } from '../views/home';
import { PasswordInitialSet, PasswordRecovery, PasswordReset } from '../views/passwordrecovery';
import { Users, User, UserNew, UserEdit } from '../views/users';
import { ProfileEdit } from '../views/profile';
import { permissionComponentKeys, PermissionNode } from './permissions';
import {
  Manual,
  ManualChapter,
  ManualChapterEdit,
  ManualEdit,
  ManualNew,
  Manuals,
} from '../views/manuals';
import { DocumentEdit, DocumentNew, Documents, Document, DocumentsNew } from '../views/documents';
import { Facilities, Facility, FacilityEdit, FacilityNew } from '../views/facilities';
import { Roles, RoleNew, RoleEdit, Role } from '../views/roles';
import {
  QualityDevelopmentMeasures,
  QualityDevelopmentMeasure,
  QualityDevelopmentMeasureEdit,
  QualityDevelopmentMeasureNew,
} from '../views/qualityDevelopmentMeasures';
import {
  QualityManagementTasks,
  QualityManagementTasksCalendar,
  QualityManagementTask,
  QualityManagementTaskAppointment,
  QualityManagementTaskNew,
  QualityManagementTaskCopy,
  QualityManagementTaskEdit,
  QualityManagementTaskAppointmentsEdit,
} from '../views/qualityManagementTasks';
import { ContentPage } from '../views/contentPage';
import { Tenant } from 'views/tenants';

// NOTE: arrange routes with :ids (i.e. "details") after static routes (i.e. "new") to clear up any ambiguous matching
// https://reacttraining.com/react-router/web/example/ambiguous-matches

export type HasComponentAccess = (obj?: PermissionNode) => boolean | null;

export interface ComponentProps {
  [key: string]: any;
}

export interface Route {
  path: string;
  title: string;
  icon?: React.ElementType | null;
  component: React.ElementType;
  componentProps?: ComponentProps | null;
  isPrivate?: boolean;
  usePublicLayout?: boolean;
  permissionComponent?: permissionComponentKeys | null;
  hasComponentAccess?: HasComponentAccess | null;
  parent?: string | null;
}

interface Routes {
  [key: string]: Route;
}

// IMPORTANT: Route parameters should be named entity-specific (i.e. ":surveyId" or ":resultId" instead of just ":id")!
export const routes: Routes = {
  MANUALS: {
    path: '/qm-handbuecher',
    isPrivate: true,
    title: 'QM-Handbücher',
    icon: BookIcon,
    component: Manuals,
    permissionComponent: permissionComponentKeys.MANUALS,
    hasComponentAccess: (permissions) => (permissions ? permissions.read : null),
    parent: 'HOME',
  },
  MANUAL: {
    path: '/qm-handbuch/:manualId',
    isPrivate: true,
    title: 'QM-Handbuch',
    icon: BookIcon,
    component: Manual,
    permissionComponent: permissionComponentKeys.MANUALS,
    hasComponentAccess: (permissions) => (permissions ? permissions.read : null),
    parent: 'MANUALS',
  },
  MANUAL_NEW: {
    path: '/qm-handbuch-erstellen',
    isPrivate: true,
    title: 'QM-Handbuch erstellen',
    icon: BookIcon,
    component: ManualNew,
    permissionComponent: permissionComponentKeys.MANUALS,
    hasComponentAccess: (permissions) => (permissions ? permissions.create : null),
    parent: 'MANUALS',
  },
  MANUAL_EDIT: {
    path: '/qm-handbuch-bearbeiten/:manualId',
    isPrivate: true,
    title: 'QM-Handbuch bearbeiten',
    icon: BookIcon,
    component: ManualEdit,
    permissionComponent: permissionComponentKeys.MANUALS,
    hasComponentAccess: (permissions) => (permissions ? permissions.update : null),
    parent: 'MANUALS',
  },
  CHAPTER: {
    path: '/qm-handbuch/:manualId/kapitel/:chapterId',
    isPrivate: true,
    title: 'Kapitel',
    icon: ChapterIcon,
    component: ManualChapter,
    permissionComponent: permissionComponentKeys.MANUALS,
    hasComponentAccess: (permissions) => (permissions ? permissions.read : null),
    parent: 'MANUAL',
  },
  CHAPTER_EDIT: {
    path: '/qm-handbuch/:manualId/kapitel-bearbeiten/:chapterId',
    isPrivate: true,
    title: 'Kapitel bearbeiten',
    icon: ChapterIcon,
    component: ManualChapterEdit,
    permissionComponent: permissionComponentKeys.MANUALS,
    hasComponentAccess: (permissions) => (permissions ? permissions.update : null),
    parent: 'MANUAL_EDIT',
  },
  QUALITYDEVELOPMENTMEASURES: {
    path: '/qualitaetsentwicklungsmassnahmen',
    isPrivate: true,
    title: 'Qualitäts­entwicklungs­maßnahmen',
    icon: LoopIcon,
    component: QualityDevelopmentMeasures,
    permissionComponent: permissionComponentKeys.QUALITYDEVELOPMENTMEASURES,
    hasComponentAccess: (permissions) => (permissions ? permissions.read : null),
    parent: 'HOME',
  },
  QUALITYDEVELOPMENTMEASURE: {
    path: '/qualitaetsentwicklungsmassnahme/:qualityDevelopmentMeasureId',
    isPrivate: true,
    title: 'Qualitäts­entwicklungs­maßnahme',
    icon: LoopIcon,
    component: QualityDevelopmentMeasure,
    permissionComponent: permissionComponentKeys.QUALITYDEVELOPMENTMEASURES,
    hasComponentAccess: (permissions) => (permissions ? permissions.read : null),
    parent: 'QUALITYDEVELOPMENTMEASURES',
  },
  QUALITYDEVELOPMENTMEASURE_CHILD: {
    path: '/qualitaetsentwicklungsmassnahme/:qualityDevelopmentMeasureId/:qualityDevelopmentMeasureChildId',
    isPrivate: true,
    title: 'Qualitäts­entwicklungs­teilmaßnahme',
    icon: LoopIcon,
    component: QualityDevelopmentMeasure,
    permissionComponent: permissionComponentKeys.QUALITYDEVELOPMENTMEASURES,
    hasComponentAccess: (permissions) => (permissions ? permissions.read : null),
    parent: 'QUALITYDEVELOPMENTMEASURE',
  },
  QUALITYDEVELOPMENTMEASURE_NEW: {
    path: '/qualitaetsentwicklungsmassnahme-anlegen',
    isPrivate: true,
    title: 'Qualitäts­entwicklungs­maßnahme anlegen',
    icon: LoopIcon,
    component: QualityDevelopmentMeasureNew,
    permissionComponent: permissionComponentKeys.QUALITYDEVELOPMENTMEASURES,
    hasComponentAccess: (permissions) => (permissions ? permissions.create : null),
    parent: 'QUALITYDEVELOPMENTMEASURES',
  },
  QUALITYDEVELOPMENTMEASURE_CHILD_NEW: {
    path: '/qualitaetsentwicklungsmassnahme-anlegen/:qualityDevelopmentMeasureId',
    isPrivate: true,
    title: 'Qualitäts­entwicklungs­teilmaßnahme anlegen',
    icon: LoopIcon,
    component: QualityDevelopmentMeasureNew,
    permissionComponent: permissionComponentKeys.QUALITYDEVELOPMENTMEASURES,
    hasComponentAccess: (permissions) => (permissions ? permissions.create : null),
    parent: 'QUALITYDEVELOPMENTMEASURE',
  },
  QUALITYDEVELOPMENTMEASURE_EDIT: {
    path: '/qualitaetsentwicklungsmassnahme-bearbeiten/:qualityDevelopmentMeasureId',
    isPrivate: true,
    title: 'Qualitäts­entwicklungs­maßnahme bearbeiten',
    icon: LoopIcon,
    component: QualityDevelopmentMeasureEdit,
    permissionComponent: permissionComponentKeys.QUALITYDEVELOPMENTMEASURES,
    hasComponentAccess: (permissions) => (permissions ? permissions.update : null),
    parent: 'QUALITYDEVELOPMENTMEASURES',
  },
  QUALITYDEVELOPMENTMEASURE_CHILD_EDIT: {
    path: '/qualitaetsentwicklungsmassnahme-bearbeiten/:qualityDevelopmentMeasureId/:qualityDevelopmentMeasureChildId',
    isPrivate: true,
    title: 'Qualitäts­entwicklungs­teilmaßnahme bearbeiten',
    icon: LoopIcon,
    component: QualityDevelopmentMeasureEdit,
    permissionComponent: permissionComponentKeys.QUALITYDEVELOPMENTMEASURES,
    hasComponentAccess: (permissions) => (permissions ? permissions.update : null),
    parent: 'QUALITYDEVELOPMENTMEASURE',
  },
  SURVEYS: {
    path: '/evaluationen',
    isPrivate: true,
    title: 'Evaluation und Auswertung',
    icon: SurveyIcon,
    component: Surveys,
    permissionComponent: permissionComponentKeys.SURVEYS,
    hasComponentAccess: (permissions) => (permissions ? permissions.read : null),
    parent: 'HOME',
  },
  SURVEY: {
    path: '/evaluation/:surveyId',
    isPrivate: true,
    title: 'Evaluation',
    icon: SurveyIcon,
    component: Survey,
    permissionComponent: permissionComponentKeys.SURVEYS,
    hasComponentAccess: (permissions) => (permissions ? permissions.read : null),
    parent: 'SURVEYS',
  },
  SURVEY_NEW: {
    path: '/evaluation-anlegen',
    isPrivate: true,
    title: 'Evaluation anlegen',
    icon: SurveyIcon,
    component: SurveyNew,
    permissionComponent: permissionComponentKeys.SURVEYS,
    hasComponentAccess: (permissions) => (permissions ? permissions.create : null),
    parent: 'SURVEYS',
  },
  SURVEY_EDIT: {
    path: '/evaluation-bearbeiten/:surveyId',
    isPrivate: true,
    title: 'Evaluation bearbeiten',
    icon: SurveyIcon,
    component: SurveyEdit,
    permissionComponent: permissionComponentKeys.SURVEYS,
    hasComponentAccess: (permissions) => (permissions ? permissions.update : null),
    parent: 'SURVEYS',
  },
  SURVEY_EVALUATION: {
    path: '/auswertung/:surveyId',
    isPrivate: true,
    title: 'Auswertung',
    icon: SurveyIcon,
    component: SurveyEvaluation,
    permissionComponent: permissionComponentKeys.SURVEYS,
    hasComponentAccess: (permissions) => (permissions ? permissions.read : null),
    parent: 'SURVEYS',
  },
  SURVEY_SUPPLEMENT: {
    path: '/auswertung-ergaenzen/:surveyId/:targetGroupId',
    isPrivate: true,
    title: 'Umfrageergebnisse ergänzen',
    icon: SurveyIcon,
    component: SurveyParticipation,
    permissionComponent: permissionComponentKeys.SURVEYS,
    hasComponentAccess: (permissions) => (permissions ? permissions.update : null),
    parent: 'SURVEY_EVALUATION',
  },
  QUALITYMANAGEMENTTASKS_CALENDAR: {
    path: '/qm-aufgaben-kalender',
    isPrivate: true,
    title: 'QM-Aufgaben',
    icon: TaskIcon,
    component: QualityManagementTasksCalendar,
    permissionComponent: permissionComponentKeys.QUALITYMANAGEMENTTASKS,
    hasComponentAccess: (permissions) => (permissions ? permissions.read : null),
    parent: 'HOME',
  },
  QUALITYMANAGEMENTTASKS: {
    path: '/qm-aufgaben',
    isPrivate: true,
    title: 'QM-Aufgabenliste',
    icon: TaskIcon,
    component: QualityManagementTasks,
    permissionComponent: permissionComponentKeys.QUALITYMANAGEMENTTASKS,
    hasComponentAccess: (permissions) => (permissions ? permissions.read : null),
    parent: 'HOME',
  },
  QUALITYMANAGEMENTTASK: {
    path: '/qm-aufgaben/:qualityManagementTaskId/:qualityManagementTaskAppointmentId',
    isPrivate: true,
    title: 'QM-Aufgabe',
    icon: TaskIcon,
    component: QualityManagementTask,
    permissionComponent: permissionComponentKeys.QUALITYMANAGEMENTTASKS,
    hasComponentAccess: (permissions) => (permissions ? permissions.read : null),
    parent: 'QUALITYMANAGEMENTTASKS_CALENDAR',
  },
  QUALITYMANAGEMENTTASKAPPOINTMENT: {
    path: '/qm-aufgabentermine/:qualityManagementTaskAppointmentId',
    isPrivate: true,
    title: 'QM-Aufgabentermin',
    icon: TaskIcon,
    component: QualityManagementTaskAppointment,
    permissionComponent: permissionComponentKeys.QUALITYMANAGEMENTTASKS,
    hasComponentAccess: (permissions) => (permissions ? permissions.read : null),
    parent: 'QUALITYMANAGEMENTTASKS_CALENDAR',
  },
  QUALITYMANAGEMENTTASK_NEW: {
    path: '/qm-aufgabe-planen',
    isPrivate: true,
    title: 'Neue QM-Aufgabe planen',
    icon: TaskIcon,
    component: QualityManagementTaskNew,
    permissionComponent: permissionComponentKeys.QUALITYMANAGEMENTTASKS,
    hasComponentAccess: (permissions) => (permissions ? permissions.create : null),
    parent: 'QUALITYMANAGEMENTTASKS_CALENDAR',
  },
  QUALITYMANAGEMENTTASK_COPY: {
    path: '/qm-aufgabe-kopieren/:qualityManagementTaskId/:qualityManagementTaskAppointmentId',
    isPrivate: true,
    title: 'QM-Aufgabe kopieren',
    icon: TaskIcon,
    component: QualityManagementTaskCopy,
    permissionComponent: permissionComponentKeys.QUALITYMANAGEMENTTASKS,
    hasComponentAccess: (permissions) => (permissions ? permissions.create : null),
    parent: 'QUALITYMANAGEMENTTASKS_CALENDAR',
  },
  QUALITYMANAGEMENTTASKAPPOINTMENT_EDIT: {
    path: '/qm-aufgabe-bearbeiten/:qualityManagementTaskId/:qualityManagementTaskAppointmentId',
    isPrivate: true,
    title: 'QM-Aufgabentermin bearbeiten',
    icon: TaskIcon,
    component: QualityManagementTaskEdit,
    permissionComponent: permissionComponentKeys.QUALITYMANAGEMENTTASKS,
    hasComponentAccess: (permissions) => (permissions ? permissions.update : null),
    parent: 'QUALITYMANAGEMENTTASKS_CALENDAR',
  },
  QUALITYMANAGEMENTTASK_EDIT: {
    path: '/qm-aufgabe-bearbeiten/:qualityManagementTaskId',
    isPrivate: true,
    title: 'QM-Aufgabe bearbeiten',
    icon: TaskIcon,
    component: QualityManagementTaskEdit,
    permissionComponent: permissionComponentKeys.QUALITYMANAGEMENTTASKS,
    hasComponentAccess: (permissions) => (permissions ? permissions.update : null),
    parent: 'QUALITYMANAGEMENTTASKS_CALENDAR',
  },
  QUALITYMANAGEMENTTASKAPPOINTMENTS_EDIT: {
    path: '/qm-aufgabenterminserie-bearbeiten/:qualityManagementTaskId/:qualityManagementTaskAppointmentId',
    isPrivate: true,
    title: 'Terminserie QM-Aufgabe bearbeiten',
    icon: TaskIcon,
    component: QualityManagementTaskAppointmentsEdit,
    permissionComponent: permissionComponentKeys.QUALITYMANAGEMENTTASKS,
    hasComponentAccess: (permissions) => (permissions ? permissions.update : null),
    parent: 'QUALITYMANAGEMENTTASKS_CALENDAR',
  },
  FACILITIES: {
    path: '/einrichtungen',
    isPrivate: true,
    title: 'Einrichtungen',
    icon: FacilityIcon,
    component: Facilities,
    permissionComponent: permissionComponentKeys.FACILITIES,
    hasComponentAccess: (permissions) => (permissions ? permissions.read : null),
    parent: 'HOME',
  },
  FACILITY: {
    path: '/einrichtung/:facilityId',
    isPrivate: true,
    title: 'Einrichtung',
    icon: FacilityIcon,
    component: Facility,
    permissionComponent: permissionComponentKeys.FACILITIES,
    hasComponentAccess: (permissions) => (permissions ? permissions.read : null),
    parent: 'FACILITIES',
  },
  FACILITY_NEW: {
    path: '/einrichtung-anlegen',
    isPrivate: true,
    title: 'Einrichtung anlegen',
    icon: FacilityIcon,
    component: FacilityNew,
    permissionComponent: permissionComponentKeys.FACILITIES,
    hasComponentAccess: (permissions) => (permissions ? permissions.create : null),
    parent: 'FACILITIES',
  },
  FACILITY_EDIT: {
    path: '/einrichtung-bearbeiten/:facilityId',
    isPrivate: true,
    title: 'Einrichtung bearbeiten',
    icon: FacilityIcon,
    component: FacilityEdit,
    permissionComponent: permissionComponentKeys.FACILITIES,
    hasComponentAccess: (permissions) => (permissions ? permissions.update : null),
    parent: 'FACILITIES',
  },
  TENANT: {
    path: '/mandant/:tenantId',
    isPrivate: true,
    title: 'Mandant',
    icon: FacilityIcon,
    component: Tenant,
    permissionComponent: permissionComponentKeys.TENANT,
    hasComponentAccess: (permissions) => (permissions ? permissions.read : null),
    parent: 'FACILITIES',
  },
  DOCUMENTS: {
    path: '/dokumente',
    isPrivate: true,
    title: 'Dokumente',
    icon: FileIcon,
    component: Documents,
    permissionComponent: permissionComponentKeys.DOCUMENTS,
    hasComponentAccess: (permissions) => (permissions ? permissions.read : null),
    parent: 'HOME',
  },
  DOCUMENT: {
    path: '/dokument/:documentId',
    isPrivate: true,
    title: 'Dokument',
    icon: FileIcon,
    component: Document,
    permissionComponent: permissionComponentKeys.DOCUMENTS,
    hasComponentAccess: (permissions) => (permissions ? permissions.read : null),
    parent: 'DOCUMENTS',
  },
  DOCUMENT_NEW: {
    path: '/dokument-anlegen',
    isPrivate: true,
    title: 'Dokument anlegen',
    icon: FileIcon,
    component: DocumentNew,
    permissionComponent: permissionComponentKeys.DOCUMENTS,
    hasComponentAccess: (permissions) => (permissions ? permissions.create : null),
    parent: 'DOCUMENTS',
  },
  DOCUMENTS_NEW: {
    path: '/dokumente-anlegen',
    isPrivate: true,
    title: 'Dokumente anlegen',
    icon: FileIcon,
    component: DocumentsNew,
    permissionComponent: permissionComponentKeys.DOCUMENTS,
    hasComponentAccess: (permissions) => (permissions ? permissions.create : null),
    parent: 'DOCUMENTS',
  },
  DOCUMENT_EDIT: {
    path: '/dokument-bearbeiten/:documentId',
    isPrivate: true,
    title: 'Dokument bearbeiten',
    icon: FileIcon,
    component: DocumentEdit,
    permissionComponent: permissionComponentKeys.DOCUMENTS,
    hasComponentAccess: (permissions) => (permissions ? permissions.update : null),
    parent: 'DOCUMENTS',
  },
  ROLES: {
    path: '/benutzerrollen',
    isPrivate: true,
    title: 'Benutzerrollen',
    icon: RolesIcon,
    component: Roles,
    permissionComponent: permissionComponentKeys.PERMISSIONS,
    hasComponentAccess: (permissions) => (permissions ? permissions.read : null),
    parent: 'HOME',
  },
  ROLE: {
    path: '/benutzerrolle/:roleId',
    isPrivate: true,
    title: 'Benutzerrolle',
    icon: RoleIcon,
    component: Role,
    permissionComponent: permissionComponentKeys.PERMISSIONS,
    hasComponentAccess: (permissions) => (permissions ? permissions.read : null),
    parent: 'ROLES',
  },
  ROLE_NEW: {
    path: '/benutzerrolle-anlegen',
    isPrivate: true,
    title: 'Neuen Benutzerrolle anlegen',
    icon: AddIcon,
    component: RoleNew,
    permissionComponent: permissionComponentKeys.PERMISSIONS,
    hasComponentAccess: (permissions) => (permissions ? permissions.create : null),
    parent: 'ROLES',
  },
  ROLE_EDIT: {
    path: '/benutzerrolle-bearbeiten/:roleId',
    isPrivate: true,
    title: 'Benutzerrolle bearbeiten',
    icon: RoleIcon,
    component: RoleEdit,
    permissionComponent: permissionComponentKeys.PERMISSIONS,
    hasComponentAccess: (permissions) => (permissions ? permissions.update : null),
    parent: 'ROLES',
  },
  USERS: {
    path: '/benutzerliste',
    isPrivate: true,
    title: 'Benutzerliste',
    icon: UsersIcon,
    component: Users,
    permissionComponent: permissionComponentKeys.USERS,
    hasComponentAccess: (permissions) => (permissions ? permissions.read : null),
    parent: 'HOME',
  },
  USER: {
    path: '/benutzer/:userId',
    isPrivate: true,
    title: 'Benutzer',
    icon: ProfileIcon,
    component: User,
    permissionComponent: permissionComponentKeys.USERS,
    hasComponentAccess: (permissions) => (permissions ? permissions.read : null),
    parent: 'USERS',
  },
  USER_NEW: {
    path: '/benutzer-anlegen',
    isPrivate: true,
    title: 'Neuen Benutzer anlegen',
    icon: UserNewIcon,
    component: UserNew,
    permissionComponent: permissionComponentKeys.USERS,
    hasComponentAccess: (permissions) => (permissions ? permissions.create : null),
    parent: 'USERS',
  },
  USER_EDIT: {
    path: '/benutzer-bearbeiten/:userId',
    isPrivate: true,
    title: 'Benutzer bearbeiten',
    icon: ProfileIcon,
    component: UserEdit,
    permissionComponent: permissionComponentKeys.USERS,
    hasComponentAccess: (permissions) => (permissions ? permissions.update : null),
    parent: 'USERS',
  },
  PROFILE: {
    path: '/profil',
    isPrivate: true,
    title: 'Profil',
    icon: ProfileIcon,
    component: ProfileEdit,
    parent: 'HOME',
  },
  HOME: {
    path: '/',
    title: 'Startseite',
    icon: HomeIcon,
    component: Home,
  },
  PASSWORD_RECOVERY: {
    path: '/passwort-vergessen',
    title: 'Passwort vergessen',
    icon: PasswordIcon,
    component: PasswordRecovery,
    parent: 'HOME',
  },
  PASSWORD_RESET: {
    path: '/passwort-neu/:token',
    title: 'Neues Passwort',
    icon: PasswordIcon,
    component: PasswordReset,
    parent: 'HOME',
  },
  PASSWORD_INITIALSET: {
    path: '/passwort-vergeben/:token',
    title: 'Passwort setzen',
    icon: PasswordIcon,
    component: PasswordInitialSet,
    parent: 'HOME',
  },
  SURVEY_PARTICIPATE: {
    path: '/umfrage/:targetGroupId',
    usePublicLayout: true,
    title: 'Umfrage',
    icon: SurveyIcon,
    component: SurveyParticipation,
  },
  SURVEY_EVALUATION_SHARED: {
    path: '/umfrage-auswertung/:shareToken',
    usePublicLayout: true,
    title: 'Auswertung',
    icon: SurveyIcon,
    component: SurveyEvaluationShared,
  },
  SURVEY_IMPRINT: {
    path: '/umfrage-impressum',
    usePublicLayout: true,
    title: 'Impressum',
    component: ContentPage,
    componentProps: {
      contentPageKey: 'impressum',
    },
  },
  IMPRINT: {
    path: '/impressum',
    title: 'Impressum',
    component: ContentPage,
    componentProps: {
      contentPageKey: 'impressum',
    },
    parent: 'HOME',
  },
  HELP: {
    path: '/hilfe',
    title: 'Hilfe',
    component: ContentPage,
    componentProps: {
      contentPageKey: 'hilfe',
    },
    parent: 'HOME',
  },
  RELEASENOTES: {
    path: '/release-notes',
    title: 'Release Notes',
    component: ContentPage,
    componentProps: {
      contentPageKey: 'release',
    },
    parent: 'HOME',
  },
};

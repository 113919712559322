import { Plugin } from '@ckeditor/ckeditor5-core';
import {
  toWidget,
  viewToModelPositionOutsideModelElement,
} from '@ckeditor/ckeditor5-widget/src/utils';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';
import InsertDocumentLinkInlineCommand from './InsertDocumentLinkInlineCommand';
import { ChapterContentLabels } from '../../models/chapters';

// https://ckeditor.com/docs/ckeditor5/latest/tutorials/widgets/implementing-an-inline-widget.html#final-solution
// https://ckeditor.com/docs/ckeditor5/latest/framework/guides/tutorials/using-react-in-a-widget.html#full-source-code
export default class DocumentLinkInlineEditing extends Plugin {
  static get requires() {
    return [Widget];
  }

  init() {
    this._defineSchema();
    this._defineConverters();

    this.editor.commands.add(
      'insertDocumentLinkInline',
      new InsertDocumentLinkInlineCommand(this.editor)
    );

    // https://ckeditor.com/docs/ckeditor5/latest/tutorials/widgets/implementing-an-inline-widget.html#fixing-position-mapping
    this.editor.editing.mapper.on(
      'viewToModelPosition',
      viewToModelPositionOutsideModelElement(
        this.editor.model,
        (viewElement) =>
          viewElement.name === 'span' && viewElement.hasAttribute('data-document-link')
      )
    );
  }

  _defineSchema() {
    const schema = this.editor.model.schema;

    schema.register('documentLinkInline', {
      inheritAllFrom: '$inlineObject',
      allowAttributes: ['data-document-link', 'data-document-title'],
    });
  }

  _defineConverters() {
    const editor = this.editor;
    const conversion = editor.conversion;
    const getDocumentLinkContent = editor.config.get('custom').getDocumentLinkContent;

    conversion.for('upcast').elementToElement({
      view: {
        name: 'span',
        attributes: ['data-document-link', 'data-document-title'],
      },
      model: (viewElement, { writer: modelWriter }) => {
        return modelWriter.createElement('documentLinkInline', viewElement.getAttributes());
      },
    });

    conversion.for('dataDowncast').elementToElement({
      model: 'documentLinkInline',
      view: (modelElement, { writer: viewWriter }) => {
        return viewWriter.createEmptyElement('span', {
          'data-document-link': modelElement.getAttribute('data-document-link'),
          'data-document-title': modelElement.getAttribute('data-document-title'),
        });
      },
    });

    conversion.for('editingDowncast').elementToElement({
      model: 'documentLinkInline',
      view: (modelElement, { writer: viewWriter }) => {
        const documentLinkId = modelElement.getAttribute('data-document-link');
        const documentLinkTitle = modelElement.getAttribute('data-document-title');

        const relatedDocuments = editor.config.get('relatedDocuments') || [];

        const { documentLinkLabel, documentLinkValid } = getDocumentLinkContent(
          documentLinkId,
          documentLinkTitle,
          relatedDocuments
        );

        const documentLink = viewWriter.createContainerElement('span', {
          'data-document-link': documentLinkId,
          'data-document-title': documentLinkTitle,
          class: !documentLinkValid ? 'invalid' : '',
        });

        const innerText = viewWriter.createText(documentLinkLabel);
        viewWriter.insert(viewWriter.createPositionAt(documentLink, 0), innerText);

        return toWidget(documentLink, viewWriter, {
          label: ChapterContentLabels.get('document-link'),
        });
      },
    });
  }
}

import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import { CustomDialogTitle } from '../common';
import SaveIcon from '@mui/icons-material/SaveRounded';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { DOCUMENTS_ID_TITLE_QUERY } from '../../operations/documents';
import { DocumentNode } from '../../models/documents';
import { useQuery } from '@apollo/client';
import { Checkbox, TextField } from '@mui/material';
import CancelIcon from '@mui/icons-material/HighlightOffRounded';

interface Props {
  dialogOpen: boolean;
  appendedDocumentNodeIds: string[];
  closeHandler: (documentIds: string[] | null) => void;
  facilityIds: string[];
}

const DocumentAddJoinDialog: React.FC<Props> = (props) => {
  const { dialogOpen, closeHandler, appendedDocumentNodeIds, facilityIds } = props;
  const [documentIds, setDocumentIds] = useState<string[]>([]);
  const [search, setSearch] = useState<string>('');

  const { error, data } = useQuery(DOCUMENTS_ID_TITLE_QUERY, {
    skip: !dialogOpen,
    variables: {
      first: 9999999,
      exists:
        !facilityIds || facilityIds.length === 0
          ? [
              {
                facility: false,
              },
            ]
          : undefined,
      facility_list: facilityIds && facilityIds.length > 0 ? facilityIds : undefined,
      order: [{ title: 'ASC' }],
    },
    fetchPolicy: 'network-only',
  });

  let documents: DocumentNode[] = data?.documents?.edges ?? [];
  documents = documents.filter(
    (document) => !appendedDocumentNodeIds.includes(document.node.id) && !!document.node.tenant?.id
  );

  if (search.trim() !== '') {
    const searchTerms = search
      .trim()
      .split(' ')
      .filter((s) => s !== '')
      .map((s) => s.toLocaleLowerCase());
    documents = documents.filter((document) => {
      let foundTerms = 0;
      const documentTitle = document.node.title.toLowerCase();
      searchTerms.forEach((searchTerm) => {
        if (documentTitle.indexOf(searchTerm) >= 0) {
          foundTerms++;
        }
      });

      return foundTerms === searchTerms.length;
    });
  }

  const handleChange = (event: any) => {
    if (event.target.checked) {
      setDocumentIds([...documentIds, event.target.name]);
    } else {
      setDocumentIds(documentIds.filter((documentId) => documentId !== event.target.name));
    }
  };

  const abortClose = () => {
    setSearch('');
    setDocumentIds([]);
    closeHandler(null);
  };

  const submit = () => {
    closeHandler(documentIds);
    setTimeout(() => {
      setDocumentIds([]);
      setSearch('');
    }, 300);
  };

  return (
    <Dialog
      open={dialogOpen}
      onClose={() => abortClose}
      aria-labelledby="dialog-documentaddjoin-title"
    >
      <CustomDialogTitle id="dialog-documentaddjoin-title" onClose={() => closeHandler(null)}>
        <Box display="flex" flexDirection="column">
          <Box>Dokumente verknüpfen {documentIds.length > 0 ? `(${documentIds.length})` : ''}</Box>
          <Box mt={1}>
            <TextField
              label="Suche"
              fullWidth
              onChange={(event) => {
                setSearch(event.target.value);
              }}
              data-test="dialogFilterSearch"
            />
          </Box>
        </Box>
      </CustomDialogTitle>
      <DialogContent>
        {error && <Alert severity="error">Es ist ein Fehler aufgetreten: {error.message}</Alert>}
        <DialogContentText color="textPrimary">
          Wählen Sie bitte die Dokumente aus, die Sie verknüpfen wollen.
        </DialogContentText>
        <Box
          mt={3}
          sx={{
            minWidth: '400px',
            minHeight: '250px',
          }}
        >
          {documents.length === 0 && (
            <Typography component="span">Keine Dokumente gefunden</Typography>
          )}
          {documents.map((documentNode) => (
            <React.Fragment key={documentNode.node.id}>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={2}>
                  <Checkbox
                    checked={documentIds.includes(documentNode.node.id)}
                    onChange={handleChange}
                    name={documentNode.node.id}
                    data-test="dialogSelectionCheckbox"
                  />
                </Grid>
                <Grid item xs={10}>
                  <Box pt={1}>
                    <Typography component="span">{documentNode.node.title}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </React.Fragment>
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          type="button"
          variant="outlined"
          color="primary"
          startIcon={<CancelIcon />}
          onClick={abortClose}
          data-test="dialogReset"
        >
          Abbrechen
        </Button>
        <Button
          type="button"
          variant="contained"
          color="primary"
          startIcon={<SaveIcon />}
          onClick={submit}
          data-test="dialogSubmit"
        >
          Übernehmen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DocumentAddJoinDialog;

import { useCallback, useState } from 'react';
import { config } from '../models/config';
import { ElasticSearchPayload, ElasticSearchResult } from '../models/elasticSearch';
import { useSnackbar } from 'notistack';
import { getLoggedInUserToken, isLoadingVar } from '../cache';

interface ElasticSearchReturnType {
  getSearchResult: (payload: ElasticSearchPayload | null) => Promise<void>;
  searchResult: ElasticSearchResult | null;
  loadingSearch: boolean;
}

export default function useElasticSearch(): ElasticSearchReturnType {
  const { enqueueSnackbar } = useSnackbar();
  const [searchResult, setSearchResult] = useState<ElasticSearchResult | null>(null);
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);

  // TODO: Use AbortController?
  const getSearchResult = useCallback(
    async (payload: ElasticSearchPayload | null): Promise<void> => {
      if (!payload) {
        return;
      }
      setLoadingSearch(true);
      isLoadingVar(true);
      // https://stackoverflow.com/a/66713599
      try {
        let formData = new FormData();
        Object.entries(payload).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            value.forEach((val) => {
              formData.append(`${key}[]`, val);
            });
          } else {
            formData.append(key, value);
          }
        });
        const response = await window.fetch(config.API_BASE_URL + '/search', {
          method: 'POST',
          headers: {
            Accept: 'application/ld+json',
            Authorization: 'Bearer ' + getLoggedInUserToken(),
          },
          body: formData,
        });
        if (!response.ok) {
          throw response;
        }
        const result = await response.json();
        if (result) {
          setSearchResult(result);
        }
      } catch (error) {
        if (error instanceof Response) {
          switch (error.status) {
            // case 401:
            //   throw new Error("Invalid login credentials");
            /* ... */
            default:
              throw new Error(`Fehler ${error.status}: ${error.statusText}`);
          }
        }
        const errorMsg = `Es ist ein Fehler aufgetreten: ${error.message || error}`;
        enqueueSnackbar(errorMsg, {
          variant: 'error',
        });
        throw new Error(errorMsg);
      } finally {
        setLoadingSearch(false);
        isLoadingVar(false);
      }
    },
    [enqueueSnackbar]
  );

  return { getSearchResult, searchResult, loadingSearch };
}

import React, { ChangeEvent, useState } from 'react';
import { Question } from '../../../models/questions';
import { ErrorMessage, FormikProps } from 'formik';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';

interface Props {
  question: Question;
  formikProps: FormikProps<any>;
}

export default function SurveyParticipationMultipleChoiceComponent(props: Props) {
  const { question, formikProps } = props;
  const [checkBoxState, setBoxState] = useState(0);

  const setValuesByChangeEvent = (event: ChangeEvent<HTMLInputElement>) => {
    const checkedValue = event.target.defaultValue;
    let newChoices = [...formikProps.values.choices];

    if (newChoices.indexOf(checkedValue) >= 0) {
      newChoices = newChoices.filter((value: string) => value !== checkedValue);
    } else {
      newChoices.push(checkedValue);
    }

    if (newChoices.length > question.maxAnswers) {
      return;
    }

    formikProps.setFieldValue('choices', newChoices);
    setBoxState(checkBoxState + 1);
  };

  return (
    <>
      <ErrorMessage name="choices">
        {(msg) => (
          <Box mb={2}>
            <FormLabel component="div" error={true}>
              {msg}
            </FormLabel>
          </Box>
        )}
      </ErrorMessage>
      {question.choices.map((choiceText, choiceIndex) => (
        <Box mb={1} key={`grid-${question.id}-${choiceIndex}-${checkBoxState}`}>
          <FormControlLabel
            control={
              <Checkbox
                key={`${question.id}-${choiceIndex}-${checkBoxState}`}
                checked={formikProps.values.choices.indexOf(choiceIndex.toString()) >= 0}
                value={choiceIndex}
                name="choices"
                onChange={(event) => setValuesByChangeEvent(event)}
              />
            }
            label={choiceText}
          />
        </Box>
      ))}
    </>
  );
}

import { Command } from '@ckeditor/ckeditor5-core';
import first from '@ckeditor/ckeditor5-utils/src/first';
import { PLUGIN_KEY } from './index';

// Based on https://github.com/p0thi/ckeditor5-line-height-plugin/blob/master/src/lineheightcommand.js
export default class LineHeightCommand extends Command {
  refresh() {
    const firstBlock = first(this.editor.model.document.selection.getSelectedBlocks());

    this.isEnabled = !!firstBlock && this._canSetLineHeight(firstBlock);

    this.value =
      this.isEnabled && firstBlock.hasAttribute(PLUGIN_KEY)
        ? firstBlock.getAttribute(PLUGIN_KEY)
        : '1';
  }

  execute(options = {}) {
    const editor = this.editor;
    const model = editor.model;
    const doc = model.document;

    const value = options.value;

    model.change((writer) => {
      const blocks = Array.from(doc.selection.getSelectedBlocks()).filter((block) =>
        this._canSetLineHeight(block)
      );
      const currentLineHeight = blocks[0].getAttribute(PLUGIN_KEY);

      const removeLineHeight = currentLineHeight === value || typeof value === 'undefined';

      if (removeLineHeight) {
        removeLineHeightFromSelection(blocks, writer);
      } else {
        setLineHeightOnSelection(blocks, writer, value);
      }
    });
  }

  _canSetLineHeight(block) {
    return this.editor.model.schema.checkAttribute(block, PLUGIN_KEY);
  }
}

function removeLineHeightFromSelection(blocks, writer) {
  for (const block of blocks) {
    writer.removeAttribute(PLUGIN_KEY, block);
  }
}

function setLineHeightOnSelection(blocks, writer, lineHeight) {
  for (const block of blocks) {
    writer.setAttribute(PLUGIN_KEY, lineHeight, block);
  }
}

import { Plugin } from '@ckeditor/ckeditor5-core';
import { isSupported, buildDefinition } from './utils';
import LineHeightCommand from './LineHeightCommand';
import { PLUGIN_KEY } from './index';

// Based on https://github.com/p0thi/ckeditor5-line-height-plugin/blob/master/src/lineheightediting.js
export default class LineHeightEditing extends Plugin {
  constructor(editor) {
    super(editor);

    editor.config.define(PLUGIN_KEY, {
      options: [1.25, 'default', 1.75, 2, 2.5],
    });
  }

  /**
   * @inheritDoc
   */
  init() {
    const editor = this.editor;
    const schema = editor.model.schema;

    // Filter out unsupported options.
    const enabledOptions = editor.config
      .get('lineHeight.options')
      .map((option) => String(option))
      .filter(isSupported);

    schema.extend('$block', { allowAttributes: PLUGIN_KEY });
    editor.model.schema.setAttributeProperties(PLUGIN_KEY, { isFormatting: true });

    const definition = buildDefinition(enabledOptions);

    editor.conversion.attributeToAttribute(definition);

    editor.commands.add(PLUGIN_KEY, new LineHeightCommand(editor));
  }
}

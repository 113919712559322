import {
  AlignmentFormat,
  SupportedOption,
} from '@ckeditor/ckeditor5-alignment/src/alignmentconfig';
import { HeadingOption } from '@ckeditor/ckeditor5-heading';

export const ckeditorConfigFontFamilyOptions = [
  'default',
  'Open Sans',
  'Noto Serif',
  'Indie Flower',
];

export const ckeditorConfigFontSizeOptions = [
  8,
  9,
  10,
  11,
  12,
  14,
  'default',
  18,
  24,
  30,
  36,
  48,
  60,
  72,
  96,
];

export const ckeditorConfigColors = [
  {
    color: 'rgb(97,189,109)',
    label: 'Grün',
  },
  {
    color: 'rgb(26,188,156)',
    label: 'Türkis',
  },
  {
    color: 'rgb(26,188,156)',
    label: 'Türkis',
  },
  {
    color: 'rgb(84,172,210)',
    label: 'Petrol',
  },
  {
    color: 'rgb(44,130,201)',
    label: 'Blau',
  },
  {
    color: 'rgb(147,101,184)',
    label: 'Violett',
  },
  {
    color: 'rgb(71,85,119)',
    label: 'Dunkelgrauviolett',
  },
  {
    color: 'rgb(204,204,204)',
    label: 'Warmes Grau',
  },
  {
    color: 'rgb(65,168,95)',
    label: 'Grün',
  },
  {
    color: 'rgb(0,168,133)',
    label: 'Kaltes Grün',
  },
  {
    color: 'rgb(61,142,185)',
    label: 'Blaugrau',
  },
  {
    color: 'rgb(41,105,176)',
    label: 'Warmes Blau',
  },
  {
    color: 'rgb(85,57,130)',
    label: 'Dunkelviolett',
  },
  {
    color: 'rgb(40,50,78)',
    label: 'Sehr dunkles Grau',
  },
  {
    color: 'rgb(0,0,0)',
    label: 'Schwarz',
  },
  {
    color: 'rgb(247,218,100)',
    label: 'Gelb',
  },
  {
    color: 'rgb(251,160,38)',
    label: 'Orange',
  },
  {
    color: 'rgb(235,107,86)',
    label: 'Orangerot',
  },
  {
    color: 'rgb(226,80,65)',
    label: 'Rot',
  },
  {
    color: 'rgb(163,143,132)',
    label: 'Mittelgrau',
  },
  {
    color: 'rgb(239,239,239)',
    label: 'Hellgrau',
    hasBorder: true,
  },
  {
    color: 'rgb(255,255,255)',
    label: 'Weiß',
    hasBorder: true,
  },
  {
    color: 'rgb(250,197,28)',
    label: 'Dunkelgelb',
  },
  {
    color: 'rgb(243,121,52)',
    label: 'Dunkelorange',
  },
  {
    color: 'rgb(209,72,65)',
    label: 'Gedecktes Rot',
  },
  {
    color: 'rgb(184,49,47)',
    label: 'Dunkelrot',
  },
  {
    color: 'rgb(124,112,107)',
    label: 'Grau',
  },
  {
    color: 'rgb(209,213,216)',
    label: 'Kaltes Grau',
  },
];

export const ckeditorConfigHeadingOptions: HeadingOption[] = [
  { model: 'paragraph', title: 'Absatz', class: '' },
  { model: 'heading1', view: 'h1', title: 'Überschrift 1', class: '' },
  { model: 'heading2', view: 'h2', title: 'Überschrift 2', class: '' },
  { model: 'heading3', view: 'h3', title: 'Überschrift 3', class: '' },
];

export const ckeditorConfigAlignmentOptions: Array<SupportedOption | AlignmentFormat> = [
  'left',
  'center',
  'right',
  'justify',
];

export const ckeditorConfigIndentBlock = {
  offset: 2,
  unit: 'rem',
};

export const ckeditorTableDefaultProperties = {
  borderStyle: 'none',
  borderColor: 'rgb(204,204,204)',
  borderWidth: '1px',
  alignment: 'center',
};

export const ckeditorTableCellDefaultProperties = {
  borderStyle: 'solid',
  borderColor: 'rgb(204,204,204)',
  borderWidth: '1px',
  horizontalAlignment: 'left',
  verticalAlignment: 'middle',
  padding: '12px',
};

export const ckeditorConfigImageUploadTypes = ['jpeg', 'png', 'gif', 'svg+xml'];

export const ckeditorConfigImageResizeOptions = [
  {
    name: 'resizeImage:original',
    value: null,
    label: 'Original',
  },
  {
    name: 'resizeImage:25',
    value: '25',
    label: '25%',
  },
  {
    name: 'resizeImage:50',
    value: '50',
    label: '50%',
  },
  {
    name: 'resizeImage:75',
    value: '75',
    label: '75%',
  },
  {
    name: 'resizeImage:100',
    value: '100',
    label: '100%',
  },
];

export const ckeditorConfigImageUploadMaxSize = 2097152;

export const CutCopyPasteContentLabels = new Map();
CutCopyPasteContentLabels.set('cutContent', 'Ausschneiden');
CutCopyPasteContentLabels.set('copyContent', 'Kopieren');
CutCopyPasteContentLabels.set('pasteContent', 'Einfügen');

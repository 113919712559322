import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { isLoadingVar, loggedInMeVar } from '../../cache';
import { config } from '../../models/config';
import { setAuth } from '../../operations/auth';
import { useSnackbar } from 'notistack';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { SURVEY_QUERY_FOR_PARTICIPATION } from '../../operations/survey';
import { Survey } from '../../models/surveys';
import {
  SurveyParticipationFinish,
  SurveyParticipationIntro,
  SurveyParticipationQuestion,
} from './surveyParticipationComponents';
import { Question, QuestionNode } from '../../models/questions';
import SubmissionContext from 'views/contexts/SubmissionContext';
import emptySubmissionValue from '../../models/submissions';
import AlertTitle from '@mui/material/AlertTitle';
import Alert from '@mui/material/Alert';
import { decodeIriFromUrlParam, encodeIriToUrlParam, parseUuidFromId } from '../../utils/helper';
import Button from '@mui/material/Button';
import { routes } from '../../models/routes';
import ChartIcon from '@mui/icons-material/EqualizerRounded';
import useBase64ImageByMediaObject from '../../hooks/mediaObjects/useBase64ImageByMediaObject';
import { get_surveyCompletion_locally } from './surveyParticipationComponents/SurveyParticipationFinish.component';
import { DefaultLanguage, LanguageLabels } from '../../models/languages';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { default as MuiSelect } from '@mui/material/Select/Select';
import MenuItem from '@mui/material/MenuItem';
import useTranslationLanguages from '../../hooks/useTranslationLanguages';
import useTranslation from '../../hooks/useTranslation';

export const getQuestionByPosition = (position: number, survey: Survey): Question | null => {
  const orderedQuestions = [...(survey?.questions?.edges ?? [])];
  orderedQuestions.sort(
    (questionA: QuestionNode, questionB: QuestionNode) =>
      (questionA.node?.position ?? 0) - (questionB.node?.position ?? 0)
  );

  return position >= 0 && orderedQuestions.length > 0 ? orderedQuestions[position].node : null;
};

export default function SurveyParticipationComponent() {
  let { targetGroupId: targetGroupIdParam } = useParams<Record<string, string | undefined>>();
  let { surveyId: surveyIdParam } = useParams<Record<string, string | undefined>>();
  // NOTE: Using explicit (local) isAuthenticated state b/c isLoggedInVar triggering reliably for participation use
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const loggedInMe = useReactiveVar(loggedInMeVar);
  const translationLanguages = useTranslationLanguages(isAuthenticated || !!loggedInMe);
  let navigate = useNavigate();

  const targetGroupQueryId: string = decodeIriFromUrlParam(targetGroupIdParam);
  const surveyQueryId: string = decodeIriFromUrlParam(surveyIdParam);

  const [surveyParticipationId, setSurveyParticipationId] = useState<string | null>(null);
  const [surveyLanguage, setSurveyLanguage] = useState<string>(DefaultLanguage);
  const translation = useTranslation(isAuthenticated || !!loggedInMe, surveyLanguage);

  const { enqueueSnackbar } = useSnackbar();

  const [position, setPosition] = useState<number>(-1);
  const [errorText, setErrorText] = useState<string | null>(null);

  const [querySurveyForParticipation, { data }] = useLazyQuery(SURVEY_QUERY_FOR_PARTICIPATION, {
    fetchPolicy: 'network-only',
    onError() {
      setErrorText(translation.eval_not_found);
    },
  });

  const question = useMemo(() => getQuestionByPosition(position, data?.survey), [position, data]);
  const questionCount = useMemo(() => data?.survey?.questions?.totalCount ?? 0, [data]);

  const { image: logoSrc } = useBase64ImageByMediaObject(data?.survey?.logo ?? null);

  const stepForward = () => {
    if (position === -1 && get_surveyCompletion_locally(targetGroupQueryId)) {
      return enqueueSnackbar(translation.already_completed, { variant: 'error' });
    }
    if (position === questionCount - 1) {
      setPosition(-2);
    } else {
      setPosition(position + 1);
    }
  };

  const stepBack = () => {
    setPosition(position - 1);
  };

  const cancel = () => {
    setPosition(-1);
  };

  useEffect(() => {
    if (loggedInMe && surveyQueryId) {
      // NOTE: coming from SURVEY_SUPPLEMENT path
      return;
    }

    const abortController = new AbortController();

    const requestTempLoginForSurvey = async () => {
      isLoadingVar(true);
      const payload = {
        targetGroupId: parseUuidFromId(targetGroupQueryId),
      };
      try {
        const response = await fetch(config.API_BASE_URL + '/survey_authentication_token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });

        const result = (await response.json()) as any;
        if (!('token' in result && 'surveyId' in result)) {
          setErrorText(translation.survey_not_found);
          isLoadingVar(false);

          return;
        }

        isLoadingVar(false);
        const authData = { token: result.token };
        setAuth(authData);
        setIsAuthenticated(true);
        setSurveyParticipationId(result.surveyId);
      } catch (error: any) {
        setErrorText(translation.error_occurred);
      }
    };

    const cancelLogin = () => {
      isLoadingVar(false);
      abortController.abort();
    };

    requestTempLoginForSurvey();

    return cancelLogin;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInMe, surveyQueryId, targetGroupQueryId]);

  useEffect(() => {
    const lang = surveyLanguage !== DefaultLanguage ? surveyLanguage : undefined;

    if (loggedInMe && surveyQueryId) {
      // NOTE: coming from SURVEY_SUPPLEMENT path using surveyId from url param
      querySurveyForParticipation({
        variables: {
          id: surveyQueryId,
          lang: lang,
        },
      });
      return;
    }

    if (isAuthenticated && surveyParticipationId) {
      // NOTE: coming from SURVEY_PARTICIPATE path using survey_authentication_token result
      querySurveyForParticipation({
        variables: {
          id: `/api/surveys/${surveyParticipationId}`,
          lang: lang,
        },
      });
    }
  }, [
    surveyLanguage,
    loggedInMe,
    surveyQueryId,
    isAuthenticated,
    surveyParticipationId,
    querySurveyForParticipation,
  ]);

  return (
    <SubmissionContext.Provider value={emptySubmissionValue}>
      <Container>
        {surveyLanguage !== DefaultLanguage && (
          <Box mb={3}>
            <Alert severity="info">{translation.note_machine_translation}</Alert>
          </Box>
        )}
        <Box
          component="header"
          mb={3}
          display="flex"
          alignItems="flex-end"
          justifyContent="space-between"
        >
          <Box>
            {data?.survey?.logo && (
              <img
                src={logoSrc ?? ''}
                alt={data.survey.logo.filePath ?? ''}
                style={{
                  display: 'block',
                  marginBottom: '1.5rem',
                  maxWidth: '10rem',
                  maxHeight: '5rem',
                }}
              />
            )}
            <Typography component="h1" variant="h2" gutterBottom>
              {errorText === null && data?.survey ? data?.survey?.title : ''}
            </Typography>
          </Box>
          <Box display="flex" alignItems="flex-end">
            {translationLanguages &&
              Object.keys(translationLanguages).length > 0 &&
              position === -1 && (
                <FormControl sx={{ minWidth: 160 }}>
                  <InputLabel id="languageSelectionLabel">{translation.language}</InputLabel>
                  <MuiSelect
                    labelId="languageSelectionLabel"
                    label={translation.language}
                    value={surveyLanguage}
                    onChange={(event: any) => setSurveyLanguage(event.target.value)}
                    data-test="selectLanguage"
                  >
                    <MenuItem value={DefaultLanguage}>{LanguageLabels[DefaultLanguage]}</MenuItem>
                    {Object.keys(translationLanguages).map((languageKey) => (
                      <MenuItem value={languageKey} key={languageKey}>
                        {translationLanguages[languageKey]}
                      </MenuItem>
                    ))}
                  </MuiSelect>
                </FormControl>
              )}
            {position >= 0 && (
              <Typography variant="h6" noWrap gutterBottom>
                {translation.step} {position + 1} {translation.of} {questionCount}
              </Typography>
            )}
            {loggedInMe && data?.survey && (
              <Box ml={2}>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    navigate(
                      routes['SURVEY_EVALUATION'].path.replace(
                        ':surveyId',
                        encodeIriToUrlParam(data.survey.id)
                      )
                    );
                  }}
                  startIcon={<ChartIcon />}
                >
                  Umfrageauswertung
                </Button>
              </Box>
            )}
          </Box>
        </Box>
        {errorText === null ? (
          <Fragment>
            {data?.survey && (
              <Fragment>
                {position === -1 && (
                  <SurveyParticipationIntro
                    survey={data.survey}
                    stepForward={stepForward}
                    translation={translation}
                  />
                )}
                {position === -2 && (
                  <SurveyParticipationFinish
                    survey={data.survey}
                    targetGroupId={targetGroupQueryId}
                    translation={translation}
                  />
                )}
                {position > -1 && question && (
                  <SurveyParticipationQuestion
                    key={position}
                    position={position}
                    questionCount={questionCount}
                    question={question}
                    survey={data.survey}
                    stepForward={stepForward}
                    stepBack={stepBack}
                    cancel={cancel}
                    translation={translation}
                  />
                )}
              </Fragment>
            )}
          </Fragment>
        ) : (
          <Alert severity="error">
            <AlertTitle>{translation.error}</AlertTitle>
            {errorText}
          </Alert>
        )}
      </Container>
    </SubmissionContext.Provider>
  );
}

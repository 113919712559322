import packageJson from '../../package.json';

export const config = {
  APP_NAME: packageJson.name,
  APP_TITLE: process.env.REACT_APP_SITE_TITLE,
  APP_ENV: process.env.REACT_APP_ENV,
  BASE_URL: process.env.REACT_APP_BASE_URL,
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
  CKEDITOR_LICENSE_KEY: process.env.REACT_APP_CKEDITOR_LICENSE_KEY,
};

import React from 'react';
import Box from '@mui/material/Box';
import { Question } from '../../../models/questions';
import parse from 'html-react-parser';
import useGlobalStyles from '../../../hooks/useGlobalStyles';

interface Props {
  question: Question;
}

export default function SurveyParticipationCopytextComponent(props: Props) {
  const { question } = props;
  const { classes: globalClasses } = useGlobalStyles();

  return (
    <>
      <Box mb={3} minHeight={200} className={globalClasses.richText}>
        {parse(question.text)}
      </Box>
    </>
  );
}

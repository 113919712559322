import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { ToolbarPaper } from '../common';
import Button from '@mui/material/Button';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { routes } from '../../models/routes';
import ListIcon from '@mui/icons-material/ReorderRounded';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import useGlobalStyles from '../../hooks/useGlobalStyles';
import EditIcon from '@mui/icons-material/EditRounded';
import useLoggedInMePermissions from '../../hooks/useLoggedInMePermissions';
import { permissionComponentKeys } from '../../models/permissions';
import { QUALITYDEVELOPMENTMEASURE_QUERY } from '../../operations/qualityDevelopmentMeasure';
import { QualityDevelopmentMeasureStatus } from './QualityDevelopmentMeasureStatus.component';
import { default as QualityDevelopmentMeasureChildren } from './QualityDevelopmentMeasureChildren.component';
import dayjs from 'dayjs';
import { DocumentsJoined } from '../documents';
import {
  decodeIriFromUrlParam,
  downloadByFetch,
  encodeIriToUrlParam,
  parseUuidFromId,
} from '../../utils/helper';
import { FacilityStatusLabels } from '../../models/facilities';
import { config } from '../../models/config';
import { useSnackbar } from 'notistack';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import { CREATE_ACTIVITYLOG_MUTATION } from '../../operations/activityLog';
import LoadingButton from '@mui/lab/LoadingButton';
import { loggedInMeVar } from '../../cache';
import { UserNode } from '../../models/users';
import { QualityDevelopmentMeasureStatusLabels } from '../../models/qualityDevelopmentMeasures';
import LoopIcon from '@mui/icons-material/LoopRounded';
import { Rating } from '@mui/material';
import { DocumentNode } from '../../models/documents';
import { useDocumentVersionDownload } from '../documents/Document.component';
import { renderEditorHTML } from '../manuals/ManualChapter.component';

export default function QualityDevelopmentMeasureComponent() {
  const { classes: globalClasses } = useGlobalStyles();
  const loggedInMe = useReactiveVar(loggedInMeVar);
  const permissions = useLoggedInMePermissions(permissionComponentKeys.QUALITYDEVELOPMENTMEASURES);
  const { enqueueSnackbar } = useSnackbar();
  let navigate = useNavigate();
  const [isDownloading, setIsDownloading] = useState(false);
  const download = useDocumentVersionDownload();

  let { qualityDevelopmentMeasureId: qualityDevelopmentMeasureIdParam } =
    useParams<Record<string, string | undefined>>();

  let { qualityDevelopmentMeasureChildId: qualityDevelopmentMeasureChildIdParam } =
    useParams<Record<string, string | undefined>>();

  const childMode = useMemo(
    () => !!qualityDevelopmentMeasureChildIdParam,
    [qualityDevelopmentMeasureChildIdParam]
  );

  const qualityDevelopmentMeasureQueryId: string = qualityDevelopmentMeasureChildIdParam
    ? decodeIriFromUrlParam(qualityDevelopmentMeasureChildIdParam)
    : decodeIriFromUrlParam(qualityDevelopmentMeasureIdParam);

  const { error, data, loading } = useQuery(QUALITYDEVELOPMENTMEASURE_QUERY, {
    variables: {
      id: qualityDevelopmentMeasureQueryId,
    },
    fetchPolicy: 'network-only',
  });

  const redirect = useMemo(
    () =>
      !childMode &&
      data?.qualityDevelopmentMeasure?.id &&
      data?.qualityDevelopmentMeasure?.parent?.id,
    [childMode, data]
  );

  const [createActivityLogMutation] = useMutation(CREATE_ACTIVITYLOG_MUTATION, {
    onError(error) {
      console.error(error);
    },
  });

  useEffect(() => {
    if (!redirect) {
      return;
    }

    navigate(
      routes['QUALITYDEVELOPMENTMEASURE_CHILD'].path
        .replace(
          ':qualityDevelopmentMeasureId',
          encodeIriToUrlParam(data.qualityDevelopmentMeasure.parent.id)
        )
        .replace(
          ':qualityDevelopmentMeasureChildId',
          encodeIriToUrlParam(data.qualityDevelopmentMeasure.id)
        )
    );
  }, [redirect, data, navigate]);

  useEffect(() => {
    if (childMode || !data?.qualityDevelopmentMeasure?.id) {
      return;
    }

    createActivityLogMutation({
      variables: {
        input: {
          accessedEntity: data.qualityDevelopmentMeasure.id,
        },
      },
    });
  }, [childMode, data, createActivityLogMutation]);

  const userHasEditScope = useMemo(() => {
    if (!data?.qualityDevelopmentMeasure?.id) {
      return false;
    }

    return (
      loggedInMe?.tenantWideEditPermission ||
      !!loggedInMe?.facilities?.edges.some(
        (edge) => edge.node?.id === data.qualityDevelopmentMeasure.facility?.id
      )
    );
  }, [loggedInMe, data]);

  const downloadQdmPdf = useCallback(async () => {
    if (!data?.qualityDevelopmentMeasure) {
      return;
    }

    const pdfFileUuid = parseUuidFromId(data.qualityDevelopmentMeasure.id ?? '');

    if (pdfFileUuid.length === 0) {
      return;
    }

    const url = `${config.API_BASE_URL}/export/quality-development-measure-pdf/${pdfFileUuid}`;
    const fileName = data.qualityDevelopmentMeasure.title.trim();
    try {
      await downloadByFetch(url, fileName, 'pdf');
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
  }, [data, enqueueSnackbar]);

  if (loading) return null;
  if (error?.message)
    return (
      <Container>
        <Alert severity="error">Es ist ein Fehler aufgetreten: {error.message}</Alert>
      </Container>
    );

  return (
    <Container>
      <Box component="header" mb={3}>
        <Typography component="h1" variant="h2" gutterBottom>
          {childMode ? 'Qualitäts­entwicklungs­teilmaßnahme' : 'Qualitäts­entwicklungs­maßnahme'}
        </Typography>
      </Box>
      {data?.qualityDevelopmentMeasure ? (
        <Fragment>
          <Paper component="section" variant="outlined" className={globalClasses.paper}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box display="flex" flexWrap="wrap">
                  <Box>
                    <Typography component="h2" variant="h3" gutterBottom>
                      {data.qualityDevelopmentMeasure.title}
                    </Typography>
                  </Box>
                  <Box ml="auto">
                    <QualityDevelopmentMeasureStatus
                      qualityDevelopmentMeasure={data.qualityDevelopmentMeasure}
                    />
                  </Box>
                </Box>
              </Grid>
              {childMode && data?.qualityDevelopmentMeasure?.parent && (
                <>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Typography variant="h6">Übergeordnete Maßnahme:</Typography>
                    <Typography variant="body1">
                      {data.qualityDevelopmentMeasure.parent.title}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="h6">Status:</Typography>
                    <Typography variant="body1">
                      {data.qualityDevelopmentMeasure.parent.state
                        ? QualityDevelopmentMeasureStatusLabels.get(
                            data.qualityDevelopmentMeasure.parent.state
                          )
                        : '—'}
                    </Typography>
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="h6">Erstellungsdatum:</Typography>
                <Typography variant="body1">
                  <time dateTime={dayjs(data.qualityDevelopmentMeasure.createdAt).toISOString()}>
                    {dayjs(data.qualityDevelopmentMeasure.createdAt).format('DD.MM.YYYY HH:mm')} Uhr
                  </time>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="h6">Endtermin:</Typography>
                <Typography variant="body1">
                  {data.qualityDevelopmentMeasure.dueDate ? (
                    <time dateTime={dayjs(data.qualityDevelopmentMeasure.dueDate).toISOString()}>
                      {dayjs(data.qualityDevelopmentMeasure.dueDate).format('DD.MM.YYYY HH:mm')} Uhr
                    </time>
                  ) : (
                    '—'
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="h6">Träger/Einrichtung:</Typography>
                {data.qualityDevelopmentMeasure.facility ? (
                  <Typography variant="body1">
                    {data.qualityDevelopmentMeasure.facility.name}
                  </Typography>
                ) : (
                  <Typography variant="body1">
                    {data.qualityDevelopmentMeasure?.tenant?.name ??
                      FacilityStatusLabels.FACILITY_TENANTWIDE}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="h6">Erstellt von:</Typography>
                <Typography variant="body1">
                  {data.qualityDevelopmentMeasure.createdBy
                    ? `${data.qualityDevelopmentMeasure.createdBy.firstName} ${data.qualityDevelopmentMeasure.createdBy.lastName}`
                    : '—'}
                </Typography>
              </Grid>
              <Grid item xs={12} md={8} lg={3}>
                <Typography variant="h6">Zuständig:</Typography>
                {data.qualityDevelopmentMeasure.assignedTo?.edges.length > 0 ? (
                  <>
                    {data.qualityDevelopmentMeasure.assignedTo.edges.map(({ node }: UserNode) => (
                      <Typography key={'assignedUser_' + node.id} variant="body1">
                        {node.firstName} {node.lastName}
                      </Typography>
                    ))}
                  </>
                ) : (
                  <Typography variant="body1">—</Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="h6">Geändert von:</Typography>
                <Typography variant="body1">
                  {data.qualityDevelopmentMeasure.updatedBy
                    ? `${data.qualityDevelopmentMeasure.updatedBy.firstName} ${data.qualityDevelopmentMeasure.updatedBy.lastName}`
                    : '—'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={8} lg={6}>
                <Typography variant="h6">Zuletzt geändert am:</Typography>
                <Typography variant="body1">
                  {data.qualityDevelopmentMeasure.updatedAt !==
                  data.qualityDevelopmentMeasure.createdAt ? (
                    <time dateTime={dayjs(data.qualityDevelopmentMeasure.updatedAt).toISOString()}>
                      {dayjs(data.qualityDevelopmentMeasure.updatedAt).format('DD.MM.YYYY HH:mm')}{' '}
                      Uhr
                    </time>
                  ) : (
                    '—'
                  )}
                </Typography>
              </Grid>
              {!childMode && (
                <>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Typography variant="h6">Wirksamkeitsbewertung:</Typography>
                    <Typography variant="body1">
                      {data.qualityDevelopmentMeasure.effectivityScore !== null ? (
                        <Rating
                          name="effectivityScoreRating"
                          readOnly
                          value={data.qualityDevelopmentMeasure.effectivityScore}
                        />
                      ) : (
                        '—'
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={8} lg={9}>
                    <Typography variant="h6">Bemerkungen:</Typography>
                    <Typography variant="body1">
                      {data.qualityDevelopmentMeasure.effectivityText ?? '—'}
                    </Typography>
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} className={globalClasses.richText}>
                {renderEditorHTML(
                  data.qualityDevelopmentMeasure.content,
                  [],
                  data.qualityDevelopmentMeasure.documents?.edges.map(
                    (documentNode: DocumentNode) => documentNode.node
                  ) ?? [],
                  download
                )}
              </Grid>
            </Grid>
          </Paper>
        </Fragment>
      ) : (
        <Alert severity="warning">Qualitäts­entwicklungs­maßnahme nicht gefunden</Alert>
      )}
      {data?.qualityDevelopmentMeasure && (
        <Fragment>
          <ToolbarPaper>
            {permissions?.update && userHasEditScope && (
              <>
                {childMode && data.qualityDevelopmentMeasure.parent?.id ? (
                  <Button
                    component={NavLink}
                    to={routes['QUALITYDEVELOPMENTMEASURE_CHILD_EDIT'].path
                      .replace(
                        ':qualityDevelopmentMeasureId',
                        encodeIriToUrlParam(data.qualityDevelopmentMeasure.parent.id)
                      )
                      .replace(
                        ':qualityDevelopmentMeasureChildId',
                        encodeIriToUrlParam(data.qualityDevelopmentMeasure.id)
                      )}
                    variant="contained"
                    color="primary"
                    startIcon={<EditIcon />}
                  >
                    Bearbeiten
                  </Button>
                ) : (
                  <Button
                    component={NavLink}
                    to={routes['QUALITYDEVELOPMENTMEASURE_EDIT'].path.replace(
                      ':qualityDevelopmentMeasureId',
                      encodeIriToUrlParam(data.qualityDevelopmentMeasure.id)
                    )}
                    variant="contained"
                    color="primary"
                    startIcon={<EditIcon />}
                  >
                    Bearbeiten
                  </Button>
                )}
              </>
            )}
            <LoadingButton
              onClick={async () => {
                setIsDownloading(true);
                await downloadQdmPdf();
                setIsDownloading(false);
              }}
              loading={isDownloading}
              loadingPosition="start"
              variant="outlined"
              color="primary"
              startIcon={<PdfIcon />}
            >
              PDF herunterladen
            </LoadingButton>
            {childMode && data.qualityDevelopmentMeasure.parent?.id && (
              <Button
                component={NavLink}
                to={routes['QUALITYDEVELOPMENTMEASURE'].path.replace(
                  ':qualityDevelopmentMeasureId',
                  encodeIriToUrlParam(data.qualityDevelopmentMeasure.parent.id)
                )}
                variant="outlined"
                color="primary"
                startIcon={<LoopIcon />}
                className="alignRight"
              >
                Übergeordnete Maßnahme
              </Button>
            )}
            <Button
              component={NavLink}
              to={routes['QUALITYDEVELOPMENTMEASURES'].path}
              variant="outlined"
              color="primary"
              startIcon={<ListIcon />}
              className={
                !(childMode && data.qualityDevelopmentMeasure.parent?.id) ? 'alignRight' : ''
              }
            >
              Alle Qualitäts­entwicklungs­maßnahmen
            </Button>
          </ToolbarPaper>
          <DocumentsJoined entityId={data.qualityDevelopmentMeasure.id} editing={false} />
          {!childMode && (
            <QualityDevelopmentMeasureChildren parentId={data.qualityDevelopmentMeasure.id} />
          )}
        </Fragment>
      )}
    </Container>
  );
}

import React, { ForwardedRef, Fragment, useCallback } from 'react';
import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Essentials } from '@ckeditor/ckeditor5-essentials';
import {
  Bold,
  Italic,
  Underline,
  Strikethrough,
  Subscript,
  Superscript,
  Code,
} from '@ckeditor/ckeditor5-basic-styles';
import { Link } from '@ckeditor/ckeditor5-link';
import { FontFamily, FontSize, FontColor, FontBackgroundColor } from '@ckeditor/ckeditor5-font';
import { Heading } from '@ckeditor/ckeditor5-heading';
import { Paragraph } from '@ckeditor/ckeditor5-paragraph';
import { BlockQuote } from '@ckeditor/ckeditor5-block-quote';
import { List } from '@ckeditor/ckeditor5-list';
import { Indent, IndentBlock } from '@ckeditor/ckeditor5-indent';
import { Alignment } from '@ckeditor/ckeditor5-alignment';
import { RemoveFormat } from '@ckeditor/ckeditor5-remove-format';
import CutCopyPastePlugin from '../../ckeditor/cutCopyPaste';
import LineHeightPlugin from '../../ckeditor/lineHeight';
import {
  ckeditorConfigAlignmentOptions,
  ckeditorConfigColors,
  ckeditorConfigFontFamilyOptions,
  ckeditorConfigFontSizeOptions,
  ckeditorConfigHeadingOptions,
  ckeditorConfigIndentBlock,
} from '../../models/ckeditor';
import { config } from '../../models/config';

const SurveyQuestionEditorComponent = React.forwardRef(
  (props: any, ref: ForwardedRef<ClassicEditor>) => {
    const { value, onChange, focusHandler, blurHandler } = props;

    const handleEditorReady = useCallback(
      (editor: ClassicEditor) => {
        const editorInstance: any = editor; // TS hack
        // NOTE: Needed because of race condition when setting data (no viewportOffsetTop determination as with ManualChapterEditorComponent)
        // Beware: will trigger formDirtyRef (which luckily is irrelevant in SurveyQuestionEditorComponent context)
        if (value && value.length > 0) {
          editorInstance.setData(value);
        } else {
          editorInstance.editing.view.focus();
        }
        if (!ref) {
          return;
        }
        if (typeof ref === 'function') {
          ref(editorInstance.ui.element);
        } else {
          ref.current = editorInstance.ui.element;
        }
      },
      [value, ref]
    );

    return (
      <Fragment>
        <CKEditor
          editor={ClassicEditor}
          data={value}
          onReady={handleEditorReady}
          onChange={(event, editor) => {
            onChange(editor.getData());
          }}
          onFocus={() => {
            focusHandler();
          }}
          onBlur={() => {
            blurHandler();
          }}
          config={{
            licenseKey: config.CKEDITOR_LICENSE_KEY,
            plugins: [
              Essentials,
              Bold,
              Italic,
              Underline,
              Strikethrough,
              Subscript,
              Superscript,
              Code,
              Link,
              FontFamily,
              FontSize,
              FontColor,
              FontBackgroundColor,
              Heading,
              Paragraph,
              BlockQuote,
              List,
              Indent,
              IndentBlock,
              Alignment,
              RemoveFormat,
              LineHeightPlugin,
              CutCopyPastePlugin,
            ],
            link: {
              decorators: {
                openInNewTab: {
                  mode: 'manual',
                  label: 'In neuem Fenster/Tab öffnen',
                  attributes: {
                    target: '_blank',
                    rel: 'noopener noreferrer',
                  },
                },
              },
              defaultProtocol: 'https://',
            },
            fontFamily: {
              options: ckeditorConfigFontFamilyOptions,
            },
            fontSize: {
              options: ckeditorConfigFontSizeOptions,
            },
            fontColor: {
              colors: ckeditorConfigColors,
            },
            fontBackgroundColor: {
              colors: ckeditorConfigColors,
            },
            heading: {
              options: ckeditorConfigHeadingOptions,
            },
            alignment: {
              options: ckeditorConfigAlignmentOptions,
            },
            indentBlock: ckeditorConfigIndentBlock,
            toolbar: {
              items: [
                'heading',
                '|',
                'alignment',
                '|',
                'bold',
                'italic',
                'strikethrough',
                'underline',
                'code',
                'subscript',
                'superscript',
                'removeFormat',
                '|',
                'bulletedList',
                'numberedList',
                '|',
                'outdent',
                'indent',
                '|',
                'blockQuote',
                '|',
                'cutContent',
                'copyContent',
                'pasteContent',
                '|',
                'fontFamily',
                'fontSize',
                'lineHeight',
                '|',
                'fontColor',
                'fontBackgroundColor',
                '|',
                'link',
                '|',
                'undo',
                'redo',
              ],
              shouldNotGroupWhenFull: false,
            },
          }}
        />
      </Fragment>
    );
  }
);

export default SurveyQuestionEditorComponent;

import React, { useState } from 'react';
import { Formik, Form, FormikValues, FormikHelpers } from 'formik';
import { TextField as MuiTextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import { CustomDialogTitle } from '../common';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import SaveIcon from '@mui/icons-material/SaveRounded';
import useLoggedInMeFacilities from '../../hooks/useLoggedInMeFacilities';
import { Autocomplete } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { FacilityStatusLabels } from '../../models/facilities';
import { useReactiveVar } from '@apollo/client';
import { loggedInMeVar } from '../../cache';
import { reqexHtmlTags } from '../../utils/helper';

interface Props {
  dialogOpen: boolean;
  formData: any;
  submitHandler: (values: FormikValues, formikBag: FormikHelpers<any>) => void;
  resetHandler: () => void;
  children?: React.ReactNode;
}

const SurveyCopyDialogComponent: React.FC<Props> = (props) => {
  const { dialogOpen, formData, submitHandler, resetHandler } = props;
  const loggedInMe = useReactiveVar(loggedInMeVar);

  const [openFacilities, setOpenFacilities] = useState<boolean>(false);
  const tenantWideOption = {
    id: null,
    name: loggedInMe?.tenant?.name ?? FacilityStatusLabels.FACILITY_TENANTWIDE,
  };
  const { facilities: facilitiesOptions, loadingFacilities } = useLoggedInMeFacilities();

  const newSurveyFromTemplateSnippet = formData?.title
    ? `der Vorlage "${formData.title.replace(reqexHtmlTags, '')}"`
    : 'einer Vorlage';
  const newSurveyFromSurveySnippet = formData?.title
    ? `der Evaluation "${formData.title.replace(reqexHtmlTags, '')}"`
    : 'einer bestehenden Evaluation ';

  return (
    <Formik
      initialValues={{
        facility: null,
      }}
      enableReinitialize
      validate={(values) => {
        const errors: any = {};
        if (loggedInMe?.tenant !== null && values.facility === null) {
          errors.facility = 'Bitte Träger/Einrichtung auswählen';
        }
        return errors;
      }}
      onSubmit={(values, formikBag) => {
        submitHandler(values, formikBag);
      }}
    >
      {(props) => (
        <Dialog
          open={dialogOpen}
          onClose={() => props.handleReset()}
          aria-labelledby="dialog-surveycopy-title"
        >
          <Form autoComplete="off">
            <CustomDialogTitle id="dialog-surveycopy-title" onClose={() => resetHandler()}>
              Evaluation kopieren
            </CustomDialogTitle>
            <DialogContent>
              {props.status && (
                <Box mb={2}>
                  <Alert severity="error">{props.status}</Alert>
                </Box>
              )}
              <DialogContentText color="textPrimary">
                Sie möchten eine neue Evaluation auf Basis{' '}
                {formData?.tenant?.id ? newSurveyFromSurveySnippet : newSurveyFromTemplateSnippet}{' '}
                erstellen? Bitte wählen Sie zunächst eine Einrichtung bzw. den Träger aus, für
                die/den die Evaluation verwendet werden soll.
              </DialogContentText>
              <FormControl fullWidth margin="normal">
                <FormLabel htmlFor="facility">Einrichtung</FormLabel>
                <Autocomplete
                  id="facility"
                  open={openFacilities}
                  onOpen={() => {
                    setOpenFacilities(true);
                  }}
                  onClose={() => {
                    setOpenFacilities(false);
                  }}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  getOptionLabel={(option) => option?.name ?? ''}
                  onChange={(e, value) => {
                    props.setFieldValue('facility', value);
                  }}
                  options={
                    loggedInMe?.tenantWideEditPermission
                      ? [tenantWideOption, ...facilitiesOptions]
                      : [...facilitiesOptions]
                  }
                  value={props.values.facility}
                  loading={loadingFacilities}
                  data-test="autocompleteFacility"
                  renderInput={(params) => (
                    <MuiTextField
                      type="text"
                      name="facility"
                      variant="outlined"
                      placeholder="Bitte auswählen"
                      error={Boolean(props.errors.facility && props.touched.facility)}
                      helperText={props.touched.facility && <>{props.errors.facility}</>}
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loadingFacilities ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                type="reset"
                color="primary"
                onClick={() => resetHandler()}
                data-test="dialogReset"
              >
                Abbrechen
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={<SaveIcon />}
                disabled={props.isSubmitting}
                data-test="dialogSubmit"
              >
                Kopieren
              </Button>
            </DialogActions>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};

export default SurveyCopyDialogComponent;

import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import useGlobalStyles from '../../../hooks/useGlobalStyles';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { Manual } from '../../../models/manuals';
import { ManualVersion, ManualVersionNode } from '../../../models/manualVersions';
import GetAppIcon from '@mui/icons-material/GetApp';
import BookIcon from '@mui/icons-material/MenuBookRounded';
import { downloadByManualVersion, getReadableFileSizeString } from '../../../utils/helper';
import { useSnackbar } from 'notistack';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/InfoRounded';
import { InfoDialog } from '../../common';

interface Props {
  manual: Manual;
}

const ManualArchiveComponent: React.FC<Props> = (props) => {
  const { manual } = props;
  const { classes: globalClasses } = useGlobalStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [archiveHeadlineInfoOpen, setManualArchiveHeadlineInfoOpen] = useState<boolean>(false);

  const versions: ManualVersionNode[] = manual.versions?.edges ?? [];

  let archiveVersions = versions.filter((versionNode) => {
    return (versionNode.node?.number ?? 0) < (manual.workingCopyVersion ?? 0);
  });

  archiveVersions.sort((versionA, versionB) => {
    return (versionB.node?.number ?? 0) - (versionA.node?.number ?? 0);
  });

  if (!archiveVersions.length) {
    return <></>;
  }

  const download = async (manualVersion: ManualVersion | undefined) => {
    if (!manualVersion) {
      return;
    }
    try {
      await downloadByManualVersion(manualVersion, manual.title);
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
  };

  return (
    <Box mt={4}>
      <Box component="header" mb={2} display="flex" justifyContent="space-between">
        <Box className={globalClasses.tooltipBox}>
          <Typography component="h2" variant="h4">
            Handbuch Archiv
          </Typography>
          <Tooltip title="Info zu “Handbuch Archiv”">
            <IconButton
              className={globalClasses.tooltipIcon}
              color="primary"
              aria-label="Info"
              onClick={() => {
                setManualArchiveHeadlineInfoOpen(true);
              }}
              size="large"
            >
              <InfoIcon />
            </IconButton>
          </Tooltip>
          <InfoDialog
            open={archiveHeadlineInfoOpen}
            title={`Handbuch Archiv`}
            onClose={() => {
              setManualArchiveHeadlineInfoOpen(false);
            }}
          >
            <Typography paragraph>
              Das Handbuch Archiv enthält an oberster Stelle die aktuelle Version des QM-Handbuchs
              sowie alle weiteren Versionen der Vergangenheit. Sie können die jeweilige Version über
              den Download-Button als PDF-Datei herunterladen.
            </Typography>
          </InfoDialog>
        </Box>
      </Box>
      <Paper component="section" variant="outlined">
        <ul className={globalClasses.listStriped} data-test="list">
          {archiveVersions.map((archiveVersion) => (
            <li key={archiveVersion.node?.number ?? 0} data-test="listItem">
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} style={{ alignSelf: 'center' }}>
                  <Box display="flex">
                    <Box mr={1}>
                      <BookIcon />
                    </Box>
                    <Box display="flex" flexDirection="column">
                      <Box>
                        <Typography variant="h5">{archiveVersion.node?.name ?? '-'}</Typography>
                      </Box>
                      <Box mt={0.3}>
                        <Typography variant="caption">
                          Dateigröße:&nbsp;
                          {getReadableFileSizeString(archiveVersion.node?.pdfFile?.fileSize ?? 0)}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box ml="auto" mr={4.5}>
                    <Grid container spacing={1} justifyContent="flex-end">
                      <Grid item>
                        <Tooltip title="Herunterladen">
                          <Button
                            variant="outlined"
                            color="grey"
                            aria-label="Herunterladen"
                            className={globalClasses.buttonSquare}
                            onClick={() => download(archiveVersion.node)}
                          >
                            <GetAppIcon />
                          </Button>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </li>
          ))}
        </ul>
      </Paper>
    </Box>
  );
};

export default ManualArchiveComponent;

import React from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import Box from '@mui/material/Box';
import logoWkd from '../../assets/logo-wkd.svg';
import { routes, Route as IRoute } from '../../models/routes';
import { LinkRouter } from './index';
import { FontWeights } from '../../models/theme';

const useStyles = makeStyles({ name: 'Footer' })((theme: Theme) => {
  return {
    footer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: 'auto',
      backgroundColor: theme.palette.footer.main,
      padding: theme.spacing(1, 4),
      fontSize: theme.typography.body1.fontSize,
      fontWeight: FontWeights.REGULAR,
      color: theme.palette.footer.contrastText,
      '@media print': {
        display: 'none',
      },
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
    },
    footerLogo: {
      display: 'flex',
      margin: theme.spacing(1, 0),
      '& > img': {
        width: 'auto',
        height: '1.625rem',
      },
    },
    footerLinks: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      listStyle: 'none',
      margin: 0,
      padding: 0,
      '& li': {
        margin: theme.spacing(2.25, 2, 1),
      },
      [theme.breakpoints.up('sm')]: {
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
        marginLeft: 'auto',
        '& li': {
          marginLeft: theme.spacing(4),
          marginRight: 0,
        },
      },
    },
    footerLink: {
      color: theme.palette.footer.contrastText,
    },
    footerCopyright: {
      margin: theme.spacing(2.25, 0, 1),
      width: '100%',
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(8),
        textAlign: 'right',
      },
      [theme.breakpoints.up('md')]: {
        width: 'auto',
      },
    },
  };
});

type Props = {
  routeKeys?: string[];
};

const FooterComponent: React.FC<Props> = (props) => {
  const { routeKeys } = props;
  const { classes } = useStyles();

  return (
    <Box component="footer" className={classes.footer}>
      <a
        href="https://www.wolterskluwer.de"
        target="_blank"
        rel="noreferrer"
        className={classes.footerLogo}
      >
        <img src={logoWkd} alt="Wolters Kluwer" />
      </a>
      {routeKeys && routeKeys.length > 0 && (
        <ul className={classes.footerLinks}>
          {routeKeys.map((key, index) => {
            if (!routes[key]) return null;
            const route: IRoute = routes[key];
            return (
              <li key={'footerLinkItem' + index}>
                <LinkRouter to={route.path} className={classes.footerLink}>
                  {route.title}
                </LinkRouter>
              </li>
            );
          })}
        </ul>
      )}
      <div className={classes.footerCopyright} data-environment={process.env.REACT_APP_ENV}>
        &copy; 2022 Wolters Kluwer
      </div>
    </Box>
  );
};

export default FooterComponent;

import { createTheme, Theme, responsiveFontSizes, alpha } from '@mui/material/styles';
import { deDE } from '@mui/material/locale';
import { grey } from '@mui/material/colors';

// https://material-ui.com/customization/palette/#adding-new-colors
declare module '@mui/material/styles/createPalette' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Palette {
    active: Palette['primary'];
    expired: Palette['primary'];
    drawer: Palette['primary'];
    footer: Palette['primary'];
    dark: Palette['primary'];
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface PaletteOptions {
    active: PaletteOptions['primary'];
    expired: PaletteOptions['primary'];
    drawer: PaletteOptions['primary'];
    footer: PaletteOptions['primary'];
    dark: PaletteOptions['primary'];
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface TypeBackground {
    light?: string;
  }
}

// https://mui.com/material-ui/guides/migration-v4/#button
// https://codesandbox.io/s/mimic-v4-button-default-color-bklx8?file=/src/Demo.tsx
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    grey: true;
  }
}

// See ButtonPropsColorOverrides above
declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    dark: true;
  }
}

declare module '@mui/material' {
  interface Color {
    main: string;
    dark: string;
  }
}

// NOTE: defaultTheme can be used for reference (i.e. "color: defaultTheme.palette.text.primary" or "paddingRight: defaultTheme.spacing(3)")
const defaultTheme: Theme = createTheme();

// NOTE: Using FontWeights enum instead of defaultTheme.typography.fontWeightRegularXyz b/c of TypeScript problems
export enum FontWeights {
  LIGHT = 300,
  REGULAR = 400,
  MEDIUM = 500,
  BOLD = 700,
}

export let theme: Theme = createTheme(
  {
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            '.ck-toolbar-container': {
              '&.ck-balloon-panel_arrowless': {
                transform: 'translateY(40px)',
                zIndex: defaultTheme.zIndex.fab + 2,
              },
            },
          },
        },
      },
      MuiContainer: {
        styleOverrides: {
          root: {
            [defaultTheme.breakpoints.up('sm')]: {
              paddingLeft: defaultTheme.spacing(4),
              paddingRight: defaultTheme.spacing(4),
            },
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            display: 'flex',
            alignItems: 'baseline',
            marginBottom: defaultTheme.spacing(1),
            fontSize: defaultTheme.typography.fontSize,
            fontWeight: FontWeights.MEDIUM,
            color: '#474747',
            '& .labelInfo': {
              fontSize: defaultTheme.typography.caption.fontSize,
              marginLeft: defaultTheme.spacing(0.75),
              fontWeight: FontWeights.REGULAR,
              color: '#a3a3a3',
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          outlined: {
            fontSize: defaultTheme.typography.body1.fontSize,
            fontWeight: FontWeights.REGULAR,
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontWeight: FontWeights.REGULAR,
            color: '#474747',
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          contained: {
            marginTop: defaultTheme.spacing(0.5),
            marginLeft: 0,
            marginRight: 0,
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            backgroundColor: alpha(defaultTheme.palette.background.default, 0.75),
          },
        },
      },
      MuiButton: {
        defaultProps: {
          disableElevation: true,
        },
        styleOverrides: {
          root: {
            textTransform: 'none',
          },
        },
        variants: [
          {
            props: { variant: 'contained', color: 'grey' },
            style: {
              color: defaultTheme.palette.getContrastText(defaultTheme.palette.grey[300]),
            },
          },
          {
            props: { variant: 'outlined', color: 'grey' },
            style: {
              color: defaultTheme.palette.text.primary,
              borderColor:
                defaultTheme.palette.mode === 'light'
                  ? 'rgba(0, 0, 0, 0.25)'
                  : 'rgba(255, 255, 255, 0.25)',
              '&.Mui-disabled': {
                border: `1px solid ${defaultTheme.palette.action.disabledBackground}`,
              },
              '&:hover': {
                borderColor:
                  defaultTheme.palette.mode === 'light'
                    ? 'rgba(0, 0, 0, 0.25)'
                    : 'rgba(255, 255, 255, 0.25)',
                backgroundColor: alpha(
                  defaultTheme.palette.text.primary,
                  defaultTheme.palette.action.hoverOpacity
                ),
              },
            },
          },
          {
            props: { color: 'grey', variant: 'text' },
            style: {
              color: defaultTheme.palette.text.primary,
              '&:hover': {
                backgroundColor: alpha(
                  defaultTheme.palette.text.primary,
                  defaultTheme.palette.action.hoverOpacity
                ),
              },
            },
          },
        ],
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            boxShadow: '0 1rem 1rem #fff',
            padding: defaultTheme.spacing(4, 3, 2),
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            padding: defaultTheme.spacing(2, 3),
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            boxShadow: '0 -1rem 1rem #fff',
            backgroundColor: '#f6f6f6',

            '&& > *': {
              margin: defaultTheme.spacing(1),
            },
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            '@media print': {
              borderTop: '1px solid #dadada',
              backgroundColor: 'transparent',
            },
          },
        },
      },
    },
    palette: {
      mode: 'light',
      background: {
        default: '#fff',
        light: '#f6f6f6',
      },
      primary: {
        light: '#80bde1',
        main: '#007ac3',
        dark: '#241866',
        contrastText: '#fff',
      },
      secondary: {
        light: '#dadada',
        main: '#bfbfbf',
        dark: '#757575',
        contrastText: '#474747',
      },
      error: {
        light: '#f6b1b6',
        main: '#e5202e',
        dark: '#721017',
        contrastText: '#fff',
      },
      warning: {
        light: '#f5c780',
        main: '#ea8f00',
        dark: '#af6b00',
        contrastText: '#fff',
      },
      info: {
        light: '#80ccc0',
        main: '#009881',
        dark: '#007261',
        contrastText: '#fff',
      },
      success: {
        light: '#c2de90',
        main: '#85bc20',
        dark: '#648d18',
        contrastText: '#fff',
      },
      active: {
        light: '#ca86b9',
        main: '#940c72',
        dark: '#6f0955',
        contrastText: '#fff',
      },
      expired: {
        light: '#a3a3a3',
        main: '#474747',
        dark: '#232323',
        contrastText: '#fff',
      },
      dark: {
        light: '#a3a3a3',
        main: '#474747',
        dark: '#232323',
        contrastText: '#fff',
      },
      drawer: {
        light: '#00619c',
        main: '#474747',
      },
      footer: {
        main: '#f6f6f6',
        contrastText: '#696a6d',
      },
      divider: '#dadada',
      text: {
        primary: '#474747',
        secondary: '#757575',
        disabled: '#bfbfbf',
      },
      grey: {
        main: grey[300],
        dark: grey[400],
      },
    },
    shape: {
      borderRadius: 0,
    },
    typography: {
      fontFamily: '"Fira Sans", "Roboto", "Helvetica", "Arial", sans-serif',
      h1: {
        fontWeight: FontWeights.MEDIUM,
        fontSize: '2rem',
      },
      h2: {
        fontWeight: FontWeights.MEDIUM,
        fontSize: '1.5rem',
      },
      h3: {
        fontWeight: FontWeights.MEDIUM,
        fontSize: '1.375rem',
      },
      h4: {
        fontWeight: FontWeights.MEDIUM,
        fontSize: '1.25rem',
      },
      h5: {
        fontWeight: FontWeights.MEDIUM,
        fontSize: '1.125rem',
      },
      h6: {
        fontWeight: FontWeights.MEDIUM,
        fontSize: '1rem',
      },
      body1: {
        fontWeight: FontWeights.LIGHT,
      },
    },
  },
  deDE
);

theme = responsiveFontSizes(theme);

import React, { useEffect, useState } from 'react';
import { gql, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FormikHelpers, FormikValues } from 'formik';
import { default as FacilityForm } from './FacilityForm.component';
import { useSnackbar } from 'notistack';
import {
  CREATE_FACILITY_MUTATION,
  FACILITES_COUNT_QUERY,
  FRAGMENT_FACILITY_BASE,
  FRAGMENT_FACILITY_SECONDARY,
} from '../../operations/facility';
import { routes } from '../../models/routes';
import { loggedInMeVar } from '../../cache';
import { UPDATE_ME_MUTATION } from '../../operations/user';
import Alert from '@mui/material/Alert';

function useFacilitySubmitHandler() {
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const loggedInMe = useReactiveVar(loggedInMeVar);

  const [updateMeMutation] = useMutation(UPDATE_ME_MUTATION, {
    onCompleted({ updateUser }) {
      if (updateUser?.user) {
        loggedInMeVar(updateUser.user);
        enqueueSnackbar('Benutzer erfolgreich zugeordnet', {
          variant: 'success',
        });
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten', {
          variant: 'warning',
        });
      }
    },
    onError(error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  const updateMe = async (facilityId: string | null) => {
    if (!loggedInMe || !facilityId) {
      return;
    }
    const facilities = loggedInMe.facilities?.edges.map((edge: any) => edge.node.id) ?? [];
    facilities.push(facilityId);
    try {
      await updateMeMutation({
        variables: {
          input: {
            id: loggedInMe.id,
            facilities: facilities,
          },
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  const [createFacilityMutation] = useMutation(CREATE_FACILITY_MUTATION, {
    onCompleted({ createFacility }) {
      if (createFacility) {
        enqueueSnackbar('Einrichtung erfolgreich angelegt', {
          variant: 'success',
        });
        updateMe(createFacility.facility?.id || null);
        navigate(routes['FACILITIES'].path);
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten', {
          variant: 'warning',
        });
      }
    },
    onError(error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
    update(cache, { data: { createFacility } }) {
      cache.modify({
        fields: {
          facilities: (existingItemsRefs, { readField }) => {
            const totalCount: number = readField('totalCount', existingItemsRefs) || 0;
            const newItemNodeRef = cache.writeFragment({
              data: {
                ...createFacility?.facility,
              },
              fragment: gql`
                fragment FacilityNew on Facility {
                  ...FacilityBase
                  ...FacilitySecondary
                }
                ${FRAGMENT_FACILITY_BASE}
                ${FRAGMENT_FACILITY_SECONDARY}
              `,
              fragmentName: 'FacilityNew',
            });
            const newItemEdge = {
              node: newItemNodeRef,
            };
            return {
              ...existingItemsRefs,
              totalCount: totalCount + 1,
              edges: [...existingItemsRefs.edges, newItemEdge],
            };
          },
        },
      });
    },
  });

  return async (values: FormikValues, formikBag: FormikHelpers<any>) => {
    try {
      await createFacilityMutation({
        variables: {
          input: {
            name: values.name,
            director: values.director,
            street: values.street,
            houseNumber: values.houseNumber,
            zipCode: values.zipCode,
            city: values.city,
            phone: values.phone,
            fax: values.fax,
            email: values.email,
            tenant: loggedInMe?.tenant?.id ?? null,
          },
        },
      });
    } catch (e) {
      console.error(e);
    } finally {
      formikBag.setSubmitting(false);
    }
  };
}

export default function FacilityNewComponent() {
  const loggedInMe = useReactiveVar(loggedInMeVar);
  const [licenceLimitReached, setLicenceLimitReached] = useState<boolean>(false);

  const handleSubmit = useFacilitySubmitHandler();

  const { error, data, loading } = useQuery(FACILITES_COUNT_QUERY, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!loggedInMe?.tenant?.numberOfLicenses || !data?.facilities?.totalCount) {
      return;
    }
    setLicenceLimitReached(loggedInMe.tenant.numberOfLicenses <= data.facilities?.totalCount);
  }, [loggedInMe, data]);

  if (loading) {
    return null;
  }
  if (error?.message)
    return (
      <Container>
        <Alert severity="error">Es ist ein Fehler aufgetreten: {error.message}</Alert>
      </Container>
    );

  return (
    <Container>
      <Box component="header" mb={3}>
        <Typography component="h1" variant="h2" gutterBottom>
          Neue Einrichtung anlegen
        </Typography>
      </Box>
      {licenceLimitReached && (
        <Box component="header" mb={3}>
          <Alert severity="error">
            Die Neuanlage einer Einrichtung ist nicht möglich, da das Limit an Einrichtungen
            erreicht ist. Bitte wenden Sie sich an Ihren Administrator.
          </Alert>
        </Box>
      )}
      <FacilityForm submitHandler={handleSubmit} formDisabled={licenceLimitReached} />
    </Container>
  );
}

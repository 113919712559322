import React from 'react';
import { Formik, Form, FormikValues, FormikHelpers, Field } from 'formik';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import { CustomDialogTitle } from '../common';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import SaveIcon from '@mui/icons-material/SaveRounded';
import { TextField } from 'formik-mui';
import { useSnackbar } from 'notistack';
import { gql, useMutation, useReactiveVar } from '@apollo/client';
import { loggedInMeVar } from '../../cache';
import { CREATE_TAG_MUTATION, FRAGMENT_TAG_BASE } from '../../operations/tag';
import { Tag } from '../../models/tags';
import * as Yup from 'yup';

function useTagCreateHandler(resultHandler: (tag: Tag | null) => void) {
  const { enqueueSnackbar } = useSnackbar();
  const loggedInMe = useReactiveVar(loggedInMeVar);

  const [createTagMutation] = useMutation(CREATE_TAG_MUTATION, {
    onCompleted({ createTag }) {
      if (createTag?.tag?.id) {
        enqueueSnackbar('Schlagwort erfolgreich angelegt', {
          variant: 'success',
        });
        resultHandler(createTag.tag);
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten', {
          variant: 'warning',
        });
        resultHandler(null);
      }
    },
    onError(error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
      resultHandler(null);
    },
    update(cache, { data: { createTag } }) {
      cache.modify({
        fields: {
          tags: (existingItemsRefs, { readField }) => {
            const totalCount: number = readField('totalCount', existingItemsRefs) || 0;
            const newItemNodeRef = cache.writeFragment({
              data: {
                ...createTag?.tag,
              },
              fragment: gql`
                fragment TagNew on Tag {
                  ...TagBase
                }
                ${FRAGMENT_TAG_BASE}
              `,
              fragmentName: 'TagNew',
            });
            const newItemEdge = {
              node: newItemNodeRef,
            };
            return {
              ...existingItemsRefs,
              totalCount: totalCount + 1,
              edges: [...existingItemsRefs.edges, newItemEdge],
            };
          },
        },
      });
    },
  });

  return async (values: FormikValues, formikBag: FormikHelpers<any>) => {
    try {
      const inputData: { [k: string]: any } = {
        name: values.name,
        tenant: loggedInMe?.tenant?.id ?? null,
      };
      await createTagMutation({
        variables: {
          input: {
            ...inputData,
          },
        },
      });
    } catch (e) {
      console.error(e);
    } finally {
      formikBag.setSubmitting(false);
    }
  };
}

interface Props {
  dialogOpen: boolean;
  data: string | null;
  resultHandler: (tag: Tag | null) => void;
  resetHandler: () => void;
  children?: React.ReactNode;
}

const TagNewDialogComponent: React.FC<Props> = (props) => {
  const { dialogOpen, data, resultHandler, resetHandler } = props;
  const handleTagCreate = useTagCreateHandler(resultHandler);

  return (
    <Formik
      initialValues={{
        name: data ?? '',
      }}
      enableReinitialize
      validationSchema={Yup.object({
        name: Yup.string()
          .min(3, 'Min. 3 Zeichen')
          .max(30, 'Max. 30 Zeichen')
          .required('Pflichtfeld'),
      })}
      onSubmit={(values, formikBag) => {
        handleTagCreate(values, formikBag);
      }}
    >
      {(props) => (
        <Dialog
          open={dialogOpen}
          onClose={(e) => props.handleReset()}
          aria-labelledby="dialog-tagcreate-title"
        >
          <Form autoComplete="off">
            <CustomDialogTitle id="dialog-tagcreate-title" onClose={() => resetHandler()}>
              Neues Schlagwort hinzufügen
            </CustomDialogTitle>
            <DialogContent>
              {props.status && (
                <Box mb={2}>
                  <Alert severity="error">{props.status}</Alert>
                </Box>
              )}
              <DialogContentText color="textPrimary">
                Dieses Schlagwort ist bisher nicht gespeichert. Soll es neu hinzugefügt werden?
              </DialogContentText>
              <FormControl fullWidth>
                <FormLabel htmlFor="name">
                  Schlagwort
                  {props.values.name.length > 0 && (
                    <span className="labelInfo">
                      {props.values.name.length} von max. 30 Zeichen
                    </span>
                  )}
                </FormLabel>
                <Field
                  autoFocus
                  component={TextField}
                  type="text"
                  name="name"
                  id="name"
                  variant="outlined"
                  fullWidth
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                type="reset"
                color="primary"
                onClick={() => resetHandler()}
                data-test="dialogReset"
              >
                Abbrechen
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={<SaveIcon />}
                disabled={props.isSubmitting}
                data-test="dialogSubmit"
              >
                Hinzufügen
              </Button>
            </DialogActions>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};

export default TagNewDialogComponent;

import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/EditRounded';
import { NavLink, useNavigate } from 'react-router-dom';
import { routes } from '../../models/routes';
import { permissionComponentKeys } from '../../models/permissions';
import useLoggedInMePermissions from '../../hooks/useLoggedInMePermissions';
import useGlobalStyles from '../../hooks/useGlobalStyles';
import Grid from '@mui/material/Grid';
import PersonAddIcon from '@mui/icons-material/PersonAddRounded';
import { DELETE_ROLE_MUTATION, ROLES_QUERY } from 'operations/role';
import ViewIcon from '@mui/icons-material/VisibilityRounded';
import { encodeIriToUrlParam } from '../../utils/helper';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import { ConfirmDialog } from '../common';
import { useSnackbar } from 'notistack';
import { Role } from '../../models/roles';
import { AlertTitle } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

function useRoleDeleteHandler(roleId: string | null): () => Promise<void> {
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [deleteRoleMutation] = useMutation(DELETE_ROLE_MUTATION, {
    onCompleted({ deleteRole }) {
      if (deleteRole) {
        enqueueSnackbar('Benutzerrolle erfolgreich gelöscht', {
          variant: 'success',
        });
        navigate(routes['ROLES'].path);
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten', {
          variant: 'warning',
        });
      }
    },
    onError(error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
    update(cache, { data: { deleteRole } }) {
      cache.modify({
        fields: {
          roles: (existingItemsRefs = [], { readField }) => {
            const totalCount: number = readField('totalCount', existingItemsRefs) || 0;
            return {
              ...existingItemsRefs,
              totalCount: totalCount - 1,
              edges: [
                ...existingItemsRefs.edges.filter(
                  (itemRef: any) => deleteRole?.role?.id !== readField('id', itemRef.node)
                ),
              ],
            };
          },
        },
      });
    },
  });

  return async () => {
    if (!roleId?.length) {
      return;
    }
    try {
      await deleteRoleMutation({
        variables: {
          input: {
            id: roleId,
          },
        },
      });
    } catch (error: any) {
      console.error(error);
    }
  };
}

const rolesPerPage = 50;

export default function RolesComponent() {
  const { classes: globalClasses } = useGlobalStyles();
  const permissions = useLoggedInMePermissions(permissionComponentKeys.PERMISSIONS);

  const [rolesQueried, setRolesQueried] = useState<boolean>(false);
  const [queryRoles, { error, data, loading, fetchMore }] = useLazyQuery(ROLES_QUERY);
  useEffect(() => {
    if (rolesQueried) {
      return;
    }
    queryRoles({
      variables: { first: rolesPerPage, after: null },
    });
    setRolesQueried(true);
  }, [rolesQueried, queryRoles]);

  const [deleteRole, setDeleteRole] = useState<Role | null>(null);
  const handleDelete = useRoleDeleteHandler(deleteRole?.id ?? null);

  if (error?.message)
    return (
      <Container>
        <Alert severity="error">Es ist ein Fehler aufgetreten: {error.message}</Alert>
      </Container>
    );

  return (
    <Container>
      <Box component="header" mb={8}>
        <Typography component="h1" variant="h2" gutterBottom>
          Benutzerrollen
        </Typography>
        {permissions?.create && (
          <Box display="flex" justifyContent="space-between" mt={2}>
            <Button
              variant="contained"
              color="primary"
              component={NavLink}
              to={routes['ROLE_NEW'].path}
              startIcon={<PersonAddIcon />}
            >
              Neue Benutzerrolle erstellen
            </Button>
          </Box>
        )}
      </Box>
      <Box component="header" mb={2}>
        <Typography component="h2" variant="h4" gutterBottom>
          Bestehende Benutzerrollen
        </Typography>
      </Box>
      <Paper component="section" variant="outlined">
        {data?.roles?.edges?.length > 0 ? (
          <ul className={globalClasses.listStriped} data-test="list">
            {data.roles.edges.map((edge: any) => {
              const { node: role } = edge;
              return (
                <li key={role.id} data-test="listItem">
                  <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                    <Grid item>
                      <Typography variant="h6">{`${role.name}`}</Typography>
                    </Grid>
                    <Grid item>
                      <Grid container spacing={1} justifyContent="flex-end">
                        <Grid item>
                          <Tooltip title="Details">
                            <Button
                              component={NavLink}
                              to={routes['ROLE'].path.replace(
                                ':roleId',
                                encodeIriToUrlParam(role.id)
                              )}
                              variant="outlined"
                              color="grey"
                              aria-label="Details"
                              className={globalClasses.buttonSquare}
                            >
                              <ViewIcon />
                            </Button>
                          </Tooltip>
                        </Grid>
                        {permissions?.update && !role.tenantAdmin && (
                          <Grid item>
                            <Tooltip title="Bearbeiten">
                              <Button
                                component={NavLink}
                                to={routes['ROLE_EDIT'].path.replace(
                                  ':roleId',
                                  encodeIriToUrlParam(role.id)
                                )}
                                variant="outlined"
                                color="grey"
                                aria-label="Bearbeiten"
                                className={globalClasses.buttonSquare}
                              >
                                <EditIcon />
                              </Button>
                            </Tooltip>
                          </Grid>
                        )}
                        {permissions?.delete && !role.tenantAdmin && (
                          <Grid item>
                            <Tooltip title="Löschen">
                              <Button
                                variant="outlined"
                                color="grey"
                                aria-label="Löschen"
                                className={globalClasses.buttonSquare}
                                onClick={() => {
                                  setDeleteRole(role);
                                }}
                              >
                                <DeleteIcon />
                              </Button>
                            </Tooltip>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </li>
              );
            })}
          </ul>
        ) : (
          <Box p={2}>
            <Typography variant="body1">
              {!rolesQueried || loading ? 'Bitte warten...' : 'Keine Benutzerrollen vorhanden'}
            </Typography>
          </Box>
        )}
        {fetchMore && data?.roles?.pageInfo?.hasNextPage && (
          <Box component="footer" className={globalClasses.paperActions}>
            <Button
              type="button"
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={() => {
                const { endCursor } = data.roles.pageInfo;
                fetchMore({
                  variables: { after: endCursor },
                });
              }}
            >
              Mehr...
            </Button>
          </Box>
        )}
      </Paper>
      <ConfirmDialog
        open={deleteRole !== null}
        title={`Benutzerrolle löschen`}
        content={`Möchten Sie die Benutzerrolle wirklich löschen?`}
        onClose={(confirm) => {
          if (confirm) {
            handleDelete();
          }
          setDeleteRole(null);
        }}
        onlyCancel={(deleteRole?.users?.totalCount ?? 0) > 0}
      >
        {deleteRole?.id && (deleteRole.users?.totalCount ?? 0) > 0 && (
          <Alert severity="warning">
            <AlertTitle>
              Löschen aktuell nicht möglich, da {deleteRole.users?.totalCount} Benutzer mit dieser
              Benutzerrolle verknüpft sind.
            </AlertTitle>
            Bitte die{' '}
            <NavLink
              to={routes['ROLE'].path.replace(':roleId', encodeIriToUrlParam(deleteRole.id))}
            >
              Details der Benutzerrolle
            </NavLink>{' '}
            ansehen und die betroffenen Benutzer ggf. anderen Benutzerrollen zuordnen.
          </Alert>
        )}
      </ConfirmDialog>
    </Container>
  );
}

import React from 'react';
import { gql, useMutation, useReactiveVar } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FormikHelpers, FormikValues } from 'formik';
import { default as UserNewForm } from './UserNewForm.component';
import { useSnackbar } from 'notistack';
import { CREATE_USER_MUTATION, FRAGMENT_USER_BASE } from '../../operations/user';
import { routes } from '../../models/routes';
import { loggedInMeVar } from '../../cache';

function useUserSubmitHandler() {
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const loggedInMe = useReactiveVar(loggedInMeVar);

  const [createUserMutation] = useMutation(CREATE_USER_MUTATION, {
    onCompleted({ createUser }) {
      if (createUser) {
        enqueueSnackbar('Benutzer erfolgreich angelegt', {
          variant: 'success',
        });
        navigate(routes['USERS'].path);
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten', {
          variant: 'warning',
        });
      }
    },
    onError(error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
    update(cache, { data: { createUser } }) {
      cache.modify({
        fields: {
          users: (existingItemsRefs, { readField }) => {
            const totalCount: number = readField('totalCount', existingItemsRefs) || 0;
            const newItemNodeRef = cache.writeFragment({
              data: {
                ...createUser?.user,
              },
              fragment: gql`
                fragment UserNew on User {
                  ...UserBase
                  role {
                    id
                    name
                    tenantAdmin
                  }
                  facilities {
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                }
                ${FRAGMENT_USER_BASE}
              `,
              fragmentName: 'UserNew',
            });
            const newItemEdge = {
              node: newItemNodeRef,
            };
            return {
              ...existingItemsRefs,
              totalCount: totalCount + 1,
              edges: [...existingItemsRefs.edges, newItemEdge],
            };
          },
        },
      });
    },
  });

  return (values: FormikValues, formikBag: FormikHelpers<any>) => {
    createUserMutation({
      variables: {
        input: {
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          jobTitle: values.jobTitle,
          group: values.group,
          tenant: loggedInMe?.tenant?.id ?? null,
          role: values.role?.id ?? null,
          facilities: values.facilities.map((item: any) => item.id),
          active: true,
          tenantWideEditPermission: values.tenantWideEditPermission,
        },
      },
    })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        formikBag.setSubmitting(false);
      });
  };
}

export default function UserNewComponent() {
  const handleSubmit = useUserSubmitHandler();

  return (
    <Container>
      <Box component="header" mb={3}>
        <Typography component="h1" variant="h2" gutterBottom>
          Neuen Benutzer anlegen
        </Typography>
      </Box>
      <UserNewForm submitHandler={handleSubmit} />
    </Container>
  );
}

import { useMutation, useReactiveVar } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { DELETE_MEDIA_OBJECT_MUTATION } from '../../operations/mediaObject';
import { UPDATE_SURVEY_MUTATION } from '../../operations/survey';
import { loggedInMeVar } from '../../cache';

export default function useSurveyAddLogoHandler(
  surveyId: string | null
): (logoId: string | null, oldLogoId: string | null) => void {
  const { enqueueSnackbar } = useSnackbar();
  const [updateSurveyMutation] = useMutation(UPDATE_SURVEY_MUTATION);
  const [deleteMediaObjectMutation] = useMutation(DELETE_MEDIA_OBJECT_MUTATION);
  const loggedInMe = useReactiveVar(loggedInMeVar);

  return async (logoId: string | null, oldLogoId: string | null) => {
    try {
      await updateSurveyMutation({
        variables: {
          input: {
            id: surveyId,
            logo: logoId,
            updatedBy: loggedInMe?.id ?? null,
          },
        },
      });

      if (oldLogoId) {
        await deleteMediaObjectMutation({
          variables: {
            input: {
              id: oldLogoId,
            },
          },
        });
      }

      const message =
        logoId !== null ? 'Logo erfolgreich erstellt/aktualisiert' : 'Das Logo wurde gelöscht';

      enqueueSnackbar(message, {
        variant: 'success',
      });
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar('Es ist ein Fehler aufgetreten', {
        variant: 'warning',
      });
    }
  };
}

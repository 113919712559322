import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { NavLink, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useMutation, useQuery } from '@apollo/client';
import {
  QUALITYMANAGEMENTTASK_QUERY,
  UPDATE_QUALITYMANAGEMENTTASK_MUTATION,
} from '../../operations/qualityManagementTask';
import { routes } from '../../models/routes';
import { default as QualityManagementTaskAppointmentsEditForm } from './QualityManagementTaskAppointmentsEditForm.component';
import { FormikHelpers, FormikValues } from 'formik';
import { decodeIriFromUrlParam, encodeIriToUrlParam } from '../../utils/helper';
import { CREATE_ACTIVITYLOG_MUTATION } from '../../operations/activityLog';
import Alert from '@mui/material/Alert';
import { ToolbarPaper } from '../common';
import Button from '@mui/material/Button';
import ListIcon from '@mui/icons-material/ReorderRounded';
import EditSingleIcon from '@mui/icons-material/DriveFileRenameOutlineRounded';
import CalendarIcon from '@mui/icons-material/CalendarMonthRounded';
import { UserOption } from '../../models/users';

function useQualityManagementTaskAppointmentsSubmitHandler(qualityManagementTaskId: string | null) {
  const { enqueueSnackbar } = useSnackbar();

  const [updateQualityManagementTaskMutation] = useMutation(UPDATE_QUALITYMANAGEMENTTASK_MUTATION, {
    onCompleted({ updateQualityManagementTask }) {
      if (updateQualityManagementTask) {
        enqueueSnackbar('QM-Aufgabe erfolgreich aktualisiert', {
          variant: 'success',
        });
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten', {
          variant: 'warning',
        });
      }
    },
    onError(error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  return async (values: FormikValues, formikBag: FormikHelpers<any>) => {
    if (!qualityManagementTaskId?.length) {
      formikBag.setStatus('Es ist ein Fehler aufgetreten');
      formikBag.setSubmitting(false);
      return false;
    }
    try {
      const inputData: { [k: string]: any } = {
        id: qualityManagementTaskId,
        title: values.title,
        description: values.description,
        assignedUsers: values.assignedUsers.map((assignedUser: UserOption) => assignedUser.id),
      };
      if (values.endDate) {
        inputData.endDate = values.endDate;
      }
      if (values.state) {
        inputData.state = values.state;
      }
      if (values.modifyAfter) {
        inputData.modifyAfter = values.modifyAfter;
      } else {
        inputData.modifyAll = true;
      }
      await updateQualityManagementTaskMutation({
        variables: {
          input: {
            ...inputData,
          },
        },
      });
    } catch (e) {
      console.error(e);
    } finally {
      formikBag.setSubmitting(false);
      formikBag.resetForm();
    }
  };
}

export default function QualityManagementTaskAppointmentsEditComponent() {
  const [qualityManagementTaskId, setQualityManagementTaskId] = useState(null);
  const handleSubmit = useQualityManagementTaskAppointmentsSubmitHandler(qualityManagementTaskId);
  let { qualityManagementTaskId: qualityManagementTaskIdParam } =
    useParams<Record<string, string | undefined>>();
  let { qualityManagementTaskAppointmentId: qualityManagementTaskAppointmentIdParam } =
    useParams<Record<string, string | undefined>>();

  const qualityManagementTaskQueryId: string = decodeIriFromUrlParam(qualityManagementTaskIdParam);
  const qualityManagementTaskAppointmentId: string = decodeIriFromUrlParam(
    qualityManagementTaskAppointmentIdParam
  );

  const { error, data, loading } = useQuery(QUALITYMANAGEMENTTASK_QUERY, {
    variables: {
      id: qualityManagementTaskQueryId,
    },
    fetchPolicy: 'network-only',
    onCompleted({ qualityManagementTask }) {
      setQualityManagementTaskId(qualityManagementTask?.id || null);
    },
  });

  const [createActivityLogMutation] = useMutation(CREATE_ACTIVITYLOG_MUTATION, {
    onError(error) {
      console.error(error);
    },
  });

  useEffect(() => {
    if (!qualityManagementTaskId) {
      return;
    }

    createActivityLogMutation({
      variables: {
        input: {
          accessedEntity: qualityManagementTaskId,
        },
      },
    });
  }, [qualityManagementTaskId, createActivityLogMutation]);

  if (loading) {
    return null;
  }
  if (error?.message)
    return (
      <Container>
        <Alert severity="error">Es ist ein Fehler aufgetreten: {error.message}</Alert>
      </Container>
    );

  return (
    <Container>
      <Box component="header" mb={3}>
        <Typography component="h1" variant="h2" gutterBottom>
          Terminserie QM-Aufgabe bearbeiten
        </Typography>
        {qualityManagementTaskId && qualityManagementTaskAppointmentId.length && (
          <Box display="flex" justifyContent="space-between" mt={2}>
            <Button
              component={NavLink}
              to={routes['QUALITYMANAGEMENTTASKAPPOINTMENT_EDIT'].path
                .replace(':qualityManagementTaskId', encodeIriToUrlParam(qualityManagementTaskId))
                .replace(
                  ':qualityManagementTaskAppointmentId',
                  encodeIriToUrlParam(qualityManagementTaskAppointmentId)
                )}
              variant="outlined"
              color="primary"
              startIcon={<EditSingleIcon />}
            >
              Aktuell ausgewählten Termin bearbeiten
            </Button>
          </Box>
        )}
      </Box>
      {data?.qualityManagementTask ? (
        <>
          <QualityManagementTaskAppointmentsEditForm
            formData={data.qualityManagementTask}
            appointmentId={qualityManagementTaskAppointmentId}
            submitHandler={handleSubmit}
          />
          <ToolbarPaper>
            <Button
              component={NavLink}
              to={routes['QUALITYMANAGEMENTTASKS'].path}
              variant="outlined"
              color="primary"
              startIcon={<ListIcon />}
              className="alignRight"
            >
              QM-Aufgabenliste
            </Button>
            <Button
              component={NavLink}
              to={routes['QUALITYMANAGEMENTTASKS_CALENDAR'].path}
              variant="outlined"
              color="primary"
              startIcon={<CalendarIcon />}
            >
              QM-Aufgabenkalender
            </Button>
          </ToolbarPaper>
        </>
      ) : (
        <Alert severity="warning">QM-Aufgabe nicht gefunden</Alert>
      )}
    </Container>
  );
}

import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import useGlobalStyles from '../../hooks/useGlobalStyles';
import { InfoDialog } from '../common';
import { Chapter } from '../../models/chapters';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/InfoRounded';
import dayjs from 'dayjs';

interface Props {
  chapter: Chapter | null;
}

const ManualChapterRevisionComponent: React.FC<Props> = (props) => {
  const { chapter } = props;
  const { classes: globalClasses } = useGlobalStyles();

  const [revisionsInfoOpen, setRevisionsInfoOpen] = useState<boolean>(false);

  if (!chapter) {
    return null;
  }

  return (
    <Box mt={4} data-test="chapterRevision">
      <Box component="header" mb={2}>
        <Box className={globalClasses.tooltipBox}>
          <Typography component="h2" variant="h4" className={globalClasses.tooltipText}>
            Revisionsinformationen zu diesem Kapitel
          </Typography>
          <Tooltip title="Info zu Revisionsinformationen">
            <IconButton
              className={globalClasses.tooltipIcon}
              color="primary"
              aria-label="Info"
              onClick={() => {
                setRevisionsInfoOpen(true);
              }}
              size="large"
            >
              <InfoIcon />
            </IconButton>
          </Tooltip>
          <InfoDialog
            open={revisionsInfoOpen}
            title={`Revisionsinformationen`}
            onClose={() => {
              setRevisionsInfoOpen(false);
            }}
          >
            <Typography paragraph>
              Die Revisionsinformationen enthalten zusätzliche Informationen zu diesem Kapitel des
              QM-Handbuchs. Bitte beachten Sie: Da es sich um optionale Angaben handelt, können die
              Revisionsinformationen unvollständig sein.
            </Typography>
          </InfoDialog>
        </Box>
      </Box>
      <Paper component="section" variant="outlined" className={globalClasses.paper}>
        <Grid container spacing={2}>
          <Grid item xs={12} md>
            <Typography variant="h6">Versions-Nr.:</Typography>
            <Typography variant="body1">{chapter.versionNumber || '—'}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md>
            <Typography variant="h6">Erstellt/geändert von:</Typography>
            <Typography variant="body1">{chapter.mostRecentEditBy || '—'}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md>
            <Typography variant="h6">Erstellt/geändert am:</Typography>
            <Typography variant="body1">
              {chapter.revisionDate && chapter.revisionDate.length > 0 ? (
                <time dateTime={dayjs(chapter.revisionDate).toISOString()}>
                  {dayjs(chapter.revisionDate).format('DD.MM.YYYY')}
                </time>
              ) : (
                '—'
              )}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md>
            <Typography variant="h6">Geprüft von:</Typography>
            <Typography variant="body1">{chapter.checkedBy || '—'}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md>
            <Typography variant="h6">Geprüft am:</Typography>
            <Typography variant="body1">
              {chapter.checkedDate && chapter.checkedDate.length > 0 ? (
                <time dateTime={dayjs(chapter.checkedDate).toISOString()}>
                  {dayjs(chapter.checkedDate).format('DD.MM.YYYY')}
                </time>
              ) : (
                '—'
              )}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md>
            <Typography variant="h6">Freigegeben von:</Typography>
            <Typography variant="body1">{chapter.publishedBy || '—'}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md>
            <Typography variant="h6">Freigegeben am:</Typography>
            <Typography variant="body1">
              {chapter.publishDate && chapter.publishDate.length > 0 ? (
                <time dateTime={dayjs(chapter.publishDate).toISOString()}>
                  {dayjs(chapter.publishDate).format('DD.MM.YYYY')}
                </time>
              ) : (
                '—'
              )}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Änderungsvermerk:</Typography>
            <Typography
              component="div"
              variant="body1"
              gutterBottom
              style={{ whiteSpace: 'pre-wrap' }}
            >
              {chapter.revisionChangelog || '—'}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default ManualChapterRevisionComponent;

import React, { useEffect, useState } from 'react';
import { Formik, Form, FormikValues, FormikHelpers, Field } from 'formik';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import { CustomDialogTitle } from '../common';
import SaveIcon from '@mui/icons-material/SaveRounded';
import { Chapter, TreeChapter } from '../../models/chapters';
import { TreeView } from '@mui/x-tree-view/TreeView';
import ExpandedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import CollapsedIcon from '@mui/icons-material/AddBoxRounded';
import { default as ManualChapterTreeLevel } from './ManualChapterTreeLevel.component';
import FormLabel from '@mui/material/FormLabel';
import { TextField } from 'formik-mui';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import { useLazyQuery } from '@apollo/client';
import { CHAPTER_QUERY } from '../../operations/chapter';
import { useSnackbar } from 'notistack';

interface Props {
  dialogOpen: boolean;
  chapters: TreeChapter[];
  submitHandler: (
    values: FormikValues,
    formikBag: FormikHelpers<any>,
    copyChapter: Chapter
  ) => void;
  resetHandler: () => void;
  children?: React.ReactNode;
  copyChapterId?: string | null | undefined;
  manualCustomChapterLabel?: boolean | undefined;
}

const ManualChapterNewAndCopyDialogComponent: React.FC<Props> = (props) => {
  const {
    dialogOpen,
    chapters,
    submitHandler,
    resetHandler,
    copyChapterId,
    manualCustomChapterLabel,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [currentPosId, setCurrentPosId] = useState<string | null>(null);

  const [queryChapter, { error: errorChapter, data: dataChapter, loading: loadingChapter }] =
    useLazyQuery(CHAPTER_QUERY, {
      fetchPolicy: 'network-only',
      onCompleted({ chapter }) {
        if (!chapter) {
          enqueueSnackbar('Es ist ein Fehler aufgetreten', {
            variant: 'warning',
          });
        }
      },
      onError(error) {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      },
    });

  useEffect(() => {
    if (!copyChapterId) {
      return;
    }
    queryChapter({ variables: { id: copyChapterId } });
  }, [copyChapterId, queryChapter]);

  return (
    <Formik
      initialValues={{
        customChapterNumber: '',
        title: '',
        parentId: null,
        targetIndex: chapters?.length ? null : 0,
        copyChapterId: copyChapterId ?? null,
      }}
      enableReinitialize
      validate={(values) => {
        const errors: any = {};
        if (values.title === '' && !copyChapterId) {
          errors.title = 'Pflichtfeld';
        }
        if (values.targetIndex === null) {
          errors.targetIndex = 'Bitte eine Position für das neue Kapitel auswählen';
        }
        return errors;
      }}
      onSubmit={(values, formikBag) => {
        submitHandler(values, formikBag, dataChapter?.chapter);
        setCurrentPosId(null);
        resetHandler();
      }}
      onReset={() => {
        setCurrentPosId(null);
      }}
    >
      {(props) => (
        <Dialog
          open={dialogOpen}
          onClose={(e) => props.handleReset()}
          aria-labelledby="dialog-manualchapternew-title"
        >
          <Form autoComplete="off">
            <Field type="hidden" name="copyChapterId" />
            <CustomDialogTitle id="dialog-manualchapternew-title" onClose={() => resetHandler()}>
              {!copyChapterId ? 'Neues Kapitel erstellen' : 'Kapitel kopieren'}
            </CustomDialogTitle>
            <DialogContent>
              {props.status && (
                <Box mb={2}>
                  <Alert severity="error">{props.status}</Alert>
                </Box>
              )}
              {!copyChapterId && (
                <Box mb={3}>
                  <DialogContentText color="textPrimary">
                    Bitte geben Sie eine (vorläufige) Kapitelüberschrift an und wählen Sie über den
                    Kapitelbaum die gewünschte Position im QM-Handbuch aus. Anschließend können Sie
                    das neue Kapitel mit dem Button "Erstellen" an der ausgewählten Position
                    erstellen.
                  </DialogContentText>
                </Box>
              )}
              {copyChapterId && (
                <Box mb={3}>
                  <DialogContentText color="textPrimary">
                    Bitte wählen Sie über den Kapitelbaum die gewünschte Position im QM-Handbuch
                    aus. Anschließend können Sie das neue Kapitel mit dem Button "Kopieren" an der
                    ausgewählten Position erstellen.
                  </DialogContentText>
                </Box>
              )}
              {!copyChapterId && (
                <Grid container spacing={3}>
                  {manualCustomChapterLabel && (
                    <Grid item xs={12} sm={3}>
                      <FormControl fullWidth>
                        <FormLabel htmlFor="customChapterNumber">
                          Kapitelnr.
                          <span className="labelInfo">optional</span>
                        </FormLabel>
                        <Field
                          component={TextField}
                          type="text"
                          name="customChapterNumber"
                          id="customChapterNumber"
                          variant="outlined"
                          fullWidth
                        />
                      </FormControl>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={manualCustomChapterLabel ? 9 : 12}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor="title">
                        Kapitelüberschrift
                        {!manualCustomChapterLabel && (
                          <span className="labelInfo">ohne Gliederungsnummer</span>
                        )}
                      </FormLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name="title"
                        id="title"
                        variant="outlined"
                        fullWidth
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              )}
              {copyChapterId && <Field type="hidden" name="title" />}
              {chapters?.length > 0 && (
                <Box mt={3}>
                  <TreeView
                    defaultCollapseIcon={<ExpandedIcon />}
                    defaultExpandIcon={<CollapsedIcon />}
                  >
                    <ManualChapterTreeLevel
                      chapters={chapters}
                      currentPosId={currentPosId}
                      currentLevel={1}
                      changeHandler={(newPosId) => {
                        setCurrentPosId(newPosId ?? null);
                      }}
                      fieldHandler={props.setFieldValue}
                      chapterToMoveId={null}
                      manualCustomChapterLabel={manualCustomChapterLabel}
                    />
                  </TreeView>
                  {props.errors.targetIndex && props.touched.targetIndex && (
                    <FormHelperText error={true}>{props.errors.targetIndex}</FormHelperText>
                  )}
                </Box>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                type="reset"
                color="primary"
                onClick={() => resetHandler()}
                data-test="dialogReset"
              >
                Abbrechen
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={<SaveIcon />}
                disabled={
                  props.isSubmitting ||
                  (!!copyChapterId &&
                    (loadingChapter || !!errorChapter || !dataChapter?.chapter.id))
                }
                data-test="dialogSubmit"
              >
                {!copyChapterId ? 'Erstellen' : 'Kopieren'}
              </Button>
            </DialogActions>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};

export default ManualChapterNewAndCopyDialogComponent;

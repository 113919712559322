import React, { useMemo } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { gql, useMutation, useReactiveVar } from '@apollo/client';
import { loggedInMeVar, qualityManagementTasksCalendarDisplayVar } from '../../cache';
import {
  CREATE_QUALITYMANAGEMENTTASK_MUTATION,
  FRAGMENT_QUALITYMANAGEMENTTASK_BASE,
} from '../../operations/qualityManagementTask';
import { routes } from '../../models/routes';
import { FormikHelpers, FormikValues } from 'formik';
import { default as QualityManagementTaskNewForm } from './QualityManagementTaskNewForm.component';
import { CREATE_ACTIVITYLOG_MUTATION } from '../../operations/activityLog';
import dayjs from 'dayjs';
import { UserOption } from '../../models/users';

function useQualityManagementTaskSubmitHandler() {
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const loggedInMe = useReactiveVar(loggedInMeVar);
  const qualityManagementTasksCalendarDisplay = useReactiveVar(
    qualityManagementTasksCalendarDisplayVar
  );

  const [createActivityLogMutation] = useMutation(CREATE_ACTIVITYLOG_MUTATION, {
    onError(error) {
      console.error(error);
    },
  });

  const [createQualityManagementTaskMutation] = useMutation(CREATE_QUALITYMANAGEMENTTASK_MUTATION, {
    async onCompleted({ createQualityManagementTask }) {
      if (createQualityManagementTask) {
        enqueueSnackbar('QM-Aufgabe erfolgreich angelegt', {
          variant: 'success',
        });

        await createActivityLogMutation({
          variables: {
            input: {
              accessedEntity: createQualityManagementTask.qualityManagementTask.id,
            },
          },
        });
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten', {
          variant: 'warning',
        });
      }
    },
    onError(error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
    update(cache, { data: { createQualityManagementTask } }) {
      cache.modify({
        fields: {
          qualityManagementTasks: (existingItemsRefs, { readField }) => {
            const totalCount: number = readField('totalCount', existingItemsRefs) || 0;
            const newItemNodeRef = cache.writeFragment({
              data: {
                ...createQualityManagementTask?.qualityManagementTask,
              },
              fragment: gql`
                fragment QualityManagementTaskNew on QualityManagementTask {
                  ...QualityManagementTaskBase
                }
                ${FRAGMENT_QUALITYMANAGEMENTTASK_BASE}
              `,
              fragmentName: 'QualityManagementTaskNew',
            });
            const newItemEdge = {
              node: newItemNodeRef,
            };
            return {
              ...existingItemsRefs,
              totalCount: totalCount + 1,
              edges: [...existingItemsRefs.edges, newItemEdge],
            };
          },
        },
      });
    },
  });

  return async (values: FormikValues, formikBag: FormikHelpers<any>) => {
    let mutationSuccessful = false;
    try {
      const inputData: { [k: string]: any } = {
        tenant: loggedInMe?.tenant?.id ?? null,
        title: values.title,
        description: values.description,
        dueDate: values.dueDate,
        endDate: values.endDate,
        repeatCycle: values.repeatCycle,
        assignedUsers: values.assignedUsers.map((assignedUser: UserOption) => assignedUser.id),
      };
      if (values.facility?.id) {
        inputData.facility = values.facility.id;
      }
      const { data } = await createQualityManagementTaskMutation({
        variables: {
          input: {
            ...inputData,
          },
        },
      });
      if (data) {
        mutationSuccessful = true;
      }
    } catch (e) {
      console.error(e);
    } finally {
      if (mutationSuccessful) {
        if (qualityManagementTasksCalendarDisplay) {
          navigate(routes['QUALITYMANAGEMENTTASKS_CALENDAR'].path);
        } else {
          navigate(routes['QUALITYMANAGEMENTTASKS'].path);
        }
      }
      formikBag.setSubmitting(false);
    }
  };
}

export default function QualityManagementTaskNewComponent() {
  const handleSubmit = useQualityManagementTaskSubmitHandler();
  const [searchParams] = useSearchParams();

  const dueDate: string | null = useMemo(() => {
    const dueDate = searchParams.get('dueDate');
    if (dueDate) {
      return dayjs(decodeURIComponent(dueDate)).format();
    }
    return null;
  }, [searchParams]);

  return (
    <Container>
      <Box component="header" mb={3}>
        <Typography component="h1" variant="h2" gutterBottom>
          Neue QM-Aufgabe planen
        </Typography>
      </Box>
      <QualityManagementTaskNewForm submitHandler={handleSubmit} dueDate={dueDate} />
    </Container>
  );
}

import React, { useEffect, useState } from 'react';
import {
  ApolloQueryResult,
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import EditIcon from '@mui/icons-material/EditRounded';
import { ConfirmDialog, OrderSelectSearch } from '../common';
import PersonAddIcon from '@mui/icons-material/PersonAddRounded';
import { NavLink } from 'react-router-dom';
import { routes } from '../../models/routes';
import { permissionComponentKeys } from '../../models/permissions';
import { DEACTIVATE_USER_MUTATION, USERS_QUERY } from '../../operations/user';
import useLoggedInMePermissions from '../../hooks/useLoggedInMePermissions';
import ViewIcon from '@mui/icons-material/VisibilityRounded';
import useGlobalStyles from '../../hooks/useGlobalStyles';
import Grid from '@mui/material/Grid';
import useOrderByHandler from '../../hooks/useOrderByHandler';
import { usersFiltersSetVar, usersOrderSelectedIndexVar } from '../../cache';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import { useSnackbar } from 'notistack';
import { User, UsersFiltersSet, usersFiltersSetInitial } from '../../models/users';
import FaceIcon from '@mui/icons-material/Face';
import useBase64ImageByMediaObject from '../../hooks/mediaObjects/useBase64ImageByMediaObject';
import { encodeIriToUrlParam } from '../../utils/helper';
import { Field, Form, Formik, FormikHelpers, FormikValues } from 'formik';
import { Select } from 'formik-mui';
import MenuItem from '@mui/material/MenuItem';
import FacilitiesFilterSearch from '../common/filters/FacilitiesFilterSearch';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { Facility, FacilityOption } from '../../models/facilities';
import { ROLES_QUERY } from '../../operations/role';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import NewIcon from '@mui/icons-material/AutorenewRounded';

function useUserDeactivateHandler(userId: string | null) {
  const { enqueueSnackbar } = useSnackbar();

  const [deactivateUserMutation] = useMutation(DEACTIVATE_USER_MUTATION, {
    onCompleted({ deactivateUser }) {
      if (deactivateUser) {
        enqueueSnackbar('Benutzerkonto erfolgreich entfernt', {
          variant: 'success',
        });
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten', {
          variant: 'warning',
        });
      }
    },
    onError(error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
    update(cache, { data: { deactivateUser } }) {
      cache.modify({
        fields: {
          users: (existingItemsRefs = [], { readField }) => {
            const totalCount: number = readField('totalCount', existingItemsRefs) || 0;
            return {
              ...existingItemsRefs,
              totalCount: totalCount - 1,
              edges: [
                ...existingItemsRefs.edges.filter(
                  (itemRef: any) => deactivateUser?.user?.id !== readField('id', itemRef.node)
                ),
              ],
            };
          },
        },
      });
    },
  });

  return () => {
    if (!userId?.length) {
      return false;
    }
    deactivateUserMutation({
      variables: {
        input: {
          id: userId,
        },
      },
    }).catch((e) => {
      console.error(e);
    });
  };
}

interface UsersItemProps {
  user: User;
  removeHandler: (user: User) => void;
}

const UsersItem: React.FC<UsersItemProps> = (props) => {
  const { user, removeHandler } = props;
  const { classes: globalClasses } = useGlobalStyles();
  const permissions = useLoggedInMePermissions(permissionComponentKeys.USERS);
  const { image: avatarSrc } = useBase64ImageByMediaObject(user?.avatar ?? null);
  return (
    <li data-test="listItem">
      <Grid container spacing={2} alignItems="center" justifyContent="space-between">
        <Grid item xs={12} md={6} style={{ alignSelf: 'center' }}>
          <Box display="flex" flexWrap="wrap" alignItems="center">
            <Box mr={2}>
              <Avatar src={avatarSrc ?? ''} alt={`${user.firstName} ${user.lastName}`}>
                <FaceIcon />
              </Avatar>
            </Box>
            <Box>
              <Typography variant="h6">{`${user.firstName} ${user.lastName}`}</Typography>
              <Typography variant="body1">{user.email}</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box display="flex" flexWrap="wrap" alignItems="center">
            {user.role?.name && (
              <Box my={0.5}>
                <Chip
                  color="primary"
                  size="small"
                  classes={{
                    root: globalClasses.chipPrimary,
                    sizeSmall: globalClasses.chipStatus,
                  }}
                  label={user.role.name}
                />
              </Box>
            )}
            <Box ml="auto">
              <Grid container spacing={1} justifyContent="flex-end">
                <Grid item>
                  <Tooltip title="Details">
                    <Button
                      component={NavLink}
                      to={routes['USER'].path.replace(':userId', encodeIriToUrlParam(user.id))}
                      variant="outlined"
                      color="grey"
                      aria-label="Details"
                      className={globalClasses.buttonSquare}
                    >
                      <ViewIcon />
                    </Button>
                  </Tooltip>
                </Grid>
                {permissions?.update && (
                  <Grid item>
                    <Tooltip title="Bearbeiten">
                      <Button
                        component={NavLink}
                        to={routes['USER_EDIT'].path.replace(
                          ':userId',
                          encodeIriToUrlParam(user.id)
                        )}
                        variant="outlined"
                        color="grey"
                        aria-label="Bearbeiten"
                        className={globalClasses.buttonSquare}
                      >
                        <EditIcon />
                      </Button>
                    </Tooltip>
                  </Grid>
                )}
                {permissions?.delete && (
                  <Grid item>
                    <Tooltip title="Löschen">
                      <Button
                        variant="outlined"
                        color="grey"
                        aria-label="Löschen"
                        className={globalClasses.buttonSquare}
                        onClick={() => removeHandler(user)}
                      >
                        <DeleteIcon />
                      </Button>
                    </Tooltip>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </li>
  );
};

const usersPerPage = 50;

const orderByOptions = [
  {
    key: 'createdAt',
    value: 'ASC',
    label: 'Erstellungsdatum aufsteigend',
  },
  {
    key: 'createdAt',
    value: 'DESC',
    label: 'Erstellungsdatum absteigend',
  },
];

interface FilterProps {
  containsAnyFacility?: Iterable<string> | undefined;
  role?: string | undefined;
  role_list?: string[] | undefined;
}

function buildFilterProps(values: FormikValues | UsersFiltersSet) {
  const { role, facilities } = values;

  // NOTE: 'undefined' needed to specifically remove unused variables for refetch
  // https://github.com/apollographql/react-apollo/issues/2300#issuecomment-458717902
  const filterProps: FilterProps = {
    containsAnyFacility: undefined,
    role: role && role.length > 0 ? role : undefined,
    role_list: undefined,
  };

  if (facilities?.length > 0) {
    filterProps.containsAnyFacility = facilities.map((facility: Facility) => facility.id);
  }

  return filterProps;
}

function useUsersFilterByHandler(
  refetch:
    | ((variables?: Partial<Record<string, any>> | undefined) => Promise<ApolloQueryResult<any>>)
    | undefined,
  updateFiltersSet?: (filters: UsersFiltersSet) => void
) {
  return async (values: FormikValues, formikBag: FormikHelpers<any>) => {
    if (!refetch) {
      return;
    }

    try {
      await refetch({
        after: null,
        ...buildFilterProps(values),
      });
      if (updateFiltersSet) {
        const { role, facilities } = values;
        updateFiltersSet({
          role: role || '',
          facilities: facilities,
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      formikBag.setSubmitting(false);
    }
  };
}

export default function UsersComponent() {
  const { classes: globalClasses } = useGlobalStyles();
  const permissions = useLoggedInMePermissions(permissionComponentKeys.USERS);

  const [deactivateConfirmOpen, setDeactivateConfirmOpen] = useState<boolean>(false);
  const [deactivateUser, setDeactivateUser] = useState<User | null>(null);
  const handleDeactivate = useUserDeactivateHandler(deactivateUser?.id ?? null);

  const handleRemove = (user: User) => {
    setDeactivateUser(user);
    setDeactivateConfirmOpen(true);
  };

  const orderSelectedIndex = useReactiveVar(usersOrderSelectedIndexVar);
  const filtersSet = useReactiveVar(usersFiltersSetVar);

  const [usersQueried, setUsersQueried] = useState<boolean>(false);
  const [queryUsers, { error, data, loading, fetchMore, refetch }] = useLazyQuery(USERS_QUERY, {
    fetchPolicy: 'cache-and-network',
  });
  useEffect(() => {
    if (usersQueried) {
      return;
    }
    const orderOption = orderByOptions[orderSelectedIndex] ?? orderByOptions[0];
    const queryOrder = {
      [orderOption.key]: orderOption.value,
    };
    queryUsers({
      variables: {
        first: usersPerPage,
        after: null,
        order: [queryOrder],
        ...buildFilterProps(filtersSet),
      },
    });
    setUsersQueried(true);
  }, [usersQueried, queryUsers, orderSelectedIndex, filtersSet]);

  const handleOrderBy = useOrderByHandler(refetch, (index: number) => {
    usersOrderSelectedIndexVar(index);
  });

  const handleFilterBy = useUsersFilterByHandler(refetch, (filters: UsersFiltersSet) => {
    usersFiltersSetVar(filters);
  });

  const { data: rolesData } = useQuery(ROLES_QUERY);

  if (error?.message)
    return (
      <Container>
        <Alert severity="error">Es ist ein Fehler aufgetreten: {error.message}</Alert>
      </Container>
    );

  return (
    <Container>
      <Box component="header" mb={3}>
        <Typography component="h1" variant="h2" gutterBottom>
          Benutzer
        </Typography>
        {permissions?.create && (
          <Box display="flex" justifyContent="space-between" mt={2}>
            <Button
              type="button"
              variant="contained"
              color="primary"
              startIcon={<PersonAddIcon />}
              component={NavLink}
              to={routes['USER_NEW'].path}
            >
              Neuen Benutzer hinzufügen
            </Button>
          </Box>
        )}
      </Box>
      <Box className={globalClasses.listSearch}>
        <Box mx={1} width={'100%'}>
          <Typography component="h2" variant="h4" mb={3}>
            Bestehende Benutzer durchsuchen und filtern:
          </Typography>
          <Formik
            initialValues={filtersSet}
            enableReinitialize
            onSubmit={(values, formikBag) => {
              handleFilterBy(values, formikBag);
            }}
          >
            {(props) => (
              <Form autoComplete="off">
                <Grid container spacing={0} rowSpacing={1} alignItems="stretch">
                  <Grid item display="flex" xs={12} sm={6} md={4}>
                    <Field
                      component={Select}
                      name="role"
                      inputProps={{
                        'aria-label': 'Benutzerrolle',
                      }}
                      label="Benutzerrolle"
                      labelId="roleLabel"
                      formControl={{ fullWidth: true }}
                      autoWidth
                      disabled={props.isSubmitting}
                      sx={{ backgroundColor: 'background.default' }}
                    >
                      <MenuItem value="">Alle</MenuItem>
                      {rolesData?.roles?.edges?.map((edge: any) => {
                        const { node: role } = edge;
                        return (
                          <MenuItem key={role.id} value={role.id}>
                            {role.name}
                          </MenuItem>
                        );
                      })}
                    </Field>
                  </Grid>
                  <Grid item display="flex" xs={12} sm={6} md={4}>
                    <FacilitiesFilterSearch
                      formikProps={props}
                      enableTenantAdminFacilitiesQuery={true}
                    />
                  </Grid>
                  <Grid item display="flex" xs={12} md={4}>
                    <Button
                      type="submit"
                      size="large"
                      variant="contained"
                      color="primary"
                      disabled={props.isSubmitting}
                      startIcon={<SearchOutlinedIcon />}
                      data-test="filterSubmit"
                      fullWidth
                    >
                      {props.dirty ? 'Suche aktualisieren' : 'Suchen'}
                    </Button>
                  </Grid>
                </Grid>
                {(props.values.role !== '' || props.values.facilities.length > 0) && (
                  <Grid container mt={1} spacing={0} rowSpacing={1} alignItems="stretch">
                    <Grid item display="flex" alignItems="center">
                      <Box display="flex" flexWrap="wrap">
                        {props.values.role !== '' &&
                          rolesData?.roles?.edges?.some(
                            (edge: any) => edge.node?.id === props.values.role
                          ) && (
                            <Chip
                              label={
                                rolesData.roles.edges.find(
                                  (edge: any) => edge.node?.id === props.values.role
                                ).node.name
                              }
                              icon={
                                props.values.role !== props.initialValues.role ? (
                                  <NewIcon />
                                ) : undefined
                              }
                              onDelete={() => {
                                props.setFieldValue('role', '');
                              }}
                              color="dark"
                              size="small"
                              className={globalClasses.listSearchChip}
                            />
                          )}
                        {props.values.facilities.map((chipFacility: FacilityOption) => (
                          <Chip
                            key={'facilityFilterChip' + chipFacility.id}
                            label={chipFacility.name}
                            icon={
                              !props.initialValues.facilities.some(
                                (facility: FacilityOption) => facility.id === chipFacility.id
                              ) ? (
                                <NewIcon />
                              ) : undefined
                            }
                            onDelete={() => {
                              props.setFieldValue(
                                'facilities',
                                props.values.facilities.filter(
                                  (facility: FacilityOption) => facility.id !== chipFacility.id
                                )
                              );
                            }}
                            color="dark"
                            size="small"
                            className={globalClasses.listSearchChip}
                          />
                        ))}
                      </Box>
                      <Button
                        type="reset"
                        size="large"
                        variant="text"
                        color="grey"
                        disabled={props.isSubmitting}
                        onClick={() => {
                          props.resetForm({
                            values: usersFiltersSetInitial,
                          });
                          props.handleSubmit();
                        }}
                        sx={{ whiteSpace: 'nowrap' }}
                        data-test="filterReset"
                      >
                        alle löschen
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Form>
            )}
          </Formik>
        </Box>
        <Box mt={2} mx={1} width={'100%'}>
          <Grid
            container
            spacing={0}
            rowSpacing={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item display="flex" xs={12} md="auto">
              {JSON.stringify(filtersSet) !== JSON.stringify(usersFiltersSetInitial) && (
                <Typography variant="h3">{data?.users?.totalCount || 'Keine'} Treffer</Typography>
              )}
            </Grid>
            <Grid item display="flex" xs={12} md="auto">
              <OrderSelectSearch
                selectOptions={orderByOptions}
                selectedIndex={orderSelectedIndex}
                submitHandler={handleOrderBy}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box className={globalClasses.listWrapper}>
        {data?.users?.edges?.length > 0 ? (
          <ul className={globalClasses.listCards} data-test="usersList">
            {data.users.edges.map((edge: any) => {
              const { node: user } = edge;
              return <UsersItem key={user.id} user={user} removeHandler={handleRemove} />;
            })}
          </ul>
        ) : (
          <Box className={globalClasses.listStatus}>
            <Typography variant="body1">
              {!usersQueried || loading ? 'Bitte warten...' : 'Keine Benutzer vorhanden'}
            </Typography>
          </Box>
        )}
        {fetchMore && data?.users?.pageInfo?.hasNextPage && (
          <Box component="footer" className={globalClasses.listActions}>
            <Button
              type="button"
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={() => {
                const { endCursor } = data.users.pageInfo;
                fetchMore({
                  variables: { after: endCursor },
                });
              }}
            >
              Mehr...
            </Button>
          </Box>
        )}
      </Box>
      <ConfirmDialog
        open={deactivateConfirmOpen}
        title={`Benutzerkonto entfernen`}
        content={`Möchten Sie das Benutzerkonto "${deactivateUser?.firstName} ${deactivateUser?.lastName}" wirklich entfernen?`}
        onClose={(confirm) => {
          setDeactivateConfirmOpen(false);
          if (confirm) {
            handleDeactivate();
          } else {
            setDeactivateUser(null);
          }
        }}
      />
    </Container>
  );
}

import { FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import useLoggedInMeFacilities from '../../../hooks/useLoggedInMeFacilities';
import { Autocomplete } from '@mui/material';
import { FacilityOption, FacilityStatusLabels } from '../../../models/facilities';
import { useReactiveVar } from '@apollo/client';
import { loggedInMeVar } from '../../../cache';
import { TextField as MuiTextField } from '@mui/material';

interface FacilityFilterProps {
  formikProps: FormikProps<any>;
  enableTenantAdminFacilitiesQuery?: boolean;
}

const FacilityFilterSearch: React.FC<FacilityFilterProps> = (props) => {
  const { formikProps, enableTenantAdminFacilitiesQuery } = props;
  const loggedInMe = useReactiveVar(loggedInMeVar);
  const { facilities } = useLoggedInMeFacilities(!!enableTenantAdminFacilitiesQuery);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [facilitiesOptions, setFacilitiesOptions] = useState<FacilityOption[]>([]);

  useEffect(() => {
    setFacilitiesOptions([
      {
        id: null,
        name: loggedInMe?.tenant?.name ?? FacilityStatusLabels.FACILITY_TENANTWIDE,
      },
      ...facilities,
    ]);
  }, [facilities, loggedInMe]);

  return (
    <Autocomplete
      id="facility"
      fullWidth
      open={openFilter}
      onOpen={() => {
        setOpenFilter(true);
      }}
      onClose={() => {
        setOpenFilter(false);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option?.name ?? ''}
      onChange={(e, value) => {
        formikProps.setFieldValue('facility', value);
      }}
      options={facilitiesOptions}
      value={formikProps.values.facility}
      data-test="filterFacility"
      renderInput={(params) => (
        <MuiTextField
          type="text"
          name="facility"
          variant="outlined"
          label="Träger/Einrichtung"
          sx={{ backgroundColor: 'background.default' }}
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
          }}
        />
      )}
    />
  );
};

export default FacilityFilterSearch;

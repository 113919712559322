import React, { useCallback, useEffect, useState } from 'react';
import { useLazyQuery, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CHAPTER_QUERY, CHAPTERS_QUERY, UPDATE_CHAPTER_MUTATION } from '../../operations/chapter';
import { ConfirmNavigation, InfoDialog, SendNotificationDialog, ToolbarPaper } from '../common';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import { NavLink, useParams } from 'react-router-dom';
import { routes } from '../../models/routes';
import BookIcon from '@mui/icons-material/MenuBookRounded';
import EmailIcon from '@mui/icons-material/Email';
import { useSnackbar } from 'notistack';
import { default as ManualChapterEditForm } from './ManualChapterEditForm.component';
import { default as ManualChapterRevisionForm } from './ManualChapterRevisionForm.component';
import { FormikHelpers, FormikValues } from 'formik';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightIcon from '@mui/icons-material/ChevronRightRounded';
import ButtonGroup from '@mui/material/ButtonGroup';
import { Chapter, TreeChapter } from '../../models/chapters';
import {
  createDataTree,
  decodeIriFromUrlParam,
  downloadByFetch,
  encodeIriToUrlParam,
  flatten,
  getChaptersFlatSortedArray,
  parseUuidFromId,
} from '../../utils/helper';
import useAquireLockMutationHandler from '../../hooks/useAquireLockMutationHandler';
import useReleaseLockMutationHandler from '../../hooks/useReleaseLockMutationHandler';
import { dirtyFormsVar, loggedInMeVar } from '../../cache';
import { USERS_ID_WITH_PERMISSIONS_QUERY } from '../../operations/user';
import { permissionComponentKeys } from '../../models/permissions';
import { DocumentsJoined } from '../documents';
import ViewIcon from '@mui/icons-material/VisibilityRounded';
import { default as ManualChapterPreview } from './ManualChapterPreview.component';
import { config } from '../../models/config';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import LoadingButton from '@mui/lab/LoadingButton';

function useChapterMutationHandler(
  chapterId: string | null,
  callbackFunc?: () => void,
  revisionUpdate?: boolean
) {
  const { enqueueSnackbar } = useSnackbar();

  const [updateChapterMutation] = useMutation(UPDATE_CHAPTER_MUTATION, {
    onCompleted({ updateChapter }) {
      if (updateChapter) {
        enqueueSnackbar('Kapitel erfolgreich aktualisiert', {
          variant: 'success',
        });
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten', {
          variant: 'warning',
        });
      }
    },
    onError(error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  return (values: FormikValues, formikBag: FormikHelpers<any>) => {
    if (!chapterId?.length) {
      formikBag.setStatus('Es ist ein Fehler aufgetreten');
      formikBag.setSubmitting(false);
      return false;
    }
    const inputData = revisionUpdate
      ? {
          versionNumber: values.versionNumber,
          mostRecentEditBy: values.mostRecentEditBy,
          revisionDate: values.revisionDate,
          checkedBy: values.checkedBy,
          checkedDate: values.checkedDate,
          publishedBy: values.publishedBy,
          publishDate: values.publishDate,
          revisionChangelog: values.revisionChangelog,
        }
      : {
          customChapterNumber: values.customChapterNumber,
          title: values.title,
          content: values.content,
        };
    updateChapterMutation({
      variables: {
        input: {
          id: chapterId,
          ...inputData,
        },
      },
    })
      .then(() => {
        if (callbackFunc) {
          callbackFunc();
        }
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        formikBag.setSubmitting(false);
        formikBag.resetForm();
      });
  };
}

const chapterAll = 99999;

export default function ManualChapterEditComponent() {
  const [chapterId, setChapterId] = useState<string | null>(null);
  const [prevChapterId, setPrevChapterId] = useState<string | null>(null);
  const [nextChapterId, setNextChapterId] = useState<string | null>(null);
  const [previewDialogOpen, setPreviewDialogOpen] = useState<boolean>(false);
  const [notificationDialogOpen, setNotificationDialogOpen] = useState<boolean>(false);
  const [notificationUserIds, setNotificationUserIds] = useState<string[] | null>(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const loggedInMe = useReactiveVar(loggedInMeVar);
  const dirtyForms = useReactiveVar(dirtyFormsVar);
  const { enqueueSnackbar } = useSnackbar();
  const [revisionNoticeInfoOpen, setRevisionNoticeInfoOpen] = useState<boolean>(false);

  let { manualId: manualIdParam, chapterId: chapterIdParam } =
    useParams<Record<string, string | undefined>>();

  const manualQueryId: string = decodeIriFromUrlParam(manualIdParam);
  const chapterQueryId: string = decodeIriFromUrlParam(chapterIdParam);

  const { error, data, loading } = useQuery(CHAPTER_QUERY, {
    variables: {
      id: chapterQueryId,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted({ chapter }) {
      setChapterId(chapter?.id || null);
    },
  });

  const handleChapterSubmit = useChapterMutationHandler(chapterId, () =>
    setRevisionNoticeInfoOpen(true)
  );
  const handleRevisionSubmit = useChapterMutationHandler(chapterId, undefined, true);
  const isLocked = useAquireLockMutationHandler(chapterId);
  const releaseLockMutation = useReleaseLockMutationHandler();

  const [querySendNotificationUsers, { loading: loadingQuerySendNotificationUsers }] = useLazyQuery(
    USERS_ID_WITH_PERMISSIONS_QUERY,
    {
      onCompleted(data) {
        const hasPermissionPublish = (user: any): boolean => {
          return (
            user.node.role &&
            user.node.role.permissions.edges.filter(
              (permission: any) =>
                permission.node.publish &&
                permission.node.component === permissionComponentKeys.PERMISSIONS
            ).length > 0
          );
        };
        const notificationUserIds = data.users.edges
          .filter((user: any) => hasPermissionPublish(user) && user.node.id !== loggedInMe?.id)
          .map((user: any) => user.node.id);

        setNotificationUserIds(notificationUserIds);
      },
    }
  );

  useEffect(() => {
    return () => {
      if (isLocked) {
        releaseLockMutation(chapterId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chapterId]);

  const [chaptersQueried, setChaptersQueried] = useState<boolean>(false);
  const [chapterTree, setChapterTree] = useState<TreeChapter[]>([]);
  const [chapterList, setChapterList] = useState<Chapter[]>([]);
  const [queryChapters, { data: dataChapters, loading: loadingChapters }] =
    useLazyQuery(CHAPTERS_QUERY);
  useEffect(() => {
    if (chaptersQueried) {
      return;
    }
    // NOTE: no version filter needed (as in ManualChapter.component) b/c current version default
    queryChapters({
      variables: { first: chapterAll, after: null, manual: manualQueryId },
    });
    // Note: Not using useLazyQuery onCompleted to setChaptersQueried b/c not triggered when cache only:
    // https://github.com/apollographql/react-apollo/issues/3968#issuecomment-644072748
    setChaptersQueried(true);
  }, [chaptersQueried, queryChapters, manualQueryId]);

  useEffect(() => {
    if (!dataChapters?.chapters?.edges) {
      return;
    }
    const chaptersFlatSorted = getChaptersFlatSortedArray(dataChapters.chapters.edges);
    const chapterDataTree = createDataTree(chaptersFlatSorted);
    setChapterTree(chapterDataTree);
    setChapterList(flatten(chapterDataTree));
  }, [dataChapters?.chapters?.edges]);

  useEffect(() => {
    const currentIndex = chapterList.findIndex((chapter) => chapter.id === chapterId);
    const prevChapter = chapterList[currentIndex - 1];
    const nextChapter = chapterList[currentIndex + 1];
    setPrevChapterId(prevChapter?.id ?? null);
    setNextChapterId(nextChapter?.id ?? null);
  }, [chapterId, chapterList]);

  const downloadChapterPdf = useCallback(async () => {
    if (!data?.chapter) {
      return;
    }

    const pdfFileUuid = parseUuidFromId(data.chapter.id ?? '');

    if (pdfFileUuid.length === 0) {
      return;
    }

    const url = `${config.API_BASE_URL}/export/quality-manual-chapter-pdf/${pdfFileUuid}`;
    const fileName = `${data.chapter.chapterNumber} ${data.chapter.title}`.trim();
    try {
      await downloadByFetch(url, fileName, 'pdf');
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
  }, [data, enqueueSnackbar]);

  if (loading || !chaptersQueried || loadingChapters) {
    return null;
  }
  if (error?.message)
    return (
      <Container>
        <Alert severity="error">Es ist ein Fehler aufgetreten: {error.message}</Alert>
      </Container>
    );

  if (!data?.chapter)
    return (
      <Container>
        <Alert severity="warning">Kapitel nicht gefunden</Alert>
      </Container>
    );

  const startSendNotification = async () => {
    querySendNotificationUsers({
      variables: { first: 999999 },
    });

    setNotificationDialogOpen(true);
  };

  return (
    <Container>
      <Box component="header" mb={3}>
        <Typography component="h1" variant="h2" gutterBottom>
          {`Kapitel ${data.chapter.chapterNumber} bearbeiten`}
        </Typography>
      </Box>
      <ToolbarPaper>
        <ButtonGroup variant="contained" disableElevation color="primary">
          {prevChapterId ? (
            <Button
              component={NavLink}
              to={routes['CHAPTER_EDIT'].path
                .replace(':manualId', encodeIriToUrlParam(data.chapter.manual.id))
                .replace(':chapterId', encodeIriToUrlParam(prevChapterId))}
              startIcon={<ChevronLeftIcon />}
            >
              Vorheriges Kapitel bearbeiten
            </Button>
          ) : (
            <Button startIcon={<ChevronLeftIcon />} disabled={true}>
              Vorheriges Kapitel bearbeiten
            </Button>
          )}
          {nextChapterId ? (
            <Button
              component={NavLink}
              to={routes['CHAPTER_EDIT'].path
                .replace(':manualId', encodeIriToUrlParam(data.chapter.manual.id))
                .replace(':chapterId', encodeIriToUrlParam(nextChapterId))}
              endIcon={<ChevronRightIcon />}
            >
              Nächstes Kapitel bearbeiten
            </Button>
          ) : (
            <Button endIcon={<ChevronRightIcon />} disabled={true}>
              Nächstes Kapitel bearbeiten
            </Button>
          )}
        </ButtonGroup>
        <Box className="alignRight" display="flex">
          <Box mr={2}>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<ViewIcon />}
              onClick={() => {
                setPreviewDialogOpen(true);
              }}
            >
              Vorschau
            </Button>
          </Box>
          <Box mr={2}>
            <LoadingButton
              onClick={async () => {
                setIsDownloading(true);
                await downloadChapterPdf();
                setIsDownloading(false);
              }}
              loading={isDownloading}
              loadingPosition="start"
              variant="outlined"
              color="primary"
              startIcon={<PdfIcon />}
            >
              PDF herunterladen
            </LoadingButton>
          </Box>
          {loggedInMe?.tenant !== null && (
            <Box mr={2}>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<EmailIcon />}
                onClick={() => startSendNotification()}
                disabled={loadingQuerySendNotificationUsers}
              >
                Benachrichtigung auslösen
              </Button>
            </Box>
          )}
          <Button
            component={NavLink}
            to={routes['MANUAL_EDIT'].path.replace(
              ':manualId',
              encodeIriToUrlParam(data.chapter.manual.id)
            )}
            variant="outlined"
            color="primary"
            startIcon={<BookIcon />}
          >
            QM-Handbuch bearbeiten
          </Button>
        </Box>
      </ToolbarPaper>
      <ManualChapterEditForm
        formData={data.chapter}
        submitHandler={handleChapterSubmit}
        isLocked={isLocked === true}
        chapterTree={chapterTree}
        manualCustomChapterLabel={data.chapter.manual?.customChapterLabel}
      />
      <InfoDialog
        open={revisionNoticeInfoOpen}
        title="Revisionsinformationen ergänzen/anpassen"
        onClose={() => {
          setRevisionNoticeInfoOpen(false);
        }}
      >
        <p>
          Bitte ergänzen Sie nach Ihrer Änderung ggf. die notwendigen Revisionsinformationen zu
          diesem Kapitel.
        </p>
      </InfoDialog>
      <ManualChapterRevisionForm
        formData={data.chapter}
        submitHandler={handleRevisionSubmit}
        isLocked={isLocked === true}
      />
      <ConfirmNavigation shouldBlock={dirtyForms.length > 0} />
      {data.chapter.manual?.tenant?.id && chapterId && (
        <DocumentsJoined
          entityId={chapterId}
          editing={true}
          permissionComponentKey={permissionComponentKeys.MANUALS}
        />
      )}
      {chapterId && loggedInMe && notificationUserIds !== null && (
        <SendNotificationDialog
          description="Nachricht an User mit Veröffentlichungs-Rechten"
          dialogOpen={notificationDialogOpen}
          recipients={notificationUserIds}
          sender={loggedInMe.id}
          notifiedItem={chapterId}
          onClose={() => setNotificationDialogOpen(false)}
          onSubmit={() => setNotificationDialogOpen(false)}
        />
      )}
      <ManualChapterPreview
        dialogOpen={previewDialogOpen}
        previewId={data.chapter.id}
        chapterList={chapterList}
        resetHandler={() => {
          setPreviewDialogOpen(false);
        }}
      />
    </Container>
  );
}

import React from 'react';
import Dialog from '@mui/material/Dialog';
import { MEDIA_OBJECT_PREVIEW_QUERY } from 'operations/mediaObjectPreview';
import { useQuery } from '@apollo/client';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import { CustomDialogTitle } from '.';
import DialogContent from '@mui/material/DialogContent';

interface Props {
  dialogOpen: boolean;
  onClose: () => void;
  documentId: string;
}

const ImagePreview: React.FC<Props> = (props) => {
  const { dialogOpen, onClose, documentId } = props;

  const { error, data, loading } = useQuery(MEDIA_OBJECT_PREVIEW_QUERY, {
    variables: {
      id: documentId,
    },
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return null;
  }
  if (error?.message)
    return (
      <Container>
        <Alert severity="error">Es ist ein Fehler aufgetreten: {error.message}</Alert>
      </Container>
    );

  const documentPreviewImage: string | undefined = data.documentVersion.file.previewImage;

  return (
    <Dialog open={dialogOpen} onClose={() => onClose()}>
      <CustomDialogTitle id="dialog-files-upload-title" onClose={() => onClose()}>
        Dokumentvorschau
      </CustomDialogTitle>
      <DialogContent>
        <img src={`data:image/jpeg;base64, ${documentPreviewImage}`} alt="Dokumentvorschau" />
      </DialogContent>
    </Dialog>
  );
};

export default ImagePreview;

import React, { useCallback, useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Typography from '@mui/material/Typography';
import { CHAPTER_QUERY, CHAPTERS_QUERY } from '../../operations/chapter';
import { ToolbarPaper } from '../common';
import useGlobalStyles from '../../hooks/useGlobalStyles';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import { NavLink, useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { routes } from '../../models/routes';
import BookIcon from '@mui/icons-material/MenuBookRounded';
import parse from 'html-react-parser';
import { Chapter, ChapterContentLabels } from '../../models/chapters';
import {
  compareByKey2Sort,
  createDataTree,
  decodeIriFromUrlParam,
  downloadByFetch,
  encodeIriToUrlParam,
  flatten,
  getChaptersFlatSortedArray,
  parseUuidFromId,
} from '../../utils/helper';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightIcon from '@mui/icons-material/ChevronRightRounded';
import { DocumentsJoined } from '../documents';
import { config } from '../../models/config';
import { useSnackbar } from 'notistack';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import { MANUAL_QUERY } from '../../operations/manual';
import LoadingButton from '@mui/lab/LoadingButton';
import { Document, DocumentNode } from '../../models/documents';
import { default as ManualChapterRevision } from './ManualChapterRevision.component';
import { DocumentVersion, DocumentVersionNode } from '../../models/documentVersions';
import { useDocumentVersionDownload } from '../documents/Document.component';
import Link from '@mui/material/Link';

const chapterAll = 99999;

export const renderEditorHTML = (
  html: string,
  chapterList: Chapter[],
  entityDocuments: Document[],
  download: (documentVersion: DocumentVersion) => Promise<void>
) =>
  parse(`${html}`, {
    replace: (domNode) => {
      // @ts-ignore
      if (!domNode.attribs) return;
      // @ts-ignore
      const nodeName = domNode.name;
      // @ts-ignore
      const crossreferenceId = domNode.attribs['data-cross-reference'];
      if (crossreferenceId) {
        const chapter = chapterList.find((chapter) => chapter.id === crossreferenceId);
        if (!chapter) {
          return nodeName === 'span' ? (
            <span data-cross-reference={crossreferenceId} className="invalid">
              {ChapterContentLabels.get('invalid-cross-reference')}
            </span>
          ) : (
            <div data-cross-reference={crossreferenceId} className="invalid">
              {ChapterContentLabels.get('invalid-cross-reference')}
            </div>
          );
        }
        const chapterHref = routes['CHAPTER'].path
          .replace(':manualId', encodeIriToUrlParam(chapter.manual.id))
          .replace(':chapterId', encodeIriToUrlParam(chapter.id));
        return nodeName === 'span' ? (
          <span data-cross-reference={crossreferenceId}>
            <NavLink to={chapterHref} className={({ isActive }) => (isActive ? 'selected' : '')}>
              {`${chapter.chapterNumber} ${chapter.title}`.trim()}
            </NavLink>
          </span>
        ) : (
          <div data-cross-reference={crossreferenceId}>
            <NavLink to={chapterHref} className={({ isActive }) => (isActive ? 'selected' : '')}>
              {`${chapter.chapterNumber} ${chapter.title}`.trim()}
            </NavLink>
          </div>
        );
      }
      // @ts-ignore
      const documentLinkId = domNode.attribs['data-document-link'];
      // @ts-ignore
      const documentLinkTitle = domNode.attribs['data-document-title'];
      if (documentLinkId) {
        const document = entityDocuments.find((document) => document.id === documentLinkId);
        const currentVersion = document?.versions?.edges
          .map((documentVersion: DocumentVersionNode) => documentVersion.node)
          .sort((versionA, versionB) => compareByKey2Sort(versionA, versionB, 'version'))
          .pop();
        if (!document || !currentVersion) {
          return nodeName === 'span' ? (
            <span data-document-link={documentLinkId} className="invalid">
              {`${ChapterContentLabels.get('invalid-document-link')}: ${documentLinkTitle}`}
            </span>
          ) : (
            <div data-document-link={documentLinkId} className="invalid">
              {`${ChapterContentLabels.get('invalid-document-link')}: ${documentLinkTitle}`}
            </div>
          );
        }
        return nodeName === 'span' ? (
          <span data-document-link={documentLinkId}>
            <Link
              component="button"
              onClick={() => download(currentVersion)}
              title={`Download ${document.title}`.trim()}
            >
              {`${document.title}`.trim()}
            </Link>
          </span>
        ) : (
          <div data-document-link={documentLinkId}>
            <Link
              component="button"
              onClick={() => download(currentVersion)}
              title={`Download ${document.title}`.trim()}
            >
              {`${document.title}`.trim()}
            </Link>
          </div>
        );
      }
      return;
    },
  });

export default function ManualChapterComponent() {
  const { classes: globalClasses, cx } = useGlobalStyles();
  const [manualWorkingCopyVersion, setManualWorkingCopyVersion] = useState<number | null>(null);
  const [chapterId, setChapterId] = useState<string | null>(null);
  const [prevChapterId, setPrevChapterId] = useState<string | null>(null);
  const [nextChapterId, setNextChapterId] = useState<string | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [isDownloading, setIsDownloading] = useState(false);
  const download = useDocumentVersionDownload();

  let { manualId: manualIdParam, chapterId: chapterIdParam } =
    useParams<Record<string, string | undefined>>();

  const manualQueryId: string = decodeIriFromUrlParam(manualIdParam);
  const chapterQueryId: string = decodeIriFromUrlParam(chapterIdParam);

  const { error: errorManual, loading: loadingManual } = useQuery(MANUAL_QUERY, {
    variables: {
      id: manualQueryId,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted({ manual }) {
      setManualWorkingCopyVersion(manual?.workingCopyVersion ?? 1);
    },
    skip: '-' === manualQueryId,
  });

  const { error, data, loading } = useQuery(CHAPTER_QUERY, {
    variables: {
      id: chapterQueryId,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted({ chapter }) {
      setChapterId(chapter?.id || null);
      if ('-' === manualQueryId) {
        setManualWorkingCopyVersion(chapter.manual?.workingCopyVersion ?? 1);
      }
    },
  });

  const [chaptersQueried, setChaptersQueried] = useState<boolean>(false);
  const [chapterList, setChapterList] = useState<Chapter[]>([]);
  const [queryChapters, { data: dataChapters, loading: loadingChapters }] =
    useLazyQuery(CHAPTERS_QUERY);
  useEffect(() => {
    if (chaptersQueried || !manualWorkingCopyVersion) {
      return;
    }
    const publishedVersion =
      manualWorkingCopyVersion && manualWorkingCopyVersion > 1 ? manualWorkingCopyVersion - 1 : 1;
    queryChapters({
      variables: {
        first: chapterAll,
        after: null,
        manual: manualQueryId,
        version: publishedVersion,
      },
    });
    // Note: Not using useLazyQuery onCompleted to setChaptersQueried b/c not triggered when cache only:
    // https://github.com/apollographql/react-apollo/issues/3968#issuecomment-644072748
    setChaptersQueried(true);
  }, [chaptersQueried, queryChapters, manualQueryId, manualWorkingCopyVersion]);

  useEffect(() => {
    if (!dataChapters?.chapters?.edges) {
      return;
    }
    const chaptersFlatSorted = getChaptersFlatSortedArray(dataChapters.chapters.edges);
    const chapterDataTree = createDataTree(chaptersFlatSorted);
    setChapterList(flatten(chapterDataTree));
  }, [dataChapters?.chapters?.edges]);

  useEffect(() => {
    const currentIndex = chapterList.findIndex((chapter) => chapter.id === chapterId);
    const prevChapter = chapterList[currentIndex - 1];
    const nextChapter = chapterList[currentIndex + 1];
    setPrevChapterId(prevChapter?.id ?? null);
    setNextChapterId(nextChapter?.id ?? null);
  }, [chapterId, chapterList]);

  const downloadChapterPdf = useCallback(async () => {
    if (!data?.chapter) {
      return;
    }

    const pdfFileUuid = parseUuidFromId(data.chapter.id ?? '');

    if (pdfFileUuid.length === 0) {
      return;
    }
    setIsDownloading(true);
    const url = `${config.API_BASE_URL}/export/quality-manual-chapter-pdf/${pdfFileUuid}`;
    const fileName = `${data.chapter.chapterNumber} ${data.chapter.title}`.trim();
    try {
      await downloadByFetch(url, fileName, 'pdf');
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
    setIsDownloading(false);
  }, [data, enqueueSnackbar]);

  if (loadingManual || loading || !chaptersQueried || loadingChapters) {
    return null;
  }
  if (errorManual?.message || error?.message)
    return (
      <Container>
        {errorManual?.message && (
          <Alert severity="error">Es ist ein Fehler aufgetreten: {errorManual.message}</Alert>
        )}
        {errorManual?.message && (
          <Alert severity="error">Es ist ein Fehler aufgetreten: {errorManual.message}</Alert>
        )}
      </Container>
    );

  if (!data?.chapter)
    return (
      <Container>
        <Alert severity="warning">Kapitel nicht gefunden</Alert>
      </Container>
    );

  return (
    <Container>
      <Box component="header" mb={3}>
        <Typography component="h1" variant="h2" gutterBottom>
          {`${data.chapter.chapterNumber} ${data.chapter.title}`.trim()}
        </Typography>
      </Box>
      <ToolbarPaper>
        <ButtonGroup variant="contained" disableElevation color="primary">
          {prevChapterId ? (
            <Button
              component={NavLink}
              to={routes['CHAPTER'].path
                .replace(':manualId', encodeIriToUrlParam(data.chapter.manual.id))
                .replace(':chapterId', encodeIriToUrlParam(prevChapterId))}
              startIcon={<ChevronLeftIcon />}
            >
              Vorheriges Kapitel
            </Button>
          ) : (
            <Button startIcon={<ChevronLeftIcon />} disabled={true}>
              Vorheriges Kapitel
            </Button>
          )}
          {nextChapterId ? (
            <Button
              component={NavLink}
              to={routes['CHAPTER'].path
                .replace(':manualId', encodeIriToUrlParam(data.chapter.manual.id))
                .replace(':chapterId', encodeIriToUrlParam(nextChapterId))}
              endIcon={<ChevronRightIcon />}
            >
              Nächstes Kapitel
            </Button>
          ) : (
            <Button endIcon={<ChevronRightIcon />} disabled={true}>
              Nächstes Kapitel
            </Button>
          )}
        </ButtonGroup>
        <Box className="alignRight" display="flex">
          <Box mr={2}>
            <LoadingButton
              onClick={downloadChapterPdf}
              variant="outlined"
              color="primary"
              loadingPosition="start"
              loading={isDownloading}
              startIcon={<PdfIcon />}
            >
              PDF herunterladen
            </LoadingButton>
          </Box>
          <Button
            component={NavLink}
            to={routes['MANUAL'].path.replace(
              ':manualId',
              encodeIriToUrlParam(data.chapter.manual.id)
            )}
            variant="outlined"
            color="primary"
            startIcon={<BookIcon />}
          >
            QM-Handbuch (Leseansicht)
          </Button>
        </Box>
      </ToolbarPaper>
      <Paper
        component="section"
        variant="outlined"
        className={cx(globalClasses.paper, globalClasses.richText)}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} data-test="richTextContent">
            {renderEditorHTML(
              data.chapter.content,
              chapterList,
              data.chapter.documents?.edges.map(
                (documentNode: DocumentNode) => documentNode.node
              ) ?? [],
              download
            )}
          </Grid>
        </Grid>
      </Paper>
      <ManualChapterRevision chapter={data.chapter} />
      {data.chapter.manual?.tenant?.id && chapterId && (
        <DocumentsJoined entityId={chapterId} editing={false} />
      )}
    </Container>
  );
}

import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/SaveRounded';
import { CustomDialogTitle } from '../common';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { LinearProgress } from '@mui/material';
import Typography from '@mui/material/Typography';
import { MediaObject } from '../../models/mediaObject';
import { checkImageDimensions, getReadableFileSizeString } from '../../utils/helper';
import UploadIcon from '@mui/icons-material/CloudUploadRounded';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import useGlobalStyles from '../../hooks/useGlobalStyles';
import IconButton from '@mui/material/IconButton';
import useDeleteMediaObject from '../../hooks/useDeleteMediaObject';
import useFilesUpload from '../../hooks/useFilesUpload';

const useStyles = makeStyles({ name: 'FilesUploadDialog' })((theme: Theme) => {
  return {
    submitButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.text.secondary,
    },
    actionButton: {
      textTransform: 'none',
    },
    inputFile: {
      display: 'none',
    },
  };
});

export interface FilesUploadDialogProps {
  onSubmit: (mediaObjects: MediaObject[]) => void;
  onClose: () => void;
  dialogOpen: boolean;
  acceptedFileTypes: string[];
  maxUploadCount: number;
  maxImageDimensions?: [number, number] | undefined;
  maxFileSize?: number | undefined;
}

const FilesUploadDialogComponent = (props: FilesUploadDialogProps) => {
  const {
    onSubmit,
    onClose,
    dialogOpen,
    acceptedFileTypes,
    maxUploadCount,
    maxImageDimensions,
    maxFileSize,
  } = props;
  const { classes: globalClasses } = useGlobalStyles();
  const { classes } = useStyles();
  const [files, setFiles] = useState<any[]>([]);
  const [warning, setWarning] = useState<any | null>(null);
  const fileInput = useRef<HTMLInputElement>(null);

  const { uploadFiles, mediaObjects, errorUpload, isUploading, clearUploads } = useFilesUpload();

  const { deleteMediaObject, isDeleting } = useDeleteMediaObject();

  const deleteExistingMediaObjects = useCallback(() => {
    mediaObjects.forEach((mediaObject) => {
      deleteMediaObject(mediaObject.id);
    });
  }, [mediaObjects, deleteMediaObject]);

  const uploadFinished = useMemo(() => {
    if (files.length === 0 || mediaObjects.length === 0) {
      return false;
    }
    return !isUploading && !errorUpload && files.length === mediaObjects.length;
  }, [files, isUploading, errorUpload, mediaObjects]);

  const onFilesChange = useCallback(
    async (event: any) => {
      await deleteExistingMediaObjects();
      const inputFiles = Array.from(event.target.files);

      setWarning(null);

      const newFiles = inputFiles.filter(
        (inputFile: any) =>
          !files.some((file: any) => file.name === inputFile.name && file.size === inputFile.size)
      );

      const newFilesLimit = maxUploadCount - files.length;

      if (newFiles.length === newFilesLimit) {
        setWarning(`Maximale Datei-Anzahl (${maxUploadCount}) erreicht.`);
      }

      if (newFiles.length > newFilesLimit) {
        setWarning(
          `Maximal ${maxUploadCount} Dateien erlaubt. Es wurden nicht alle von Ihnen ausgewählten Dateien zur
          Upload-Liste hinzugefügt. Bitte entfernen Sie Dateien von der Upload-Liste, um weitere Dateien
          hinzufügen zu können.`
        );
      }

      newFiles.slice(0, newFilesLimit).forEach(async (newFile: any) => {
        if (maxImageDimensions !== undefined) {
          const validFileDimensions = await checkImageDimensions(newFile, maxImageDimensions);
          if (validFileDimensions === false) {
            setWarning(
              `Datei mit ungültiger Bildgröße. Maximal ${maxImageDimensions[0]} x ${maxImageDimensions[1]} Pixel erlaubt.`
            );
            return;
          }
        }

        if (maxFileSize !== undefined && newFile.size > maxFileSize) {
          setWarning(
            `Datei mit ungültiger Dateigröße. Maximal ${getReadableFileSizeString(
              maxFileSize
            )} erlaubt.`
          );
          return;
        }
        setFiles((prevState) => [...prevState, newFile]);
      });
    },
    [deleteExistingMediaObjects, maxUploadCount, files, maxImageDimensions, maxFileSize]
  );

  const chooseFiles = useCallback(() => {
    if (!fileInput?.current) {
      return;
    }
    fileInput.current.click();
  }, [fileInput]);

  const removeFile = useCallback(
    (fileToRemove: any) => {
      const updatedFiles = files.filter(
        (file) => file.name !== fileToRemove.name || file.size !== fileToRemove.size
      );
      if (updatedFiles.length < maxUploadCount) {
        setWarning(null);
      }
      setFiles(updatedFiles);
    },
    [files, maxUploadCount]
  );

  const handleUploadFiles = useCallback(() => {
    uploadFiles(files);
  }, [files, uploadFiles]);

  const submit = useCallback(() => {
    const mediaObjectsTemp = [...mediaObjects];
    setFiles([]);
    setWarning(null);
    clearUploads();
    if (mediaObjectsTemp.length > 0) {
      onSubmit(mediaObjectsTemp);
    }
  }, [clearUploads, mediaObjects, onSubmit]);

  const close = useCallback(() => {
    deleteExistingMediaObjects();
    setFiles([]);
    setWarning(null);
    clearUploads();
    onClose();
  }, [deleteExistingMediaObjects, clearUploads, onClose]);

  return (
    <Dialog open={dialogOpen} aria-labelledby="dialog-files-upload-title">
      <CustomDialogTitle id="dialog-files-upload-title" onClose={close}>
        Dateien hochladen {isUploading && ' isUploading'}
      </CustomDialogTitle>
      <DialogContent>
        {files.length > 0 && errorUpload && (
          <Box mb={2}>
            <Alert severity="error">{errorUpload}</Alert>
          </Box>
        )}
        {warning && (
          <Box mb={2}>
            <Alert severity="warning">{warning}</Alert>
          </Box>
        )}
        {files.length === 0 && (
          <Box mb={2}>
            <DialogContentText color="textPrimary">
              Wählen Sie bis zu 10 Dateien aus, die Sie hochladen möchten:
            </DialogContentText>
          </Box>
        )}
        {files.length > 0 && (
          <Box my={1}>
            <Grid container spacing={2}>
              {files.map((file, index) => (
                <Grid key={`uploadDialogFiles${index}`} item xs={12}>
                  <Box display="flex">
                    <Box mr={1}>
                      <InsertDriveFileIcon />
                    </Box>
                    <Box mr={2}>
                      <Typography
                        variant="h6"
                        sx={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
                      >
                        {file.name}
                      </Typography>
                      <Typography component="p" variant="caption">
                        {getReadableFileSizeString(file.size ?? 0)}
                      </Typography>
                    </Box>
                    {!uploadFinished && (
                      <Box ml="auto">
                        <Tooltip title="Entfernen">
                          <IconButton
                            onClick={() => removeFile(file)}
                            className={globalClasses.tooltipIcon}
                            color="primary"
                            aria-label="Entfernen"
                            size="large"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    )}
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
        {!uploadFinished && (
          <Box mb={2} mt={2} display="flex">
            <Box mr={2}>
              <Button
                variant="outlined"
                color="primary"
                className={classes.actionButton}
                onClick={chooseFiles}
                disabled={isUploading || isDeleting || files.length >= maxUploadCount}
              >
                Dateien auswählen
              </Button>
            </Box>
            {files.length > 0 && (
              <Box mr={2}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.actionButton}
                  disabled={isUploading || isDeleting}
                  startIcon={<UploadIcon />}
                  onClick={handleUploadFiles}
                >
                  Hochladen
                </Button>
              </Box>
            )}
          </Box>
        )}
        <input
          type="file"
          multiple
          required
          onChange={onFilesChange}
          accept={acceptedFileTypes.join(', ')}
          className={classes.inputFile}
          ref={fileInput}
        />
      </DialogContent>
      {(isUploading || isDeleting) && <LinearProgress />}
      <DialogActions>
        <Button color="primary" onClick={close} data-test="dialogReset">
          Abbrechen
        </Button>
        {mediaObjects.length > 0 && (
          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            onClick={submit}
            disabled={isUploading || isDeleting}
            data-test="dialogSubmit"
          >
            Übernehmen
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default FilesUploadDialogComponent;

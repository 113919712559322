import { Plugin } from '@ckeditor/ckeditor5-core';
import { toWidget } from '@ckeditor/ckeditor5-widget/src/utils';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';
import InsertCrossreferenceCommand from './InsertCrossreferenceCommand';
import { ChapterContentLabels } from '../../models/chapters';

// https://ckeditor.com/docs/ckeditor5/latest/framework/guides/tutorials/implementing-a-block-widget.html#full-source-code
// https://ckeditor.com/docs/ckeditor5/latest/framework/guides/tutorials/using-react-in-a-widget.html#full-source-code
export default class CrossreferenceEditing extends Plugin {
  static get requires() {
    return [Widget];
  }

  init() {
    this._defineSchema();
    this._defineConverters();

    this.editor.commands.add('insertCrossreference', new InsertCrossreferenceCommand(this.editor));
  }

  _defineSchema() {
    const schema = this.editor.model.schema;

    schema.register('crossreference', {
      isObject: true,
      allowWhere: '$block',
      allowAttributes: ['data-cross-reference'],
    });
  }

  _defineConverters() {
    const editor = this.editor;
    const conversion = editor.conversion;
    const getCrossreferenceContent = editor.config.get('custom').getCrossreferenceContent;

    conversion.for('upcast').elementToElement({
      view: {
        name: 'div',
        attributes: 'data-cross-reference',
      },
      model: (viewElement, { writer: modelWriter }) => {
        return modelWriter.createElement('crossreference', viewElement.getAttributes());
      },
    });

    conversion.for('dataDowncast').elementToElement({
      model: 'crossreference',
      view: (modelElement, { writer: viewWriter }) => {
        return viewWriter.createEmptyElement('div', {
          'data-cross-reference': modelElement.getAttribute('data-cross-reference'),
        });
      },
    });

    conversion.for('editingDowncast').elementToElement({
      model: 'crossreference',
      view: (modelElement, { writer: viewWriter }) => {
        const crossreferenceId = modelElement.getAttribute('data-cross-reference');
        const { crossreferenceLabel, crossreferenceValid } =
          getCrossreferenceContent(crossreferenceId);

        const crossreference = viewWriter.createContainerElement(
          'div',
          {
            'data-cross-reference': crossreferenceId,
            class: !crossreferenceValid ? 'invalid' : '',
          },
          [
            viewWriter.createRawElement('span', {}, function (domElement) {
              domElement.innerHTML = crossreferenceLabel;
            }),
          ]
        );

        return toWidget(crossreference, viewWriter, {
          label: ChapterContentLabels.get('cross-reference'),
        });
      },
    });
  }
}

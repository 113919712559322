import React, { Fragment, useState } from 'react';
import { Formik, Form, Field, FormikValues, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { Checkbox, TextField } from 'formik-mui';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import FormLabel from '@mui/material/FormLabel';
import useGlobalStyles from '../../hooks/useGlobalStyles';
import {
  ConfirmDialog,
  FormActions,
  InfoDialog,
  ConfirmNavigation,
  FormikContextDirty,
} from '../common';
import SaveIcon from '@mui/icons-material/SaveRounded';
import CancelIcon from '@mui/icons-material/HighlightOffRounded';
import { ManualStatus } from './ManualStatus.component';
import Typography from '@mui/material/Typography';
import { FacilityStatusLabels } from '../../models/facilities';
import FormControlLabel from '@mui/material/FormControlLabel';
import InfoIcon from '@mui/icons-material/InfoRounded';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useReactiveVar } from '@apollo/client';
import { isFormDirtyVar } from '../../cache';

interface IProps {
  formData: any;
  submitHandler: (values: FormikValues, formikBag: FormikHelpers<any>) => void;
  children?: React.ReactNode;
}

const ManualEditFormComponent: React.FC<IProps> = (props) => {
  const { formData, submitHandler } = props;
  const { classes: globalClasses } = useGlobalStyles();
  let navigate = useNavigate();
  const labelTenantWide = formData?.tenant?.name ?? FacilityStatusLabels.FACILITY_TENANTWIDE;

  const [customChapterLabelInfoOpen, setCustomChapterLabelInfoOpen] = useState<boolean>(false);
  const [customChapterLabelChangeInfoOpen, setCustomChapterLabelChangeInfoOpen] =
    useState<boolean>(false);
  const [facilitiesHeadlineInfoOpen, setFacilitiesHeadlineInfoOpen] = useState<boolean>(false);

  const isFormDirty = useReactiveVar(isFormDirtyVar);
  const [resetConfirmOpen, setResetConfirmOpen] = useState<boolean>(false);

  return (
    <Fragment>
      <ConfirmNavigation shouldBlock={isFormDirty} />
      <Formik
        initialValues={{
          title: formData.title || '',
          tenant: formData?.tenant || null,
          customChapterLabel: !!formData.customChapterLabel,
        }}
        enableReinitialize
        validationSchema={Yup.object({
          title: Yup.string().required('Pflichtfeld'),
        })}
        onSubmit={(values, formikBag) => {
          isFormDirtyVar(false);
          submitHandler(values, formikBag);
        }}
      >
        {(props) => {
          return (
            <Form autoComplete="off" style={{ width: '100%' }} data-test="form">
              <FormikContextDirty />
              {props.status && (
                <Box mb={2}>
                  <Alert severity="error">{props.status}</Alert>
                </Box>
              )}
              <Paper component="section" variant="outlined" className={globalClasses.paper}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor="title">Titel</FormLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name="title"
                        id="title"
                        variant="outlined"
                        fullWidth
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className={globalClasses.tooltipBox}>
                      <FormControlLabel
                        className={globalClasses.tooltipText}
                        control={
                          <Field
                            component={Checkbox}
                            type="checkbox"
                            name="customChapterLabel"
                            id="customChapterLabel"
                            color="primary"
                            onChange={(event: React.ChangeEvent<any>) => {
                              const customChapterLabel = event.target.checked;
                              if (!!formData.customChapterLabel && !customChapterLabel) {
                                setCustomChapterLabelChangeInfoOpen(true);
                              }
                              props.setFieldValue('customChapterLabel', customChapterLabel);
                            }}
                          />
                        }
                        label="manuelle Kapitelnummerierung verwenden"
                      />
                      <Tooltip title="Info zur manuellen Kapitelnummerierung">
                        <IconButton
                          className={globalClasses.tooltipIcon}
                          color="primary"
                          aria-label="Info"
                          onClick={() => {
                            setCustomChapterLabelInfoOpen(true);
                          }}
                          size="large"
                        >
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                      <InfoDialog
                        open={customChapterLabelInfoOpen}
                        title={`Manuelle Kapitelnummerierung`}
                        content={`Wenn Sie „Manuelle Kapitelnummerierung“ auswählen, können Sie die Kapitelnummern Ihres QM-Handbuchs selbstständig vergeben. Bitte beachten Sie: bei manueller Kapitelnummerierung aktualisiert sich die Nummerierung Ihrer Kapitel nicht automatisch, wenn Kapitel verschoben oder neu eingefügt werden.`}
                        onClose={() => {
                          setCustomChapterLabelInfoOpen(false);
                        }}
                      />
                      <InfoDialog
                        open={customChapterLabelChangeInfoOpen}
                        title={`Hinweis`}
                        content={`Bei einer Umstellung von manueller auf automatische Kapitelnummerierung werden automatisch generierte Kapitelnummern genutzt. Ggf. bereits erstellte manuelle Kapitelnummern bleiben aber in der Datenbank erhalten. Insofern Sie erneut auf manuelle Kapitelnummerierung umschalten, sollten Sie die Nummerierung überprüfen, da die Kapitelstruktur ggf. zwischenzeitlich verändert wurde.`}
                        onClose={() => {
                          setCustomChapterLabelChangeInfoOpen(false);
                        }}
                      />
                    </Box>
                  </Grid>
                  {formData?.tenant?.id ? (
                    <Grid item xs={12}>
                      <Box className={globalClasses.tooltipBox}>
                        <Typography variant="h6">Träger/Einrichtung:</Typography>
                        <Tooltip title="Info zu “Träger/Einrichtung”">
                          <IconButton
                            className={globalClasses.tooltipIcon}
                            color="primary"
                            aria-label="Info"
                            onClick={() => {
                              setFacilitiesHeadlineInfoOpen(true);
                            }}
                            size="large"
                          >
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                        <InfoDialog
                          open={facilitiesHeadlineInfoOpen}
                          title={`Träger/Einrichtung`}
                          onClose={() => {
                            setFacilitiesHeadlineInfoOpen(false);
                          }}
                        >
                          <Typography paragraph>
                            Hier wird Ihnen diejenige Einrichtung bzw. die Trägerorganisaton
                            angezeigt, der das QM-Handbuch zugeordnet ist. Diese Zuordnung lässt
                            sich nachträglich nicht mehr ändern.
                          </Typography>
                          <Typography paragraph>
                            Um das QM-Handbuch einer anderen Einrichtung zuzuordnen, erstellen Sie
                            bitte ein neues QM-Handbuch mit der korrekten Einrichtungszuordnung.
                            Löschen Sie ggf. das falsch zugeordnete QM-Handbuch im Anschluss.
                          </Typography>
                        </InfoDialog>
                      </Box>
                      <Typography variant="body1">
                        {formData?.facility?.name ?? labelTenantWide}
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid item xs={12}>
                      <ManualStatus manual={formData} />
                    </Grid>
                  )}
                </Grid>
              </Paper>
              <FormActions>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  startIcon={<SaveIcon />}
                  disabled={props.isSubmitting}
                  data-test="manualEditSubmit"
                >
                  Speichern
                </Button>
                <Button
                  type="button"
                  variant="outlined"
                  size="large"
                  color="primary"
                  startIcon={<CancelIcon />}
                  onClick={() => {
                    if (isFormDirty) {
                      setResetConfirmOpen(true);
                    } else {
                      navigate(-1);
                    }
                  }}
                  data-test="manualEditReset"
                >
                  Abbrechen
                </Button>
              </FormActions>
              <ConfirmDialog
                open={resetConfirmOpen}
                title={`Änderungen verwerfen`}
                content={`Wollen Sie die vorgenommenen Änderungen im Formular verwerfen?`}
                onClose={(confirm) => {
                  setResetConfirmOpen(false);
                  if (confirm) {
                    props.handleReset();
                    isFormDirtyVar(false);
                    navigate(-1);
                  }
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </Fragment>
  );
};

export default ManualEditFormComponent;

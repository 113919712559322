import { MediaObject } from '../../models/mediaObject';
import { useEffect, useState } from 'react';
import { convertBlobToBase64, getFileBlobByMediaObject } from '../../utils/helper';

interface ReturnType {
  image: string | null;
  refetch: (mediaObject?: MediaObject | null | undefined) => Promise<void>;
}

export default function useBase64ImageByMediaObject(mediaObject: MediaObject | null): ReturnType {
  const [image, setImage] = useState<string | null>(null);
  const [currentMediaObject, setCurrentMediaObject] = useState<MediaObject | null>(null);

  const loadImage = async (mediaObject: MediaObject) => {
    try {
      const blob = await getFileBlobByMediaObject(mediaObject);
      const base64img = await convertBlobToBase64(blob);
      setImage(base64img);
    } catch (error) {
      console.error(error);
    }
  };

  const refetch = async (mediaObject: MediaObject | null | undefined = undefined) => {
    if (currentMediaObject && currentMediaObject.filePath === mediaObject?.filePath) {
      return;
    }
    let refetchMediaObject = currentMediaObject;

    if (mediaObject !== undefined) {
      setCurrentMediaObject(mediaObject);
      refetchMediaObject = mediaObject;
    }

    if (refetchMediaObject === null) {
      setImage(null);
    } else {
      await loadImage(refetchMediaObject);
    }
  };

  useEffect(() => {
    if (mediaObject && !image) {
      loadImage(mediaObject);
    }
    setCurrentMediaObject(mediaObject);
  }, [setImage, mediaObject, image]);

  return { image, refetch };
}

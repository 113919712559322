import React, { useState, useEffect } from 'react';
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import { matchPath } from 'react-router';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Theme, useTheme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import useMediaQuery from '@mui/material/useMediaQuery';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Fab from '@mui/material/Fab';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import MenuIcon2 from '@mui/icons-material/ListRounded';
import Button from '@mui/material/Button';
import LogoutIcon from '@mui/icons-material/PowerSettingsNewRounded';
import logoWkd from '../assets/logo-wkd.svg';
import { config } from '../models/config';
import { GlobalLoadingIndicator, LinkRouter, Footer, ScrollToTop } from './common';
import { routes, Route as IRoute } from '../models/routes';
import {
  removeAuth,
  LOGIN_STATUS_QUERY,
  sessionsStorageSync,
  initSessionStorages,
} from '../operations/auth';
import { ME_QUERY } from '../operations/user';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {
  documentsLastFetchedItemsCountVar,
  documentsLastFetchedItemIdVar,
  documentsLastFetchedSearchPageIndexVar,
  isDrawerMenuOpenVar,
  loggedInMeVar,
} from '../cache';
import NextIcon from '@mui/icons-material/NavigateNextRounded';
import useBase64ImageByMediaObject from '../hooks/mediaObjects/useBase64ImageByMediaObject';
import FaceIcon from '@mui/icons-material/Face';
import { MediaObject } from '../models/mediaObject';
import useGlobalStyles from '../hooks/useGlobalStyles';
import Tooltip from '@mui/material/Tooltip';
import { FontWeights } from '../models/theme';

export const headerHeight = 80;
const drawerOffset = 32;
const drawerWidth = 256;

// @ts-ignore
const useStyles = makeStyles({ name: 'App' })((theme: Theme) => {
  return {
    appBar: {
      top: headerHeight,
      width: `calc(100% - ${drawerOffset}px)`,
      marginLeft: drawerOffset,
      zIndex: theme.zIndex.drawer + 2,
      boxShadow: theme.shadows[1],
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      '@media print': {
        display: 'none',
      },
    },
    appBarTitle: {
      fontSize: theme.typography.body1.fontSize,
      fontWeight: FontWeights.MEDIUM,
      color: theme.palette.primary.contrastText,
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginLeft: theme.spacing(-5.5),
      marginRight: theme.spacing(4),
      boxShadow: theme.shadows[2],
      backgroundColor: theme.palette.common.white,
      '&:hover, &:active': {
        backgroundColor: theme.palette.background.default,
      },
      color: theme.palette.primary.main,
    },
    menuButtonIcon: {
      transform: 'rotate(0)',
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    menuButtonIconShift: {
      transform: 'rotate(90deg)',
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      '@media print': {
        display: 'none',
      },
    },
    drawerPaper: {
      top: headerHeight,
      bottom: 0,
      width: drawerWidth,
      height: 'auto',
      backgroundColor: theme.palette.drawer.main,
    },
    drawerPaperOffset: {
      transform: `translateX(-${drawerWidth - drawerOffset}px) !important`,
      visibility: 'visible !important',
    },
    drawerPaperAnchorDockedLeft: {
      borderRight: 0,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: theme.palette.drawer.light,
      ...theme.mixins.toolbar,
    },
    drawerHeaderListItem: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      color: theme.palette.common.white,
    },
    drawerHeaderListItemText: {
      fontWeight: FontWeights.MEDIUM,
    },
    drawerListItem: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      color: theme.palette.secondary.main,
      '&.selected': {
        color: theme.palette.common.white,
      },
    },
    drawerListItemDisabled: {
      color: theme.palette.secondary.dark,
    },
    drawerListItemIcon: {
      minWidth: theme.spacing(5),
      color: 'inherit',
    },
    drawerListItemText: {
      fontWeight: FontWeights.REGULAR,
      '.selected &': {
        fontWeight: FontWeights.MEDIUM,
      },
    },
    main: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -(drawerWidth - drawerOffset),
      '@media print': {
        transition: 'none',
        display: 'block',
        marginLeft: 0,
      },
    },
    mainShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
      '@media print': {
        transition: 'none',
      },
    },
    mainContent: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(4),
      '@media print': {
        display: 'block',
      },
    },
    breadcrumbs: {
      margin: theme.spacing(0, 2, 3),
      fontSize: theme.typography.body2.fontSize,
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
      },
      '@media print': {
        display: 'none',
      },
    },
    breadcrumbsContent: {
      display: 'flex',
      alignItems: 'center',
      lineHeight: '1.375rem',
      fontWeight: FontWeights.REGULAR,
    },
  };
});

interface IMatchParams {
  [key: string]: string;
}

interface IBreadcrumbRoute {
  path: string;
  title: string;
  icon?: React.ElementType | null;
  routeKey?: string;
  parentRouteKey?: string;
}

const updateDocumentTitle = (routeKey: string): void => {
  const route: IRoute = routes[routeKey];
  document.title = `${route.title} ‧ ${config.APP_TITLE}`;
};

const buildBreadcrumbRoutes = (pathname: string): IBreadcrumbRoute[] => {
  let currentRouteKey;
  let match: any = null;
  let routesArray: IBreadcrumbRoute[] = [];
  currentRouteKey = Object.keys(routes).find((key) => {
    const route: IRoute = routes[key];
    match = matchPath(
      {
        path: route.path,
      },
      pathname
    );
    return match !== null;
  });
  if (typeof currentRouteKey === 'string') {
    updateDocumentTitle(currentRouteKey);
  }
  let params = match && match.params ? match.params : null;
  while (typeof currentRouteKey === 'string' && routes[currentRouteKey]) {
    const currentRoute: IRoute = routes[currentRouteKey];
    routesArray.unshift({
      path: replaceRoutePathParams(params, currentRoute.path),
      title: currentRoute.title,
      icon: currentRoute.icon ?? null,
      routeKey: currentRouteKey,
      parentRouteKey: currentRoute.parent ?? undefined,
    });
    currentRouteKey = currentRoute.parent;
  }
  return routesArray;
};

const replaceRoutePathParams = (params: IMatchParams, path: string): string => {
  const routeParams = params || null;
  let routePath = path;
  if (routeParams !== null) {
    Object.keys(routeParams).forEach((key) => {
      const paramValue = routeParams[key];
      routePath = routePath.replace(`:${key}`, paramValue);
    });
  }
  return routePath;
};

const handleLogout = () => {
  removeAuth();
};

export default function AppComponent() {
  const { classes: globalClasses } = useGlobalStyles();
  const { classes, cx } = useStyles();
  const theme = useTheme();
  const smallViewport = useMediaQuery(theme.breakpoints.down('xl'));
  let navigate = useNavigate();
  let { pathname, state: locationState } = useLocation();
  const loggedInMe = useReactiveVar(loggedInMeVar);
  const [avatarObject, setAvatarObject] = useState<MediaObject | null>(null);

  useEffect(() => {
    window.addEventListener('storage', sessionsStorageSync);
    initSessionStorages();

    return function cleanup() {
      window.removeEventListener('storage', sessionsStorageSync);
    };
  }, []);

  const isDrawerMenuOpen = useReactiveVar(isDrawerMenuOpenVar);
  const [breadcrumbRoutes, setBreadcrumbRoutes] = useState<IBreadcrumbRoute[]>([]);
  const { data: loginStatusQueryData } = useQuery(LOGIN_STATUS_QUERY);
  const [queryMe, { data: meQueryData }] = useLazyQuery(ME_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: ({ user }) => {
      if (!user) {
        removeAuth();
      }
      loggedInMeVar(user);
      // @ts-ignore
      const redirect = locationState?.from;
      if (redirect) {
        navigate(redirect, { replace: true });
      }
    },
    onError(error) {
      console.error(error);
      removeAuth();
    },
  });
  const [appBarUserMenuAnchor, setAppBarUserMenuAnchor] = useState<null | HTMLElement>(null);

  useEffect(() => {
    if (!loginStatusQueryData.isLoggedIn || !loginStatusQueryData.loggedInUserId) {
      return;
    }
    const userId = '/api/users/' + loginStatusQueryData.loggedInUserId;
    queryMe({
      variables: {
        id: userId,
      },
    });
  }, [loginStatusQueryData, queryMe]);

  useEffect(() => {
    const breadcrumbRoutes: IBreadcrumbRoute[] = buildBreadcrumbRoutes(pathname);
    if (
      !breadcrumbRoutes.some(
        (route) => route.routeKey === 'DOCUMENTS' || route.parentRouteKey === 'DOCUMENTS'
      )
    ) {
      documentsLastFetchedItemsCountVar(0);
      documentsLastFetchedItemIdVar(null);
      documentsLastFetchedSearchPageIndexVar(0);
    }
    setBreadcrumbRoutes([...breadcrumbRoutes]);
    // ga.send(["pageview", location.pathname]);
  }, [pathname]);

  const { image: avatarSrc, refetch: refetchAvatarSrc } = useBase64ImageByMediaObject(avatarObject);

  useEffect(() => {
    setAvatarObject(loggedInMe?.avatar ?? null);
  }, [loggedInMe]);

  useEffect(() => {
    refetchAvatarSrc(avatarObject);
  }, [avatarObject, refetchAvatarSrc]);

  const handleAppBarUserMenuRoute = (key: string) => {
    setAppBarUserMenuAnchor(null);
    if (key in routes) {
      navigate(routes[key].path);
    }
  };

  const handleAppBarUserMenuLogout = () => {
    setAppBarUserMenuAnchor(null);
    handleLogout();
  };

  return (
    <>
      <ScrollToTop />
      <div className={globalClasses.appRoot}>
        <header className={globalClasses.appHeader}>
          <LinkRouter
            to={routes['HOME'].path}
            underline="none"
            className={globalClasses.appHeaderLogo}
          >
            <img src={logoWkd} alt="Wolters Kluwer" />
          </LinkRouter>
        </header>
        <div className={globalClasses.appContent}>
          <AppBar
            component="div"
            className={cx(classes.appBar, {
              [classes.appBarShift]: isDrawerMenuOpen,
            })}
          >
            <Toolbar>
              <Tooltip title={isDrawerMenuOpen ? 'Menü zuklappen' : 'Menü aufklappen'}>
                <Fab
                  aria-label="Menü"
                  onClick={() => isDrawerMenuOpenVar(!isDrawerMenuOpen)}
                  size="small"
                  classes={{ root: classes.menuButton }}
                  data-test="drawerToggle"
                >
                  <MenuIcon
                    className={cx(classes.menuButtonIcon, {
                      [classes.menuButtonIconShift]: isDrawerMenuOpen,
                    })}
                  />
                </Fab>
              </Tooltip>
              <LinkRouter
                to={routes['HOME'].path}
                underline="none"
                className={classes.appBarTitle}
                data-test="appBarHome"
              >
                QEasy
              </LinkRouter>
              {meQueryData && meQueryData.user && (
                <Box display="flex" alignItems="center" ml="auto">
                  <Button
                    color="inherit"
                    size="large"
                    aria-label="Benutzermenü"
                    aria-controls="appBarUserMenu"
                    aria-haspopup="true"
                    onClick={(event) => setAppBarUserMenuAnchor(event.currentTarget)}
                    data-test="appBarUserMenuToggle"
                  >
                    <Box mr={2}>
                      {meQueryData.user.firstName} {meQueryData.user.lastName}
                    </Box>
                    <Avatar
                      src={avatarSrc || ''}
                      alt={`${meQueryData.user.firstName} ${meQueryData.user.lastName}`}
                    >
                      <FaceIcon />
                    </Avatar>
                  </Button>
                  <Menu
                    id="appBarUserMenu"
                    anchorEl={appBarUserMenuAnchor}
                    keepMounted
                    open={Boolean(appBarUserMenuAnchor)}
                    onClose={() => setAppBarUserMenuAnchor(null)}
                  >
                    <MenuItem
                      onClick={() => handleAppBarUserMenuRoute('PROFILE')}
                      data-test="appBarUserMenuProfile"
                    >
                      Benutzerprofil
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleAppBarUserMenuLogout()}
                      data-test="appBarUserMenuLogout"
                    >
                      Logout
                    </MenuItem>
                  </Menu>
                </Box>
              )}
            </Toolbar>
            <GlobalLoadingIndicator />
          </AppBar>
          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={isDrawerMenuOpen}
            classes={{
              paper: cx(classes.drawerPaper, {
                [classes.drawerPaperOffset]: !isDrawerMenuOpen,
              }),
              paperAnchorDockedLeft: classes.drawerPaperAnchorDockedLeft,
            }}
          >
            <div className={classes.drawerHeader}>
              <ListItem
                ContainerComponent="div"
                classes={{
                  root: classes.drawerHeaderListItem,
                }}
              >
                <ListItemIcon classes={{ root: classes.drawerListItemIcon }}>
                  <MenuIcon2 />
                </ListItemIcon>
                <ListItemText
                  primary="Menü"
                  classes={{ primary: classes.drawerHeaderListItemText }}
                />
              </ListItem>
            </div>
            <List component="nav">
              {[
                'HOME',
                'FACILITIES',
                'USERS',
                'ROLES',
                'MANUALS',
                'DOCUMENTS',
                'SURVEYS',
                'QUALITYDEVELOPMENTMEASURES',
                'QUALITYMANAGEMENTTASKS_CALENDAR',
              ].map((key, index) => {
                if (!routes[key]) {
                  return null;
                }
                const route: IRoute = routes[key];
                const IconComponent = route.icon ?? null;
                const userComponentPermissions =
                  loginStatusQueryData.loggedInMe?.role?.permissions?.edges.find(
                    (edge: any) => edge.node?.component === route.permissionComponent
                  );
                const itemDisabled =
                  (route.isPrivate && !loginStatusQueryData.isLoggedIn) ||
                  (route.isPrivate && !userComponentPermissions) ||
                  (userComponentPermissions &&
                    typeof route.hasComponentAccess === 'function' &&
                    route.hasComponentAccess(userComponentPermissions?.node) !== true);
                const ItemComponent = itemDisabled ? 'div' : NavLink;
                const navLinkProps = itemDisabled
                  ? {}
                  : {
                      to: route.path,
                      end: true,
                      onClick: () => {
                        if (smallViewport) {
                          isDrawerMenuOpenVar(false);
                        }
                      },
                      'data-test': `drawerMenu${
                        key.charAt(0).toUpperCase() + key.slice(1).toLowerCase()
                      }`,
                    };
                return (
                  <ListItem
                    key={'drawerListItem' + index}
                    component={ItemComponent}
                    disabled={itemDisabled}
                    classes={{
                      root: classes.drawerListItem,
                      disabled: classes.drawerListItemDisabled,
                    }}
                    {...navLinkProps}
                  >
                    {IconComponent && (
                      <ListItemIcon classes={{ root: classes.drawerListItemIcon }}>
                        <IconComponent />
                      </ListItemIcon>
                    )}
                    <ListItemText
                      primary={route.title}
                      classes={{ primary: classes.drawerListItemText }}
                    />
                    {/* NOTE: Reference for new features in the future
                    {key === 'QUALITYMANAGEMENTTASKS_CALENDAR' && isDrawerMenuOpen && (
                      <ListItemSecondaryAction>
                        <Chip
                          label="Neu"
                          size="small"
                          color="warning"
                          sx={{ fontWeight: FontWeights.MEDIUM }}
                        />
                      </ListItemSecondaryAction>
                    )}
                    */}
                  </ListItem>
                );
              })}
            </List>
            {loginStatusQueryData.isLoggedIn && (
              <Box py={2} px={4}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={<LogoutIcon />}
                  onClick={() => handleLogout()}
                  data-test="drawerLogout"
                >
                  Logout
                </Button>
              </Box>
            )}
          </Drawer>
          <main
            className={cx(classes.main, {
              [classes.mainShift]: isDrawerMenuOpen,
            })}
          >
            <div className={globalClasses.appHeaderSpacer} />
            <div className={classes.mainContent}>
              {loginStatusQueryData.isLoggedIn && (
                <Breadcrumbs
                  maxItems={5}
                  separator={<NextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                  classes={{ root: classes.breadcrumbs }}
                >
                  {breadcrumbRoutes.map((route, index) => {
                    const last = index === breadcrumbRoutes.length - 1;
                    return last ? (
                      <Typography
                        key={`breadcrumb-${index}`}
                        component="span"
                        variant="body2"
                        color="primary"
                        classes={{ root: classes.breadcrumbsContent }}
                      >
                        {route.title}
                      </Typography>
                    ) : (
                      <LinkRouter
                        key={`breadcrumb-${index}`}
                        color="primary"
                        to={route.path}
                        classes={{ root: classes.breadcrumbsContent }}
                      >
                        {route.title}
                      </LinkRouter>
                    );
                  })}
                </Breadcrumbs>
              )}
              <Outlet />
            </div>
            <Footer routeKeys={['IMPRINT', 'HELP', 'RELEASENOTES']} />
          </main>
        </div>
      </div>
    </>
  );
}

import { Permissions } from './permissions';
import { Facilities, FacilityOption } from './facilities';
import { MediaObject } from './mediaObject';

export interface UsersFiltersSet {
  role: string;
  facilities: FacilityOption[];
}

export const usersFiltersSetInitial: UsersFiltersSet = {
  role: '',
  facilities: [],
};

export interface UserOption {
  id: string;
  name: string;
}

export interface User {
  id: string;
  createdAt: string;
  updatedAt: string;
  email: string;
  firstName: string;
  lastName: string;
  avatar?: MediaObject;
  jobTitle?: string;
  group?: string;
  active: boolean;
  role?: {
    id: string;
    name: string;
    tenantAdmin?: boolean;
    permissions: Permissions;
  };
  tenant?: {
    id: string;
    name?: string;
    numberOfLicenses?: number;
  };
  facilities?: Facilities;
  notificationAsEmail?: boolean;
  tenantWideEditPermission?: boolean;
}

export interface Me extends User {}

export interface UpdateUserInput {
  id: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  avatar?: string;
  jobTitle?: string;
  group?: string;
  tenant?: string;
  role?: string;
  facilities?: string[];
  plainPassword?: string;
  active?: boolean;
  notificationAsEmail?: boolean;
  tenantWideEditPermission?: boolean;
}

export interface UserNode {
  node: User;
}

export interface Users {
  edges: UserNode[];
  totalCount?: number;
  pageInfo?: any;
}

import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Formik, Form, Field, FormikValues, FormikHelpers, FieldArray } from 'formik';
import * as Yup from 'yup';
import { TextField, Select, Checkbox } from 'formik-mui';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/CloseRounded';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import { QuestionKeys, QuestionLabels, questionTypeOptions } from '../../models/questions';
import MenuItem from '@mui/material/MenuItem';
import AddIcon from '@mui/icons-material/AddBoxRounded';
import SubtractIcon from '@mui/icons-material/IndeterminateCheckBoxRounded';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { ConfirmDialog, InfoDialog } from '../common';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import SaveIcon from '@mui/icons-material/SaveRounded';
import useGlobalStyles from '../../hooks/useGlobalStyles';
import Tooltip from '@mui/material/Tooltip';
import { ckeditorSubmitTransform } from '../../ckeditor/utils';
import RichTextField from '../common/RichTextField';
import SurveyQuestionEditorComponent from './SurveyQuestionEditor.component';
import InfoIcon from '@mui/icons-material/InfoRounded';
import { List, ListItem, ListItemText } from '@mui/material';

const useStyles = makeStyles({ name: 'SurveyQuestionForm' })((theme: Theme) => {
  return {
    dialogWrapper: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    dialogAppBar: {
      boxShadow: '0 1rem 1rem #fff',
    },
    dialogToolbar: {
      padding: theme.spacing(4, 3, 2),
    },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
    },
    dialogActions: {
      zIndex: 1, // NOTE: -> boxShadow
      margin: 0,
      boxShadow: '0 -1rem 1rem #fff',
      backgroundColor: theme.palette.background.light,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.text.secondary,
    },
    ckEditor: {
      '& .ck': {
        '& .ck-content': {
          minHeight: '0',
        },
      },
    },
  };
});

interface IProps {
  dialogOpen: boolean;
  formData: any;
  copyMode?: boolean;
  posMax?: number;
  submitHandler: (values: FormikValues, formikBag: FormikHelpers<any>) => void;
  resetHandler: () => void;
  children?: React.ReactNode;
}

const SurveyQuestionFormComponent: React.FC<IProps> = (props) => {
  const { dialogOpen, formData, copyMode, posMax, submitHandler, resetHandler } = props;
  const { classes } = useStyles();
  const { classes: globalClasses } = useGlobalStyles();
  const [scaleConfirmOpen, setScaleConfirmOpen] = useState<boolean>(false);
  const [propsType, setPropsType] = useState<QuestionKeys | null>(null);
  const [propsAdditionalAnswerAllowed, setPropsAdditionalAnswerAllowed] = useState<boolean>(false);
  const [previousType, setPreviousType] = useState<QuestionKeys | null>(null);
  const [selectedType, setSelectedType] = useState<QuestionKeys | null>(null);

  const [surveyQuestionFormQuestionLabelDialogOpen, setSurveyQuestionFormQuestionLabelDialogOpen] =
    useState<boolean>(false);
  const [surveyQuestionFormDescrLabelDialogOpen, setSurveyQuestionFormDescrLabelDialogOpen] =
    useState<boolean>(false);
  const [surveyQuestionFormTypeLabelDialogOpen, setSurveyQuestionFormTypeLabelDialogOpen] =
    useState<boolean>(false);
  const [surveyQuestionFormCommentsLabelDialogOpen, setSurveyQuestionFormCommentsLabelDialogOpen] =
    useState<boolean>(false);
  const [
    surveyQuestionFormCommentsHeadlineLabelDialogOpen,
    setSurveyQuestionFormCommentsHeadlineLabelDialogOpen,
  ] = useState<boolean>(false);

  const initialQuestionText = useMemo(() => {
    if (formData?.text) {
      return copyMode ? formData.text + ' (Kopie)' : formData.text;
    }
    return '';
  }, [formData, copyMode]);

  const initialPosition = useMemo(() => {
    if (formData?.position && !copyMode) {
      return formData?.position;
    }
    return posMax ? posMax + 1 : 1;
  }, [formData, posMax, copyMode]);

  useEffect(() => {
    let type = QuestionKeys.SINGLE_CHOICE;
    let additionalAnswerAllowed = !!formData?.additionalAnswerAllowed;
    if (formData?.type === QuestionKeys.COPY_TEXT) {
      type = QuestionKeys.FREE_TEXT;
      additionalAnswerAllowed = false;
    } else if (formData?.type) {
      type = formData?.type;
      if (formData?.type === QuestionKeys.FREE_TEXT) {
        additionalAnswerAllowed = true;
      }
    }
    setPropsType(type);
    setPropsAdditionalAnswerAllowed(additionalAnswerAllowed);
  }, [formData]);

  return (
    <Formik
      initialValues={{
        text: initialQuestionText,
        explanation: formData?.explanation ?? '',
        position: initialPosition,
        type: propsType,
        additionalAnswerAllowed: propsAdditionalAnswerAllowed,
        additionalAnswerLabel: formData?.additionalAnswerLabel ?? '',
        choices: formData?.choices ?? [],
      }}
      enableReinitialize
      validationSchema={Yup.object({
        text: Yup.string().required('Pflichtfeld'),
      })}
      onSubmit={(values, formikBag) => {
        submitHandler({ ...values, text: ckeditorSubmitTransform(values.text) }, formikBag);
      }}
    >
      {(props) => (
        <Dialog
          open={dialogOpen}
          disableEnforceFocus
          fullScreen
          onClose={() => props.handleReset()}
          aria-labelledby="dialog-question-title"
        >
          <Form autoComplete="off" className={classes.dialogWrapper}>
            <AppBar
              position="static"
              color="inherit"
              classes={{
                root: classes.dialogAppBar,
              }}
            >
              <Toolbar
                classes={{
                  root: classes.dialogToolbar,
                }}
              >
                <Typography id="dialog-question-title" variant="h5">
                  {formData && !copyMode ? 'Frage bearbeiten' : 'Neue Frage erstellen'}
                </Typography>
                <Tooltip title="Schließen">
                  <IconButton
                    aria-label="Schließen"
                    color="inherit"
                    size="small"
                    className={classes.closeButton}
                    onClick={() => {
                      props.handleReset();
                      resetHandler();
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </Toolbar>
            </AppBar>
            <DialogContent
              classes={{
                root: classes.dialogContent,
              }}
            >
              {props.status && (
                <Box mb={2}>
                  <Alert severity="error">{props.status}</Alert>
                </Box>
              )}
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box display="flex" alignItems="flex-end">
                    <FormControl fullWidth margin="normal">
                      <Box className={globalClasses.tooltipBox}>
                        <FormLabel htmlFor="text" className={globalClasses.tooltipText}>
                          Frage
                        </FormLabel>
                        <Tooltip title="Info zu “Frage”">
                          <IconButton
                            className={globalClasses.tooltipIcon}
                            color="primary"
                            aria-label="Info"
                            onClick={() => {
                              setSurveyQuestionFormQuestionLabelDialogOpen(true);
                            }}
                            size="large"
                          >
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                        <InfoDialog
                          open={surveyQuestionFormQuestionLabelDialogOpen}
                          title="Frage"
                          onClose={() => {
                            setSurveyQuestionFormQuestionLabelDialogOpen(false);
                          }}
                        >
                          <Typography paragraph>
                            Geben Sie hier die Evaluationsfrage in das Eingabefeld ein und editieren
                            Sie diese nach Wunsch mithilfe des Editors.
                          </Typography>
                        </InfoDialog>
                      </Box>
                      <Field
                        component={RichTextField}
                        type="text"
                        id="text"
                        name="text"
                        // maxLength={250}
                        fullWidth
                        editorComponent={SurveyQuestionEditorComponent}
                        richTextInputProps={{
                          className: classes.ckEditor,
                        }}
                      />
                    </FormControl>
                    <Box ml={1}>
                      <FormControl margin="normal">
                        <Box className={globalClasses.tooltipBox}>
                          <FormLabel htmlFor="type" className={globalClasses.tooltipText}>
                            Fragetyp
                          </FormLabel>
                          <Tooltip title="Info zu “Fragetyp”">
                            <IconButton
                              className={globalClasses.tooltipIcon}
                              color="primary"
                              aria-label="Info"
                              onClick={() => {
                                setSurveyQuestionFormTypeLabelDialogOpen(true);
                              }}
                              size="large"
                            >
                              <InfoIcon />
                            </IconButton>
                          </Tooltip>
                          <InfoDialog
                            open={surveyQuestionFormTypeLabelDialogOpen}
                            title="Fragetyp"
                            onClose={() => {
                              setSurveyQuestionFormTypeLabelDialogOpen(false);
                            }}
                          >
                            <Typography paragraph>
                              Wählen Sie im Dropdown-Menü den gewünschten Fragetyp Ihrer
                              Evaluationsfrage aus. Sie haben folgende Auswahloptionen:
                            </Typography>
                            <List className={globalClasses.listBullets}>
                              <ListItem>
                                <ListItemText>
                                  <Box
                                    component="span"
                                    sx={{ display: 'block', fontWeight: '500' }}
                                  >
                                    Einfachauswahl:
                                  </Box>
                                  Es kann nur eine der zur Auswahl gestellten Antwortoptionen
                                  ausgewählt werden.
                                </ListItemText>
                              </ListItem>
                              <ListItem>
                                <ListItemText>
                                  <Box
                                    component="span"
                                    sx={{ display: 'block', fontWeight: '500' }}
                                  >
                                    Mehrfachauswahl:
                                  </Box>
                                  Es können beliebig viele der zur Auswahl gestellten
                                  Antwortoptionen ausgewählt werden.
                                </ListItemText>
                              </ListItem>
                              <ListItem>
                                <ListItemText>
                                  <Box
                                    component="span"
                                    sx={{ display: 'block', fontWeight: '500' }}
                                  >
                                    Freitextfrage:
                                  </Box>
                                  Es kann ein individueller Antworttext verfasst werden.
                                </ListItemText>
                              </ListItem>
                            </List>
                          </InfoDialog>
                        </Box>
                        <Field
                          component={Select}
                          name="type"
                          id="type"
                          variant="outlined"
                          inputProps={{
                            'aria-label': 'Fragetyp',
                            onChange: (e: React.ChangeEvent<any>) => {
                              if (
                                props.values.choices?.length > 0 &&
                                (e.target?.value === QuestionKeys.FREE_TEXT ||
                                  e.target?.value === QuestionKeys.SCALE_CHOICE)
                              ) {
                                setPreviousType(props.values.type);
                                setSelectedType(e.target?.value);
                                setScaleConfirmOpen(true);
                              }
                            },
                          }}
                        >
                          {questionTypeOptions.map((option) => {
                            // NOTE: COPY_TEXT will be using a different UI component
                            if (option.value === QuestionKeys.COPY_TEXT) {
                              return false;
                            }
                            // NOTE: For the time being no SCALE_CHOICE selection by user allowed
                            if (option.value === QuestionKeys.SCALE_CHOICE) {
                              if (props.values.type === QuestionKeys.SCALE_CHOICE) {
                                return (
                                  <MenuItem
                                    key={`type-${option.value}`}
                                    value={option.value}
                                    disabled
                                  >
                                    {option.label}
                                  </MenuItem>
                                );
                              }
                              return false;
                            }
                            return (
                              <MenuItem key={`type-${option.value}`} value={option.value}>
                                {option.label}
                              </MenuItem>
                            );
                          })}
                        </Field>
                      </FormControl>
                      <ConfirmDialog
                        open={scaleConfirmOpen}
                        title={`Fragetyp auf "${QuestionLabels.get(selectedType)}" ändern?`}
                        content={`Achtung: Bereits erstellte Antwortoptionen werden dadurch gelöscht!`}
                        onClose={(confirm) => {
                          setScaleConfirmOpen(false);
                          if (confirm) {
                            props.setFieldValue('choices', []);
                            props.setFieldValue('type', selectedType);
                          } else {
                            props.setFieldValue('type', previousType);
                          }
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Box className={globalClasses.tooltipBox}>
                      <FormLabel htmlFor="explanation" className={globalClasses.tooltipText}>
                        Erklärungstext
                        <span className="labelInfo">optional</span>
                      </FormLabel>
                      <Tooltip title="Info zu “Erklärungstext”">
                        <IconButton
                          className={globalClasses.tooltipIcon}
                          color="primary"
                          aria-label="Info"
                          onClick={() => {
                            setSurveyQuestionFormDescrLabelDialogOpen(true);
                          }}
                          size="large"
                        >
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                      <InfoDialog
                        open={surveyQuestionFormDescrLabelDialogOpen}
                        title="Erklärungstext"
                        onClose={() => {
                          setSurveyQuestionFormDescrLabelDialogOpen(false);
                        }}
                      >
                        <Typography paragraph>
                          Geben Sie in das Feld “Erklärungstext” optional einen individuellen,
                          erklärenden Text zur Evaluationsfrage ein (z.B. weitere
                          Hintergrundinformationen), der den Evaluationsteilnehmer*innen angezeigt
                          wird.
                        </Typography>
                      </InfoDialog>
                    </Box>
                    <Field
                      component={TextField}
                      type="text"
                      name="explanation"
                      id="explanation"
                      variant="outlined"
                      multiline={true}
                      fullWidth
                    />
                  </FormControl>
                </Grid>
                {!(
                  props.values.type === QuestionKeys.FREE_TEXT ||
                  props.values.type === QuestionKeys.COPY_TEXT
                ) && (
                  <Fragment>
                    <Grid item xs={12} md={4}>
                      <Box className={globalClasses.tooltipBox}>
                        <FormControlLabel
                          className={globalClasses.tooltipText}
                          control={
                            <Field
                              component={Checkbox}
                              type="checkbox"
                              name="additionalAnswerAllowed"
                              color="primary"
                            />
                          }
                          label="Freitextfeld für zusätzliche Kommentare"
                        />
                        <Tooltip title="Info zu “Freitextfeld für zusätzliche Kommentare”">
                          <IconButton
                            className={globalClasses.tooltipIcon}
                            color="primary"
                            aria-label="Info"
                            onClick={() => {
                              setSurveyQuestionFormCommentsLabelDialogOpen(true);
                            }}
                            size="large"
                          >
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                        <InfoDialog
                          open={surveyQuestionFormCommentsLabelDialogOpen}
                          title="Freitextfeld für zusätzliche Kommentare"
                          onClose={() => {
                            setSurveyQuestionFormCommentsLabelDialogOpen(false);
                          }}
                        >
                          <Typography paragraph>
                            Wählen Sie die Option “Freitextfeld für zusätzliche Kommentare”, um bei
                            den Fragetypen “Einfachauswahl” und “Mehrfachauswahl” ein zusätzliches
                            Freitextfeld für die Evaluationsteilnehmer*innen einzublenden.
                          </Typography>
                        </InfoDialog>
                      </Box>
                    </Grid>
                    {props.values.additionalAnswerAllowed && (
                      <Grid item xs={12} md={8}>
                        <FormControl fullWidth>
                          <Box className={globalClasses.tooltipBox}>
                            <FormLabel
                              htmlFor="additionalAnswerLabel"
                              className={globalClasses.tooltipText}
                            >
                              Überschrift des Freitextfeldes für zusätzliche Kommentare
                              <span className="labelInfo">optional</span>
                            </FormLabel>
                            <Tooltip title="Info zu “Überschrift des Freitextfeldes für zusätzliche Kommentare”">
                              <IconButton
                                className={globalClasses.tooltipIcon}
                                color="primary"
                                aria-label="Info"
                                onClick={() => {
                                  setSurveyQuestionFormCommentsHeadlineLabelDialogOpen(true);
                                }}
                                size="large"
                              >
                                <InfoIcon />
                              </IconButton>
                            </Tooltip>
                            <InfoDialog
                              open={surveyQuestionFormCommentsHeadlineLabelDialogOpen}
                              title="Überschrift des Freitextfeldes für zusätzliche Kommentare"
                              onClose={() => {
                                setSurveyQuestionFormCommentsHeadlineLabelDialogOpen(false);
                              }}
                            >
                              <Typography paragraph>
                                Geben Sie in das Feld “Überschrift des Freitextfeldes für
                                zusätzliche Kommentare” optional eine individuelle Überschrift ein,
                                die den Evaluationsteilnehmer*innen über dem Freitextfeld angezeigt
                                wird.
                              </Typography>
                            </InfoDialog>
                          </Box>
                          <Field
                            component={TextField}
                            type="text"
                            name="additionalAnswerLabel"
                            id="additionalAnswerLabel"
                            variant="outlined"
                            fullWidth
                          />
                        </FormControl>
                      </Grid>
                    )}
                  </Fragment>
                )}
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                {props.values.type !== QuestionKeys.FREE_TEXT ? (
                  <FieldArray
                    name="choices"
                    render={(arrayHelpers) => (
                      <Grid item xs={12}>
                        <Typography component="h4" variant="h6" gutterBottom>
                          Antworten
                        </Typography>
                        {props.values?.choices?.map((answer: any, index: number) => (
                          <Box
                            key={`choices${index}`}
                            display="flex"
                            alignItems="flex-end"
                            data-test="formItemAnswer"
                          >
                            <FormControl fullWidth margin="normal">
                              <FormLabel htmlFor={`choices.${index}`}>Antwort</FormLabel>
                              <Field
                                component={TextField}
                                type={
                                  props.values.type === QuestionKeys.SCALE_CHOICE
                                    ? 'number'
                                    : 'text'
                                }
                                name={`choices.${index}`}
                                id={`choices.${index}`}
                                variant="outlined"
                                fullWidth
                                value={answer}
                              />
                            </FormControl>
                            <Box ml={1} mb={1}>
                              <Tooltip title="Weitere Antwortoption hinzufügen">
                                <Button
                                  variant="outlined"
                                  color="grey"
                                  aria-label="Weitere Antwortoption hinzufügen"
                                  className={globalClasses.buttonSquare}
                                  onClick={() => {
                                    arrayHelpers.insert(
                                      index,
                                      props.values.type === QuestionKeys.SCALE_CHOICE ? 0 : ''
                                    );
                                  }}
                                >
                                  <AddIcon />
                                </Button>
                              </Tooltip>
                            </Box>
                            <Box ml={1} mb={1}>
                              <Tooltip title="Entfernen">
                                <Button
                                  variant="outlined"
                                  color="grey"
                                  aria-label="Entfernen"
                                  className={globalClasses.buttonSquare}
                                  onClick={() => {
                                    arrayHelpers.remove(index);
                                  }}
                                >
                                  <SubtractIcon />
                                </Button>
                              </Tooltip>
                            </Box>
                          </Box>
                        ))}
                        <Box mt={1}>
                          <Button
                            variant="outlined"
                            aria-label="Hinzufügen"
                            startIcon={<AddIcon color="primary" />}
                            onClick={() =>
                              arrayHelpers.push(
                                props.values.type === QuestionKeys.SCALE_CHOICE ? 0 : ''
                              )
                            }
                          >
                            Antwortoption hinzufügen
                          </Button>
                        </Box>
                      </Grid>
                    )}
                  />
                ) : (
                  <Grid item xs={12}>
                    <Typography>
                      Bei Freitextfragen werden keine Antwortoptionen benötigt.
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </DialogContent>
            <DialogActions
              classes={{
                root: classes.dialogActions,
              }}
            >
              <Button type="reset" color="primary" size="large" onClick={() => resetHandler()}>
                Abbrechen
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                startIcon={<SaveIcon />}
                disabled={props.isSubmitting}
              >
                Speichern
              </Button>
            </DialogActions>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};

export default SurveyQuestionFormComponent;

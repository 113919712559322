import React, { useEffect, useState, useReducer, Fragment, useMemo } from 'react';
import { Formik, Form, Field, FormikValues, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-mui';
import { IconButton, TextField as MuiTextField, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { Autocomplete } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import { FacilityOption } from '../../models/facilities';
import { Role } from '../../models/roles';
import { ROLES_QUERY } from '../../operations/role';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import useGlobalStyles from '../../hooks/useGlobalStyles';
import {
  ConfirmDialog,
  FormActions,
  InfoDialog,
  ConfirmNavigation,
  FormikContextDirty,
} from '../common';
import SaveIcon from '@mui/icons-material/SaveRounded';
import CancelIcon from '@mui/icons-material/HighlightOffRounded';
import useLoggedInMeFacilities, {
  facilitiesOptionsReducer,
} from '../../hooks/useLoggedInMeFacilities';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InfoIcon from '@mui/icons-material/InfoRounded';
import { Typography } from '@mui/material';
import { isFormDirtyVar } from '../../cache';

interface IProps {
  formData: any;
  submitHandler: (values: FormikValues, formikBag: FormikHelpers<any>) => void;
  children?: React.ReactNode;
}

const UserEditFormComponent: React.FC<IProps> = (props) => {
  const { formData, submitHandler } = props;
  const { classes: globalClasses } = useGlobalStyles();
  let navigate = useNavigate();

  const defaultFacilitiesOptions: FacilityOption[] = useMemo(() => {
    return (
      (formData.facilities?.edges.map((edge: any) => ({
        id: edge.node.id,
        name: edge.node.name,
      })) as FacilityOption[]) || []
    );
  }, [formData]);
  const [openFacilities, setOpenFacilities] = useState<boolean>(false);
  const [facilitiesOptions, dispatchFacilitiesOptions] = useReducer(
    facilitiesOptionsReducer,
    defaultFacilitiesOptions
  );
  const { facilities, loadingFacilities } = useLoggedInMeFacilities(openFacilities);
  useEffect(() => {
    if (loadingFacilities || !openFacilities) {
      return;
    }
    dispatchFacilitiesOptions({
      type: 'reset',
      data: { facilities: defaultFacilitiesOptions },
    });
    dispatchFacilitiesOptions({ type: 'update', data: { facilities } });
  }, [
    openFacilities,
    facilities,
    loadingFacilities,
    dispatchFacilitiesOptions,
    defaultFacilitiesOptions,
  ]);

  const [openRoles, setOpenRoles] = useState<boolean>(false);
  const [rolesOptions, setRolesOptions] = useState<Role[]>([]);
  const [queryRoles, { data: dataRoles, loading: loadingRoles }] = useLazyQuery(ROLES_QUERY);
  useEffect(() => {
    if (loadingRoles || !openRoles) {
      return;
    }
    if (dataRoles?.roles?.edges?.length > 0) {
      setRolesOptions(
        dataRoles.roles.edges.map((edge: any) => ({
          id: edge.node.id,
          name: edge.node.name,
          tenantAdmin: edge.node.tenantAdmin,
        })) as Role[]
      );
    } else {
      queryRoles();
    }
  }, [openRoles, queryRoles, dataRoles, loadingRoles]);

  const isFormDirty = useReactiveVar(isFormDirtyVar);
  const [resetConfirmOpen, setResetConfirmOpen] = useState<boolean>(false);

  const [permissionsDialogOpen, setPermissionsDialogOpen] = useState<boolean>(false);

  return (
    <Fragment>
      <ConfirmNavigation shouldBlock={isFormDirty} />
      <Formik
        initialValues={{
          firstName: formData.firstName || '',
          lastName: formData.lastName || '',
          email: formData.email || '',
          jobTitle: formData.jobTitle || '',
          group: formData.group || '',
          role: formData.role || null,
          facilities: defaultFacilitiesOptions,
          tenantWideEditPermission:
            'tenantWideEditPermission' in formData ? formData.tenantWideEditPermission : true,
        }}
        enableReinitialize
        validationSchema={Yup.object({
          email: Yup.string().email('Keine E-Mail Adresse').required('Pflichtfeld'),
          firstName: Yup.string().required('Pflichtfeld'),
          lastName: Yup.string().required('Pflichtfeld'),
        })}
        onSubmit={(values, formikBag) => {
          isFormDirtyVar(false);
          submitHandler(values, formikBag);
        }}
      >
        {(props) => {
          return (
            <Form autoComplete="off" style={{ width: '100%' }}>
              <FormikContextDirty />
              {props.status && (
                <Box mb={2}>
                  <Alert severity="error">{props.status}</Alert>
                </Box>
              )}
              <Paper component="section" variant="outlined" className={globalClasses.paper}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor="firstName">Vorname</FormLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name="firstName"
                        id="firstName"
                        variant="outlined"
                        fullWidth
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor="lastName">Nachname</FormLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name="lastName"
                        id="lastName"
                        variant="outlined"
                        fullWidth
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor="email">E-Mail Adresse</FormLabel>
                      <Field
                        component={TextField}
                        type="email"
                        name="email"
                        id="email"
                        variant="outlined"
                        fullWidth
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor="role">
                        Benutzerrolle
                        <span className="labelInfo">optional</span>
                      </FormLabel>
                      <Autocomplete
                        id="role"
                        open={openRoles}
                        onOpen={() => {
                          setOpenRoles(true);
                        }}
                        onClose={() => {
                          setOpenRoles(false);
                        }}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        getOptionLabel={(option) => option?.name ?? ''}
                        onChange={(e, value) => {
                          props.setFieldValue('role', value);
                          if (value?.tenantAdmin) {
                            props.setFieldValue('tenantWideEditPermission', true);
                          }
                        }}
                        options={rolesOptions}
                        value={props.values.role}
                        loading={loadingRoles}
                        renderInput={(params) => (
                          <MuiTextField
                            type="text"
                            name="role"
                            variant="outlined"
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {loadingRoles ? (
                                    <CircularProgress color="inherit" size={20} />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor="jobTitle">
                        Position
                        <span className="labelInfo">optional</span>
                      </FormLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name="jobTitle"
                        id="jobTitle"
                        variant="outlined"
                        fullWidth
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor="facilities">
                        Einrichtungen
                        <span className="labelInfo">optional</span>
                      </FormLabel>
                      <Autocomplete
                        id="facilities"
                        multiple
                        filterSelectedOptions
                        open={openFacilities}
                        onOpen={() => {
                          setOpenFacilities(true);
                        }}
                        onClose={() => {
                          setOpenFacilities(false);
                        }}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        getOptionLabel={(option) => option?.name ?? ''}
                        onChange={(e, value) => {
                          props.setFieldValue('facilities', value);
                        }}
                        options={facilitiesOptions}
                        value={props.values.facilities}
                        loading={loadingFacilities}
                        renderInput={(params) => (
                          <MuiTextField
                            type="text"
                            name="facilities"
                            variant="outlined"
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {loadingFacilities ? (
                                    <CircularProgress color="inherit" size={20} />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor="group">
                        Gruppe
                        <span className="labelInfo">optional</span>
                      </FormLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name="group"
                        id="group"
                        variant="outlined"
                        fullWidth
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <Box className={globalClasses.tooltipBox}>
                        <FormLabel
                          component="div"
                          focused={false}
                          className={globalClasses.tooltipText}
                        >
                          Erweiterte Berechtigungen
                        </FormLabel>
                        <Tooltip title="Info zu “Erweiterte Berechtigungen“">
                          <IconButton
                            className={globalClasses.tooltipIcon}
                            color="primary"
                            aria-label="Info"
                            onClick={() => setPermissionsDialogOpen(true)}
                            size="large"
                          >
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                        <InfoDialog
                          open={permissionsDialogOpen}
                          title={`Erweiterte Berechtigungen`}
                          onClose={() => setPermissionsDialogOpen(false)}
                        >
                          <Typography paragraph>
                            Sie haben die Möglichkeit, für jeden einzelnen Benutzer festzulegen, ob
                            die Berechtigungen der Benutzerrolle oberhalb der Berechtigungsstufe
                            “Lesen” auch für Datensätze greifen sollen, die der Trägerorganisation
                            zugeordnet sind. Setzen Sie das Häkchen neben
                            “Benutzerrollenberechtigungen auf Daten der Trägerorganisation
                            anwenden”, wenn Sie dem jeweiligen Benutzer erlauben möchten, auch Daten
                            auf der Trägerebene gemäß seiner Benutzerrolle zu bearbeiten, zu
                            erstellen, zu löschen oder zu veröffentlichen.
                          </Typography>
                          <Typography paragraph>
                            Bitte beachten Sie: Sieht eine Benutzerrolle Leseberechtigungen vor,
                            gelten diese AUTOMATISCH für Daten auf der Trägerebene, ohne dass Sie
                            das Häkchen bei “Benutzerrollenberechtigungen auf Daten der
                            Trägerorganisation anwenden” setzen müssen. Wählen Sie diese Option nur
                            dann, wenn Sie einem Benutzer auch die über die Berechtigungsstufe
                            “Lesen” hinausgehenden Berechtigungsstufen (“Bearbeiten”,
                            “Erstellen/Kopieren”, “Löschen”, “Veröffentlichen”) für Daten der
                            Trägerorganisation geben möchten.
                          </Typography>
                        </InfoDialog>
                      </Box>

                      <FormControlLabel
                        control={
                          <Field
                            component={Checkbox}
                            type="checkbox"
                            name="tenantWideEditPermission"
                            id="tenantWideEditPermission"
                            color="primary"
                          />
                        }
                        checked={
                          props.values.tenantWideEditPermission || props.values.role?.tenantAdmin
                        }
                        onChange={(event: React.ChangeEvent<any>) => {
                          const tenantWideEditPermission = event.target.checked;
                          props.setFieldValue('tenantWideEditPermission', tenantWideEditPermission);
                        }}
                        disabled={props.values.role?.tenantAdmin}
                        label={
                          'Benutzerrollenberechtigungen auf Daten der Trägerorganisation anwenden'
                        }
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Paper>
              <FormActions>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  startIcon={<SaveIcon />}
                  disabled={props.isSubmitting}
                >
                  Änderungen speichern
                </Button>
                <Button
                  type="button"
                  variant="outlined"
                  size="large"
                  color="primary"
                  startIcon={<CancelIcon />}
                  onClick={() => {
                    if (isFormDirty) {
                      setResetConfirmOpen(true);
                    } else {
                      navigate(-1);
                    }
                  }}
                >
                  Abbrechen
                </Button>
              </FormActions>
              <ConfirmDialog
                open={resetConfirmOpen}
                title={`Änderungen verwerfen`}
                content={`Wollen Sie die vorgenommenen Änderungen im Formular verwerfen?`}
                onClose={(confirm) => {
                  setResetConfirmOpen(false);
                  if (confirm) {
                    props.handleReset();
                    isFormDirtyVar(false);
                    navigate(-1);
                  }
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </Fragment>
  );
};

export default UserEditFormComponent;

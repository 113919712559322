import { QualityManagementTaskAppointmentsDeleteRangeKeys } from '../../models/qualityManagementTaskAppointments';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useMutation, useReactiveVar } from '@apollo/client';
import {
  REMOVE_QUALITYMANAGEMENTTASK_APPOINTMENT_MUTATION,
  REMOVE_QUALITYMANAGEMENTTASK_MUTATION,
  UPDATE_QUALITYMANAGEMENTTASK_MUTATION,
} from '../../operations/qualityManagementTask';
import { routes } from '../../models/routes';
import { parseUuidFromId } from '../../utils/helper';
import dayjs from 'dayjs';
import { qualityManagementTasksCalendarDisplayVar } from '../../cache';

export default function useQualityManagementTaskRemoveHandler(
  qualityManagementTaskId: string | null,
  qualityManagementTaskAppointmentId: string | null,
  qualityManagementTaskAppointmentsRange: QualityManagementTaskAppointmentsDeleteRangeKeys,
  qualityManagementTaskAppointmentsDate: string | null,
  update: () => void
) {
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const qualityManagementTasksCalendarDisplay = useReactiveVar(
    qualityManagementTasksCalendarDisplayVar
  );

  const [removeQualityManagementTaskMutation] = useMutation(REMOVE_QUALITYMANAGEMENTTASK_MUTATION, {
    onCompleted({ removeQualityManagementTask }) {
      if (removeQualityManagementTask) {
        enqueueSnackbar('QM-Aufgabe erfolgreich gelöscht', {
          variant: 'success',
        });
        if (qualityManagementTasksCalendarDisplay) {
          navigate(routes['QUALITYMANAGEMENTTASKS_CALENDAR'].path);
        } else {
          navigate(routes['QUALITYMANAGEMENTTASKS'].path);
        }
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten', {
          variant: 'warning',
        });
      }
    },
    onError(error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  const [removeQualityManagementTaskAppointmentsMutation] = useMutation(
    REMOVE_QUALITYMANAGEMENTTASK_APPOINTMENT_MUTATION,
    {
      onCompleted({ removeQualityManagementTask }) {
        if (removeQualityManagementTask) {
          enqueueSnackbar('QM-Aufgabentermin erfolgreich gelöscht', {
            variant: 'success',
          });
          if (qualityManagementTasksCalendarDisplay) {
            navigate(routes['QUALITYMANAGEMENTTASKS_CALENDAR'].path);
          } else {
            navigate(routes['QUALITYMANAGEMENTTASKS'].path);
          }
        } else {
          enqueueSnackbar('Es ist ein Fehler aufgetreten', {
            variant: 'warning',
          });
        }
      },
      onError(error) {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      },
    }
  );

  const [updateQualityManagementTaskMutation] = useMutation(UPDATE_QUALITYMANAGEMENTTASK_MUTATION, {
    onCompleted({ updateQualityManagementTask }) {
      if (updateQualityManagementTask) {
        enqueueSnackbar('QM-Aufgabe erfolgreich aktualisiert', {
          variant: 'success',
        });
        if (qualityManagementTasksCalendarDisplay) {
          navigate(routes['QUALITYMANAGEMENTTASKS_CALENDAR'].path);
        } else {
          navigate(routes['QUALITYMANAGEMENTTASKS'].path);
        }
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten', {
          variant: 'warning',
        });
      }
    },
    onError(error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  return async () => {
    if (!qualityManagementTaskId?.length) {
      return false;
    }

    if (
      qualityManagementTaskAppointmentId?.length &&
      qualityManagementTaskAppointmentsRange ===
        QualityManagementTaskAppointmentsDeleteRangeKeys.SINGLE
    ) {
      try {
        await removeQualityManagementTaskAppointmentsMutation({
          variables: {
            input: {
              id: qualityManagementTaskId,
              deleteOnly: parseUuidFromId(qualityManagementTaskAppointmentId),
            },
          },
        });
      } catch (e) {
        console.error(e);
      } finally {
        update();
      }
    } else if (
      qualityManagementTaskAppointmentId?.length &&
      qualityManagementTaskAppointmentsRange ===
        QualityManagementTaskAppointmentsDeleteRangeKeys.PENDING &&
      qualityManagementTaskAppointmentsDate
    ) {
      try {
        await updateQualityManagementTaskMutation({
          variables: {
            input: {
              id: qualityManagementTaskId,
              endDate: dayjs(qualityManagementTaskAppointmentsDate)
                .subtract(1, 'day')
                .format('YYYY-MM-DD'),
            },
          },
        });
      } catch (e) {
        console.error(e);
      } finally {
        update();
      }
    } else {
      try {
        await removeQualityManagementTaskMutation({
          variables: {
            input: {
              id: qualityManagementTaskId,
            },
          },
        });
      } catch (e) {
        console.error(e);
      } finally {
        update();
      }
    }
  };
}

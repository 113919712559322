import { useSnackbar } from 'notistack';
import { gql, useMutation, useReactiveVar } from '@apollo/client';
import { loggedInMeVar } from '../../cache';
import { CREATE_DOCUMENT_MUTATION, FRAGMENT_DOCUMENT_BASE } from '../../operations/documents';
import {
  CREATE_DOCUMENTVERSION_MUTATION,
  FRAGMENT_DOCUMENTVERSION_BASE,
} from '../../operations/documentVersion';

export default function useDocumentBulkSubmitHandler() {
  const { enqueueSnackbar } = useSnackbar();
  const loggedInMe = useReactiveVar(loggedInMeVar);

  const [createDocumentMutation] = useMutation(CREATE_DOCUMENT_MUTATION, {
    onCompleted({ createDocument }) {
      if (!createDocument) {
        enqueueSnackbar('Es ist ein Fehler aufgetreten', {
          variant: 'warning',
        });
      }
    },
    onError(error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
    update(cache, { data: { createDocument } }) {
      cache.modify({
        fields: {
          documents: (existingItemsRefs, { readField }) => {
            const totalCount: number = readField('totalCount', existingItemsRefs) || 0;
            const newItemNodeRef = cache.writeFragment({
              data: {
                ...createDocument?.document,
              },
              fragment: gql`
                fragment DocumentNew on Document {
                  ...DocumentBase
                }
                ${FRAGMENT_DOCUMENT_BASE}
              `,
              fragmentName: 'DocumentNew',
            });
            const newItemEdge = {
              node: newItemNodeRef,
            };
            return {
              ...existingItemsRefs,
              totalCount: totalCount + 1,
              edges: [...existingItemsRefs.edges, newItemEdge],
            };
          },
        },
      });
    },
  });

  const [createDocumentVersionMutation] = useMutation(CREATE_DOCUMENTVERSION_MUTATION, {
    onCompleted({ createDocumentVersion }) {
      if (!createDocumentVersion) {
        enqueueSnackbar('Es ist ein Fehler aufgetreten', {
          variant: 'warning',
        });
      }
    },
    onError(error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
    update(cache, { data: { createDocumentVersion } }) {
      cache.modify({
        fields: {
          documentVersions: (existingItemsRefs, { readField }) => {
            const totalCount: number = readField('totalCount', existingItemsRefs) || 0;
            const newItemNodeRef = cache.writeFragment({
              data: {
                ...createDocumentVersion?.documentVersion,
              },
              fragment: gql`
                fragment DocumentVersionNew on DocumentVersion {
                  ...DocumentVersionBase
                }
                ${FRAGMENT_DOCUMENTVERSION_BASE}
              `,
              fragmentName: 'DocumentVersionNew',
            });
            const newItemEdge = {
              node: newItemNodeRef,
            };
            return {
              ...existingItemsRefs,
              totalCount: totalCount + 1,
              edges: [...existingItemsRefs.edges, newItemEdge],
            };
          },
        },
      });
    },
  });

  return async (data: { [k: string]: any }): Promise<boolean> => {
    try {
      const inputData: { [k: string]: string | null | boolean } = {
        title: data.title,
        description: data.description ?? '',
        tenant: loggedInMe?.tenant?.id ?? null,
        createdBy: loggedInMe?.id ?? null,
      };
      if (data.facility?.id) {
        inputData.facility = data.facility.id;
      }
      if (data.tags && data.tags.length) {
        inputData.tags = data.tags.map((item: any) => item.id);
      }
      const { data: createDocumentData } = await createDocumentMutation({
        variables: {
          input: {
            ...inputData,
          },
        },
      });

      const documentId = await createDocumentData.createDocument?.document?.id;

      await createDocumentVersionMutation({
        variables: {
          input: {
            document: documentId,
            file: data.file,
            version: 1,
            createdBy: loggedInMe?.id ?? null,
            versionNumber: data.versionNumber || undefined,
            mostRecentEditBy: data.mostRecentEditBy || undefined,
            revisionDate: data.revisionDate ?? null,
            checkedBy: data.checkedBy || undefined,
            checkedDate: data.checkedDate ?? null,
            publishedBy: data.publishedBy || undefined,
            publishDate: data.publishDate ?? null,
            revisionChangelog: data.revisionChangelog || undefined,
          },
        },
      });

      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  };
}

import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Formik, Form, Field, FormikValues, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { Select, TextField } from 'formik-mui';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import useGlobalStyles from '../../hooks/useGlobalStyles';
import {
  ConfirmDialog,
  FormActions,
  FormikContextDirty,
  InfoDialog,
  ConfirmNavigation,
} from '../common';
import SaveIcon from '@mui/icons-material/SaveRounded';
import CancelIcon from '@mui/icons-material/HighlightOffRounded';
import { FacilityStatusLabels } from '../../models/facilities';
import { Autocomplete, TextField as MuiTextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { USERS_QUERY } from '../../operations/user';
import { UserNode, UserOption } from '../../models/users';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/InfoRounded';
import Typography from '@mui/material/Typography';
import { qualityManagementTaskStatusOptions } from '../../models/qualityManagementTasks';
import {
  QualityManagementTaskAppointment,
  QualityManagementTaskAppointmentNode,
} from '../../models/qualityManagementTaskAppointments';
import { DatePicker } from 'formik-mui-x-date-pickers';
import { isFormDirtyVar } from '../../cache';
import dayjs from 'dayjs';

const usersAll = 99999;

interface Props {
  formData: any;
  appointmentId?: string;
  submitHandler: (values: FormikValues, formikBag: FormikHelpers<any>) => void;
  children?: React.ReactNode;
}

const QualityManagementTaskEditFormComponent: React.FC<Props> = (props) => {
  const { formData, appointmentId, submitHandler } = props;
  const { classes: globalClasses } = useGlobalStyles();
  let navigate = useNavigate();

  const labelTenantWide = useMemo(() => {
    return formData?.tenant?.name ?? FacilityStatusLabels.FACILITY_TENANTWIDE;
  }, [formData]);

  const appointment: QualityManagementTaskAppointment | null = useMemo(() => {
    if (appointmentId) {
      return (
        formData?.qualityManagementTaskAppointments?.edges?.find(
          (edge: QualityManagementTaskAppointmentNode) => edge.node.id === appointmentId
        )?.node ?? null
      );
    }
    return formData?.qualityManagementTaskAppointments?.edges?.[0]?.node ?? null;
  }, [formData, appointmentId]);

  const assignedUsersOptions: UserOption[] = useMemo(() => {
    let assignedUsers: UserOption[] = [];
    if (appointment?.assignedUsers?.edges && appointment.assignedUsers.edges.length > 0) {
      assignedUsers =
        appointment.assignedUsers.edges.map(({ node }: UserNode) => ({
          id: node.id,
          name: `${node.firstName} ${node.lastName}`,
        })) || [];
    }
    if (
      assignedUsers.length === 0 &&
      formData?.assignedUsers?.edges &&
      formData.assignedUsers.edges.length > 0
    ) {
      assignedUsers =
        formData.assignedUsers.edges.map(({ node }: UserNode) => ({
          id: node.id,
          name: `${node.firstName} ${node.lastName}`,
        })) || [];
    }
    return assignedUsers;
  }, [formData, appointment]);
  const defaultUsersOptions: UserOption[] = useMemo(
    () => [...assignedUsersOptions],
    [assignedUsersOptions]
  );
  const [openUsers, setOpenUsers] = useState<boolean>(false);
  const [usersOptions, setUsersOptions] = useState<UserOption[]>(defaultUsersOptions);
  const [queryUsers, { data: dataUsers, loading: loadingUsers }] = useLazyQuery(USERS_QUERY, {
    fetchPolicy: 'network-only',
  });
  useEffect(() => {
    if (!openUsers) {
      return;
    }
    if (formData.facility?.id) {
      // @ts-ignore
      queryUsers({ variables: { first: usersAll, containsAnyFacility: [formData.facility?.id] } });
    } else {
      queryUsers({ variables: { first: usersAll } });
    }
  }, [openUsers, formData, queryUsers]);
  useEffect(() => {
    if (loadingUsers || !dataUsers?.users?.edges) {
      return;
    }
    const newUsersOptions = dataUsers.users.edges
      .filter(
        ({ node }: UserNode) =>
          !assignedUsersOptions.some((userOption: UserOption) => node.id === userOption.id)
      )
      .map(({ node }: UserNode) => ({
        id: node.id,
        name: `${node.firstName} ${node.lastName}`,
      })) as UserOption[];
    setUsersOptions([...defaultUsersOptions, ...newUsersOptions]);
  }, [assignedUsersOptions, defaultUsersOptions, dataUsers, loadingUsers]);

  const isFormDirty = useReactiveVar(isFormDirtyVar);
  const [resetConfirmOpen, setResetConfirmOpen] = useState<boolean>(false);
  const [dueDateLabelDialogOpen, setDueDateLabelDialogOpen] = useState<boolean>(false);
  const [statusLabelDialogOpen, setStatusLabelDialogOpen] = useState<boolean>(false);

  const [
    qualityManagementTaskFacilityLabelDialogOpen,
    setQualityManagementTaskFacilityLabelDialogOpen,
  ] = useState<boolean>(false);
  const [roleLabelDialogOpen, setRoleLabelDialogOpen] = useState<boolean>(false);

  return (
    <Fragment>
      <ConfirmNavigation shouldBlock={isFormDirty} />
      <Formik
        initialValues={{
          title: appointment?.title ?? formData?.title ?? '',
          description: appointment?.description ?? formData?.description ?? '',
          state: appointment?.stateName ?? null,
          appointmentDate: appointment?.appointmentDate ?? null,
          assignedUsers: assignedUsersOptions,
          appointmentId: appointment?.id ?? null,
        }}
        enableReinitialize
        validationSchema={Yup.object({
          title: Yup.string().required('Pflichtfeld'),
          description: Yup.string().required('Pflichtfeld'),
        })}
        onSubmit={(values, formikBag) => {
          // Note: Adjust DateTimePicker timezone problem
          const appointmentDate = values.appointmentDate
            ? dayjs(values.appointmentDate).utc().local().format('YYYY-MM-DD')
            : null;
          const data = {
            ...values,
            appointmentDate: appointmentDate,
          };
          isFormDirtyVar(false);
          submitHandler(data, formikBag);
        }}
      >
        {(props) => {
          return (
            <Form autoComplete="off" style={{ width: '100%' }}>
              <FormikContextDirty />
              {props.status && (
                <Box mb={2}>
                  <Alert severity="error">{props.status}</Alert>
                </Box>
              )}
              <Paper component="section" variant="outlined" className={globalClasses.paper}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor="title">Titel/Name</FormLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name="title"
                        id="title"
                        variant="outlined"
                        fullWidth
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <FormLabel htmlFor="description">Inhalt/Text</FormLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name="description"
                        id="description"
                        variant="outlined"
                        fullWidth
                        multiline
                        maxRows={6}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <Box className={globalClasses.tooltipBox}>
                        <FormLabel htmlFor="infoFacility" className={globalClasses.tooltipText}>
                          Träger/Einrichtung:
                        </FormLabel>
                        <Tooltip title="Info zu “Träger/Einrichtung”">
                          <IconButton
                            className={globalClasses.tooltipIcon}
                            color="primary"
                            aria-label="Info"
                            onClick={() => {
                              setQualityManagementTaskFacilityLabelDialogOpen(true);
                            }}
                            size="large"
                          >
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                        <InfoDialog
                          open={qualityManagementTaskFacilityLabelDialogOpen}
                          title={`Träger/Einrichtung`}
                          onClose={() => {
                            setQualityManagementTaskFacilityLabelDialogOpen(false);
                          }}
                        >
                          <Typography paragraph>
                            Hier wird Ihnen diejenige Einrichtung bzw. die Trägerorganisaton
                            angezeigt, der die QM-Aufgabe zugeordnet ist. Diese Zuordnung lässt sich
                            nachträglich nicht mehr ändern.
                          </Typography>
                          <Typography paragraph>
                            Um die QM-Aufgabe einer anderen Einrichtung zuzuordnen, erstellen Sie
                            bitte einen neuen Datensatz mit der korrekten Einrichtungszuordnung.
                            Löschen Sie ggf. die falsch zugeordnete QM-Aufgabe im Anschluss.
                          </Typography>
                        </InfoDialog>
                      </Box>
                      <MuiTextField
                        type="text"
                        id="infoFacility"
                        variant="outlined"
                        fullWidth
                        disabled
                        defaultValue={formData?.facility?.name ?? labelTenantWide}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <Box className={globalClasses.tooltipBox}>
                        <FormLabel htmlFor="assignedUsers" className={globalClasses.tooltipText}>
                          Zuständig
                          <span className="labelInfo">optional</span>
                        </FormLabel>
                        <Tooltip title="Info zu “Zuständig”">
                          <IconButton
                            className={globalClasses.tooltipIcon}
                            color="primary"
                            aria-label="Info"
                            onClick={() => {
                              setRoleLabelDialogOpen(true);
                            }}
                            size="large"
                          >
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                        <InfoDialog
                          open={roleLabelDialogOpen}
                          title={`Zuständig`}
                          onClose={() => {
                            setRoleLabelDialogOpen(false);
                          }}
                        >
                          <Typography paragraph>
                            Wählen Sie hier optional Benutzer aus, die für die Umsetzung der
                            QM-Aufgabe zuständig sind.
                          </Typography>
                          <Typography paragraph>
                            Bitte beachten Sie, dass Sie hier nur diejenigen Benutzer auswählen
                            können, die eine Berechtigung für die ausgewählte Einrichtung haben. Die
                            hier ausgewählten Benutzer erhalten 7 Tage vor Fälligkeit, 3 Tage vor
                            Fälligkeit sowie am eingestellten Fälligkeitsdatum eine
                            Erinnerungsnachricht.
                          </Typography>
                          <Typography paragraph>
                            Sie können die Zuständigkeit nachträglich verändern.
                          </Typography>
                        </InfoDialog>
                      </Box>
                      <Autocomplete
                        id="assignedUsers"
                        multiple
                        filterSelectedOptions
                        open={openUsers}
                        onOpen={() => {
                          setOpenUsers(true);
                        }}
                        onClose={() => {
                          setOpenUsers(false);
                        }}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        getOptionLabel={(option) => option?.name ?? ''}
                        onChange={(e, value) => {
                          props.setFieldValue('assignedUsers', value);
                        }}
                        options={usersOptions}
                        value={props.values.assignedUsers}
                        loading={loadingUsers}
                        renderInput={(params) => (
                          <MuiTextField
                            type="text"
                            name="assignedUsers"
                            variant="outlined"
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {loadingUsers ? (
                                    <CircularProgress color="inherit" size={20} />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <Box className={globalClasses.tooltipBox}>
                        <FormLabel htmlFor="appointmentDate" className={globalClasses.tooltipText}>
                          Fälligkeitsdatum
                        </FormLabel>
                        <Tooltip title="Info zu “Fälligkeitsdatum”">
                          <IconButton
                            className={globalClasses.tooltipIcon}
                            color="primary"
                            aria-label="Info"
                            onClick={() => {
                              setDueDateLabelDialogOpen(true);
                            }}
                            size="large"
                          >
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                        <InfoDialog
                          open={dueDateLabelDialogOpen}
                          title={`Fälligkeitsdatum`}
                          onClose={() => {
                            setDueDateLabelDialogOpen(false);
                          }}
                        >
                          <Typography paragraph>
                            Wählen Sie hier das Datum aus, bis zu dem die QM-Aufgabe umgesetzt
                            werden soll. Sie können das Fälligkeitsdatum der QM-Aufgabe jederzeit
                            aktualisieren. Ist der QM-Aufgabe ein zuständiger Benutzer zugeordnet,
                            erhält dieser eine Erinnerungsnachricht 7 Tage vor Fälligkeit, 3 Tage
                            vor Fälligkeit sowie am eingestellten Fälligkeitsdatum. Bitte beachten
                            Sie: Gehört die ausgewählte QM-Aufgabe zu einer Terminserie, ändern Sie
                            hier lediglich das Fälligkeitsdatum der aktuell ausgewählten QM-Aufgabe.
                            Die übrigen Fälligkeitstermine der Terminserie bleiben von der Änderung
                            unbetroffen.
                          </Typography>
                        </InfoDialog>
                      </Box>
                      <Field
                        component={DatePicker}
                        inputVariant="outlined"
                        fullWidth
                        name="appointmentDate"
                        id="appointmentDate"
                        inputFormat="DD.MM.YYYY"
                        mask="__.__.____"
                        disablePast={true}
                        inputProps={{
                          placeholder: 'TT.MM.JJJJ',
                        }}
                      />
                    </FormControl>
                  </Grid>
                  {props.values.state && (
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth>
                        <Box className={globalClasses.tooltipBox}>
                          <FormLabel htmlFor="state" className={globalClasses.tooltipText}>
                            Status
                          </FormLabel>
                          <Tooltip title="Info zu “Status”">
                            <IconButton
                              className={globalClasses.tooltipIcon}
                              color="primary"
                              aria-label="Info"
                              onClick={() => {
                                setStatusLabelDialogOpen(true);
                              }}
                              size="large"
                            >
                              <InfoIcon />
                            </IconButton>
                          </Tooltip>
                          <InfoDialog
                            open={statusLabelDialogOpen}
                            title={`Status`}
                            onClose={() => {
                              setStatusLabelDialogOpen(false);
                            }}
                          >
                            <Typography paragraph>
                              Wählen Sie hier einen Status für die QM-Aufgabe aus. Der Status zeigt
                              Ihnen und allen weiteren berechtigten Benutzern an, in welchem
                              Bearbeitungsstadium sich die jeweilige QM-Aufgabe befindet. Der Status
                              einer QM-Aufgabe kann jederzeit geändert werden.
                            </Typography>
                          </InfoDialog>
                        </Box>
                        <Field component={Select} name="state" id="state" variant="outlined">
                          {qualityManagementTaskStatusOptions.map((option) => (
                            <MenuItem key={`status-${option.value}`} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Field>
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
              </Paper>
              <FormActions>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  startIcon={<SaveIcon />}
                  disabled={props.isSubmitting}
                >
                  Speichern
                </Button>
                <Button
                  type="button"
                  variant="outlined"
                  size="large"
                  color="primary"
                  startIcon={<CancelIcon />}
                  onClick={() => {
                    if (isFormDirty) {
                      setResetConfirmOpen(true);
                    } else {
                      navigate(-1);
                    }
                  }}
                >
                  Abbrechen
                </Button>
              </FormActions>
              <ConfirmDialog
                open={resetConfirmOpen}
                title={`Änderungen verwerfen`}
                content={`Wollen Sie die vorgenommenen Änderungen im Formular verwerfen?`}
                onClose={(confirm) => {
                  setResetConfirmOpen(false);
                  if (confirm) {
                    props.handleReset();
                    isFormDirtyVar(false);
                    navigate(-1);
                  }
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </Fragment>
  );
};

export default QualityManagementTaskEditFormComponent;
